import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import "./PanelUser.css";

const PanelUploadImage = () => {
  return (
    <>
      <div className="cardUser">
        <div className="cardUser1">
          <p
            style={{
              fontWeight: 400,
              fontFamily: "Oswald",
              fontSize: "24px",
              marginTop: "25px",
            }}
          >
            Subir logotipo
          </p>
          <CloudUploadIcon
            className="iconUser"
            sx={{
              width: "60px",
              height: "60px",
              color: "gray",
              marginTop: "10px",
            }}
          />
          <p className="smallUser">
            Permite subir una imagen como logotipo de la empresa
          </p>
          <div className="go-cornerUser" href="#">
            <div className="go-arrowUser">
              <CloudUploadIcon sx={{ paddingTop: "5px" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PanelUploadImage;
