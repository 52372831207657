import { useMutation,  useQueryClient } from "react-query";
import axios from 'axios';

const editProduct = async (payload) => {
    const { data } = await axios.put(
      process.env.REACT_APP_API + "/api/v1/billing/product/update/" + payload.id,
      payload,
      {
        headers: {
          "Content-type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return data;
}

export const useEditProduct = () => {
  const queryClient = useQueryClient();
    return useMutation({
      mutationFn: editProduct,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get-products"] });
      },
    });
}