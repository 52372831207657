import { useMutation, useQueryClient } from "react-query";
import axios from 'axios';

const editComputarize = async (payload) => {
  const { data } = await axios.put(
    process.env.REACT_APP_API + "/api/v1/billing/business/"+payload.id,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    },
  );
  return data;
};

export const useEditComputarize = () =>{
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: editComputarize,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get-company"] });
      },
    });
}

