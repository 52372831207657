import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import StoreIcon from "@mui/icons-material/Store";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import Select from "react-select";
import Image from "../assets/img/Qhatubit3.png";
import * as Yup from "yup";
import axios from "axios";
import "./SelectPointOfSale.css";
import CustomDropdownPOV from "../components/style/CustomDropdownPOV";
import handleError from '../components/utilities/HandleError';

const SelectPointOfSale = () => {
  const navigation = useNavigate();

  const [pointOfSaleList, setPointOfSaleList] = useState([]);

  const handleChangePOV = (e) => {
    formikPointOfSale.setFieldValue("pointOfSaleId", e.value);
    localStorage.setItem("nombrePuntoVenta", e.label);
  };
  const handleClose = async () => {
    formikPointOfSale.resetForm();
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("expiresIn");
    window.localStorage.removeItem("isLoggedIn");
    window.localStorage.removeItem("pointOfSaleId");
    navigation("/dashboard/statistics/invoice");
  };

  const cuis = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/siat/generateCuis",
        params: {
          pointOfSaleId: localStorage.getItem("pointOfSaleId"),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      getCufd();
    } catch (error) {
      if (error.response.status === 504) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Lo sentimos!",
          text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }        
      if (error.response.status === 503) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ocurrió un problema!",
          text: error.response.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }      
      if (error.response.status === 500) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ocurrió un problema!",
          text: "Por favor comuniquese con el administrador",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status === 404) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ocurrió un problema!",
          text: "El CUIS no fue encontrado, por favor comuniquese con el administrador",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status === 403) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ocurrió un problema!",
          text: error.response.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status === 400) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ocurrió un problema!",
          text: error.response.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.request.status >= 0 && error.request.status <= 99) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: "Compruebe su conexión a internet",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }      
    }
  };

  const getCufd = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/siat/generateCufd",
        params: {
          pointOfSaleId: parseInt(localStorage.getItem("pointOfSaleId")),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
    } catch (error) {
      if (
        error.response.status === 400 && error.response.data.message === "CODIGO UNICO DE INICIO DE SISTEMA (CUIS) INVALIDO") {
        cuis();
      }
      else{
        handleError(error);
      }
    }
  };

  const configUser = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/auth/me",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      localStorage.setItem("role", response.data.roles[0].roleName);
      localStorage.setItem("modality", response.data.modality);
      localStorage.setItem("fileLogo", response.data.fileLogo);
      localStorage.setItem("mostrarLogo", response.data.mostrarLogo);
      localStorage.setItem("user", response.data.name);
      localStorage.setItem("sector", response.data.sector);
      await saveConfig(response.data.settingUser);
      await savePointOfSale(response.data.pointOfSaleUser);
    } catch (error) {
      if (error.response.status === 504) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Lo sentimos!",
          text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }       
      if (error.response.status === 503) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ocurrió un problema!",
          text: error.response.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }      
      if (error.response.status === 500) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ocurrió un problema!",
          text: "Por favor comuniquese con el administrador",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status === 404) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ocurrió un problema!",
          text: "No se pudo recuperar la información del usuario",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status === 403) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ocurrió un problema!",
          text: error.response.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status === 400) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ocurrió un problema!",
          text: error.response.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.request.status >= 0 && error.request.status <= 99) {
        Swal.fire({
          customClass: {
            container: "codigoCUFC",
            title: "codigoTitlteCUFC",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: "Compruebe su conexión a internet",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }      
    }
  };
  const saveConfig = async (config) => {
    const response = await config.map((e) => {
      localStorage.setItem("pointOfSaleId", e.pointOfSaleId);
    });
  };
  const savePointOfSale = async (config) => {
    setPointOfSaleList(
      await config.map((sup) => ({
        label: sup.nombrePuntoVenta,
        value: sup.pointOfSaleId,
      }))
    );
  };

  useEffect(() => {
    configUser();
  }, []);

  const formikPointOfSale = useFormik({
    initialValues: {
      pointOfSaleId: "",
    },
    validationSchema: Yup.object({
      pointOfSaleId: Yup.string().required("El campo es requerido"),
    }),
    onSubmit: async (data) => {
      localStorage.setItem("pointOfSaleId", data.pointOfSaleId);
      cuis();
      formikPointOfSale.resetForm();
      navigation("/dashboard/statistics/invoice");
    },
  });
  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Box
        className="panelLogoPov"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "240px",
          height: "260px",
          boxShadow: "5px 5px 15px black",
          backgroundColor: "black",
        }}
      >
        <img src={Image} alt="" width={"200px"} height={"200px"} />
      </Box>
      <Box
        className="panelFormPov"
        sx={{
          width: "380px",
          height: "260px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "15px",
          boxShadow: "5px 5px 15px black",
          backgroundColor: `rgba(202, 182, 118, 0.2)`,
        }}
      >
        <form onSubmit={formikPointOfSale.handleSubmit}>
          <Box
            className="containerFormPov"
            sx={{
              width: "290px",
              height: "420px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              className="logoIconPov"
              sx={{
                display: "none",
                width: "80px",
                height: "80px",
                backgroundColor: "#0f0f0f",
                borderRadius: "50%",
                border: "2px solid white",
              }}
            >
              <StoreIcon
                className="iconStore"
                sx={{ color: "white", fontSize: "124px" }}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} align={"center"}>
                <Typography
                  className="labelPointOfSale"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "28px",
                    color: "white",
                    marginBottom: "5px",
                  }}
                >
                  Punto de venta
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  {/* <FormControl fullWidth> */}
                    <Select
                      styles={CustomDropdownPOV}
                      isSearchable={true}
                      options={pointOfSaleList}
                      placeholder="Seleccione una opción"
                      onChange={(e) => handleChangePOV(e)}
                    />
                  {/* </FormControl> */}
                  <TextField
                    sx={{ display: "none" }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="pointOfSaleId"
                    onBlur={formikPointOfSale.handleBlur}
                    onChange={formikPointOfSale.handleChange}
                    value={formikPointOfSale.values.pointOfSaleId}
                  />
                  <FormHelperText
                    className="labelErrorPov"
                    sx={{
                      color: "yellow",
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  >
                    {formikPointOfSale.touched.pointOfSaleId &&
                      formikPointOfSale.errors.pointOfSaleId}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={6} md={6}>
                <button
                  className="buttonCancelPov"
                  style={{ width: "100%", padding: "5px" }}
                  onClick={() => handleClose()}
                  variant="outlined"
                >
                  <Typography
                    className="labelCancelButtonPov"
                    sx={{
                      fontFamily: "Oswald",
                      fontSize: "16px",
                      color: "black",
                    }}
                  >
                    Cancelar
                  </Typography>
                </button>
              </Grid>
              <Grid item xs={6} md={6}>
                <button
                  className="buttonSelectPov"
                  style={{
                    width: "100%",
                    padding: "5px",
                    backgroundColor: "black",
                  }}
                  type="submit"
                  variant="contained"
                >
                  <Typography
                    className="labelButtonPov"
                    sx={{
                      fontFamily: "Oswald",
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    Aceptar
                  </Typography>
                </button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </div>
  );
};
export default SelectPointOfSale;
