import React from "react";
import { Box } from "@mui/material";
import DataTableUserPov from "../components/DataTableUserPov";
import {useParams} from 'react-router-dom'

const UserPov = () => {
  const params = useParams()
  return (
    <Box>
      <DataTableUserPov id={params.id} username={params.userName} businessId={params.businessId}/>
    </Box>
  );
};
export default UserPov;
