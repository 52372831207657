import { useState } from "react";
import { useQuery } from "react-query";
import { Grid, Stack, Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import ButtonDisableProduct from "./ButtonDisableProduct";
import ButtonEnableProduct from "./ButtonEnableProduct";
import ButtonProductList from "./ButtonProductList";
import ButtonEditProduct from "./ButtonEditProduct";
import SearchProduct from "./SearchProduct";
import { Tooltip } from 'react-tooltip';
import axios from "axios";
import handleError from "./utilities/HandleError";

const DataTableProduct = ({ searchProduct }) => {
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState(searchProduct);

  const productList = async ( page, perPage, search) => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/product/list",
        params: {
          pageNumber: page,
          pageSize: perPage,
          search: search,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const { data } = response;
      return data;
    } catch (error) {
      handleError(error);
      return error.response.message;
    }
  };

  const { isLoading, isError, error, data } = useQuery(
    ["get-products",  page, perPage, search],
    () => productList( page, perPage, search),
    {
      onSuccess: (data) => {
        setTotalRows(data.totalElements);
        setPage(data.pageNumber + 1);
      },
      onError: (error) => {

      },
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    }
  );


  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const columns = [
    {
      name: "Nro",
      selector: (row, index) => index + 1,
      width: "70px",
      id: 1,
      omit: (row, index) => index + 1,
    },
    {
      name: "Nombre producto",
      selector: (row) => <p data-tooltip-id="product-tooltip" data-tooltip-content={row.descripcionProducto}>{row.descripcionProducto}</p>,
      id: 2,
    },
    {
      name: "Código producto",
      selector: (row) => <p data-tooltip-id="product-tooltip" data-tooltip-content={row.codigoProducto}>{row.codigoProducto}</p>,
      id: 3,
    },
    {
      name: "Unidad medida",
      selector: (row) => <p data-tooltip-id="product-tooltip" data-tooltip-content={row.unidadMedida}>{row.unidadMedida}</p>,
      id: 4,
    },
    {
      name: "Precio",
      selector: (row) => <p data-tooltip-id="product-tooltip" data-tooltip-content={row.precio}>{row.precio}</p>,
      id: 5,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => <ButtonEditProduct list={row} disableBtn={!row.status} />,
    },
    {
      name: "Inhabilitar",
      button: true,
      cell: (row) =>
        row.status ? (
          <>
            <ButtonDisableProduct id={row.id} />
          </>
        ) : (
          <>
            <ButtonEnableProduct id={row.id} />
          </>
        ),
    },
  ];

  const dataTable = data?.content.map((product) => {
    return {
      id: product.id,
      descripcionProductoSiat: product.descripcionProductoSiat,
      descripcionProducto: product.descripcionProducto,
      codigoProducto: product.codigoProducto,
      razonSocial: product.razonSocial,
      unidadMedida: product.unidadMedida,
      precio: Number(product.precio).toFixed(2),
      codigoProductoSiat: product.codigoProductoSiat,
      codigoActividadSiat: product.codigoActividadSiat,
      status: product.status,
    };
  });

  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <table
        style={{
          paddingLeft: "60px",
          fontSize: "14px",
          backgroundColor: "whitesmoke",
          with: "100%",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Nombre producto</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.descripcionProducto}
            </td>
          </tr>

          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Código producto</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.codigoProducto}
            </td>
          </tr>

          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Unidad de medida</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.unidadMedida}
            </td>
          </tr>

          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Precio</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.precio}
            </td>
          </tr>

          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Código actividad</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.codigoActividadSiat}
            </td>
          </tr>

          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Código producto SIAT</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.codigoProductoSiat}
            </td>
          </tr>

          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Descripción producto SIAT</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.descripcionProductoSiat}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };
  
const customStyles = {
  headCells: {
    style: {
      backgroundColor: "whitesmok",
      fontSize: "13px",
      fontWeight: 600,
      color: "black",
    },
  },
};  

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            {" "}
            Gestión de productos
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} className="containerAddProduct">
          <ButtonProductList />
        </Grid>
        <Grid item xs={12} md={6} align="left">
          <SearchProduct search={search} setSearch={setSearch} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={dataTable}
            customStyles={customStyles}
            fixedHeader={true}
            progressPending={isLoading}
            progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
            defaultSortFieldId={1}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            striped
            responsive
            pointerOnHover
            highlightOnHover
            selectableRowsSingle
            noDataComponent={
              <Stack
                height="50vh"
                width="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <h2
                  style={{
                    color: "gray",
                    fontFamily: "Oswald",
                    fontWeight: 200,
                    fontSize: "28px",
                  }}
                >
                  "No se encontró ningún registro"
                </h2>
              </Stack>
            }
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={paginationComponentOptions}
            visibleRowIndex
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
          <Tooltip id="product-tooltip" place='right'/>
      </Grid>  
    </>
  ); 
};
export default DataTableProduct;
