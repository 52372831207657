import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import PestControlIcon from '@mui/icons-material/PestControl';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { NavLink} from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./SidebarComponent.css";
import CellTowerIcon from '@mui/icons-material/CellTower';

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SidebarComponent = () => {
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:600px)');
  const [open, setOpen] = useState(matches);

  const handleLogout = (e) => {
    e.preventDefault();
    window.localStorage.clear();
    window.location.href = "/";
  }; 

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderSector = (sector) => {
    switch(sector) {
      case 'FACTURA_COMPRA_VENTA':
        return 'Sector Compra y Venta';
      case 'FACTURA_HOTEL':
        return 'Sector Hotel';
      case 'FACTURA_EDUCACION':
        return 'Sector Educación';
      default:
        return 'Sector Privado';
    }
  };

  const renderUsernameCompany = () => {
    const username = window.localStorage.getItem("user");
    const nombrePuntoVenta = window.localStorage.getItem("nombrePuntoVenta");

    const formattedUsername = username ? username.charAt(0).toUpperCase() + username.slice(1) : 'Usuario';
    return `${formattedUsername} - ${nombrePuntoVenta}`;
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          elevation={4}
          sx={{ backgroundColor: "#2f2f2f", color: "#fff" }}
        >
          <Toolbar
            position="static"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography
                variant="h5"
                noWrap
                component="div"
                sx={{ marginRight: "80px" }}
              ></Typography>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(!open)}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography sx={{color: 'white'}}>
                {renderUsernameCompany()}
              </Typography>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openMenu ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
              >
                <Avatar sx={{ width: 35, height: 35 }}>
                  <AccountCircleRoundedIcon
                    fontSize="large"
                    sx={{ color: "#f0f0f0" }}
                  />
                </Avatar>
              </IconButton>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 35,
                    height: 35,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem >
                <ListItemIcon>
                </ListItemIcon>
                  {renderSector(localStorage.getItem('sector'))}
              </MenuItem>
              <Divider/>
              <MenuItem >
                <ListItemIcon>
                </ListItemIcon>
                  {localStorage.getItem('modality') === "COMPUTERIZED_ONLINE" ? "Modalidad Computarizada": "Modalidad Electrónica"}
              </MenuItem>             
              <Divider/>
              <MenuItem onClick={(e) => handleLogout(e)}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Cerrar sesión
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{ textAlign: "center", height: "100vh" }}
        >
          <DrawerHeader></DrawerHeader>
          <Divider />
          <List
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100vh",
              backgroundColor: "whitesmoke",
              borderRight: "5px solid white",
            }}
          >
            <div>
              <NavLink
                to="statistics/invoice" style={{ textDecoration: 'none'}}
              >
                {({ isActive }) => (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                   className={isActive ? "active" : "inActive"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor: theme.primary,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "gray",
                            borderRadius: "5px",
                            padding: "4px",
                          }}
                        >
                          <EqualizerIcon sx={{ color: "white" }} />
                        </div>
                      }
                    </ListItemIcon>
                    {open === true && <ListItemText primary={"Estadísticas"} />}
                  </ListItemButton>
                </ListItem>
                )}
              </NavLink>
              <NavLink
                to="client" style={{ textDecoration: 'none' }}
              >
                {({ isActive }) => (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                  className={isActive ? "active" : "inActive"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "gray",
                            borderRadius: "5px",
                            padding: "4px",
                          }}
                        >
                          <PeopleAltIcon sx={{ color: "white" }} />
                        </div>
                      }
                    </ListItemIcon>
                    {open === true && (
                      <ListItemText primary={"Gestión de clientes"} />
                    )}
                  </ListItemButton>
                </ListItem>
                )}
              </NavLink>
              <NavLink
                to="product" style={{ textDecoration: 'none' }}
              >
                {({ isActive }) => (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                  className={isActive ? "active" : "inActive"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "gray",
                            borderRadius: "5px",
                            padding: "4px",
                          }}
                        >
                          <LocalGroceryStoreIcon sx={{ color: "white" }} />
                        </div>
                      }
                    </ListItemIcon>
                    {open === true && (
                      <ListItemText primary={"Gestión de productos"} />
                    )}
                  </ListItemButton>
                </ListItem>
                 )}
              </NavLink>
              <NavLink
                to="billing" style={{ textDecoration: 'none' }}
              >
                {({ isActive }) => (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                  className={isActive ? "active" : "inActive"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "gray",
                            borderRadius: "5px",
                            padding: "4px",
                          }}
                        >
                          <ReceiptLongIcon sx={{ color: "white" }} />
                        </div>
                      }
                    </ListItemIcon>
                    {open === true && (
                      <ListItemText primary={"Emisión de factura"} />
                    )}
                  </ListItemButton>
                </ListItem>
                 )}
              </NavLink>
              <NavLink
                to="bills_list" style={{ textDecoration: 'none' }}
              >
                {({ isActive }) => (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                  className={isActive ? "active" : "inActive"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "gray",
                            borderRadius: "5px",
                            padding: "4px",
                          }}
                        >
                          <FormatListBulletedIcon sx={{ color: "white" }} />
                        </div>
                      }
                    </ListItemIcon>
                    {open === true && (
                      <ListItemText primary={"Lista de facturas"} />
                    )}
                  </ListItemButton>
                </ListItem>
                  )}
              </NavLink>
              <NavLink
                to="massive_emission" style={{ textDecoration: 'none' }} 
              >
                {({ isActive }) => (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                  className={isActive ? "active" : "inActive"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "gray",
                            borderRadius: "5px",
                            padding: "4px",
                          }}
                        >
                          <FileCopyIcon sx={{ color: "white" }} />
                        </div>
                      }
                    </ListItemIcon>
                    {open === true && (
                      <ListItemText primary={"Emisión masiva"} />
                    )}
                  </ListItemButton>
                </ListItem>
                  )}
              </NavLink>
              <NavLink
                to="contingencia" style={{ textDecoration: 'none' }} 
              >
                {({ isActive }) => (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                  className={isActive ? "active" : "inActive"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "gray",
                            borderRadius: "5px",
                            padding: "4px",
                          }}
                        >
                          <CellTowerIcon sx={{ color: "white" }} />
                        </div>
                      }
                    </ListItemIcon>
                    {open === true && (
                      <ListItemText primary={"Contingencia"} />
                    )}
                  </ListItemButton>
                </ListItem>
                  )}
              </NavLink>
              <NavLink
                to="cuf" style={{ textDecoration: 'none' }} 
              >
                {({ isActive }) => (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                  className={isActive ? "active" : "inActive"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "gray",
                            borderRadius: "5px",
                            padding: "4px",
                          }}
                        >
                          <PestControlIcon sx={{ color: "white" }} />
                        </div>
                      }
                    </ListItemIcon>
                    {open === true && (
                      <ListItemText primary={"CUF Observados"} />
                    )}
                  </ListItemButton>
                </ListItem>
                  )}
              </NavLink>              
              <Divider />
            </div>
            <div>
              <Divider />
              <NavLink
                to="setting" style={{ textDecoration: 'none' }}  
              >
              {({ isActive }) => (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                  className={isActive ? "active" : "inActive"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "gray",
                            borderRadius: "5px",
                            padding: "4px",
                          }}
                        >
                          <SettingsIcon sx={{ color: "white" }} />
                        </div>
                      }
                    </ListItemIcon>
                    {open === true && (
                      <ListItemText primary={"Configuraciones"} />
                    )}
                  </ListItemButton>
                </ListItem>
                  )}
              </NavLink>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={(e) => handleLogout(e)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "gray",
                          borderRadius: "5px",
                          padding: "4px",
                        }}
                      >
                        <LogoutIcon sx={{ color: "white" }} />
                      </div>
                    }
                  </ListItemIcon>
                  {open === true && (
                    <ListItemText
                      sx={{ color: "gray" }}
                      primary={"Cerrar sesión"}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            </div>
          </List>
        </Drawer>
      </Box>
    </>
  );
};

export default SidebarComponent;
