import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';

const disableUser = async (id) => {
  try {
    const response = await axios({
      method: "delete",
      url:
        process.env.REACT_APP_API +
        "/api/v1/user/"+id,
      headers: {
        "content-type": "application/json;charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
  } catch (error) {
    throw error;
  }
};


export const useDisableUser = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: disableUser,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get-users"] });
      },
    });
}

