import { Box, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ValidationActiveUser from "../validation/ValidationActiveUser";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Image from "../assets/img/Qhatubit3.png";
import "./ActiveUser.css";

const ActiveUser = () => {
const navigate = useNavigate();
const [showPass, setShowPass] = useState(false);
const [showComfirmPass, setShowComfirmPass] = useState(false);

const handlePassVisibilty = () => {
  setShowPass(!showPass);
};
const handleComfirmPassVisibilty = () => {
  setShowComfirmPass(!showComfirmPass);
};

const getUrl = () => {
  return window.location.pathname.substring(15, 115);
};

const formik = useFormik({
  initialValues: {
    password: "",
    confirmPassword: "",
  },
  validationSchema: ValidationActiveUser,
  onSubmit: async (data) => {
    try {
      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_API + "/api/v1/auth/user/activate",
        params:{
          userToken: getUrl(),
        },
        data: JSON.stringify({
          password: data.password,
        }),
        headers: {
          "Content-type": "application/json;charset=utf-8",
        },
      });
      if (response.status === 200) {
        formik.resetForm({ data: "" });
        Swal.fire({
          customClass: {
            container: "activeUser",
            title: "titleActiveUser",
          },
          icon: "success",
          title: "¡El usuario ha sido habilitado!",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        navigate("/");
      }
    } catch (error) {
      if (error.response.status === 504) {
        Swal.fire({
          customClass: {
            container: "activeUser",
            title: "titleActiveUser",
          },
          icon: "error",
          title: "!Lo sentimos!",
          text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }        
      if (error.response.status === 500 || error.response.status === 503) {
        Swal.fire({
          customClass: {
            container: "activeUser",
            title: "titleActiveUser",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: "Por favor vuelva a intentarlo más tarde",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }

      if (error.response.status >= 400 && error.response.status <= 499) {
        Swal.fire({
          customClass: {
            container: "activeUser",
            title: "titleActiveUser",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: error.response.data.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status >= 0 && error.response.status <= 99) {
        Swal.fire({
          customClass: {
            container: "activeUser",
            title: "titleActiveUser",
          },
          icon: "error",
          title: "!Ha ocurrido un problema de conectividad!",
          text: "Por favor vuelva a intentarlo más tarde",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    }
  },
});

useEffect(() => {
  getUrl();
}, []);    

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Box
        className="panelLogoActiveUser"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "380px",
          height: "450px",
          boxShadow: "5px 5px 15px black",
          backgroundColor: "black",
        }}
      >
        <img src={Image} alt="" width={"200px"} height={"200px"} />
      </Box>
      <Box
        className="panelFormActive"
        sx={{
          width: "380px",
          height: "450px",
          display: "flex",
          padding: "30px",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "5px 5px 15px black",
          backgroundColor: `rgba(202, 182, 118, 0.2)`,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            className="containerFormActive"
            sx={{
              width: "290px",
              height: "420px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {" "}
            <Box
              className="logoIconActive"
              sx={{
                display: "none",
                width: "80px",
                height: "80px",
                backgroundColor: "#0f0f0f",
                borderRadius: "50%",
                border: "2px solid white",
              }}
            >
              <VpnKeyIcon
                className="iconVPN"
                sx={{ color: "white", fontSize: "124px" }}
              />
            </Box>
            <Grid container>
              <Grid item xs={12} align="center" sx={{ marginBottom: "20px" }}>
                <Typography
                  className="labelActiveUser"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "36px",
                    color: "white",
                  }}
                >
                  Activación de cuenta
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className="labelPassword"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "18px",
                    marginBottom: "5px",
                    color: "white",
                  }}
                >
                  Contraseña
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    sx={{ color: "white" }}
                    type={showPass ? "text" : "password"}
                    variant="standard"
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    InputProps={{
                      style: {
                        color: "white",
                        borderBottom: "2px solid white",
                        fontSize: "22px",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {showPass ? (
                            <VisibilityOffIcon sx={{ color: "white" }} />
                          ) : (
                            <VisibilityIcon sx={{ color: "white" }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText
                    className="labelErrorPassword"
                    sx={{ color: "yellow", fontSize: "14px" }}
                  >
                    {formik.touched.password && formik.errors.password}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className="labelComfirmAP"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "18px",
                    marginBottom: "5px",
                    color: "white",
                    marginTop: "20px",
                  }}
                >
                  Confirmar contraseña
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    type={showComfirmPass ? "text" : "password"}
                    fullWidth
                    variant="standard"
                    name="confirmPassword"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    InputProps={{
                      style: {
                        color: "white",
                        borderBottom: "2px solid white",
                        fontSize: "22px",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {showComfirmPass ? (
                            <VisibilityOffIcon sx={{ color: "white" }} />
                          ) : (
                            <VisibilityIcon sx={{ color: "white" }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText
                    className="labelErrorComfirm"
                    sx={{ color: "yellow", fontSize: "14px" }}
                  >
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <button
                  className="buttomActiveUser"
                  type="submit"
                  style={{
                    width: "100%",
                    padding: "5px",
                    backgroundColor: "black",
                  }}
                >
                  <Typography
                    className="labelButtomActive"
                    sx={{
                      fontFamily: "Oswald",
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    Activar
                  </Typography>
                </button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </div>
  );
};
export default ActiveUser;
