import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import './index.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const queryClient = new QueryClient();

async function refreshTokenAction(token) {
  await fetch(
    process.env.REACT_APP_API + "/api/v1/auth/refresh",{
        method: 'POST',
        body:  JSON.stringify(token),
        headers: {
        'Content-Type': 'application/json',
        },
      }
  )
    .then(async (response) => await response.json())
    .then(async (token) => {
      localStorage.setItem("expiresIn", token.expiresIn);
      localStorage.setItem("accessToken", token.accessToken);
      localStorage.setItem("refreshToken", token.refreshToken);
      localStorage.setItem("expireRefreshTokenIn", token.expireRefreshTokenIn);
    })
    .catch((error) => console.log("error", error));
}

async function checkingTokenAction(
  timeTokenResponse,
  timeRefreshTokenResponse
) {

  var dateNow = new Date(Date.now());
  var expireToken = epochToDate(timeTokenResponse);
  var expireRefreshToken = epochToDate(timeRefreshTokenResponse);

  if (minutesDiff(dateNow, expireToken) < 0) {
    if (minutesDiff(dateNow, expireRefreshToken) > 0) {
      await refreshTokenAction({refreshToken: localStorage.getItem("refreshToken")});
    } else {
      localStorage.clear();
      window.location.href = "/";
    }
  }
}

function minutesDiff(date1, date2) {
  var diff = date2 - date1;
  return Math.floor(diff / 1000 / 60);
}

function epochToDate(epoch) {
  return new Date(epoch * 1000);
}

async function checkWord(palabra, arreglo) {
  for (let i = 0; i < arreglo.length; i++) {
    if (palabra.includes(arreglo[i])) {
      return true;
    }
  }
  return false;
} 

const palabras = [
  "/login",
  "/resetPassword",
  "/reSendActivationUser",
  "/activate",
  "/newPassword",
];

axios.interceptors.request.use(
  async (config) => {
    const palabraBuscada = config.url;

    if (await checkWord(palabraBuscada, palabras)) {

    } else {
      await checkingTokenAction(
        Number(localStorage.getItem("expiresIn")),
        Number(localStorage.getItem("expireRefreshTokenIn"))
      );
      config.headers.Authorization =
        "Bearer " + localStorage.getItem("accessToken");
    }
    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>
);

// Change unregister to register to enable service worker
serviceWorkerRegistration.register();