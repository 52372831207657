import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import ValidationRegisterCustomer from "../validation/ValidationRegisterCustomer";
import ValidationSearchCustomer from "../validation/ValidationSearchCustomer";
import ValidationEditDetailProduct from "../validation/ValidationEditDetailProduct";
import ValidationRegisterDetailProduct from "../validation/ValidationRegisterDetailProduct";
import DisabledByDefaultSharpIcon from '@mui/icons-material/DisabledByDefaultSharp';
import ValidationBilling from "../validation/ValidationBilling";
import AddCardIcon from "@mui/icons-material/AddCard";
import IconButton from "@mui/material/IconButton";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import useMediaQuery from "@mui/material/useMediaQuery";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ArticleIcon from "@mui/icons-material/Article";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CustomDropdownEdit from "./style/CustomDropdownEdit";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Tooltip } from "react-tooltip";
import "./ButtonGoBackSettings.css";
import "./ButtonSendData.css";
import "./ButtonLoadingData.css";
import "./ButtonEditClient.css";
import "./FormBilling.css";
import "./ButtonRDBilling.css";
import "./ButtonRCBilling.css";
import handleError from "./utilities/HandleError"

const today = dayjs();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



const styleViewBillings = {
  position: "absolute",
  zIndex: 8,
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  marginTop: 10,
  p: 2,
};

const FormBillding = () => {
  const inputRef = useRef(null);
  const metodosRef = useRef();
  const descuentoRef = useRef(0);
  const [gift, setGift] = useState(0.0);
  const matches = useMediaQuery("(min-width:900px)");
  const [idBilling, setIdBilling] = useState("0");
  const [openBilling, setOpenBilling] = useState(false);
  const [openMinorBilling, setOpenMinorBilling] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderProduct, setLoaderProduct] = useState(false);
  const [auxProduct, setAuxProduct] = useState("")

  const themes = useTheme();
  const fullScreen = useMediaQuery(themes.breakpoints.down("sm"));

  const navigate = useNavigate();
  //const theme = createTheme(esES);

  const handleCloseBilling = () => {
    setOpenBilling(false);
  };
  const handleOpenBilling = () => {
    setOpenBilling(true);
  };
  const handleOpenMinorBilling = () => {
    setOpenMinorBilling(true);
  };
  const handleCloseMinorBilling = () => {
    setOpenMinorBilling(false);
  };

  const handleCheckErrors = () => {
    if (formikBilling.errors.tarjeta1 !== undefined) {
      formikBilling.values.codeT && formikBilling.errors.tarjeta1
        ? handleOpenMethodPay()
        : console.log(formikBilling.errors.tarjeta1.length);
    }
    if (formikBilling.values.codeG && formikBilling.errors.gift) {
      handleOpenMethodPay();
    }
  };

  const [clientId, setClientId] = useState();
  const [productDetails, setProductDetails] = useState([]);
  const [shippingType, setShippingType] = useState("ONLINE");
  const [showDropdown, setShowDropdown] = useState("none");
  const [tipoDescuento, setTipoDescuento] = useState(1);
  const [cantidadDescuento, setCantidadDescuento] = useState(1);

  const [unidadMedidaR, setUnidadMedidaR] = useState("");
  const [precioUnitarioR, setPrecioUnitarioR] = useState(0.0);

  const [productId, setProductId] = useState(0);
  const [productIdE, setProductIdE] = useState(0);
  const [unidadMedidaE, setUnidadMedidaE] = useState("");
  const [precioUnitarioE, setPrecioUnitarioE] = useState(0.0);

  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [checkNit, setCheckNit] = useState(false);
  const [type, setType] = useState("");
  const [codigoClasificadorSiat, setCodigoClasificadorSiat] = useState(0);

  const [cantidad, setCantidad] = useState(0);
  const [descripcionProducto, setDescripcionProducto] = useState("");
  const [codigoProducto, setCodigoProducto] = useState("");
  const [idDetail, setIdDetail] = useState("");

  const [documentoView, setDocumentoView] = useState("");
  const [typeView, setTypeView] = useState("");
  const [razonSocialView, setRazonSocialView] = useState("");
  const [emailView, setEmailView] = useState("");
  const [numCelularView, setNumCelularView] = useState("");
  const [codigoClasificador, setCodigoClasificador] = useState();

  const [valuePay, setValuePay] = useState(1);
  const [labelPay, setLabelPay] = useState("EFECTIVO");

  const [boton, setBoton] = useState(false);
  const [value, setValue] = useState(dayjs(Date()));

  const [expanded1, setExpanded1] = useState("panel1");
  const [expanded2, setExpanded2] = useState("panel2");

  const [open, setOpen] = useState(false);

  let [o, setO] = useState(0.0);

  const cleanFieldsClient = () => {
    setClientId("");
    setTypeView("");
    setDocumentoView("");
    setRazonSocialView("");
    setEmailView("");
    setNumCelularView("");
    setCodigoClasificador(0);
    setSearchClient("");
  };

  const handleOpen = async () => {
    formikRegisterDetailBilling.resetForm({ data: "" });
    getMethodPayList();
    setTipoDescuento(1);
    limpiarCampos();
    setOpen(true);
  };

  const handleClose = () => {
    setType("");
    setAuxProduct("");
    setTipoDescuento(1);
    limpiarCampos();
    setOpen(false);
  };

  const handleNumericFieldChange = (event) => {
    const inputValue = event.target.value;

    // Validar que el valor solo contiene números enteros o con hasta dos decimales
    if (/^\d*(\.\d{0,2})?$/.test(inputValue) || inputValue === "") {
      setCantidadDescuento(inputValue);
      // Actualizar el valor en useFormik
      formikRegisterDetailBilling.setFieldValue("cantidad", inputValue);
    }
  };

  const handleNumericFieldChangeEdit = (event) => {
    const inputValue = event.target.value;

    // Validar que el valor solo contiene números enteros o con hasta dos decimales
    if (/^\d*(\.\d{0,2})?$/.test(inputValue) || inputValue === "") {
      setCantidadDescuento(inputValue);
      // Actualizar el valor en useFormik
      formikEditDetailBilling.setFieldValue("cantidad", inputValue);
    }
  };

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => {
    setTipoDescuento(1);
    setOpenEdit(false);
  };

  const optionsSpecialCases = [
    { label: "Casos especiales (Ninguno)", value: "Ninguno" },
    { label: "Extranjero no inscrito (99001)", value: "99001" },
    { label: "Control tributario (99002)", value: "99002" },
    { label: "Cliente sin nombre", value: "99999" },
  ];

  /*****************************************************************************/
  const [specialOption, setSpecialOption] = useState("Ninguno");
  const handleSpecialOptions = (e) => {
    if (e.value === "99001") {
      formikBilling.setFieldValue("code", true);
    }
    setSpecialOption(e.value);
    if (e.value !== "Ninguno") {
      cleanFieldsClient();
      getCasosEspaciales(e.value);
      setSearchClient(null);
    }
    if (e.value !== "99001") {
      formikBilling.setFieldValue("code", false);
    }
  };

  const [openRegister, setOpenRegister] = useState(false);
  const handleOpenRegister = () => {
    formikRegisterClient.resetForm({ data: "" });
    loadTypes();
    cleanFieldsClient();
    setSpecialOption("Ninguno");
    setOpenRegister(true);
    setSearchClient(null);
  };


  const handleCloseRegister = (e) => {
    setType("");
    setCodigoClasificadorSiat(0);
    setOpenRegister(false);
  };

  /*************************************** Evento que permite seleccionar el tipo de producto ******************************************************/
  const handleChangeProduct = (valor) => {
    getProduct(valor.value);
    setProductId(valor.value);
  };
  const [searchClient, setSearchClient] = useState(null);
  const handleSearchClient = (e) => {
    setSearchClient(e);
    getClient(e.value);
  };

  /*************************************** Evento que permite actualizar el tipo de producto ******************************************************/
  const handleChangeProductEdit = (valor) => {
    getProduct(valor.value);
    setProductIdE(valor.value);
  };

  /*************************************** Evento que permite seleccionar el tipo de documento de un cliente *****************************************************/
  const handleChangeCliente = (e) => {
    formikRegisterClient.setFieldValue("type", e.label);
    setType(e.label);
    setCodigoClasificadorSiat(e.value);
  };

  function getHora() {
    let hora = new Date().getHours();
    if (hora < 10) {
      return "0".concat("", hora);
    } else {
      return hora.toString();
    }
  }
  function getMinuto() {
    let minuto = new Date().getMinutes();
    if (minuto < 10) {
      return "0".concat("", minuto);
    } else {
      return minuto.toString();
    }
  }
  function getSegundo() {
    let segundo = new Date().getSeconds();
    if (segundo < 10) {
      return "0".concat("", segundo);
    } else {
      return segundo.toString();
    }
  }
  function getMilisegundos() {
    return new Date().getMilliseconds();
  }

  function zfill(number, width) {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */
    var zero = "0"; /* String de cero */

    if (width <= length) {
      if (number < 0) {
        return "-" + numberOutput.toString();
      } else {
        return numberOutput.toString();
      }
    } else {
      if (number < 0) {
        return "-" + zero.repeat(width - length) + numberOutput.toString();
      } else {
        return zero.repeat(width - length) + numberOutput.toString();
      }
    }
  }

  /*************************************** Evento para obtener la hora en formato ISO *****************************************************/
  const handleChangeDate = (newValue) => {
    let dia =
      newValue.$d.getDate() < 10
        ? "0" + newValue.$d.getDate().toString()
        : newValue.$d.getDate();
    setValue(
      newValue.$d.getFullYear() +
        "-" +
        zfill(parseInt(newValue.$d.getMonth() + 1), 2) +
        "-" +
        dia +
        "T" +
        getHora() +
        ":" +
        getMinuto() +
        ":" +
        getSegundo() +
        "." +
        getMilisegundos()
    );
    console.log(
      newValue.$d.getFullYear() +
        "-" +
        zfill(parseInt(newValue.$d.getMonth() + 1), 2) +
        "-" +
        dia +
        "T" +
        getHora() +
        ":" +
        getMinuto() +
        ":" +
        getSegundo() +
        "." +
        getMilisegundos()
    );
  };
  const [methodPayList, setMethodPayList] = useState([]);
  /************************************************* Obtener lista de metodos de pago  ******************************************************/
  const getMethodPayList = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "TIPO_METODO_PAGO",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setMethodPayList(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {
      handleError(error)
    }
  };

  /********************************************* Evento que sirve para mostrar y ocultar informacion ****************************************/
  const handleChange1 = (panel1) => (event, newExpanded1) => {
    setExpanded1(newExpanded1 ? panel1 : false);
  };
  const handleChange2 = (panel2) => (event, newExpanded2) => {
    setExpanded2(newExpanded2 ? panel2 : false);
  };

  const [openMethodPay, setOpenMethodPay] = useState(false);
  const handleOpenMethodPay = () => {
    setOpenMethodPay(true);
  };
  const handleCloseMethodPay = (e) => {
    setOpenMethodPay(false);
  };

  const [codigoMoneda, setCodigoMoneda] = useState(1);
  const [labelMoneda, setLabelMoneda] = useState("BOLIVIANO");
  const handleChangeMoneda = (e) => {
    setCodigoMoneda(e.value);
    setLabelMoneda(e.label);
  };
  /********************************************* Evento que establece los valores por defecha de una tarjeta ****************************************/
  const handleChangePay = (e) => {
    formikBilling.setFieldValue("tipoPago", e.value);

    if (e.label.includes("TARJETA")) {
      formikBilling.setFieldValue("codeT", true);
    } else {
      formikBilling.setFieldValue("codeT", false);
      formikBilling.values.tarjeta1 = "0";
    }
    if (e.label.includes("GIFT")) {
      formikBilling.setFieldValue("codeG", true);
    } else {
      formikBilling.setFieldValue("codeG", false);
      formikBilling.values.gift = 0;
    }
    setValuePay(e.value);
    setLabelPay(e.label);
  };

  /********************************************* Bolivianos y porcentajes ****************************************/

  const tiposDescuento = [
    { value: 1, label: "Bs" },
    { value: 2, label: "%" },
  ];

  /********************************************* Evento para seleccionar el tipo de descuento ****************************************/
  const handleChangeTipoDescuento = (e) => {
    if (e.value === 1) {
      formikRegisterDetailBilling.setFieldValue("montoDescuento", 0);
      formikRegisterDetailBilling.setFieldValue("porcentaje", 0);
      setTipoDescuento(e.value);
    } else {
      formikRegisterDetailBilling.setFieldValue("montoDescuento", 0);
      formikRegisterDetailBilling.setFieldValue("porcentaje", 0);
      setTipoDescuento(e.value);
    }
  };

  /********************************************* Evento que permite actualizar el tipo descuento ****************************************/
  const handleChangeTipoDescuentoEdit = (e) => {
    setTipoDescuento(e.value);
    if (e.value === 1) {
      formikEditDetailBilling.setFieldValue("montoDescuento", 0);
      formikEditDetailBilling.setFieldValue("porcentaje", 0);
    } else if (e.value === 2) {
      formikEditDetailBilling.setFieldValue("porcentaje", 0);
      formikEditDetailBilling.setFieldValue("montoDescuento", 0);
    }
  };

  const options = [
    { value: "ONLINE", label: "ONLINE" },
    { value: "OFFLINE", label: "OFFLINE" },
  ];

  /********************************************* Evento que permite cambiar el tipo de emisión de factura ****************************************/
  const handleChangeFactura = (e) => {
    if (e.value === "ONLINE") {
      setCodeEvent(0);
    }
    setShippingType(e.value);
    e.value === "OFFLINE" ? setShowDropdown("block") : setShowDropdown("none");
  };

  /************************************************* Métodos para la selección de campos *****************************************************/
  const handleSelectTipoCambio = () => {
    document.getElementById("tipoCambio").select();
  };
  const handleSelectGlobalDescuento = (e) => {
    document.getElementById("descuento").select();
  };
  const handleSelectCantidad = () => {
    document.getElementById("cantidad").select();
  };
  const handleSelectDescuento = () => {
    document.getElementById("montoDescuento").select();
  };
  const handleSelectPorcentaje = () => {
    document.getElementById("porcentaje").select();
  };
  const handleSelectDescuentoEdit = () => {
    document.getElementById("montoDescuentoEdit").select();
  };
  const handleSelectPorcentajeEdit = () => {
    document.getElementById("porcentajeEdit").select();
  };
  const handleSelectGift = () => {
    document.getElementById("gift").select();
  };
  const handleSelectTarjeta1 = () => {
    document.getElementById("tarjeta1").select();
  };

  /*************************************** Evento que permita cambiar el tipo de descuento *****************************************************/
  const handleClickEditar = (e, cellValues) => {
    formikBilling.setFieldValue("montoDescuento", 0);
    handleOpenEdit();
    if (cellValues.row.tipoDescuento === 1) {
      formikEditDetailBilling.setValues({
        cantidad: cellValues.row.cantidad,
        montoDescuento: cellValues.row.montoDescuento,
        porcentaje: cellValues.row.porcentaje,
      });
      setTipoDescuento(cellValues.row.tipoDescuento);
    } else if (cellValues.row.tipoDescuento === 2) {
      formikEditDetailBilling.setValues({
        cantidad: cellValues.row.cantidad,
        montoDescuento: cellValues.row.montoDescuento,
        porcentaje: cellValues.row.porcentaje,
      });
      setTipoDescuento(cellValues.row.tipoDescuento);
    }

    setCantidad(cellValues.row.cantidad);
    setIdDetail(cellValues.row.idE);
    setDescripcionProducto(cellValues.row.descripcionProducto);
    setProductIdE(cellValues.row.productId);
    setTipoDescuento(cellValues.row.tipoDescuento);

    setCodigoProducto(cellValues.row.codigoProducto);
    setPrecioUnitarioE(cellValues.row.precio);
    setUnidadMedidaE(cellValues.row.unidadMedida);
  };

  /****************************************************** Eliminar producto *****************************************************/
  const handleClickEliminar = (e, cellValues) => {
    Swal.fire({
      customClass: {
        container: "formDeleteDetailProduct",
        title: "formTitleDeleteDetailProduct",
      },
      title: "¿Estás seguro de eliminarlo?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#94948f",
      confirmButtonColor: "#34974d",
      reverseButtons: true,
      confirmButtonText: "Aceptar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem(cellValues.row.idE);
        setO(0);
        Swal.fire({
          customClass: {
            container: "formDeleteDetailProduct",
            title: "formTitleDeleteDetailProduct",
          },
          title: "¡El producto ha sido eliminado!",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  /***********************************  Definir las columnas del DataGrid detalle de producto *************************************/

  const columns1 = [
    {
      field: "descripcionProducto",
      headerName: "Descripción del producto",
      headerClassName: "super-app-theme--header",
      width: 350,
      renderCell: (params) => (
        <p
          data-tooltip-id="detail-tooltip"
          data-tooltip-content={params.row.descripcionProducto}
        >
          {params.row.descripcionProducto}
        </p>
      ),
    },
    {
      field: "unidadMedida",
      headerName: "Unidad de medida",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      headerClassName: "super-app-theme--header",
      width: 125,
    },
    {
      field: "precio",
      headerName: "Precio unitario",
      headerClassName: "super-app-theme--header",
      width: 125,
    },
    {
      field: "montoDescuento",
      headerName: "Descuento",
      headerClassName: "super-app-theme--header",
      width: 110,
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      headerClassName: "super-app-theme--header",
      width: 110,
    },
    {
      field: "Editar",
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <button
            className="buttonEdit"
            type="button"
            onClick={(e) => handleClickEditar(e, cellValues)}
          >
            <span className="buttonEdit__text">Editar</span>
            <span className="buttonEdit__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svgEdit"
              >
                <EditIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        );
      },
      width: 100,
    },
    {
      field: "Eliminar",
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <button
            className="button"
            type="button"
            onClick={(e) => handleClickEliminar(e, cellValues)}
          >
            <span className="button__text">Eliminar</span>
            <span className="button__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svg"
              >
                <DeleteIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        );
      },
      width: 100,
    },
  ];

  const columns2 = [
    {
      field: "descripcionProducto",
      headerName: "Descripción del producto",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="detail-tooltip"
          data-tooltip-content={params.row.descripcionProducto}
        >
          {params.row.descripcionProducto}
        </p>
      ),
      flex: 3,
    },
    {
      field: "unidadMedida",
      headerName: "Unidad de medida",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="detail-tooltip"
          data-tooltip-content={params.row.unidadMedida}
        >
          {params.row.unidadMedida}
        </p>
      ),
      flex: 2,
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="detail-tooltip"
          data-tooltip-content={params.row.cantidad}
        >
          {params.row.cantidad}
        </p>
      ),
      flex: 1,
    },
    {
      field: "precio",
      headerName: "Precio unitario",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="detail-tooltip"
          data-tooltip-content={params.row.precio}
        >
          {params.row.precio}
        </p>
      ),
      flex: 1.5,
    },
    {
      field: "montoDescuento",
      headerName: "Descuento",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="detail-tooltip"
          data-tooltip-content={params.row.montoDescuento}
        >
          {params.row.montoDescuento}
        </p>
      ),
      flex: 1,
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="detail-tooltip"
          data-tooltip-content={params.row.subtotal}
        >
          {params.row.subtotal}
        </p>
      ),
      flex: 1,
    },
    {
      field: "Editar",
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <button
            className="buttonEdit"
            type="button"
            onClick={(e) => handleClickEditar(e, cellValues)}
          >
            <span className="buttonEdit__text">Editar</span>
            <span className="buttonEdit__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svgEdit"
              >
                <EditIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        );
      },
      flex: 1,
    },
    {
      field: "Eliminar",
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <button
            className="button"
            type="button"
            onClick={(e) => handleClickEliminar(e, cellValues)}
          >
            <span className="button__text">Eliminar</span>
            <span className="button__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svg"
              >
                <DeleteIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        );
      },
      flex: 1,
    },
  ];

  const rows = productDetails.map(
    (row, index) => ({
      internalId: index + 1,
      idE: row.idE,
      cantidad: Number(row.cantidad).toFixed(2),
      descripcionProducto: row.descripcionProducto,
      unidadMedida: row.unidadMedida,
      precio: Number(row.precio).toFixed(2),
      codigoProducto: row.codigoProducto,
      montoDescuento: Number(row.montoDescuento).toFixed(2),
      subtotal: Number(row.precio * row.cantidad - row.montoDescuento).toFixed(
        2
      ),
      productId: row.productId,
      tipoDescuento: row.tipoDescuento,
      porcentaje: row.porcentaje,
    }),
    1
  );

  const getCasosEspaciales = async (doc) => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API + "/api/v1/billing/client/findSpecialCodes",
        params: {
          document: doc,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setClientId(response.data.businessClientId);
    } catch (error) {
      handleError(error)
    }
  };

  /********************************************** Listar productos ******************************************/
  const [lista, setLista] = useState([]);
  const loadListProducts = async (search) => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/product/select",
        params: {
          search: search,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setLista(
        response.data.map((sup) => ({
          label: sup.label,
          value: sup.value,
        }))
      );
      return response.data.map((sup) => ({
        label: sup.label,
        value: sup.value,
      }));
    } catch (error) {
      handleError(error)
    }
  };

  useEffect(() => {
    getMoneda();
    loadListProducts();
    const initialDate = dayjs(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSS");
    setValue(initialDate);
  }, []);

  /****************************************** Obtener moneda ************************************/
  const [tipoMoneda, setTipoMoneda] = useState([]);
  const getMoneda = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "TIPO_MONEDA",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setTipoMoneda(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {
      handleError(error)
    }
  };

  /****************************************** Listar tipos de documentos ************************************/
  const loadTypes = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "TIPO_DOCUMENTO_IDENTIDAD",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setTiposDocumentos(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {
      handleError(error);
    }
  };
  const getClient = async (id) => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/client/get/" + id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      setValuesClient(
        response.data.businessClientId,
        response.data.type,
        response.data.documento,
        response.data.complemento,
        response.data.razonSocial,
        response.data.email === null ? "" : response.data.email,
        response.data.numCelular === null ? "" : response.data.numCelular
      );

      if (response.data.codigoClasificadorSiat === 5) {
        verificarNIT(response.data.documento);
        setCodigoClasificadorSiat(response.data.codigoClasificadorSiat);
      } else {
        setNitValido("");
        setCheckNit(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  /****************************************** Verificar el estado del NIT ****************************************/
  const [nitValido, setNitValido] = useState("");
  const verificarNIT = async (nit) => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/client/checkingNit",
        params: {
          nit: parseInt(nit),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const res = response.data.mensajesList.map(
        (element) => element.descripcion
      );
      if (res[0] === "NIT ACTIVO") {
        setCheckNit(true);
        setNitValido("NIT ACTIVO");
      } else if (res[0] === "NIT INACTIVO") {
        setCheckNit(false);
        setNitValido("NIT INACTIVO");
      } else if (res[0] === "NIT INEXISTENTE") {
        setCheckNit(false);
        setNitValido("NIT INEXISTENTE");
      } else {
        setCheckNit(false);
        setNitValido("");
      }
      return res[0];
    } catch (error) {
      handleError(error);
    }
  };

  /******************************************************** Obtener un producto ***************************************/
  const getProduct = async (id) => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/product/find/" + id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setAuxProduct(response.data.precioUnitario);
      setDescripcionProducto(response.data.descripcionProducto);
      setCodigoProducto(response.data.codigoProducto);
      setPrecioUnitarioR(response.data.precioUnitario);
      setUnidadMedidaR(response.data.nombreUnidadMedida);
      setPrecioUnitarioE(response.data.precioUnitario);
      setUnidadMedidaE(response.data.nombreUnidadMedida);
    } catch (error) {
      handleError(error);
    }
  };

  const deleteItem = (id) => {
    const newProductDetails = productDetails.filter(
      (element) => id !== element.idE
    );
    setProductDetails(newProductDetails);
    formikBilling.resetForm({ data: "" });
  };

  function cafc() {
    if (
      codigoClasificador === 5 ||
      codigoClasificador === 6 ||
      codigoClasificador === 7
    ) {
      return codigoCafcFormulario;
    }
    return "";
  }
  const [codeEvent, setCodeEvent] = useState(0);
  const [showDropdownCafc, setShowDropdownCafc] = useState("none");

  /**************************************************** Evento para visualizar campos CAFC *************************************************************/
  const handleChangeEvent = (value) => {
    setCodigoClasificador(value.codigoClasificador);
    setCodeEvent(value.codigoClasificador);
    if (
      value.codigoClasificador === 5 ||
      value.codigoClasificador === 6 ||
      value.codigoClasificador === 7
    ) {
      setShowDropdownCafc("block");
    } else {
      setShowDropdownCafc("none");
    }
  };

  /************************************************** Listar códigos de los eventos significativos ************************************/
  const getSigniticantEvents = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "EVENTOS_SIGNIFICATIVOS",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      return response.data.content;
    } catch (error) {
      handleError(error);
    }
  };

  const [openCafc, setOpenCafc] = useState(false);

  const handleOpenCafc = (e) => setOpenCafc(true);
  const handleCloseCafc = (e) => setOpenCafc(false);

  const [codigoCafcFormulario, setCodigoCafcFormulario] = useState();
  const handleChangeCafc = (e) => {
    setCodigoCafcFormulario(e.value);
  };

  const [codigoCafc, setCodigoCafc] = useState("");

  const getListCafc = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/cafc/list",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const tipos = response.data.map((sup) => ({
        label: sup.codeCafc,
        value: sup.codeCafc,
      }));
      return tipos;
    } catch (error) {
      handleError(error);
    }
  };

  /****************************************************** Registrar código CAFC ******************************************************** */
  const handleSubmitCafc = async (e) => {
    e.preventDefault(e);
    try {
      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_API + "/api/v1/billing/cafc/register",
        headers: {
          "content-type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        data: JSON.stringify({
          codeCafc: codigoCafc,
          init: 1,
          end: 1000,
        }),
      });
      if (response.data.status === 200) {
        setOpenCafc(false);
        setCodigoCafc("");
        getListCafc();
        Swal.fire({
          customClass: {
            container: "formCAFC",
            title: "formTitleCAFC",
          },
          icon: "success",
          title: "¡El cofigo CAFC fue creado exitosamente!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };
  const loadOptions =  async (inputValue, callback) => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API + "/api/v1/billing/product/select",
        params: {
          search: inputValue,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const toSelectOption = await response.data.map((sup) => ({
        label: sup.label,
        value: sup.value,
      }));

      callback(toSelectOption);
    } catch (error) {
      handleError(error);
    }
  };

  function limpiarCampos() {
    loadListProducts();
    setUnidadMedidaR("");
    setPrecioUnitarioR(0);
  }

  const getDetailBilling = () => {
    return {
      cantidad: 1,
      montoDescuento: 0,
      type: "",
      porcentaje: 0,
    };
  };

  /***************************************************** Registrar detalle de producto ************************************************************/
  const formikRegisterDetailBilling = useFormik({
    initialValues: getDetailBilling(),
    validationSchema: ValidationRegisterDetailProduct(cantidadDescuento, precioUnitarioR),
    onSubmit: async (data) => {
      setLoaderProduct(true);
      if (
        await productDetails.find((element) => element.productId === productId)
      ) {
        Swal.fire({
          customClass: {
            container: "formRegisterDetailProduct",
            title: "formTitleRegisterDetailProduct",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: "El producto ya ha sido registrado",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        setLoaderProduct(false);
      } else {
        let newItem = null;
        if (tipoDescuento === 1) {
          newItem = await {
            idE: new Date().toISOString(),

            productId: parseInt(productId),
            cantidad: parseFloat(data.cantidad),
            montoDescuento: parseFloat(data.montoDescuento),

            precio: parseFloat(precioUnitarioR),
            unidadMedida: unidadMedidaR,
            descripcionProducto: descripcionProducto,
            codigoProducto: codigoProducto,
            tipoDescuento: tipoDescuento,
            porcentaje: data.porcentaje,
          };
        } else if (tipoDescuento === 2) {
          newItem = await {
            idE: new Date().toISOString(),

            productId: parseInt(productId),
            cantidad: parseFloat(data.cantidad),
            montoDescuento: parseFloat(
              Number(
                (parseFloat(data.cantidad) *
                  parseFloat(precioUnitarioR) *
                  parseFloat(data.porcentaje)) /
                  100
              ).toFixed(2)
            ),

            precio: parseFloat(precioUnitarioR),
            unidadMedida: unidadMedidaR,
            descripcionProducto: descripcionProducto,
            codigoProducto: codigoProducto,
            tipoDescuento: tipoDescuento,
            porcentaje: data.porcentaje,
          };
        } else {
          newItem = await {
            idE: new Date().toISOString(),

            productId: parseInt(productId),
            cantidad: parseFloat(data.cantidad),
            montoDescuento: parseFloat(0),

            precio: parseFloat(precioUnitarioR),
            unidadMedida: unidadMedidaR,
            descripcionProducto: descripcionProducto,
            codigoProducto: codigoProducto,
            tipoDescuento: tipoDescuento,
            porcentaje: data.porcentaje,
          };
        }

        setProductDetails([newItem, ...productDetails]);
        formikRegisterDetailBilling.resetForm({ data: "" });
        setTipoDescuento(1);
        Swal.fire({
          customClass: {
            container: "formRegisterDetailProduct",
            title: "formTitleRegisterDetailProduct",
          },
          icon: "success",
          title: "¡El producto ha sido registrado!",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoaderProduct(false);
        handleClose();
      }
    },
  });

  /********************************************************* Actualizar detalle de producto **********************************************************************/
  const formikEditDetailBilling = useFormik({
    initialValues: {
      cantidad: cantidad,
      montoDescuento: 0,
      porcentaje: 0,
    },
    validationSchema: ValidationEditDetailProduct(cantidadDescuento, precioUnitarioE),
    onSubmit: async (data) => {
      const listaActualizada = await productDetails.map((element) => {
        if (element.idE === idDetail) {
          if (tipoDescuento === 1) {
            return {
              ...element,
              idE: idDetail,

              productId: parseInt(productIdE),
              cantidad: parseFloat(data.cantidad),
              montoDescuento: parseFloat(data.montoDescuento),

              precio: parseFloat(precioUnitarioE),
              unidadMedida: unidadMedidaE,
              descripcionProducto: descripcionProducto,
              codigoProducto: codigoProducto,
              tipoDescuento: tipoDescuento,
              porcentaje: data.porcentaje,
            };
          } else if (tipoDescuento === 2) {
            return {
              ...element,
              idE: idDetail,

              productId: parseInt(productIdE),
              cantidad: parseFloat(data.cantidad),
              montoDescuento: parseFloat(
                Number(
                  (parseFloat(data.cantidad) *
                    parseFloat(precioUnitarioE) *
                    parseFloat(data.porcentaje)) /
                    100
                ).toFixed(2)
              ),

              precio: parseFloat(precioUnitarioE),
              unidadMedida: unidadMedidaE,
              descripcionProducto: descripcionProducto,
              codigoProducto: codigoProducto,
              tipoDescuento: tipoDescuento,
              porcentaje: data.porcentaje,
            };
          } else {
            return {
              ...element,
              idE: idDetail,

              productId: parseInt(productIdE),
              cantidad: parseFloat(data.cantidad),
              montoDescuento: parseFloat(0),

              precio: parseFloat(precioUnitarioE),
              unidadMedida: unidadMedidaE,
              descripcionProducto: descripcionProducto,
              codigoProducto: codigoProducto,
              tipoDescuento: tipoDescuento,
              porcentaje: data.porcentaje,
            };
          }
        }
        return element;
      });
      setIdDetail("");
      setProductDetails(listaActualizada);
      Swal.fire({
        customClass: {
          container: "formEditDetailProduct",
          title: "formTitleEditDetailProduct",
        },
        icon: "success",
        title: "¡El producto ha sido actualizado!",
        showConfirmButton: false,
        timer: 1500,
      });
      handleCloseEdit();
    },
  });

  const setValuesClient = (
    id,
    type,
    documento,
    complemento,
    razonSocial,
    email,
    numCelular
  ) => {
    setClientId(id);
    setTypeView(type);
    setDocumentoView(documento);
    setRazonSocialView(razonSocial);
    setEmailView(email);
    setNumCelularView(numCelular);
  };

  const getInitialSearch = () => {
    return {
      documentoSearch: "",
      complementoSearch: "",
      type: "",
    };
  };

  /*******************************************************************************************************************/
  const loadOptions2 = async (inputValue, callback) => {
    const response = await axios({
      method: "get",
      url:
        process.env.REACT_APP_API + "/api/v1/billing/client/find-client-full",
      params: {
        documento: inputValue,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
    ).catch(error => {
      handleError(error)
    }); 

    const toSelectOption = await response.data.map((sup) => ({
      label: sup.fullDocument + " - " + sup.razonSocial,
      value: sup.id,
    }));
    callback(toSelectOption);
  };

  /***************************************************** Buscar cliente ***************************************************************/
  const formikSearchClient = useFormik({
    initialValues: getInitialSearch(),
    validationSchema: ValidationSearchCustomer,
    onSubmit: async (data) => {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/client/find-client-full",
        params: {
          businessId: Number(localStorage.getItem("businessId")),
          documento: data.documentoSearch,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }).then(data => {
        if (data !== "") {
          setValuesClient(
            data.businessClientId,
            data.type,
            data.documento,
            data.complemento,
            data.razonSocial,
            data.email === null ? "" : data.email,
            data.numCelular === null ? "" : data.numCelular
          );
          if (codigoClasificador === 5) {
            verificarNIT(data.documentoSearch);
          } else {
            setNitValido("");
          }
          formikSearchClient.resetForm({ data: "" });
        }
        if (response.data === "") {
          formikSearchClient.resetForm({ data: "" });
        }
      });
    },
  });

  /************************************************* Registrar cliente **********************************************************************/
  const formikRegisterClient = useFormik({
    initialValues: {
      documento: "",
      complemento: "",
      razonSocial: "",
      email: "",
      numCelular: "",
      type: "",
    },
    validationSchema: ValidationRegisterCustomer,
    onSubmit: async (data) => {
      setLoader(true);
        const response = await axios({
          method: "post",
          url: process.env.REACT_APP_API + "/api/v1/billing/client/save",
          data: JSON.stringify({
            documento: data.documento,
            complemento: data.complemento,
            codigoClasificadorSiat: parseInt(codigoClasificadorSiat),
            razonSocial: data.razonSocial,
            email: data.email,
            numCelular: data.numCelular,
          }),
          headers: {
            "content-type": "application/json;charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
        ).then(data =>{
          handleCloseRegister();
          setCodigoClasificadorSiat(0);
          formikRegisterClient.resetForm({ data: "" });
          Swal.fire({
            customClass: {
              container: "formRegisterClient",
              title: "formTitleRegisterClient",
            },
            icon: "success",
            title: "¡El cliente ha sido registrado correctamente!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setLoader(false);
          getClient(response.data.businessClientId);
        }).catch(error => {
          setLoader(false);
          handleError(error, error.response.data.message);
        });    
    },
  });

  /********************************************************** Enviar factura ***************************************************************/
  const getInitialValues = () => {
    return {
      code: false,
      codeT: false,
      codeG: false,
      razonSocial99001: "",
      tipoCambio: 1,
      descuento: 0,
      gift: 0,
      tipoPago: "1",
      tarjeta1: "",
    };
  };
  const formikBilling = useFormik({
    initialValues: getInitialValues(),
    //validationSchema: ValidationBilling(productDetails, gift, o),
    onSubmit: async (data) => {
      setBoton(true);
      if (nitValido !== "NIT INEXISTENTE") {
        let emissionDate = "";
        if (codigoClasificador > 4 && shippingType === "OFFLINE") {
          emissionDate = value;
        } else {
          emissionDate = "";
        }
        try {
          const response = await axios({
            method: "post",
            url:
              process.env.REACT_APP_API + "/api/v1/billing/buyAndSellBilling",
            params: {
              cafc: cafc(),
            },
            data: JSON.stringify({
              pointOfSaleId: parseInt(localStorage.getItem("pointOfSaleId")),
              clientId: parseInt(clientId),
              codeEvent: parseInt(codeEvent),
              totalDescuento: parseFloat(data.descuento),
              shippingType: shippingType,
              checkNit: checkNit,
              emissionDate: emissionDate,
              razonSocial: data.razonSocial99001,
              codigoMetodoPago: parseInt(valuePay),
              numeroTarjeta: data.tarjeta1 === "0" ? "" : data.tarjeta1,
              codigoMoneda: parseInt(codigoMoneda),
              tipoCambio: parseFloat(data.tipoCambio),
              montoGiftCard: parseFloat(data.gift),
              productDetails: productDetails,
            }),
            headers: {
              "content-type": "application/json;charset=utf-8",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          })
          setIdBilling(response.data.cuf);
          typeBilling(response.data.codigoDescripcion);
          
          if (response.status === 200) {
            setValuePay(1);
            setO(0.0);
            setGift(0.0);
            setLabelPay("EFECTIVO");
            setProductDetails([]);
            setCodigoClasificadorSiat(0);
            formikBilling.resetForm({ data: "" });
            cleanFieldsClient();
            window.setTimeout(() => {
              setBoton(false);
            }, 2000);
            
          }
          
        } catch (error) {
          setBoton(false);
          handleError(error, error.response.data.message);
        }
      }
      if (nitValido === "NIT INEXISTENTE") {
        let emissionDate = "";
        if (codigoClasificador > 4 && shippingType === "OFFLINE") {
          emissionDate = value;
        } else {
          emissionDate = "";
        }
        Swal.fire({
          customClass: {
            container: "notFoundNIT",
            title: "notTitleFoundNIT",
          },
          title: "El NIT es inexistente",
          text: "¿Desea emitir la factura de todas formas?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          cancelButtonColor: "#94948f",
          confirmButtonColor: "#34974d",
          reverseButtons: true,
          confirmButtonText: "Aceptar",
        }).then((result) => {
          if (result.isConfirmed) {
            setBoton(true);
            (async () => {
              try {
                const response = await axios({
                  method: "post",
                  url:
                    process.env.REACT_APP_API +
                    "/api/v1/billing/buyAndSellBilling",
                  params: {
                    cafc: cafc(),
                  },
                  data: JSON.stringify({
                    pointOfSaleId: parseInt(
                      localStorage.getItem("pointOfSaleId")
                    ),
                    clientId: parseInt(clientId),
                    codeEvent: parseInt(codeEvent),
                    totalDescuento: parseFloat(data.descuento),
                    shippingType: shippingType,
                    checkNit: checkNit,
                    emissionDate: emissionDate,
                    razonSocial: data.razonSocial99001,
                    codigoMetodoPago: parseInt(valuePay),
                    numeroTarjeta: data.tarjeta1 === "0" ? "" : data.tarjeta1,
                    tipoFacturaDocumento: parseInt(
                      localStorage.getItem("tipoFacturaDocumento")
                    ),
                    codigoMoneda: parseInt(codigoMoneda),
                    tipoCambio: parseFloat(data.tipoCambio),
                    montoGiftCard: parseFloat(data.gift),
                    codigoDocumentoSector: parseInt(
                      localStorage.getItem("codigoDocumentoSector")
                    ),
                    productDetails: productDetails,
                  }),
                  headers: {
                    "content-type": "application/json;charset=utf-8",
                    Authorization:
                      "Bearer " + localStorage.getItem("accessToken"),
                  },
                });
                setIdBilling(response.data.cuf);
                typeBilling(response.data.codigoDescripcion);
                if (response.status === 200) {
                  setBoton(false);
                  setValuePay(1);
                  setO(0.0);
                  setGift(0.0);
                  setLabelPay("EFECTIVO");
                  setProductDetails([]);
                  setCodigoClasificadorSiat(0);
                  formikBilling.resetForm();
                  Swal.fire({
                    customClass: {
                      container: "sendBilling",
                      title: "sendTitleBilling",
                    },
                    icon: "success",
                    title: "!Se ha enviado exitosamente!",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                  });
                  cleanFieldsClient();
                }
              } catch (error) {
                setBoton(false);
                handleError(error);
              }
            })();
          }
          window.setTimeout(() => {
            setBoton(false);
          }, 2000);
        });
      }
    },
  });

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center"></Stack>
    );
  };

  const typeBilling = async (label) => {
    switch (label) {
      case "VALIDADA":
        handleOpenBilling();
        break;
      case "CONTINGENCIA":
        handleOpenBilling();
        break;
      case "TOTAL_MENOR":
        handleOpenMinorBilling();
        break;
      default:
        console.log("");
    }
  };

  return (
    <>
      <Accordion
        expanded={expanded1 === "panel1"}
        onChange={handleChange1("panel1")}
      >
        <AccordionSummary
          sx={{ backgroundColor: "#2c5e13", color: "#fff" }}
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            className="titleProduct"
            sx={{ fontSize: "32px", fontFamily: "Oswald" }}
          >
            Facturación simple
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} md={6} sx={{ marginBottom: "20px" }}>
              <button
                className="buttonRCBilling"
                type="button"
                onClick={() => handleOpenRegister()}
              >
                <span className="buttonRCBilling__text">Clientes</span>
                <span className="buttonRCBilling__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    viewBox="0 0 48 48"
                    height="48"
                    className="svgRCBilling"
                  >
                    <AddIcon sx={{ color: "white" }} />
                  </svg>
                </span>
              </button>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "right",
              }}
            >
              {specialOption === "Ninguno" ? (
                <>
                  <Box
                    className="searchClientBilling"
                    sx={{ zIndex: 999, width: "100%", paddingLeft: "5px" }}
                  >
                    <AsyncSelect
                      isSearchable
                      menuPosition="fixed"
                      styles={CustomDropdownStyle}
                      loadOptions={loadOptions2}
                      value={searchClient}
                      noOptionsMessage={() => "Escriba para buscar clientes"}
                      placeholder={"Buscar cliente"}
                      onChange={(e) => handleSearchClient(e)}
                    />
                  </Box>
                </>
              ) : (
                " "
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
              <hr />
              <Typography
                className="labelClient"
                sx={{ fontSize: "26px", fontWeight: 400, fontFamily: "Oswald" }}
              >
                Datos básicos del contribuyente
              </Typography>
              <hr />
            </Grid>
            <Grid item xs={12} md={12} sx={{ display: "none" }}>
              <TextField value={clientId} />
            </Grid>
            {specialOption === "Ninguno" ? (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={typeView}
                    variant="outlined"
                    label="Tipo de documento (Cliente)"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={documentoView}
                    variant="outlined"
                    label="Número de documento/NIT (Cliente)"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={razonSocialView}
                    variant="outlined"
                    label="Razón social (Cliente)"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={emailView}
                    onChange={(e) => e.target.value}
                    variant="outlined"
                    label="Correo electrónico (Cliente)"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={numCelularView}
                    onChange={(e) => e.target.value}
                    variant="outlined"
                    label="Número de celular (Cliente)"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Fecha de emisión"
                        defaultValue={dayjs(today)}
                        disabled
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </>
            ) : (
              " "
            )}
            <Grid item xs={12} md={6} sx={{ zIndex: 999 }}>
              <Select
                styles={CustomDropdownStyle}
                options={options}
                onChange={(e) => handleChangeFactura(e)}
                value={options.value}
                defaultValue={options[0]}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ zIndex: 99 }}>
              <Select
                ref={inputRef}
                styles={CustomDropdownStyle}
                options={optionsSpecialCases}
                onChange={(e) => handleSpecialOptions(e)}
                placeholder={"Casos especiales"}
                value={optionsSpecialCases.value}
                defaultValue={optionsSpecialCases[0]}
              />
            </Grid>
            {specialOption === "99001" ? (
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    label="Razón social"
                    id="razonSocial99001"
                    name="razonSocial99001"
                    value={formikBilling.values.razonSocial99001}
                    onBlur={formikBilling.handleBlur}
                    onChange={formikBilling.handleChange}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikBilling.touched.razonSocial99001 &&
                      formikBilling.errors.razonSocial99001}
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded2 === "panel2"}
        onChange={handleChange2("panel2")}
        sx={{ display: showDropdown }}
      >
        <AccordionSummary
          sx={{ backgroundColor: "#2c5e13", color: "white" }}
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Emisión de factura por contingencia</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: showDropdown, zIndex: 9 }}
            >
              <AsyncSelect
                styles={CustomDropdownStyle}
                defaultOptions={true}
                getOptionLabel={(e) => e.descripcion}
                getOptionValue={(e) => e.codigoClasificador}
                loadOptions={getSigniticantEvents}
                isSearchable={true}
                noOptionsMessage={() => "No existen elementos"}
                placeholder={"Descripción del evento significativo"}
                onChange={handleChangeEvent}
              />
            </Grid>

            <Grid item xs={12} md={12} sx={{ display: showDropdownCafc }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  defaultValue={dayjs(value)}
                  onChange={(e) => handleChangeDate(e)}
                  views={["day", "month", "year"]}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: "small",
                      placeholder: dayjs(Date.now()).format(
                        "YYYY-MM-DDTHH:mm:ss.SSS"
                      ),
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid
              item
              xs={12}
              md={10}
              sx={{ display: showDropdownCafc, zIndex: 8 }}
            >
              <AsyncSelect
                styles={CustomDropdownStyle}
                defaultOptions={true}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                loadOptions={getListCafc}
                isSearchable={true}
                noOptionsMessage={() => "No existen elementos"}
                placeholder={"Codigo Cafc"}
                onChange={handleChangeCafc}
              />
            </Grid>
            <Grid item xs={12} md={2} sx={{ display: showDropdownCafc }}>
              <button
                className="buttonRCBilling"
                style={{ top: "0px" }}
                type="button"
                onClick={handleOpenCafc}
              >
                <span className="buttonRCBilling__text">CAFC</span>
                <span className="buttonRCBilling__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    viewBox="0 0 48 48"
                    height="48"
                    className="svgRCBilling"
                  >
                    <AddIcon sx={{ color: "white" }} />
                  </svg>
                </span>
              </button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* *************************************************************** */}
      <form onSubmit={formikBilling.handleSubmit}>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ marginBottom: "20px" }}>
            <button
              style={{ marginLeft: "16px" }}
              className="buttonRDBilling"
              type="button"
              onClick={() => handleOpen()}
            >
              <span className="buttonRDBilling__text">Productos</span>
              <span className="buttonRDBilling__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  viewBox="0 0 48 48"
                  height="48"
                  className="svgRDBilling"
                >
                  <AddIcon sx={{ color: "white" }} />
                </svg>
              </span>
            </button>
          </Grid>

          {productDetails.length > 0 ? (
            <Grid
              item
              xs={4}
              md={4}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
                marginTop: "20px",
              }}
            >
              <Typography
                className="labelPay"
                sx={{
                  fontSize: "26px",
                  fontFamily: "Oswald",
                }}
              >
                Pagos:{" "}
              </Typography>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={(e) => handleOpenMethodPay(e)}
              >
                <AddCardIcon
                  fontSize="medium"
                />
              </IconButton>
            </Grid>
          ) : (
            ""
          )}

          <Grid
            item
            xs={8}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              paddingRight: "10px",
              marginTop: "20px",
            }}
          >
            <Typography
              className="labelTotal"
              sx={{
                marginLeft: "20px",
                fontSize: "26px",
                fontFamily: "Oswald",
              }}
            >
              Subtotal:{" "}
              {Number(
                productDetails
                  .map(
                    (item) => item.cantidad * item.precio - item.montoDescuento
                  )
                  .reduce((prev, curr) => prev + curr, 0)
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              marginTop: "20px",
            }}
          >
            <FormControl
              fullWidth
              sx={{
                display: "flex",
                paddingLeft: "20px",
              }}
            >
              {productDetails.length > 0 ? (
                <>
                  <TextField
                    fullWidth
                    ref={descuentoRef}
                    variant="outlined"
                    label="Descuento"
                    size="small"
                    id="descuento"
                    onClick={(e) => handleSelectGlobalDescuento(e)}
                    name="descuento"
                    onBlur={formikBilling.handleBlur}
                    onChange={(e) => {
                      parseFloat(e.target.value) < 0 || e.target.value === ''
                        ? setO(parseFloat(0))
                        : setO(parseFloat(e.target.value));
                      formikBilling.handleChange(e);
                    }}
                    value={formikBilling.values.descuento || 0}
                  />
                </>
              ) : (
                " "
              )}
              <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                {formikBilling.touched.descuento &&
                  formikBilling.errors.descuento}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Box
            width="100%"
            sx={{
              height: 350,
              width: "100%",
              marginTop: "20px",
              marginBottom: "10px",
              "& .super-app-theme--header": {
                backgroundColor: "whitesmoke",
                fontFamily: "Cambria",
                fontWeight: 400,
                fontSize: "16px",
                color: "black",
              },
            }}
          >
            {/* <ThemeProvider theme={theme}> */}
            <DataGrid
              columns={matches ? columns2 : columns1}
              rows={rows}
              pageSize={100}
              rowsPerPageOptions={[100]}
              components={{
                NoRowsOverlay,
              }}
              getRowId={(row) => row.internalId}
              disableColumnMenu={true}
              disableColumnFilter={true}
              disableColumnSelector={true}
              disableSelectionOnClick
              componentsProps={{
                pagination: {
                  labelRowsPerPage: "Filas por página",
                },
              }}
            />
            {/* </ThemeProvider> */}
          </Box>

          {productDetails.length > 0 ? (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <p className="montoPagar" style={{ fontFamily: "Oswald" }}>
                  Monto total a pagar: Bs {" "}
                  {Number(
                    productDetails
                      .map(
                        (item) =>
                          item.cantidad * item.precio - item.montoDescuento
                      )
                      .reduce((prev, curr) => prev + curr, 0) <
                      o + gift
                      ? "0"
                      : Number(
                          productDetails
                            .map(
                              (item) =>
                                item.cantidad * item.precio -
                                item.montoDescuento
                            )
                            .reduce((prev, curr) => prev + curr, 0)
                            .toFixed(2)
                        ) -
                          Number(o).toFixed(2) -
                          gift.toFixed(2)
                  ).toFixed(2)}{" "}
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                {boton ? (
                  <button
                    type="submit"
                    style={{ width: "150px", marginTop: "20px" }}
                    disabled={boton}
                    className="buttonLoading"
                    onClick={() => handleCheckErrors()}
                  >
                    <span className="buttonLoading__text">Enviando...</span>
                    <span className="buttonLoading__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        viewBox="0 0 48 48"
                        height="48"
                        className="svgLoading"
                      >
                        <RotateLeftIcon
                          fontSize="small"
                          sx={{ color: "white" }}
                        />
                      </svg>
                    </span>
                  </button>
                ) : (
                  <button
                    style={{ width: "150px", marginTop: "20px" }}
                    className="buttonSendData"
                    type="submit"
                    onClick={() => handleCheckErrors()}
                  >
                    <span className="buttonSendData__text">Emitir factura</span>
                    <span className="buttonSendData__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        viewBox="0 0 48 48"
                        height="48"
                        className="svgSendData"
                      >
                        <SendIcon sx={{ color: "white" }} />
                      </svg>
                    </span>
                  </button>
                )}
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px" }}></Grid>
            </>
          ) : (
            " "
          )}
        </Grid>
      </form>

      {/********************************************** Ventana modal para el registro de detalle de producto *****************************************/}

      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "green", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Detalles de la transacción
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikRegisterDetailBilling.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ zIndex: 7 }}>
                <Typography className="labelFormClient">
                  Seleccione un producto
                </Typography>
                <AsyncSelect
                  menuPosition="fixed"
                  styles={CustomDropdownStyle}
                  defaultOptions
                  name="product"
                  loadOptions={loadOptions}
                  isSearchable={true}
                  noOptionsMessage={() => "Escribe para buscar productos"}
                  placeholder={"Seleccionar..."}
                  onChange={(e) => handleChangeProduct(e)}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="Número de documento"
                  size="small"
                  fullWidth
                  name="type"
                  onBlur={formikRegisterDetailBilling.handleBlur}
                  onChange={formikRegisterDetailBilling.handleChange}
                  value={formikRegisterDetailBilling.values.type}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {type === "" &&
                    formikRegisterDetailBilling.touched.type &&
                    formikRegisterDetailBilling.errors.type}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                  Unidad de medida
                </Typography>
                <TextField
                  disabled
                  value={unidadMedidaR}
                  onChange={(e) => setUnidadMedidaR(e.target.value)}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: {
                      borderRadius: "0px",
                      border: "1px solid white",
                      borderColor: "white",
                    },
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="Número de documento"
                  size="small"
                  fullWidth
                  name="type"
                  onBlur={formikRegisterDetailBilling.handleBlur}
                  onChange={formikRegisterDetailBilling.handleChange}
                  value={formikRegisterDetailBilling.values.type}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {type === "" &&
                    formikRegisterDetailBilling.touched.type &&
                    formikRegisterDetailBilling.errors.type}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                  Precio unitario
                </Typography>
                <TextField
                  disabled
                  value={Number(precioUnitarioR).toFixed(2)}
                  onChange={(e) => setPrecioUnitarioR(e.target.value)}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: {
                      borderRadius: "0px",
                      border: "1px solid white",
                      borderColor: "white",
                    },
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="Número de documento"
                  size="small"
                  fullWidth
                  name="type"
                  onBlur={formikRegisterDetailBilling.handleBlur}
                  onChange={formikRegisterDetailBilling.handleChange}
                  value={formikRegisterDetailBilling.values.type}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {type === "" &&
                    formikRegisterDetailBilling.touched.type &&
                    formikRegisterDetailBilling.errors.type}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">Cantidad</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    id="cantidad"
                    onClick={(e) => handleSelectCantidad(e)}
                    name="cantidad"
                    onBlur={formikRegisterDetailBilling.handleBlur}
                    value={formikRegisterDetailBilling.values.cantidad}
                    onChange={handleNumericFieldChange}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterDetailBilling.touched.cantidad &&
                      formikRegisterDetailBilling.errors.cantidad}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {tipoDescuento === 1 ? (
                <Grid item xs={12} md={9}>
                  <FormControl fullWidth>
                    <Typography className="labelFormClient">
                      Descuento (Bs)
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      id="montoDescuento"
                      onClick={(e) => handleSelectDescuento()}
                      name="montoDescuento"
                      onBlur={formikRegisterDetailBilling.handleBlur}
                      onChange={formikRegisterDetailBilling.handleChange}
                      value={formikRegisterDetailBilling.values.montoDescuento}
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                          border: "1px solid white",
                          borderColor: "white",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikRegisterDetailBilling.touched.montoDescuento &&
                        formikRegisterDetailBilling.errors.montoDescuento}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12} md={9}>
                  <FormControl fullWidth>
                    <Typography className="labelFormClient">
                      Descuento (%)
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      id="porcentaje"
                      onClick={(e) => handleSelectPorcentaje()}
                      name="porcentaje"
                      onBlur={formikRegisterDetailBilling.handleBlur}
                      onChange={formikRegisterDetailBilling.handleChange}
                      value={formikRegisterDetailBilling.values.porcentaje}
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                          border: "1px solid white",
                          borderColor: "white",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikRegisterDetailBilling.touched.porcentaje &&
                        formikRegisterDetailBilling.errors.porcentaje}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <Typography
                  className="labelFormClient"
                  sx={{ visibility: "hidden" }}
                >
                  Bs/%
                </Typography>
                <Select
                  styles={CustomDropdownStyle}
                  onChange={(e) => handleChangeTipoDescuento(e)}
                  options={tiposDescuento}
                  value={tiposDescuento.value}
                  defaultValue={tiposDescuento[0]}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() =>
                  handleClose(
                    formikRegisterDetailBilling.resetForm({ data: "" })
                  )
                }
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loaderProduct ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
                auxProduct ? (
                <button className="buttonRegisterData" type="submit">
                  <span className="buttonRegisterData">Registrar</span>
                </button>
                ) : ("")
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      {/********************************************** Ventana modal para actualizar detalle de producto *****************************************/}

      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={openEdit}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "#D1B000", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Editar datos de la transacción
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikEditDetailBilling.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ display: "none" }}>
                <TextField
                  value={idDetail}
                  onChange={(e) => setIdDetail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ zIndex: 7 }}>
                <Typography className="labelFormClient">
                  Seleccione un producto
                </Typography>
                <AsyncSelect
                  styles={CustomDropdownEdit}
                  value={productDetails.filter(
                    (element) => element.label === descripcionProducto
                  )}
                  loadOptions={loadOptions}
                  isSearchable={true}
                  noOptionsMessage={() => "No existen elementos"}
                  placeholder={descripcionProducto}
                  onChange={handleChangeProductEdit}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                  Unidad de medida
                </Typography>
                <TextField
                  disabled
                  value={unidadMedidaE}
                  onChange={(e) => setUnidadMedidaE(e.target.value)}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: {
                      borderRadius: "0px",
                      border: "1px solid white",
                      borderColor: "white",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                  Precio unitario
                </Typography>
                <TextField
                  disabled
                  value={precioUnitarioE}
                  onChange={(e) => setPrecioUnitarioE(e.target.value)}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: {
                      borderRadius: "0px",
                      border: "1px solid white",
                      borderColor: "white",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">Cantidad</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    id="cantidad"
                    onClick={(e) => handleSelectCantidad(e)}
                    name="cantidad"
                    value={formikEditDetailBilling.values.cantidad}
                    onChange={handleNumericFieldChangeEdit}
                    onBlur={formikEditDetailBilling.handleBlur}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikEditDetailBilling.touched.cantidad &&
                      formikEditDetailBilling.errors.cantidad}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {tipoDescuento === 1 ? (
                <Grid item xs={12} md={9}>
                  <FormControl fullWidth>
                    <Typography className="labelFormClient">
                      Descuento (Bs)
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      id="montoDescuentoEdit"
                      onClick={(e) => handleSelectDescuentoEdit()}
                      name="montoDescuento"
                      onBlur={formikEditDetailBilling.handleBlur}
                      onChange={formikEditDetailBilling.handleChange}
                      value={formikEditDetailBilling.values.montoDescuento}
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                          border: "1px solid white",
                          borderColor: "white",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikEditDetailBilling.touched.montoDescuento &&
                        formikEditDetailBilling.errors.montoDescuento}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12} md={9}>
                  <FormControl fullWidth>
                    <Typography className="labelFormClient">
                      Descuento (%)
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      id="porcentajeEdit"
                      onClick={(e) => handleSelectPorcentajeEdit(e)}
                      name="porcentaje"
                      onBlur={formikEditDetailBilling.handleBlur}
                      value={formikEditDetailBilling.values.porcentaje}
                      onChange={formikEditDetailBilling.handleChange}
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                          border: "1px solid white",
                          borderColor: "white",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikEditDetailBilling.touched.porcentaje &&
                        formikEditDetailBilling.errors.porcentaje}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <Typography
                  className="labelFormClient"
                  sx={{ visibility: "hidden" }}
                >
                  Bs/%
                </Typography>
                <Select
                  styles={CustomDropdownEdit}
                  value={tiposDescuento.filter(
                    (element) => element.value === tipoDescuento
                  )}
                  options={tiposDescuento}
                  onChange={(e) => handleChangeTipoDescuentoEdit(e)}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleCloseEdit()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              <button
                className="buttonRegisterData"
                style={{ border: "1px solid #D1B000" }}
                type="submit"
              >
                <span className="buttonEdit">Actualizar</span>
              </button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      {/********************************************** Ventana modal para el registro de CAFC *****************************************/}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={openCafc}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            className="titleProduct"
            sx={{
              color: "gray",
              fontFamily: "Oswald",
              fontSize: "30px",
              marginTop: "15px",
            }}
          >
            Registrar código Cafc
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={(e) => handleSubmitCafc(e)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ textAlign: "start" }}>
                <Typography className="labelFormClient">Código CAFC</Typography>
                <TextField
                  value={codigoCafc}
                  onChange={(e) => setCodigoCafc(e.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleCloseCafc()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              <button className="buttonRegisterData" type="submit">
                <span className="buttonRegisterData">Registrar</span>
              </button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      {/********************************************** Ventana modal para métodos de pago *****************************************/}
      <Dialog
        fullScreen
        open={openMethodPay}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="contentDialog">
          {formikBilling.touched.tipoCambio &&
          formikBilling.errors.tipoCambio ? (
            <button
              className="buttonGoBack"
              type="button"
              onClick={(e) => handleCloseMethodPay(e)}
              style={{ marginBottom: "40px" }}
            >
              <span className="buttonGoBack__text">Cerrar</span>
              <span className="buttonGoBack__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  viewBox="0 0 48 48"
                  height="48"
                  className="svgGoBack"
                >
                  <CloseIcon sx={{ color: "white" }} />
                </svg>
              </span>
            </button>
          ) : (
            <button
              className="buttonGoBack"
              type="button"
              onClick={(e) => handleCloseMethodPay(e)}
              style={{ marginBottom: "40px" }}
            >
              <span className="buttonGoBack__text">Cerrar</span>
              <span className="buttonGoBack__textSmall">
                <CloseIcon sx={{ color: "white", borderRadius: "50%" }} />
              </span>
              <span className="buttonGoBack__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  viewBox="0 0 48 48"
                  height="48"
                  className="svgGoBack"
                >
                  <CloseIcon sx={{ color: "white" }} />
                </svg>
              </span>
            </button>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  marginRight: "10px",
                  fontFamily: "Oswald",
                }}
              >
                Tipo de cambio
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  color: "gray",
                  fontFamily: "Oswald",
                }}
              >
                Tipo de moneda:
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Select
                //  fullWidth
                options={tipoMoneda}
                placeholder={"Seleccione el tipo de moneda"}
                sx={{ width: "100%" }}
                styles={CustomDropdownStyle}
                isSearchable={true}
                noOptionsMessage={() => "No existen elementos"}
                onChange={(e) => handleChangeMoneda(e)}
                value={tipoMoneda.filter(
                  (element) => element.label === labelMoneda
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  color: "gray",
                  fontFamily: "Oswald",
                }}
              >
                Tipo de cambio:
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  size="small"
                  id="tipoCambio"
                  name="tipoCambio"
                  onClick={(e) => handleSelectTipoCambio(e)}
                  onBlur={formikBilling.handleBlur}
                  onChange={(e) => {
                    formikBilling.handleChange(e);
                  }}
                  value={formikBilling.values.tipoCambio}
                />
                <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                  {formikBilling.touched.tipoCambio &&
                    formikBilling.errors.tipoCambio}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  marginRight: "10px",
                  fontFamily: "Oswald",
                }}
              >
                Método de pago
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  color: "gray",
                  marginRight: "10px",
                  fontFamily: "Oswald",
                }}
              >
                Método de pago:
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} sx={{ marginTop: "10px" }}>
              <Select
                autoFocus
                sx={{ width: "100%" }}
                styles={CustomDropdownStyle}
                options={methodPayList}
                isSearchable={true}
                noOptionsMessage={() => "No existen elementos"}
                placeholder={"Seleccione un método de pago"}
                onChange={(e) => handleChangePay(e)}
                value={methodPayList.filter(
                  (element) => element.label === labelPay
                )}
              />
            </Grid>
            {labelPay.includes("GIFT") ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    margintTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 400,
                      color: "gray",
                      fontFamily: "Oswald",
                    }}
                  >
                    Gift card:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="gift"
                      name="gift"
                      onClick={(e) => handleSelectGift(e)}
                      onBlur={formikBilling.handleBlur}
                      onChange={(e) => {
                        e.target.value < 0
                          ? setGift(parseFloat(0))
                          : setGift(parseFloat(e.target.value));
                        formikBilling.handleChange(e);
                      }}
                      value={formikBilling.values.gift}
                    />
                    <FormHelperText
                      sx={{
                        color: "chocolate",
                        fontSize: "14px",
                      }}
                    >
                      {formikBilling.touched.gift && formikBilling.errors.gift}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            ) : (
              ""
            )}
            {labelPay.includes("TARJETA") ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 400,
                      color: "gray",
                      fontFamily: "Oswald",
                    }}
                  >
                    Número de tarjeta:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  sx={{
                    display: "flex",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      ref={metodosRef}
                      size="small"
                      fullWidth
                      autoFocus
                      id="tarjeta1"
                      name="tarjeta1"
                      onClick={(e) => handleSelectTarjeta1(e)}
                      onBlur={formikBilling.handleBlur}
                      onChange={formikBilling.handleChange}
                      value={formikBilling.values.tarjeta1}
                    />
                    <FormHelperText
                      sx={{
                        color: "chocolate",
                        fontSize: "14px",
                        marginTop: "0px",
                      }}
                    >
                      {formikBilling.touched.tarjeta1 &&
                        formikBilling.errors.tarjeta1}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <Modal open={openMinorBilling} 
     // onClose={handleCloseMinorBilling}
      >
        <Box>
          <Paper elevation={4} sx={styleViewBillings}>
          <Box display={"flex"} justifyContent={"flex-end"} >
              <DisabledByDefaultSharpIcon onClick={() =>handleCloseMinorBilling()} fontSize="medium"/>
            </Box> 
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Oswald",
                    fontWeight: 400,
                    fontSize: "28px",
                  }}
                >
                  ¿Deseas ver la factura?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <button
                  style={{ width: "150px", marginTop: "20px" }}
                  className="buttonSendData"
                  onClick={() => navigate("/dashboard/send_billing_minor")}
                >
                  <span className="buttonSendData__text">Redireccionar</span>
                  <span className="buttonSendData__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      viewBox="0 0 48 48"
                      height="48"
                      className="svgSendData"
                    >
                      <SendIcon sx={{ color: "white" }} />
                    </svg>
                  </span>
                </button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
      <Modal open={openBilling} id="viewBilling">
        <Box>
          <Paper elevation={4} sx={styleViewBillings}>
            <Box display={"flex"} justifyContent={"flex-end"} >
              <DisabledByDefaultSharpIcon onClick={() =>handleCloseBilling()} fontSize="medium"/>
            </Box>
            <Grid container>
              <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    fontFamily: "Oswald",
                    fontWeight: 400,
                    fontSize: "28px",
                  }}
                >
                  ¿Cómo desea imprimir la factura?
                </Typography>
              </Grid>
            </Grid>
            <Grid item sx={{ textAlign: "center", justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                  marginTop: "10px",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    color: "chocolate",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Oswald",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", width: "120px" }}
                      key={idBilling}
                      href={
                        process.env.REACT_APP_API +
                        "/api/v1/pdf/billing/show?cuf=" +
                        idBilling +
                        "&modality=" +
                        localStorage.getItem("modality") +
                        "&emissionCode=ONLINE&typePdf=ROLLO"
                      }
                      target="_blank"
                      className="buttonEdit"
                    >
                      <span
                        className="buttonEdit__text"
                        style={{ fontSize: "16px", fontWeight: 400 }}
                      >
                        Rollo
                      </span>
                      <span className="buttonEdit__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          viewBox="0 0 48 48"
                          height="48"
                          className="svgEdit"
                        >
                          <ReceiptLongIcon sx={{ color: "white" }} />
                        </svg>
                      </span>
                    </a>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    color: "goldenrod",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Oswald",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", width: "120px" }}
                      key={idBilling + 1}
                      href={
                        process.env.REACT_APP_API +
                        "/api/v1/pdf/billing/show?cuf=" +
                        idBilling +
                        "&modality=" +
                        localStorage.getItem("modality") +
                        "&emissionCode=ONLINE&typePdf=MEDIA"
                      }
                      target="_blank"
                      className="button"
                    >
                      <span
                        className="button__text"
                        style={{ fontSize: "16px", fontWeight: 400 }}
                      >
                        1/2 Página
                      </span>
                      <span className="button__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          viewBox="0 0 48 48"
                          height="48"
                          className="svg"
                        >
                          <ArticleIcon sx={{ color: "white" }} />
                        </svg>
                      </span>
                    </a>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Paper>
        </Box>
      </Modal>

      {/********************************************** Ventana modal para el registro de clientes *****************************************/}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={openRegister}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "green", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Registrar cliente
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikRegisterClient.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ zIndex: 9 }}>
                <Typography className="labelFormClient">
                  Seleccione el tipo de documento
                </Typography>
                <Select
                  isSearchable
                  menuPosition="fixed"
                  styles={CustomDropdownStyle}
                  placeholder="Seleccionar..."
                  options={tiposDocumentos}
                  onChange={(e) => {
                    handleChangeCliente(e);
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="type"
                  onBlur={formikRegisterClient.handleBlur}
                  onChange={formikRegisterClient.handleChange}
                  value={formikRegisterClient.values.type}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {type === "" &&
                    formikRegisterClient.touched.type &&
                    formikRegisterClient.errors.type}
                </FormHelperText>
              </FormControl>

              {codigoClasificadorSiat !== 1 ? (
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <Typography className="labelFormClient">
                      Número de documento
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      name="documento"
                      onBlur={formikRegisterClient.handleBlur}
                      onChange={formikRegisterClient.handleChange}
                      value={formikRegisterClient.values.documento}
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                          border: "1px solid white",
                          borderColor: "white",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikRegisterClient.touched.documento &&
                        formikRegisterClient.errors.documento}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <>
                  <Grid item xs={7} md={7}>
                    <FormControl fullWidth>
                      <Typography className="labelFormClient">
                        Número de documento
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="documento"
                        onBlur={formikRegisterClient.handleBlur}
                        onChange={formikRegisterClient.handleChange}
                        value={formikRegisterClient.values.documento}
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: "0px",
                            border: "1px solid white",
                            borderColor: "white",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{ color: "chocolate", fontSize: "14px" }}
                      >
                        {formikRegisterClient.touched.documento &&
                          formikRegisterClient.errors.documento}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5} md={5}>
                    <FormControl fullWidth>
                      <Typography className="labelFormClient">
                        Complemento
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="complemento"
                        onBlur={formikRegisterClient.handleBlur}
                        onChange={formikRegisterClient.handleChange}
                        value={formikRegisterClient.values.complemento}
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: "0px",
                            border: "1px solid white",
                            borderColor: "white",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{ color: "chocolate", fontSize: "14px" }}
                      >
                        {formikRegisterClient.touched.complemento &&
                          formikRegisterClient.errors.complemento}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Razón social
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="razonSocial"
                    onBlur={formikRegisterClient.handleBlur}
                    onChange={formikRegisterClient.handleChange}
                    value={formikRegisterClient.values.razonSocial}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterClient.touched.razonSocial &&
                      formikRegisterClient.errors.razonSocial}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Correo electrónico
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="email"
                    onBlur={formikRegisterClient.handleBlur}
                    onChange={formikRegisterClient.handleChange}
                    value={formikRegisterClient.values.email}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterClient.touched.email &&
                      formikRegisterClient.errors.email}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Número de celular
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="numCelular"
                    onBlur={formikRegisterClient.handleBlur}
                    onChange={formikRegisterClient.handleChange}
                    value={formikRegisterClient.values.numCelular}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterClient.touched.numCelular &&
                      formikRegisterClient.errors.numCelular}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={(e) => handleCloseRegister(e)}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cancelar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
                <button className="buttonRegisterData" type="submit">
                  <span className="buttonRegisterData">Registrar</span>
                </button>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <Grid item xs={12}>
        <Tooltip id="detail-tooltip" place="right" />
      </Grid>
    </>
  );
};
export default FormBillding;
