import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import ValidationCancellBilling from "../validation/ValidationCancellBilling";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import { useFormik } from "formik";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import "./ButtonDisableClient.css";
import "./ButtonCancelBilling.css";
import { useDeleteCuf } from "../hooks/useDeleteCuf";
import handleError from "./utilities/HandleError"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonCancelCUF = ({ id }) => {
  const [listaCancelacion, setListaCancelacion] = useState([]);
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = (ide) => {
    getCUF(ide);
    getListCancellationReason();
    setOpen(true);
  };
  const handleClose = () => {
    formikCancell.resetForm();
    setOpen(false);
  };

  const handleChangeType = (e) => {
    formikCancell.setFieldValue("type", e.value);
  };

  const getListCancellationReason = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "MOTIVO_ANULACION",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setListaCancelacion(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getCUF = async (ide) => {
    
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API + "/api/v1/billing/cufObservation/" + ide,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }).then(data =>{
        formikCancell.setFieldValue("cuf", data.data.cuf);
        formikCancell.setFieldValue(
          "codigoDescripcion",
          data.data.codigoDescripcion
        );
        formikCancell.setFieldValue("codigoEstado", data.data.codigoEstado);
        formikCancell.setFieldValue("nit", data.data.businessNit);
        formikCancell.setFieldValue("modalidad", data.data.modalidad);
      }).catch(error => {
        handleError(error);
      });
     
    
  };

  const getValue = () => {
    return {
      type: "",
      cuf: "",
      codigoDescripcion: "",
      codigoEstado: "",
      nit: "",
      modalidad: "",
    };
  };

  const { mutate } = useDeleteCuf();

  const formikCancell = useFormik({
    initialValues: getValue(),
    validationSchema: ValidationCancellBilling,
    onSubmit: async (data) => {
      data.observationId = id;
      data.codigoMotivo = data.type;
      Swal.fire({
        customClass: {
          container: "formCancelBilling",
          title: "formTitleCancelBilling",
        },
        title: "¿Estás seguro de anular la factura?",
        text: "¡No serás capaz de revertir los cambios!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#94948f",
        confirmButtonColor: "#34974d",
        confirmButtonText: "Aceptar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          mutate(data, {
            onSuccess: () => {
              handleClose();
              Swal.fire({
                customClass: {
                  container: "formCancelBilling",
                  title: "formTitleCancelBilling",
                },
                icon: "success",
                title: "¡La factura ha sido anulada!",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            },
            onError: (error) => {
              if (error.response.status === 504) {
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Lo sentimos!",
                  text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
              if (
                error.response.status === 500 &&
                error.response.status === 503
              ) {
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Ha ocurrido un error!",
                  text: "Por favor comuniquese con el administrador",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
              if (error.request.status === 422) {
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Ha ocurrido un error!",
                  text: error.response.data.errors.map(
                    (element) => element.message
                  ),
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
              if (error.request.status === 404) {
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Ha ocurrido un error!",
                  text: error.response.data.message,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
              if (error.request.status === 400) {
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Ha ocurrido un error!",
                  text: error.response.data.message,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
              if (error.response.status >= 0 && error.response.status <= 99) {
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Ha ocurrido un problema de conectividad!",
                  text: "Por favor vuelva a intentarlo más tarde",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
            },
          });
        }
      });
    },
  });
  return (
    <>
      <button className="button" type="button" onClick={() => handleOpen(id)}>
        <span className="button__text">Anular</span>
        <span className="button__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svg"
          >
            <DeleteIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ backgroundColor: 'green', margin: "20px 23px 15px 23px"}}>
          <Typography
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Motivo de la anulación
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikCancell.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ zIndex: 9 }}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                      Seleccione un motivo de la anulación
                  </Typography>
                  <Select
                    isSearchable
                    menuPosition="fixed"
                    placeholder="Seleccionar"
                    styles={CustomDropdownStyle}
                    options={listaCancelacion}
                    onChange={(e) => {
                      handleChangeType(e);
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                      marginLeft: "20px",
                      marginTop: "0px",
                    }}
                  >
                    {formikCancell.values.type === "" &&
                      formikCancell.touched.type &&
                      formikCancell.errors.type}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                      Código CUF
                  </Typography>
                  <TextField
                    disabled={true}
                    size="small"
                    fullWidth
                    name="cuf"
                    onChange={formikCancell.handleChange}
                    value={formikCancell.values.cuf}
                    variant="outlined"
                    InputProps={{
                      disabled: true,
                      style: {
                        backgroundColor: "#eae9e9",
                        color: "white"
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                     Código descripción
                  </Typography>
                  <TextField
                    disabled={true}
                    size="small"
                    fullWidth
                    name="codigoDescripcion"
                    onChange={formikCancell.handleChange}
                    value={formikCancell.values.codigoDescripcion}
                    variant="outlined"
                    InputProps={{
                      disabled: true,
                      style: {
                        backgroundColor: "#eae9e9",
                        color: "white"
                      },
                    }}
                  />
                </FormControl>
              </Grid>   
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    NIT
                  </Typography>
                  <TextField
                    disabled={true}
                    size="small"
                    fullWidth
                    name="nit"
                    onChange={formikCancell.handleChange}
                    value={formikCancell.values.nit}
                    variant="outlined"
                    InputProps={{
                      disabled: true,
                      style: {
                        backgroundColor: "#eae9e9",
                        color: "white"
                      },
                    }}
                  />
                </FormControl>
              </Grid>  
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Código de estado
                  </Typography>
                  <TextField
                    disabled={true}
                    size="small"
                    fullWidth
                    name="codigoEstado"
                    onChange={formikCancell.handleChange}
                    value={formikCancell.values.codigoEstado}
                    variant="outlined"
                    InputProps={{
                      disabled: true,
                      style: {
                        backgroundColor: "#eae9e9",
                        color: "white"
                      },
                    }}
                  />
                </FormControl>
              </Grid>   
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Modalidad
                  </Typography>
                  <TextField
                    disabled={true}                  
                    size="small"
                    fullWidth
                    name="modalidad"
                    onChange={formikCancell.handleChange}
                    value={formikCancell.values.modalidad}
                    variant="outlined"
                    InputProps={{
                      disabled: true,
                      style: {
                        backgroundColor: "#eae9e9",
                        color: "white"
                      },
                    }}
                  />
                </FormControl>
              </Grid>                                                     
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              <button className="buttonRegisterData" type="submit" >
                <span className="buttonRegisterData">Anular</span>
              </button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonCancelCUF;
