import { useMutation, useQueryClient} from 'react-query';
import axios from 'axios';

const editUser = async (payload) => {
  const { data } = await axios.put(
    process.env.REACT_APP_API + "/api/v1/user/" + payload.id,
    payload,
    {
      headers: {
        "Content-type": "application/json;charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
  return data;
};


export const useEditUser = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: editUser,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['get-users']})
        }
    })
}