import {useMutation, useQueryClient} from 'react-query'
import axios from 'axios';

const editClient = async (payload) => {
  const { data } = await axios.put(
    process.env.REACT_APP_API +
      "/api/v1/billing/client/update/" +
      payload.businessClientId,
    payload,
    {
      headers: {
        "Content-type": "application/json;",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
  return data;
}

export const useEditClient = () => {
  const queryClient = useQueryClient();
    return useMutation({
      mutationFn: editClient,
      onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["get-clients"] });
      },
    });
}