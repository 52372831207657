import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import ValidationCancellBilling from "../validation/ValidationCancellBilling";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import { useFormik } from "formik";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import { useDeleteBilling } from "../hooks/useDeleteBilling";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import "./ButtonDisableClient.css";
import "./ButtonCancelBilling.css";

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

const ButtonCancelBilling = ({ billing }) => {
  const [listaCancelacion, setListaCancelacion] = useState([]);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    getListCancellationReason();
    setOpen(true);
  };
  const handleClose = () => {
    formikCancell.resetForm();
    setOpen(false);
  };

  const handleChangeType = (e) => {
    formikCancell.setFieldValue("type", e.value);
  };

  const getListCancellationReason = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "MOTIVO_ANULACION",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setListaCancelacion(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  
  const getValue = () => {
    return {
      type: "",
    };
  };
  
  const { mutate } = useDeleteBilling();

  const formikCancell = useFormik({
    initialValues: getValue(),
    validationSchema: ValidationCancellBilling,
    onSubmit: async (data) => {
      setLoader(true)
      data.billingId = billing.id;
      data.codigoMotivo = data.type;
      Swal.fire({
        customClass: {
          container: "formCancelBilling",
          title: "formTitleCancelBilling",
        },
        title: "¿Estás seguro de anular la factura?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#94948f",
        confirmButtonColor: "#34974d",
        confirmButtonText: "Aceptar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          mutate(data, {
            onSuccess: () => {
              handleClose();
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "success",
                  title: "¡La factura ha sido anulada!",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
                setLoader(false)
            },
            onError: (error) => {
              setLoader(false)
              if (error.response.status === 504) {
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Lo sentimos!",
                  text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
              if (
                error.response.status === 500 ||
                error.response.status === 503
              ) {
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Ha ocurrido un error!",
                  text: "Por favor comuniquese con el administrador",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
              if (error.request.status === 422) {
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Ha ocurrido un error!",
                  text: error.response.data.errors.map(
                    (element) => element.message
                  ),
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
              if (error.request.status === 404) {
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Ha ocurrido un error!",
                  text: error.response.data.message,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
              if (error.request.status === 400) {
                console.log(error.response.data);
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Ha ocurrido un error!",
                  text: error.response.data.message,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
              if (error.response.status >= 0 && error.response.status <= 99) {
                Swal.fire({
                  customClass: {
                    container: "formCancelBilling",
                    title: "formTitleCancelBilling",
                  },
                  icon: "error",
                  title: "!Ha ocurrido un problema de conectividad!",
                  text: "Por favor vuelva a intentarlo más tarde",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
            },
          });
        }
      });
    },
  });
  return (
    <>
      <button className="button" type="button" onClick={() => handleOpen()}>
        <span className="button__text">Anular</span>
        <span className="button__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svg"
          >
            <DeleteIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ backgroundColor: 'green', margin: "20px 23px 15px 23px"}}>
          <Typography
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Motivo de la anulación
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikCancell.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ zIndex: 9}}>
                <Typography className="labelFormClient">
                   Seleccione el motivo de la anulación
                </Typography>
                <Select
                  isSearchable
                  menuPosition="fixed"
                  placeholder="Seleccionar..."
                  styles={CustomDropdownStyle}
                  options={listaCancelacion}
                  onChange={(e) => {
                    handleChangeType(e);
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="oculto"
                  size="small"
                  fullWidth
                  name="type"
                  onChange={formikCancell.handleChange}
                  value={formikCancell.values.type}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {formikCancell.values.type === "" &&
                    formikCancell.touched.type &&
                    formikCancell.errors.type}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                   Número de documento
                </Typography>
                <TextField
                  defaultValue={billing.clientDocument}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    disabled: true,
                    style: {
                      backgroundColor: "#eae9e9",
                      color: "white"
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                  Razón Social
                </Typography>
                <TextField
                  defaultValue={billing.razonSocial}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    disabled: true,
                    style: {
                      backgroundColor: "#eae9e9",
                      color: "white"
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                    Fecha de emisión
                </Typography>
                <TextField
                  defaultValue={billing.fechaEmision}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    disabled: true,
                    style: {
                      backgroundColor: "#eae9e9",
                      color: "white"
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                  Número de factura
                </Typography>
                <TextField
                  defaultValue={billing.numBilling}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    disabled: true,
                    style: {
                      backgroundColor: "#eae9e9",
                      color: "white"
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
               <Typography className="labelFormClient">
                   Monto total (Bs)
                </Typography>
                <TextField
                
                  defaultValue={billing.montoTotal}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    disabled: true,
                    style: {
                      backgroundColor: "#eae9e9",
                      color: "white"
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                 <button
                 className="buttonLoaderChangePOV"
                 style={{ width: "150px", height: "40px" }}
               >
                 <div className="loader">
                   <div className="bar1"></div>
                   <div className="bar2"></div>
                   <div className="bar3"></div>
                   <div className="bar4"></div>
                   <div className="bar5"></div>
                   <div className="bar6"></div>
                   <div className="bar7"></div>
                   <div className="bar8"></div>
                   <div className="bar9"></div>
                   <div className="bar10"></div>
                   <div className="bar11"></div>
                   <div className="bar12"></div>
                 </div>
               </button>
              ) : (
              <button className="buttonRegisterData" type="submit" >
                <span className="buttonRegisterData">Anular</span>
              </button>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonCancelBilling;
