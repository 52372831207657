import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import "./ButtonLoadingData.css";

const ButtonLoadingData = ({hide}) => {
  return (
    <div>
      <button
        type="submit"
        style={{ width: "150px", marginTop: "20px" }}
        disabled={hide}
        className="buttonLoading"
      >
        <span className="buttonLoading__text">Enviando...</span>
        <span className="buttonLoading__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgLoading"
          >
            <RotateLeftIcon fontSize="small" sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
    </div>
  );
}
export default ButtonLoadingData;

