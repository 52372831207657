import * as Yup from "yup";

const ValidationRegisterPOV = Yup.object().shape({
  businessId: Yup.string(),
  sucursalId: Yup.string().required("El campo es requerido"),
  codigoTipoPuntoVenta: Yup.string().required("El campo es requerido"),
  descripcion: Yup.string().required("El campo es requerido"),
  nombrePuntoVenta: Yup.string().required("El campo es requerido"),
});

export default ValidationRegisterPOV;