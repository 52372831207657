import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import "./PanelSwitchPointOfSale.css";

const PanelSwitchPointOfSale = () => {
  return (
    <>
      <div className="cardSwitch">
        <div className="cardSwitch1">
          <p
            style={{ 
                fontWeight: 400, 
                fontFamily: "Oswald", 
                fontSize: "24px", 
                marginTop: "25px" 
            }}
          >
            Cambio de PV
          </p>
          <ManageHistoryIcon
            className="iconSwitch"
            sx={{
              width: "60px",
              height: "60px",
              color: "gray",
              marginTop: "10px",
            }}
          />
          <p className="smallSwitch">Cambio manual de punto de venta</p>
          <div className="go-cornerSwitch" href="#">
            <div className="go-arrowSwitch">
              <ManageHistoryIcon sx={{ paddingTop: "5px" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PanelSwitchPointOfSale;
