import "./SearchPointOfSales.css";

const SearchPointOfSales = ({ search = '', setSearch }) => {
  return (
    <input
      type="search"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      placeholder="Buscar"
      className="searchPointOfSales"
      style={{ backgroundColor: "whitesmoke" }}
    />
  );
};
export default SearchPointOfSales;
