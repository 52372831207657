import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

const registerClient = async (payLoad) => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API + "/api/v1/billing/client/save",
      payLoad,
      {
        headers: {
          "Content-type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        }
      } 
    );
    return data;
  } catch (error) {
    throw error;
  }
}

export const useRegisterClient = () => {
  const queryClient = useQueryClient();

  return useMutation(registerClient,{
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get-clients"] });
      },
      onError: (error) => {
        console.error("Error in useRegisterClient mutation:", error);
      }
    });
};
