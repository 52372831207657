import ButtonRegisterCompany from "./ButtonRegisterCompany";
import ButtonDisableCompany from "./ButtonDisableCompany";
import { Grid, Stack, Typography } from "@mui/material";
import ButtonEnableCompany from "./ButtonEnableCompany";
import ButtonGoBackSetting from "./ButtonGoBackSetting";
import SearchPointOfSales from "./SearchPointOfSales";
import ButtonEditComputarize from "./ButtonEditComputarize";
import ButtonEditCertificated from "./ButtonEditCertificated";
import ButtonRegisterBranch from "./ButtonRegisterBranch";
import ButtonRegisterSync from "./ButtonRegisterSync";
import ButtonViewBranch from "./ButtonViewBranch";
import ButtonRegisterPOV from "./ButtonRegisterPOV";
import DataTable from "react-data-table-component";
import { Tooltip } from 'react-tooltip';
import { useQuery } from "react-query";
import { useState } from "react";
import axios from "axios";
import dayjs from 'dayjs';
import handleError from "./utilities/HandleError";

// Business List function.
const businessList = async (page = 1, perPage = 10, search = '') => {
  try {
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_API + "/api/v1/billing/business/all",
      params: {
        pageNumber: page,
        pageSize: perPage,
        search: search,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    handleError(error, 'No se pudo recuperar la lista de empresas');
    console.error('No se pudo recuperar la lista de empresas:', error);
    throw new Error('No se pudo recuperar la lista de empresas.');
  }
};

// Function to return custom messages based on the value of modalidad.
const getCustomModality = (modalidad) => {
  switch (modalidad) {
    case 'COMPUTERIZED_ONLINE':
      return 'Computarizada';
    case 'ELECTRONIC_ONLINE':
      return 'Electrónica';
    default:
      return 'No asignado';
  }
};

const DataTableCompany = ({ searchPointOfSale }) => {
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState(searchPointOfSale || '');
  const [errorMessage, setErrorMessage] = useState('');
  
  const { isLoading, isError, error, data } = useQuery(
    ["get-company", page, perPage, search],
    () => businessList(page, perPage, search),
    {
      onSuccess: (data) => {
        setTotalRows(data.totalElements);
        setPage(data.pageNumber);
      },
      onError: (error) => {
        if (!errorMessage) {
          setErrorMessage(error.message);
        }
      },
    }
  );
  
  if (isError) {
    return (
      <>
        {isError && <div>Error: {errorMessage}</div>}
      </>
    );
  }

  const columns = [
    {
      name: "Nro",
      selector: (row, index) => index + 1,
      id: 1,
      omit: (row, index) => index + 1,
    },
    {
      name: "Nombre empresa",
      selector: (row) => <p data-tooltip-id="company-tooltip" data-tooltip-content={row.nombreSistema}>{row.nombreSistema}</p>,
      id: 2,
    },
    {
      name: "Correo electrónico",
      selector: (row) => <p data-tooltip-id="company-tooltip" data-tooltip-content={row.email}>{row.email}</p>,
      id: 3,
    }, 
    {
      name: "Modalidad",
      selector: (row) => <p data-tooltip-id="company-tooltip" data-tooltip-content={getCustomModality(row.modalidad)}>{getCustomModality(row.modalidad)}</p>,
      id: 4,
    },
    {
      name: "NIT",
      selector: (row) => <p data-tooltip-id="company-tooltip" data-tooltip-content={row.nit}>{row.nit}</p>,
      id: 5,
    },
    {
      name: "Ciudad",
      selector: (row) => <p data-tooltip-id="company-tooltip" data-tooltip-content={row.dependencia}>{row.dependencia}</p>,
      id: 6,
    }, 
    {
      name: "Fecha de inicio del contrato",
      selector: (row) => <p data-tooltip-id="company-tooltip" data-tooltip-content={row.fechaInicio}>{row.fechaInicio}</p>,
      id: 7,
    },
    {
      name: "Fecha final del contrato",
      selector: (row) => <p data-tooltip-id="company-tooltip" data-tooltip-content={row.fechaFin}>{row.fechaFin}</p>,
      id: 8,
    },
    {
      name: "Días faltantes",
      selector: (row) => <p data-tooltip-id="company-tooltip" data-tooltip-content={row.diasVigente}>{row.diasVigente}</p>,
      id: 9,
    },
    {
      name: "Sincronización",
      button: true,
      cell: (row) => (
        <ButtonRegisterSync id={row.id} disableBtn={!row.status} />
      )
    }
    ,
    {
      name: "Certificado",
      button: true,
      cell: (row) => row.modalidad === "ELECTRONIC_ONLINE" ? (
      
      <ButtonEditCertificated id={row.id} disableBtn={!row.status} />
      ) :("")
    },
    {
      name: "Ver",
      button: true,
      cell: (row) => (
        <ButtonViewBranch id={row.id} disableBtn={!row.status}/>
      )
    },    
    {
      name: "Registrar",
      button: true,
      cell: (row) => (
        <ButtonRegisterBranch id={row.id} disableBtn={!row.status}/>
      )
    },    
    {
      name: "Registrar",
      button: true,
      cell: (row) => (
        <ButtonRegisterPOV id={row.id} disableBtn={!row.status}/>
      )
    },   
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <ButtonEditComputarize id={row.id} disableBtn={!row.status} />
      ) 
    },
    {
      name: "Inhabilitar",
      button: true, 
      cell: (row) =>
        row.id === 1 ? (
          ""
        ) : row.status ? (
          <>
            <ButtonDisableCompany id={row.id} />
          </>
        ) : (
          <>
            <ButtonEnableCompany id={row.id} />
          </>
        ),

    },
  ];

  const dataTable = data?.content.map((row) => {
    return {
      id: row.id,
      nombreSistema: row.nombreSistema,
      razonSocial: row.razonSocial,
      nit: row.nit,
      email: row.email ? row.email : "No tiene correo",
      dependencia: row.dependencia,
      status: row.status,
      modalidad: row.modalidad,
      fechaInicio: dayjs(row.fechaInicio).format('DD/MM/YYYY'),
      fechaFin: dayjs(row.fechaFin).format('DD/MM/YYYY'),
      diasVigente: row.diasVigente < 0 ? 0 : row.diasVigente
    };
  });
  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <table
        style={{
          paddingLeft: "60px",
          fontSize: "14px",
          backgroundColor: "whitesmoke",
          with: "100%",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Nombre empresa</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.nombreSistema}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>RazonSocial</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.razonSocial}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Modalidad</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {getCustomModality(data.modalidad)}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>NIT</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.nit}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Ciudad</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.dependencia}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }; 

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "whitesmok",
        fontSize: "13px",
        fontWeight: 600,
        color: "black",
      },
    },
  };  

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            Gestión de empresas
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
          }}
        >
          <ButtonGoBackSetting />
          <ButtonRegisterCompany />
        </Grid>
        <Grid item xs={12} md={6} align="left">
          <SearchPointOfSales search={search} setSearch={setSearch} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={dataTable}
            customStyles={customStyles}
            fixedHeader={true}
            progressPending={isLoading}
            progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
            defaultSortFieldId={1}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            striped
            responsive
            pointerOnHover
            highlightOnHover
            selectableRowsSingle
            noDataComponent={
              <Stack
                height="50vh"
                width="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <h2
                  style={{
                    color: "gray",
                    fontFamily: "Oswald",
                    fontWeight: 200,
                    fontSize: "28px",
                  }}
                >
                  "No se encontró ningún registro"
                </h2>
              </Stack>
            }
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={paginationComponentOptions}
            visibleRowIndex
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
          <Tooltip id="company-tooltip" place='right'/>
      </Grid> 
    </>
  );
};
export default DataTableCompany;
