import './SearchBilling.css';
const SearchBilling = ({ search = '', setSearch }) => {
  return (
    <input
      className="searchBilling"
      placeholder="Buscar"
      type="search"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      style={{ backgroundColor: "whitesmoke", width: "100%" }}
    />
  );
};
export default SearchBilling