import { useMutation, useQueryClient } from "react-query";
import axios from 'axios';

const registerBranch = async (payLoad) => {
    return axios.post(process.env.REACT_APP_API + "/api/v1/billing/pointOfSale/registerBranch",payLoad,{
        headers: {
          "content-type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        }}
    )
}

export const useRegisterBranch = () =>{
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: registerBranch,
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ["get-company"]})
      }
    });
}
