import ValidationEditCustomer from "../validation/ValidationEditCustomer";
import CustomDropdownEdit from "./style/CustomDropdownEdit";
import FormHelperText from "@mui/material/FormHelperText";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import { useEditClient } from "../hooks/useEditClient";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import { TextField } from "@mui/material";
import Slide from "@mui/material/Slide";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";
import axios from "axios";
import "./ButtonEditClient.css";
import "./ButtonEnableClient.css";
import "./ButtonEnableTable.css";
import handleError from "./utilities/HandleError";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonEditClient = ({ id, disableBtn, fetch }) => {
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [codigoClasificadorSiat, setCodigoClasificadorSiat] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCodigoClasificadorSiat(0);
    formikEditClient.resetForm({});
    setOpen(false);
    fetch();
  };

  const handleButtonEdit = (ide) => {
    documentList();
    getClient(ide);
  };

  const handleChangeClasificadorSiat = (e) => {
    setCodigoClasificadorSiat(e.value);
    formikEditClient.setFieldValue("codigoClasificadorSiat", e.value);
  };

  const documentList = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "TIPO_DOCUMENTO_IDENTIDAD",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setTiposDocumentos(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {}
  };

  const getClient = async (ide) => {
    
      await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/client/get/" + ide,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }).then(data => {
        handleOpen();
        setCodigoClasificadorSiat(data.codigoClasificadorSiat);
        formikEditClient.setValues({
          codigoClasificadorSiat: data.data.codigoClasificadorSiat,
          documento: data.data.documento,
          complemento: data.data.complemento,
          razonSocial: data.data.razonSocial,
          email: data.data.email,
          numCelular: data.data.numCelular,
        });
      }).catch( error => {
        handleError(error);
      });
    
  };

  /********************************************* Actualizar clientes ****************************************/
  const { mutate } = useEditClient();

  const formikEditClient = useFormik({
    initialValues: {
      documento: "",
      complemento: "",
      razonSocial: "",
      email: "",
      numCelular: "",
      codigoClasificadorSiat: "",
    },
    validationSchema: ValidationEditCustomer,
    onSubmit: async (data) => {
      setLoader(true);
      data.businessClientId = id;
      data.razonSocial = data.razonSocial.trim();
      data.email = data.email.trim();
      data.numCelular = data.numCelular ? data.numCelular : null;
      mutate(data, {
        onSuccess: () => {
          handleClose();
          setCodigoClasificadorSiat(0);
          Swal.fire({
            customClass: {
              container: "formEditClient",
              title: "formEditTitleClient",
            },
            icon: "success",
            title: "¡La información ha sido actualizada!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setLoader(false);
        },
        onError: (error) => {
          setLoader(false);
          if (error.response.status === 504) {
            Swal.fire({
              customClass: {
                container: "formEditClient",
                title: "formEditTitleClient",
                text: "formEditTitleClient",
              },
              icon: "error",
              title: "!Lo sentimos!",
              text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }           
          if (error.response.status === 500 || error.response.status === 503) {
            Swal.fire({
              customClass: {
                container: "formEditClient",
                title: "formEditTitleClient",
                text: "formEditTitleClient",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status === 422) {
            Swal.fire({
              customClass: {
                container: "formEditClient",
                title: "formEditTitleClient",
                text: "formEditTitleClient",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.errors.map(
                (element) => element.message
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status === 404) {
            Swal.fire({
              customClass: {
                container: "formEditClient",
                title: "formEditTitleClient",
                text: "formEditTitleClient",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status === 400) {
            Swal.fire({
              customClass: {
                container: "formEditClient",
                title: "formEditTitleClient",
                text: "formEditTitleClient",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status >= 0 && error.response.status <= 99) {
            Swal.fire({
              customClass: {
                container: "formEditClient",
                title: "formEditTitleClient",
                text: "formEditTitleClient",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Compruebe su conexión a internet",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });
    },
  });

  return (
    <>
      {!disableBtn ? (
        <button
          disabled={disableBtn}
          className="buttonEdit"
          type="button"
          onClick={() => handleButtonEdit(id)}
        >
          <span className="buttonEdit__text">Editar</span>
          <span className="buttonEdit__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              viewBox="0 0 48 48"
              height="48"
              className="svgEdit"
            >
              <EditIcon sx={{ color: "white" }} />
            </svg>
          </span>
        </button>
      ) : (
        <button
          disabled={disableBtn}
          className="buttonEnableTable"
          type="button"
          onClick={() => handleButtonEdit(id)}
        >
          <span className="buttonEnableTable__text">Editar</span>
        </button>
      )}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "#D1B000", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Editar cliente
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikEditClient.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ marginTop: "5px" }}>
                <FormControl fullWidth>
                  <Typography className="labelFormEditClient">
                    Seleccione el tipo de documento
                  </Typography>
                  <Select
                    isSearchable
                    menuPosition="fixed"
                    placeholder="Seleccionar..."
                    styles={CustomDropdownEdit}
                    options={tiposDocumentos}
                    value={tiposDocumentos.filter(
                      (element) =>
                        element.value ===
                        formikEditClient.values.codigoClasificadorSiat
                    )}
                    onChange={(e) => {
                      handleChangeClasificadorSiat(e);
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikEditClient.touched.codigoClasificadorSiat &&
                      formikEditClient.errors.codigoClasificadorSiat}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {codigoClasificadorSiat !== 1 ? (
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <Typography className="labelFormEditClient">
                      Número de documento
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      id="documento"
                      name="documento"
                      onBlur={formikEditClient.handleBlur}
                      onChange={formikEditClient.handleChange}
                      value={formikEditClient.values.documento}
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                          border: "1px solid white",
                          borderColor: "white",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikEditClient.touched.documento &&
                        formikEditClient.errors.documento}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <>
                  <Grid item xs={7} md={7}>
                    <FormControl fullWidth>
                      <Typography className="labelFormEditClient">
                        Número de documento
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        id="documento"
                        name="documento"
                        onBlur={formikEditClient.handleBlur}
                        onChange={formikEditClient.handleChange}
                        value={formikEditClient.values.documento}
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: "0px",
                            border: "1px solid white",
                            borderColor: "white",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{ color: "chocolate", fontSize: "14px" }}
                      >
                        {formikEditClient.touched.documento &&
                          formikEditClient.errors.documento}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5} md={5}>
                    <FormControl fullWidth>
                      <Typography className="labelFormEditClient">
                        Complemento
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="complemento"
                        onBlur={formikEditClient.handleBlur}
                        onChange={formikEditClient.handleChange}
                        value={formikEditClient.values.complemento}
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: "0px",
                            border: "1px solid white",
                            borderColor: "white",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{ color: "chocolate", fontSize: "14px" }}
                      >
                        {formikEditClient.touched.complemento &&
                          formikEditClient.errors.complemento}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Razón social
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    id="razonSocial"
                    name="razonSocial"
                    onBlur={formikEditClient.handleBlur}
                    onChange={formikEditClient.handleChange}
                    value={formikEditClient.values.razonSocial}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikEditClient.touched.razonSocial &&
                      formikEditClient.errors.razonSocial}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Correo electrónico
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    id="email"
                    name="email"
                    onBlur={formikEditClient.handleBlur}
                    onChange={formikEditClient.handleChange}
                    value={formikEditClient.values.email}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikEditClient.touched.email &&
                      formikEditClient.errors.email}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Número de celular
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    id="numCelular"
                    name="numCelular"
                    onBlur={formikEditClient.handleBlur}
                    onChange={formikEditClient.handleChange}
                    value={formikEditClient.values.numCelular}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikEditClient.touched.numCelular &&
                      formikEditClient.errors.numCelular}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : 
                <button
                  className="buttonRegisterData"
                  style={{ border: "1px solid #D1B000" }}
                  type="submit"
                >
                  <span className="buttonEdit">Actualizar</span>
                </button>
              }
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonEditClient;
