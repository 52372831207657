import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Select from "react-select";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import ValidationRegisterDetailProduct from "../validation/ValidationRegisterDetailProduct";
import ValidationEditDetailProduct from "../validation/ValidationEditDetailProduct";
import ValidationRegisterCustomer from "../validation/ValidationRegisterCustomer";
import ValidationSearchCustomer from "../validation/ValidationSearchCustomer";
import ValidationBilling from "../validation/ValidationBilling";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import ModalRegisterStyle from "./style/ModalRegisterStyle";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AddCardIcon from "@mui/icons-material/AddCard";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonLoadingData from "./ButtonLoadingData";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ArticleIcon from "@mui/icons-material/Article";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import { Tooltip } from "react-tooltip";
import "./ButtonRegisterClient.css";
import "./ButtonEditClient.css";
import "./ButtonSendData.css";
import "./FormBilling.css";
import dayjs from "dayjs";
import CustomDropdownEdit from "./style/CustomDropdownEdit";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DisabledByDefaultSharpIcon from '@mui/icons-material/DisabledByDefaultSharp';
import handleError from './utilities/HandleError';

const today = dayjs();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styleViewBillings = {
  position: "absolute",
  zIndex: 8,
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  marginTop: 10,
  p: 1,
};

const FormMassiveEmission = () => {
  const inputRef = useRef(null);
  const metodosRef = useRef(null);
  const descuentoRef = useRef(0);
  const [gift, setGift] = useState(0);
  const [loader, setLoader] = useState(false);
  const [loaderProduct, setLoaderProduct] = useState(false);
  const [openBilling, setOpenBilling] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");

  const [idBilling, setIdBilling] = useState("0");

  const themes = useTheme();
  const fullScreen = useMediaQuery(themes.breakpoints.down("sm"));

  const handleCheckErrors = () => {
    if (formikBilling.errors.tarjeta1 !== undefined) {
      formikBilling.values.codeT && formikBilling.errors.tarjeta1
        ? handleOpenMethodPay()
        : console.log(formikBilling.errors.tarjeta1.length);
    }
    if (formikBilling.values.codeG && formikBilling.errors.gift) {
      handleOpenMethodPay();
    }
  };

  const [auxProduct, setAuxProduct] = useState("");
  const [businessId, setBusinessId] = useState(1);
  const [type, setType] = useState("");
  const [codigoClasificadorSiat, setCodigoClasificadorSiat] = useState(0);

  const [documentoView, setDocumentoView] = useState("");
  const [complementoView, setComplementoView] = useState("");
  const [typeView, setTypeView] = useState("");
  const [razonSocialView, setRazonSocialView] = useState("");
  const [emailView, setEmailView] = useState("");
  const [numCelularView, setNumCelularView] = useState("");
  const [idView, setIdView] = useState(0);

  const [cantidad, setCantidad] = useState(0);
  const [descripcionProducto, setDescripcionProducto] = useState("");
  const [codigoProducto, setCodigoProducto] = useState("");
  const [idDetail, setIdDetail] = useState(0);

  const [unidadMedidaR, setUnidadMedidaR] = useState("");
  const [precioUnitarioR, setPrecioUnitarioR] = useState(0.0);

  const [productId, setProductId] = useState(0);
  const [productIdE, setProductIdE] = useState(0);
  const [unidadMedidaE, setUnidadMedidaE] = useState("");
  const [precioUnitarioE, setPrecioUnitarioE] = useState(0.0);
  const [tiposDocumentos, setTiposDocumentos] = useState([]);

  const [expanded, setExpanded] = useState("panel1");
  const [expanded2, setExpanded2] = useState("panel2");
  const [expanded3, setExpanded3] = useState("panel3");

  const [valuePay, setValuePay] = useState(1);
  const [labelPay, setLabelPay] = useState("EFECTIVO");

  const [botonEmitir, setBotonEmitir] = useState(false);
  const [codigoClasificador, setCodigoClasificador] = useState();

  const [botonEmitirMasivo, setBotonEmitirMasivo] = useState(false);
  const [shippingType, setShippingType] = useState("MASSIVE");
  const [checkNit, setCheckNit] = useState(false);

  let [o, setO] = useState(0);

  const cleanDataClient = () =>{
    setTypeView("");
  
  }

  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChange2 = (panel2) => (event, newExpanded2) => {
    setExpanded2(newExpanded2 ? panel2 : true);
  };

  const handleChange3 = (panel3) => (event, newExpanded3) => {
    setExpanded3(newExpanded3 ? panel3 : false);
  };

  const [codigoMoneda, setCodigoMoneda] = useState(1);
  const [labelMoneda, setLabelMoneda] = useState("BOLIVIANO");
  const handleChangeMoneda = (e) => {
    setCodigoMoneda(e.value);
    setLabelMoneda(e.label);
  };

  const [openMethodPay, setOpenMethodPay] = useState(false);
  const handleOpenMethodPay = () => {
    setOpenMethodPay(true);
  };
  const handleCloseMethodPay = () => {
    setOpenMethodPay(false);
  };

  const optionsSpecialCases = [
    { label: "Casos especiales (Ninguno)", value: "Ninguno" },
    { label: "Extranjero no inscrito (99001)", value: "99001" },
    { label: "Control tributario (99002)", value: "99002" },
    { label: "Cliente sin nombre", value: "99999" },
  ];

  /*****************************************************************************/

  const [specialOption, setSpecialOption] = useState("Ninguno");
  const handleSpecialOptions = (e) => {
    if (e.value === "99001") {
      formikBilling.setFieldValue("code", true);
    }
    setSpecialOption(e.value);
    if (e.value !== "Ninguno") {
      cleanFieldsClient();
      getCasosEspaciales(e.value);
      setSearchClient(null);
    }
    if (e.value !== "99001") {
      formikBilling.setFieldValue("code", false);
    }
  };

  const handleNumericFieldChange = (event) => {
    const inputValue = event.target.value;

    // Validar que el valor solo contiene números enteros o con hasta dos decimales
    if (/^\d*(\.\d{0,2})?$/.test(inputValue) || inputValue === "") {
      setCantidadDescuento(inputValue);
      // Actualizar el valor en useFormik
      formikRegisterDetailBilling.setFieldValue("cantidad", inputValue);
    }
  };

  const handleNumericFieldChangeEdit = (event) => {
    const inputValue = event.target.value;

    // Validar que el valor solo contiene números enteros o con hasta dos decimales
    if (/^\d*(\.\d{0,2})?$/.test(inputValue) || inputValue === "") {
      setCantidadDescuento(inputValue);
      // Actualizar el valor en useFormik
      formikEditDetailBilling.setFieldValue("cantidad", inputValue);
    }
  };

  /********************************************* Evento que establece los valores por defecha de una tarjeta ****************************************/
  const handleChangePay = (e) => {
    formikBilling.setFieldValue("tipoPago", e.value);

    if (e.label.includes("TARJETA")) {
      formikBilling.setFieldValue("codeT", true);
    } else {
      formikBilling.setFieldValue("codeT", false);
      formikBilling.values.tarjeta1 = "0";
    }
    if (e.label.includes("GIFT")) {
      formikBilling.setFieldValue("codeG", true);
    } else {
      formikBilling.setFieldValue("codeG", false);
      formikBilling.values.gift = 0;
    }
    setValuePay(e.value);
    setLabelPay(e.label);
  };

  const handleCloseBilling = () => {
    setOpenBilling(false);
  };

  const handleOpenBilling = () => {
    setOpenBilling(true);
  };

  /************************************************* Métodos para la selección de campos *****************************************************/

  const handleSelectTipoCambio = () => {
    document.getElementById("tipoCambio").select();
  };
  const handleSelectGlobalDescuento = (e) => {
    document.getElementById("descuento").select();
  };
  const handleSelectCantidad = () => {
    document.getElementById("cantidad").select();
  };
  const handleSelectDescuento = () => {
    document.getElementById("montoDescuento").select();
  };
  const handleSelectPorcentaje = () => {
    document.getElementById("porcentaje").select();
  };
  const handleSelectDescuentoEdit = () => {
    document.getElementById("montoDescuentoEdit").select();
  };
  const handleSelectPorcentajeEdit = () => {
    document.getElementById("porcentajeEdit").select();
  };
  const handleSelectGift = () => {
    document.getElementById("gift").select();
  };
  const handleSelectTarjeta1 = () => {
    document.getElementById("tarjeta1").select();
  };

  const [tipoDescuento, setTipoDescuento] = useState(1);

  const [cantidadDescuento, setCantidadDescuento] = useState(1);

  const tiposDescuento = [
    { value: 1, label: "Bs" },
    { value: 2, label: "%" },
  ];

  /********************************************* Evento para seleccionar el tipo de descuento ****************************************/
  const handleChangeTipoDescuento = (e) => {
    if (e.value === 1) {
      formikRegisterDetailBilling.setFieldValue("montoDescuento", 0);
      formikRegisterDetailBilling.setFieldValue("porcentaje", 0);
      setTipoDescuento(e.value);
    } else {
      formikRegisterDetailBilling.setFieldValue("montoDescuento", 0);
      formikRegisterDetailBilling.setFieldValue("porcentaje", 0);
      setTipoDescuento(e.value);
    }
  };

  /********************************************* Evento que permite actualizar el tipo descuento ****************************************/
  const handleChangeTipoDescuentoEdit = (e) => {
    setTipoDescuento(e.value);

    if (e.value === 1) {
      formikEditDetailBilling.setFieldValue("montoDescuento", 0);
      formikEditDetailBilling.setFieldValue("porcentaje", 0);
    } else if (e.value === 2) {
      formikEditDetailBilling.setFieldValue("porcentaje", 0);
      formikEditDetailBilling.setFieldValue("montoDescuento", 0);
    }
  };

  const loadOptions =  async (inputValue, callback) => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API + "/api/v1/billing/product/select",
        params: {
          search: inputValue,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const toSelectOption = await response.data.map((sup) => ({
        label: sup.label,
        value: sup.value,
      }));

      callback(toSelectOption);
    } catch (error) {
      handleError(error);
    }
  };

  //const ocultarBotonEmitirMasivo = () => {};

  /********************************************* Evento que oculta el botón de emisión de factura ****************************************/
  const ocultarBotonEmitir = () => {
    setBotonEmitir(true);
    setTimeout(function () {
      setBotonEmitir(false);
    }, 2000);
  };

  /*******************************************   CAMPOS PARA LA FACTURACION   ************************************* */
  const [clientId, setClientId] = useState();
  const [productDetails, setProductDetails] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    formikRegisterDetailBilling.resetForm({ data: "" });
    getMethodPayList();
    setTipoDescuento(1);
    limpiarCampos();
    setOpen(true);
  };
  const handleClose = () => {
    setType("");
    setAuxProduct("");
    setTipoDescuento(1);
    limpiarCampos();
    setOpen(false);
  };

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => {
    setTipoDescuento(1);
    setOpenEdit(false);
  };

  const [openSearch, setOpenSearch] = useState(false);

  const handleCloseSearch = () => {
    setType("");
    setCodigoClasificador(0);
    setOpenSearch(false);
  };

  const [openRegister, setOpenRegister] = useState(false);
  const handleOpenRegister = () => {
    formikRegisterClient.resetForm({ data: "" });
    loadTypes();
    cleanFieldsClient();
    setSpecialOption("Ninguno");
    setOpenRegister(true);
    setSearchClient(null);
  };

  const cleanFieldsClient = () => {
    setClientId("");
    setTypeView("");
    setDocumentoView("");
    setRazonSocialView("");
    setEmailView("");
    setNumCelularView("");
    setCodigoClasificador(0);
    setSearchClient("");

  };
  const handleCloseRegister = () => {
    setType("");
    setCodigoClasificadorSiat(0);
    setOpenRegister(false);
  };

  const [openQuestion, setOpenQuestion] = useState(false);
  const handleOpenQuestion = () => setOpenQuestion(true);
  const handleCloseQuestion = () => {
    setCodigoClasificador(0);
    setOpenQuestion(false);
  };

  /*************************************** Evento que permite seleccionar el tipo de producto ******************************************************/
  const handleChangeProduct = (valor) => {
    formikRegisterDetailBilling.setFieldValue("type", valor.label);
    getProduct(valor.value);
    setProductId(valor.value);
  };

  /*************************************** Evento que permite actualizar el tipo de producto ******************************************************/
  const handleChangeProductEdit = (valor) => {
    getProduct(valor.value);
    setProductIdE(valor.value);
  };

  /*************************************** Evento que permite seleccionar el tipo de documento de un cliente *****************************************************/
  const handleChangeCliente = (e) => {
    formikRegisterClient.setFieldValue("type", e.label);
    setType(e.label);
    setCodigoClasificadorSiat(e.value);
  };

  const [codigos, setCodigos] = useState();

  const handleClickEditar = (e, cellValues) => {
    formikBilling.setFieldValue("montoDescuento", 0);
    handleOpenEdit();
    if (cellValues.row.tipoDescuento === 1) {
      formikEditDetailBilling.setValues({
        cantidad: cellValues.row.cantidad,
        montoDescuento: cellValues.row.montoDescuento,
        porcentaje: cellValues.row.porcentaje,
      });
      setTipoDescuento(cellValues.row.tipoDescuento);
    } else if (cellValues.row.tipoDescuento === 2) {
      formikEditDetailBilling.setValues({
        cantidad: cellValues.row.cantidad,
        porcentaje: cellValues.row.porcentaje,
        montoDescuento: cellValues.row.montoDescuento,
      });
      setTipoDescuento(cellValues.row.tipoDescuento);
    }
    setCantidad(cellValues.row.cantidad);
    setIdDetail(cellValues.row.idE);
    setDescripcionProducto(cellValues.row.descripcionProducto);
    setProductIdE(cellValues.row.productId);
    setTipoDescuento(cellValues.row.tipoDescuento);
    setCodigoProducto(cellValues.row.codigoProducto);
    setPrecioUnitarioE(cellValues.row.precio);
    setUnidadMedidaE(cellValues.row.unidadMedida);
  };

  const handleClickEliminar = (e, cellValues) => {
    Swal.fire({
      customClass: {
        container: "formDeleteDetailProduct",
        title: "formTitleDeleteDetailProduct",
      },
      title: "¿Estás seguro de eliminarlo?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#94948f",
      confirmButtonColor: "#34974d",
      reverseButtons: true,
      confirmButtonText: "Aceptar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem(cellValues.row.idE);
        setO(0);
        Swal.fire({
          customClass: {
            container: "formDeleteDetailProduct",
            title: "formTitleDeleteDetailProduct",
          },
          title: "¡El producto ha sido eliminado!",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  /************************************** Buscar y obtener cliente por cosas especiales ***********************************************/
  const getCasosEspaciales = async (doc) => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API + "/api/v1/billing/client/findSpecialCodes",
        params: {
          document: doc,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setClientId(response.data.businessClientId);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getMoneda();
    loadListProducts();
    validarEnvioFactura();
    getListMassiveBilling();
  }, []);

  const [methodPayList, setMethodPayList] = useState([]);
  /************************************************* Obtener lista de metodos de pago  ******************************************************/
  const getMethodPayList = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "TIPO_METODO_PAGO",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setMethodPayList(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {
      handleError(error)
    }
  };

  /************************************ Buscar un producto ******************************************** */
  const getProduct = async (id) => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/product/find/" + id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setAuxProduct(response.data.precioUnitario);
      setDescripcionProducto(response.data.descripcionProducto);
      setCodigoProducto(response.data.codigoProducto);
      setPrecioUnitarioR(response.data.precioUnitario);
      setPrecioUnitarioE(response.data.precioUnitario);
      setUnidadMedidaE(response.data.nombreUnidadMedida);
      setUnidadMedidaR(response.data.nombreUnidadMedida);
    } catch (error) {
      console.log(error);
    }
  };

  /****************************************** Obtener moneda ************************************/
  const [tipoMoneda, setTipoMoneda] = useState([]);
  const getMoneda = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "TIPO_MONEDA",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setTipoMoneda(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {
      handleError(error);
    }
  };

  /***********************************  Definir columnas para el DataGrid detalle de producto *************************************/

  const columns1 = [
    {
      field: "descripcionProducto",
      headerName: "Descripción del producto",
      headerClassName: "super-app-theme--header",
      width: 350,
    },
    {
      field: "unidadMedida",
      headerName: "Unidad de medida",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      headerClassName: "super-app-theme--header",
      whidth: 125,
    },
    {
      field: "precio",
      headerName: "Precio unitario",
      headerClassName: "super-app-theme--header",
      width: 125,
    },
    {
      field: "montoDescuento",
      headerName: "Descuento",
      headerClassName: "super-app-theme--header",
      width: 110,
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      headerClassName: "super-app-theme--header",
      width: 110,
    },
    {
      field: "Editar",
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <button
            className="buttonEdit"
            type="button"
            onClick={(e) => handleClickEditar(e, cellValues)}
          >
            <span className="buttonEdit__text">Editar</span>
            <span className="buttonEdit__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svgEdit"
              >
                <EditIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        );
      },
      width: 100,
    },
    {
      field: "Eliminar",
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <button
            className="button"
            type="button"
            onClick={(e) => handleClickEliminar(e, cellValues)}
          >
            <span className="button__text">Eliminar</span>
            <span className="button__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svg"
              >
                <DeleteIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        );
      },
      width: 100,
    },
  ];

  const columns2 = [
    {
      field: "descripcionProducto",
      headerName: "Descripción del producto",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="massive-tooltip"
          data-tooltip-content={params.row.descripcionProducto}
        >
          {params.row.descripcionProducto}
        </p>
      ),
      flex: 3,
    },
    {
      field: "unidadMedida",
      headerName: "Unidad de medida",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="massive-tooltip"
          data-tooltip-content={params.row.unidadMedida}
        >
          {params.row.unidadMedida}
        </p>
      ),
      flex: 2,
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="massive-tooltip"
          data-tooltip-content={params.row.cantidad}
        >
          {params.row.cantidad}
        </p>
      ),
      flex: 1,
    },
    {
      field: "precio",
      headerName: "Precio unitario",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="massive-tooltip"
          data-tooltip-content={params.row.precio}
        >
          {params.row.precio}
        </p>
      ),
      flex: 1.5,
    },
    {
      field: "montoDescuento",
      headerName: "Descuento",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="massive-tooltip"
          data-tooltip-content={params.row.montoDescuento}
        >
          {params.row.montoDescuento}
        </p>
      ),
      flex: 1,
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p
          data-tooltip-id="massive-tooltip"
          data-tooltip-content={params.row.subtotal}
        >
          {params.row.subtotal}
        </p>
      ),
      flex: 1,
    },
    {
      field: "Editar",
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <button
            className="buttonEdit"
            type="button"
            onClick={(e) => handleClickEditar(e, cellValues)}
          >
            <span className="buttonEdit__text">Editar</span>
            <span className="buttonEdit__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svgEdit"
              >
                <EditIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        );
      },
      width: 100,
    },
    {
      field: "Eliminar",
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <button
            className="button"
            type="button"
            onClick={(e) => handleClickEliminar(e, cellValues)}
          >
            <span className="button__text">Eliminar</span>
            <span className="button__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svg"
              >
                <DeleteIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        );
      },
      width: 100,
    },
  ];

  const rows = productDetails.map(
    (row, index) => ({
      internalId: index + 1,
      idE: row.idE,
      cantidad: Number(row.cantidad).toFixed(2),
      descripcionProducto: row.descripcionProducto,
      unidadMedida: row.unidadMedida,
      precio: Number(row.precio).toFixed(2),
      codigoProducto: row.codigoProducto,
      montoDescuento: Number(row.montoDescuento).toFixed(2),
      subtotal: Number(row.precio * row.cantidad - row.montoDescuento).toFixed(
        2
      ),
      productId: row.productId,
      tipoDescuento: row.tipoDescuento,
      porcentaje: row.porcentaje,
    }),
    1
  );

  const [listMassiveBilling, setListMassiveBilling] = useState([]);

  /******************************************* Definir columnas para el DataGrid lista de facturas pendientes **************************************************** */
  const columnsBilling1 = [
    {
      field: "numBilling",
      headerName: "Nro factura",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "documentoSector",
      headerName: "Documento",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "razonSocial",
      headerName: "Razón social",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "montoTotal",
      headerName: "Monto total",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Fecha de creación",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "status",
      headerName: "Estado",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
  ];

  const columnsBilling2 = [
    {
      field: "numBilling",
      headerName: "Nro factura",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "documentoSector",
      headerName: "Documento",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "razonSocial",
      headerName: "Razón social",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "montoTotal",
      headerName: "Monto total",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Fecha de creación",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Estado",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
  ];
  const rowsBilling = listMassiveBilling.map((row) => ({
    id: row.id,
    numBilling: row.numBilling,
    documentoSector: row.documentoSector,
    razonSocial: row.razonSocial,
    montoTotal: row.montoTotal,
    createdAt: row.createdAt.substring(0, 10),
    status: row.status,
  }));
  //  const [facturasEspera, setFacturasEspera] = useState(0);

  const getListMassiveBilling = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/massiveBillingList",
        params: {
          pointOfSaleId: parseInt(localStorage.getItem("pointOfSaleId")),
          pageNo: 0,
          pageSize: 10,
          sortBy: "id",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      // setFacturasEspera(response.data.totalElements);
      setListMassiveBilling(response.data.content);
    } catch (error) {
      handleError(error);
    }
  };

  /************************************************* Buscar cliente *********************************************/

  const getClient = async (id) => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/client/get/" + id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setValuesClient(
        response.data.businessClientId,
        response.data.type,
        response.data.documento,
        response.data.complemento,
        response.data.razonSocial,
        response.data.email === null ? "" : response.data.email,
        response.data.numCelular === null ? "" : response.data.numCelular
      );
      if (response.data.codigoClasificadorSiat === 5) {
        verificarNIT(response.data.documento);
        setCodigoClasificadorSiat(response.data.codigoClasificadorSiat);
      } else {
        setNitValido("");
        setCheckNit(false);
      }
    } catch (error) {
      handleError(error)
    }
  };

  /************************************************ Listar productos ********************************************/
  const [lista, setLista] = useState([]);
  const loadListProducts = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/product/listAll",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setLista(
        response.data.map((sup) => ({
          label: sup.descripcionProducto,
          value: sup.id,
        }))
      );
      return response.data.map((sup) => ({
        label: sup.descripcionProducto,
        value: sup.id,
      }));
    } catch (error) {
      handleError(error);
    }
  };

  /****************************************** Listar tipos de documento ************************************/
  const loadTypes = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "TIPO_DOCUMENTO_IDENTIDAD",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setTiposDocumentos(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {
      handleError(error)
    }
  };

  /********************************* Eliminar producto *********************************************************/
  const deleteItem = (id) => {
    const newProductDetails = productDetails.filter(
      (element) => id !== element.idE
    );
    setProductDetails(newProductDetails);
    formikBilling.resetForm({ data: "" });
  };

  /******************************************  ENVIO Y REGISTRO DE FACTURA ELECTRONICA MASIVA ***************************************** */
  const handleSubmitMassiveBilling = async (e) => {
    setBotonEmitirMasivo(true);
    e.preventDefault();
    try {
      const response = await axios({
        method: "post",
        url:
          process.env.REACT_APP_API + "/api/v1/billing/receptionMassiveInvoice",
        data: JSON.stringify({
          pointOfSaleId: parseInt(localStorage.getItem("pointOfSaleId")),
          codigoDocumentoSector: parseInt(
            localStorage.getItem("codigoDocumentoSector")
          ),
          tipoFacturaDocumento: parseInt(
            localStorage.getItem("tipoFacturaDocumento")
          ),
        }),
        headers: {
          "content-type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      if (response.status === 200) {
        setProductDetails([]);
        getListMassiveBilling();
        validarEnvioFactura();
        Swal.fire({
          customClass: {
            container: "validateBilling",
            title: "validateTitleBilling",
          },
          icon: "success",
          title: "¡Se ha enviado Exitosamente!",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        await validacionMasivo();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleChangeSearch = (e) => {
    formikSearchClient.setFieldValue("type", e.label);
    setCodigos(e.label);
    setType(e.label);
    setCodigoClasificador(e.value);
  };

  const [searchClient, setSearchClient] = useState(null);
  const handleSearchClient = (e) => {
    setSearchClient(e);
    getClient(e.value);
  };

  const validacionMasivo = async (nit) => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/validationMassiveInvoice",
        params: {
          pointOfSaleId: localStorage.getItem("pointOfSaleId"),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  /************************************ Verificar el estado del NIT *********************************/
  const [nitValido, setNitValido] = useState("");
  const verificarNIT = async (nit) => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/client/checkingNit",
        params: {
          nit: parseInt(nit),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const res = response.data.mensajesList.map(
        (element) => element.descripcion
      );
      if (res[0] === "NIT ACTIVO") {
        setCheckNit(true);
        setNitValido("NIT ACTIVO");
      } else if (res[0] === "NIT INACTIVO") {
        setCheckNit(false);
        setNitValido("NIT INACTIVO");
      } else {
        setNitValido("NIT INEXISTENTE");
      }
      return res[0];
    } catch (error) {
      handleError(error);
    }
  };

  const [x, setX] = useState(0);

  /*************************************************** Verificar la cantidad de facturas pendientes ************************************************/
  const validarEnvioFactura = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/checkingCountMassiveInvoice",
        params: {
          pointOfSaleId: parseInt(localStorage.getItem("pointOfSaleId")),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      return v(response.data.countBilling);
    } catch (error) {
      console.log(error);
    }
  };

  const v = async (y) => {
    try {
      const z = await y;
      setX(z);
      return z;
    } catch (error) {
      console.log(error);
    }
  };

  function limpiarCampos() {
    loadListProducts();
    setUnidadMedidaR("");
    setPrecioUnitarioR(0);
  }

  const getDetailBilling = () => {
    return {
      cantidad: 1,
      montoDescuento: 0,
      type: "",
      porcentaje: 0,
    };
  };

  /*********************************************** Registrar detalle de producto *****************************************************/
  const formikRegisterDetailBilling = useFormik({
    initialValues: getDetailBilling(),
    validationSchema: ValidationRegisterDetailProduct(cantidadDescuento, precioUnitarioR),
    onSubmit: async (data) => {
      setLoaderProduct(true);
      if (
        await productDetails.find((element) => element.productId === productId)
      ) {
        Swal.fire({
          customClass: {
            container: "formRegisterDetailProduct",
            title: "formTitleRegisterDetailProduct",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: "El producto ya ha sido registrado",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        setLoaderProduct(false);
      } else {
        let newItem = null;
        if (tipoDescuento === 1) {
          newItem = await {
            idE: new Date().toISOString(),

            productId: parseInt(productId),
            cantidad: parseFloat(data.cantidad),
            montoDescuento: parseFloat(data.montoDescuento),

            precio: parseFloat(precioUnitarioR),
            unidadMedida: unidadMedidaR,
            descripcionProducto: descripcionProducto,
            codigoProducto: codigoProducto,
            tipoDescuento: tipoDescuento,
            porcentaje: data.porcentaje,
          };
        } else if (tipoDescuento === 2) {
          newItem = await {
            idE: new Date().toISOString(),

            productId: parseInt(productId),
            cantidad: parseFloat(data.cantidad),
            montoDescuento: parseFloat(
              Number(
                (parseFloat(data.cantidad) *
                  parseFloat(precioUnitarioR) *
                  parseFloat(data.porcentaje)) /
                  100
              ).toFixed(2)
            ),

            precio: parseFloat(precioUnitarioR),
            unidadMedida: unidadMedidaR,
            descripcionProducto: descripcionProducto,
            codigoProducto: codigoProducto,
            tipoDescuento: tipoDescuento,
            porcentaje: data.porcentaje,
          };
        } else {
          newItem = await {
            idE: new Date().toISOString(),

            productId: parseInt(productId),
            cantidad: parseFloat(data.cantidad),
            montoDescuento: parseFloat(0),

            precio: parseFloat(precioUnitarioR),
            unidadMedida: unidadMedidaR,
            descripcionProducto: descripcionProducto,
            codigoProducto: codigoProducto,
            tipoDescuento: tipoDescuento,
            porcentaje: data.porcentaje,
          };
        }
        setProductDetails([newItem, ...productDetails]);
        formikRegisterDetailBilling.resetForm({ data: "" });
        setTipoDescuento(1);
        Swal.fire({
          customClass: {
            container: "formRegisterDetailProduct",
            title: "formTitleRegisterDetailProduct",
          },
          icon: "success",
          title: "¡El producto ha sido registrado!",
          showConfirmButton: false,
          timer: 1500,
        });
        setAuxProduct("");
        setLoaderProduct(false);
        handleClose();
      }
    },
  });

  /*********************************************** Actualizar detalle de producto ***********************************************/
  const formikEditDetailBilling = useFormik({
    initialValues: {
      cantidad: cantidad,
      montoDescuento: 0,
      porcentaje: 0,
    },
    validationSchema: ValidationEditDetailProduct(cantidadDescuento, precioUnitarioE),
    onSubmit: async (data) => {
      const listaActualizada = await productDetails.map((element) => {
        if (element.idE === idDetail) {
          if (tipoDescuento === 1) {
            return {
              ...element,
              idE: idDetail,

              productId: parseInt(productIdE),
              cantidad: parseFloat(data.cantidad),
              montoDescuento: parseFloat(data.montoDescuento),

              precio: parseFloat(precioUnitarioE),
              unidadMedida: unidadMedidaE,
              descripcionProducto: descripcionProducto,
              codigoProducto: codigoProducto,
              tipoDescuento: tipoDescuento,
              porcentaje: data.porcentaje,
            };
          } else if (tipoDescuento === 2) {
            return {
              ...element,
              idE: idDetail,

              productId: parseInt(productIdE),
              cantidad: parseFloat(data.cantidad),
              montoDescuento: parseFloat(
                Number(
                  (parseFloat(data.cantidad) *
                    parseFloat(precioUnitarioE) *
                    parseFloat(data.porcentaje)) /
                    100
                ).toFixed(2)
              ),

              precio: parseFloat(precioUnitarioE),
              unidadMedida: unidadMedidaE,
              descripcionProducto: descripcionProducto,
              codigoProducto: codigoProducto,
              tipoDescuento: tipoDescuento,
              porcentaje: data.porcentaje,
            };
          } else {
            return {
              ...element,
              idE: idDetail,

              productId: parseInt(productIdE),
              cantidad: parseFloat(data.cantidad),
              montoDescuento: parseFloat(0),

              precio: parseFloat(precioUnitarioE),
              unidadMedida: unidadMedidaE,
              descripcionProducto: descripcionProducto,
              codigoProducto: codigoProducto,
              tipoDescuento: tipoDescuento,
              porcentaje: data.porcentaje,
            };
          }
        }
        return element;
      });
      setIdDetail("");
      setProductDetails(listaActualizada);
      Swal.fire({
        customClass: {
          container: "formEditDetailProduct",
          title: "formTitleEditDetailProduct",
        },
        icon: "success",
        title: "¡El producto ha sido actualizado!",
        showConfirmButton: false,
        timer: 1500,
      });
      handleCloseEdit();
    },
  });

  const setValuesClient = (
    id,
    type,
    documento,
    complemento,
    razonSocial,
    email,
    numCelular
  ) => {
    setIdView(id);
    setClientId(id);
    setTypeView(type);
    setDocumentoView(documento);
    setComplementoView(complemento);
    setRazonSocialView(razonSocial);
    setEmailView(email);
    setNumCelularView(numCelular);
  };

  const loadOptions2 = async (inputValue, callback) => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API + "/api/v1/billing/client/find-client-full",
        params: {
          documento: inputValue,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const toSelectOption = await response.data.map((sup) => ({
        label: sup.fullDocument + " - " + sup.razonSocial,
        value: sup.id,
      }));

      callback(toSelectOption);
    } catch (error) {
      handleError(error)
    }
  };

  const getInitialSearch = () => {
    return {
      documentoSearch: "",
      complementoSearch: "",
      type: "",
    };
  };
  /********************************************************* Buscar cliente *******************************/
  const formikSearchClient = useFormik({
    initialValues: getInitialSearch(),
    validationSchema: ValidationSearchCustomer,
    onSubmit: async (data) => {
      try {
        const response = await axios({
          method: "get",
          url:
            process.env.REACT_APP_API +
            "/api/v1/billing/client/find-client-full",
          params: {
            documento: data.documentoSearch,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
        if (response.data !== "") {
          setValuesClient(
            response.data.businessClientId,
            response.data.type,
            response.data.documento,
            response.data.complemento,
            response.data.razonSocial,
            response.data.email === null ? "" : response.data.email,
            response.data.numCelular === null ? "" : response.data.numCelular
          );
          handleCloseSearch();
          formikSearchClient.resetForm({ data: "" });
        }
        if (response.data === "") {
          handleCloseSearch();
          formikSearchClient.resetForm({ data: "" });
          handleOpenQuestion();
        }
        if (codigoClasificador === 5) {
          verificarNIT(data.documentoSearch);
        } else {
          setNitValido("");
        }
      } catch (error) {
        handleError(error);
      }
    },
  });

  const getInitialValueRegister = () => {
    return {
      documento: "",
      complemento: "",
      razonSocial: "",
      email: "",
      numCelular: "",
      type: "",
    };
  };

  /*********************************************** Registrar cliente *************************************/
  const formikRegisterClient = useFormik({
    initialValues: getInitialValueRegister(),
    validationSchema: ValidationRegisterCustomer,
    onSubmit: async (data) => {
      setLoader(true);
      try {
        const response = await axios({
          method: "post",
          url: process.env.REACT_APP_API + "/api/v1/billing/client/save",
          data: JSON.stringify({
            documento: data.documento,
            complemento: data.complemento,
            codigoClasificadorSiat: parseInt(codigoClasificadorSiat),
            razonSocial: data.razonSocial,
            email: data.email,
            numCelular: parseInt(data.numCelular),
          }),
          headers: {
            "content-type": "application/json;charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
        if (response.status === 200) {
          handleCloseRegister();
          setCodigoClasificadorSiat(0);
          formikRegisterClient.resetForm({ data: "" });
          Swal.fire({
            customClass: {
              container: "formRegisterClient",
              title: "formTitleRegisterClient",
            },
            icon: "success",
            title: "¡El cliente ha sido registrado exitosamente!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setLoader(false);
          getClient(response.data.businessClientId);
        }
      } catch (error) {
        setLoader(false);
        handleError(error, error.response.data.message);
      }
    },
  });

  /************************************************** Emitir factura *******************************************************/
  const getInitialValues = () => {
    return {
      code: false,
      codeT: false,
      codeG: false,
      razonSocial99001: "",
      tipoCambio: 1,
      descuento: 0,
      gift: 0,
      tipoPago: "1",
      tarjeta1: "",
    };
  };
  const formikBilling = useFormik({
    initialValues: getInitialValues(),
    validationSchema: ValidationBilling(productDetails, gift, o),
    onSubmit: async (data) => {
      setBotonEmitir(true);
      if (nitValido !== "NIT INEXISTENTE") {
        try {
          const response = await axios({
            method: "post",
            url:
              process.env.REACT_APP_API + "/api/v1/billing/buyAndSellBilling",
            data: JSON.stringify({
              pointOfSaleId: parseInt(localStorage.getItem("pointOfSaleId")),
              clientId: parseInt(clientId),
              codeEvent: 0,
              totalDescuento: parseFloat(data.descuento),
              shippingType: shippingType,
              checkNit: checkNit,
              emissionDate: "",
              razonSocial: data.razonSocial99001,
              codigoMetodoPago: parseInt(valuePay),
              numeroTarjeta: data.tarjeta1 === "0" ? "" : data.tarjeta1,
              tipoFacturaDocumento: parseInt(
                localStorage.getItem("tipoFacturaDocumento")
              ),
              codigoMoneda: parseInt(codigoMoneda),
              tipoCambio: parseFloat(data.tipoCambio),
              montoGiftCard: parseFloat(data.gift),
              codigoDocumentoSector: parseInt(
                localStorage.getItem("codigoDocumentoSector")
              ),
              productDetails: productDetails,
            }),
            headers: {
              "content-type": "application/json;charset=utf-8",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          });
          setIdBilling(response.data.cuf);
          if (response.status === 200) {
            setBotonEmitirMasivo(false);
            setProductDetails([]);
            setValuePay(1);
            setO(0.0);
            setGift(0.0);
            setLabelPay("EFECTIVO");
            getListMassiveBilling();
            formikBilling.resetForm({ data: "" });
            setBotonEmitir(false);
            handleOpenBilling();
            setTimeout(function () {
              setBotonEmitir(false);
            }, 2000);
            cleanFieldsClient();
          }
        } catch (error) {
          handleError(error, null);
        }
        finally
        {
          cleanFieldsClient();
        }
      }
      if (nitValido === "NIT INEXISTENTE") {
        Swal.fire({
          customClass: {
            container: "notFoundNIT",
            title: "notTitleFoundNIT",
          },
          title: "El NIT es inexistente",
          text: "¿Desea emitir la factura de todas formas?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          cancelButtonColor: "#94948f",
          confirmButtonColor: "#34974d",
          reverseButtons: true,
          confirmButtonText: "Aceptar",
        }).then((result) => {
          if (result.isConfirmed) {
            setBotonEmitir(true);
            (async () => {
              try {
                const response = await axios({
                  method: "post",
                  url:
                    process.env.REACT_APP_API +
                    "/api/v1/billing/buyAndSellBilling",
                  data: JSON.stringify({
                    pointOfSaleId: parseInt(
                      localStorage.getItem("pointOfSaleId")
                    ),
                    clientId: parseInt(clientId),
                    codeEvent: 0,
                    totalDescuento: parseFloat(data.descuento),
                    shippingType: shippingType,
                    checkNit: checkNit,
                    emissionDate: "",
                    razonSocial: data.razonSocial99001,
                    codigoMetodoPago: parseInt(valuePay),
                    numeroTarjeta: data.tarjeta1 === "0" ? "" : data.tarjeta1,
                    tipoFacturaDocumento: parseInt(
                      localStorage.getItem("tipoFacturaDocumento")
                    ),
                    codigoMoneda: parseInt(codigoMoneda),
                    tipoCambio: parseFloat(data.tipoCambio),
                    montoGiftCard: parseFloat(data.gift),
                    codigoDocumentoSector: parseInt(
                      localStorage.getItem("codigoDocumentoSector")
                    ),
                    productDetails: productDetails,
                  }),
                  headers: {
                    "content-type": "application/json;charset=utf-8",
                    Authorization:
                      "Bearer " + localStorage.getItem("accessToken"),
                  },
                });
                setIdBilling(response.data.cuf);
                if (response.status === 200) {
                  setBotonEmitirMasivo(false);
                  setBotonEmitir(true);
                  setValuePay(1);
                  setO(0.0);
                  setGift(0.0);
                  setLabelPay("EFECTIVO");
                  setProductDetails([]);
                  getListMassiveBilling();
                  formikBilling.resetForm({ data: "" });
                  setBotonEmitir(false);
                  handleOpenBilling();
                  cleanFieldsClient();
                }
              } catch (error) {
                setBotonEmitir(false);
                handleError(error);
              }
              finally
              {
                formikSearchClient.resetForm();
              }
            })();
          }
          setTimeout(function () {
            setBotonEmitir(false);
          }, 2000);
        });
      }
    },
  });

  function NoRowsOverlay() {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <h2 style={{ color: "gray" }}></h2>
      </Stack>
    );
  }

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange1("panel1")}
      >
        <AccordionSummary
          sx={{ backgroundColor: "#2c5e13", color: "#fff" }}
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            className="titleProduct"
            sx={{ fontSize: "32px", fontFamily: "Oswald" }}
          >
            Facturación masiva
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} md={6} sx={{ marginBottom: "20px" }}>
              <button
                className="buttonRCBilling"
                type="button"
                onClick={() => handleOpenRegister()}
              >
                <span className="buttonRCBilling__text">Clientes</span>
                <span className="buttonRCBilling__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    viewBox="0 0 48 48"
                    height="48"
                    className="svgRCBilling"
                  >
                    <AddIcon sx={{ color: "white" }} />
                  </svg>
                </span>
              </button>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "right",
              }}
            >
              {specialOption === "Ninguno" ? (
                <>
                  <Box
                    className="searchClientBilling"
                    sx={{ zIndex: 999, width: "100%", paddingLeft: "5px" }}
                  >
                    <AsyncSelect
                      isSearchable
                      menuPosition="fixed"
                      styles={CustomDropdownStyle}
                      loadOptions={loadOptions2}
                      value={searchClient}
                      noOptionsMessage={() => "Escriba para buscar clientes"}
                      placeholder={"Buscar cliente"}
                      onChange={(e) => handleSearchClient(e)}
                      name="searchClient"
                    />
                  </Box>
                </>
              ) : (
                " "
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
              <hr />
              <Typography
                className="labelClient"
                sx={{ fontSize: "26px", fontWeight: 400, fontFamily: "Oswald" }}
              >
                Datos básicos del contribuyente
              </Typography>
              <hr />
            </Grid>

            {specialOption === "Ninguno" ? (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={typeView}
                    variant="outlined"
                    label="Tipo de documento (Cliente)"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={documentoView}
                    variant="outlined"
                    label="Número de documento/NIT (Cliente)"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={razonSocialView}
                    variant="outlined"
                    label="Razón social (Cliente)"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={emailView}
                    onChange={(e) => e.target.value}
                    variant="outlined"
                    label="Correo electrónico (Cliente)"
                    size="small"
                    fullWidth
                    name="correoCliente"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={numCelularView}
                    onChange={(e) => e.target.value}
                    variant="outlined"
                    label="Número de celular (Cliente)"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Fecha de emisión"
                        defaultValue={dayjs(today)}
                        disabled={true}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </>
            ) : (
              " "
            )}
            <Grid item xs={12} md={6} sx={{ zIndex: 999 }}>
              <Select
                ref={inputRef}
                styles={CustomDropdownStyle}
                options={optionsSpecialCases}
                onChange={(e) => handleSpecialOptions(e)}
                placeholder={"Casos especiales"}
                value={optionsSpecialCases.value}
                defaultValue={optionsSpecialCases[0]}
              />
            </Grid>
            {specialOption === "99001" ? (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    label="Razón social"
                    id="razonSocial99001"
                    name="razonSocial99001"
                    value={formikBilling.values.razonSocial99001}
                    onBlur={formikBilling.handleBlur}
                    onChange={formikBilling.handleChange}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikBilling.touched.razonSocial99001 &&
                      formikBilling.errors.razonSocial99001}
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded2 === "panel2"}
        onChange={handleChange2("panel2")}
      >
        <AccordionSummary
          sx={{ backgroundColor: "#2c5e13", color: "#fff" }}
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Emisión de factura</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={formikBilling.handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={12} sx={{ marginBottom: "20px" }}>
                <button
                  className="buttonRDBilling"
                  type="button"
                  onClick={() => handleOpen()}
                >
                  <span className="buttonRDBilling__text">Productos</span>
                  <span className="buttonRDBilling__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      viewBox="0 0 48 48"
                      height="48"
                      className="svgRDBilling"
                    >
                      <AddIcon sx={{ color: "white" }} />
                    </svg>
                  </span>
                </button>
              </Grid>

              {productDetails.length > 0 ? (
                <Grid
                  item
                  xs={4}
                  md={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "20px",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    className="labelPay"
                    sx={{
                      fontSize: "26px",
                      fontFamily: "Oswald",
                    }}
                  >
                    Pagos:{" "}
                  </Typography>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <AddCardIcon
                      fontSize="medium"
                      onClick={(e) => handleOpenMethodPay(e)}
                    />
                  </IconButton>
                </Grid>
              ) : (
                ""
              )}

              <Grid
                item
                xs={8}
                md={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                  marginTop: "20px",
                }}
              >
                <Typography
                  className="labelTotal"
                  sx={{
                    fontSize: "26px",
                    fontFamily: "Oswald",
                  }}
                >
                  Subtotal:{" "}
                  {Number(
                    productDetails
                      .map(
                        (item) =>
                          item.cantidad * item.precio - item.montoDescuento
                      )
                      .reduce((prev, curr) => prev + curr, 0)
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                  marginTop: "20px",
                }}
              >
                <FormControl
                  fullWidth
                  sx={{
                    display: "flex",
                    paddingLeft: "20px",
                  }}
                >
                  {productDetails.length > 0 ? (
                    <>
                      <TextField
                        ref={descuentoRef}
                        variant="outlined"
                        label="Descuento"
                        size="small"
                        fullWidth
                        id="descuento"
                        onClick={(e) => handleSelectGlobalDescuento(e)}
                        name="descuento"
                        onBlur={formikBilling.handleBlur}
                        onChange={(e) => {
                          parseFloat(e.target.value) < 0
                            ? setO(parseFloat(0))
                            : setO(parseFloat(e.target.value));
                          formikBilling.handleChange(e);
                        }}
                        value={formikBilling.values.descuento}
                      />
                    </>
                  ) : (
                    " "
                  )}
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikBilling.touched.descuento &&
                      formikBilling.errors.descuento}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Box
                width="100%"
                sx={{
                  height: 350,
                  width: "100%",
                  marginTop: "20px",
                  marginBottom: "10px",
                  "& .super-app-theme--header": {
                    backgroundColor: "whitesmoke",
                    fontFamily: "Cambria",
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "black",
                  },
                }}
              >
                {/* <ThemeProvider theme={theme}> */}
                <DataGrid
                  columns={matches ? columns2 : columns1}
                  rows={rows}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  components={{
                    NoRowsOverlay,
                  }}
                  getRowId={(row) => row.internalId}
                  disableColumnMenu={true}
                  disableColumnFilter={true}
                  disableColumnSelector={true}
                  disableSelectionOnClick
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: "Filas por página",
                    },
                  }}
                />
                {/* </ThemeProvider> */}
              </Box>

              {productDetails.length > 0 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <p className="montoPagar" style={{ fontFamily: "Oswald" }}>
                      Monto a pagar: Bs {" "}
                      {Number(
                        productDetails
                          .map(
                            (item) =>
                              item.cantidad * item.precio - item.montoDescuento
                          )
                          .reduce((prev, curr) => prev + curr, 0) <
                          o + gift
                          ? "0"
                          : Number(
                              productDetails
                                .map(
                                  (item) =>
                                    item.cantidad * item.precio -
                                    item.montoDescuento
                                )
                                .reduce((prev, curr) => prev + curr, 0)
                                .toFixed(2)
                            ) -
                              Number(o).toFixed(2) -
                              gift.toFixed(2)
                      ).toFixed(2)}{" "}
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "right" }}
                  >
                    {botonEmitir ? (
                      <button
                        type="submit"
                        style={{ width: "150px", marginTop: "20px" }}
                        disabled={botonEmitir}
                        className="buttonLoading"
                        onClick={() => handleCheckErrors()}
                      >
                        <span className="buttonLoading__text">Enviando...</span>
                        <span className="buttonLoading__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            viewBox="0 0 48 48"
                            height="48"
                            className="svgLoading"
                          >
                            <RotateLeftIcon
                              fontSize="small"
                              sx={{ color: "white" }}
                            />
                          </svg>
                        </span>
                      </button>
                    ) : (
                      <button
                        style={{ width: "150px", marginTop: "20px" }}
                        className="buttonSendData"
                        type="submit"
                        onClick={() => handleCheckErrors()}
                      >
                        <span className="buttonSendData__text">
                          Emitir factura
                        </span>
                        <span className="buttonSendData__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            viewBox="0 0 48 48"
                            height="48"
                            className="svgSendData"
                          >
                            <SendIcon sx={{ color: "white" }} />
                          </svg>
                        </span>
                      </button>
                    )}
                  </Grid>
                </>
              ) : (
                " "
              )}
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded3 === "panel3"}
        onChange={handleChange3("panel3")}
      >
        <AccordionSummary
          sx={{ backgroundColor: "#2c5e13", color: "#fff" }}
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Lista de facturas de emisión masiva</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            width="100%"
            sx={{
              height: 350,
              width: "100%",
              marginTop: "20px",
              marginBottom: "10px",
              "& .super-app-theme--header": {
                backgroundColor: "whitesmoke",
                fontFamily: "Cambria",
                fontWeight: 400,
                fontSize: "16px",
                color: "black",
              },
            }}
          >
            {/* <ThemeProvider theme={theme}> */}
            <DataGrid
              columns={matches ? columnsBilling2 : columnsBilling1}
              rows={rowsBilling}
              pageSize={100}
              rowsPerPageOptions={[100]}
              components={{
                NoRowsOverlay,
              }}
              disableSelectionOnClick
              disableColumnMenu={true}
              disableColumnFilter={true}
              disableColumnSelector={true}
              componentsProps={{
                pagination: {
                  labelRowsPerPage: "Filas por página",
                },
              }}
            />
          </Box>

          <Grid container>
            <Grid item xs={12} md={12} align="right">
              <form onSubmit={(e) => handleSubmitMassiveBilling(e)}>
                <TextField
                  sx={{ display: "none" }}
                  value={localStorage.getItem("pointOfSaleId")}
                ></TextField>
                <TextField
                  sx={{ display: "none" }}
                  value={localStorage.getItem("codigoDocumentoSector")}
                ></TextField>
                <TextField
                  sx={{ display: "none" }}
                  value={localStorage.getItem("tipoFacturaDocumento")}
                ></TextField>

                {listMassiveBilling.length > 0 ? (
                  botonEmitirMasivo ? (
                    <ButtonLoadingData hide={true} />
                  ) : (
                    <button
                      type="submit"
                      style={{
                        width: "150px",
                        marginTop: "20px",
                        marginBottom: "40px",
                      }}
                      disabled={botonEmitirMasivo}
                      //  onClick={(e) => ocultarBotonEmitirMasivo(e)}
                      className="buttonSendData"
                    >
                      <span className="buttonSendData__text">
                        Validar factura
                      </span>
                      <span className="buttonSendData__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          viewBox="0 0 48 48"
                          height="48"
                          className="svgSendData"
                        >
                          <SendIcon sx={{ color: "white" }} />
                        </svg>
                      </span>
                    </button>
                  )
                ) : (
                  " "
                )}
              </form>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/********************************************** Ventana modal para métodos de pago *****************************************/}
      <Dialog
        fullScreen
        open={openMethodPay}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="contentDialog">
          {formikBilling.touched.tipoCambio &&
          formikBilling.errors.tipoCambio ? (
            <button
              className="buttonGoBack"
              type="button"
              onClick={(e) => handleCloseMethodPay(e)}
              style={{ marginBottom: "40px" }}
            >
              <span className="buttonGoBack__text">Cerrar</span>
              <span className="buttonGoBack__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  viewBox="0 0 48 48"
                  height="48"
                  className="svgGoBack"
                >
                  <CloseIcon sx={{ color: "white" }} />
                </svg>
              </span>
            </button>
          ) : (
            <button
              className="buttonGoBack"
              type="button"
              onClick={(e) => handleCloseMethodPay(e)}
              style={{ marginBottom: "40px" }}
            >
              <span className="buttonGoBack__text">Cerrar</span>
              <span className="buttonGoBack__textSmall">
                <CloseIcon sx={{ color: "white", borderRadius: "50%" }} />
              </span>
              <span className="buttonGoBack__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  viewBox="0 0 48 48"
                  height="48"
                  className="svgGoBack"
                >
                  <CloseIcon sx={{ color: "white" }} />
                </svg>
              </span>
            </button>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  marginRight: "10px",
                  fontFamily: "Oswald",
                }}
              >
                Tipo de cambio
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  color: "gray",
                  fontFamily: "Oswald",
                }}
              >
                Tipo de moneda:
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Select
                // fullWidth
                options={tipoMoneda}
                placeholder={"Seleccione el tipo de moneda"}
                sx={{ width: "100%" }}
                styles={CustomDropdownStyle}
                isSearchable={true}
                noOptionsMessage={() => "No existen elementos"}
                onChange={(e) => handleChangeMoneda(e)}
                value={tipoMoneda.filter(
                  (element) => element.label === labelMoneda
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  color: "gray",
                  fontFamily: "Oswald",
                }}
              >
                Tipo de cambio:
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  size="small"
                  id="tipoCambio"
                  name="tipoCambio"
                  onClick={(e) => handleSelectTipoCambio(e)}
                  onBlur={formikBilling.handleBlur}
                  onChange={(e) => {
                    formikBilling.handleChange(e);
                  }}
                  value={formikBilling.values.tipoCambio}
                />
                <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                  {formikBilling.touched.tipoCambio &&
                    formikBilling.errors.tipoCambio}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  marginRight: "10px",
                  fontFamily: "Oswald",
                }}
              >
                Método de pago
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  color: "gray",
                  marginRight: "10px",
                  fontFamily: "Oswald",
                }}
              >
                Método de pago:
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} sx={{ marginTop: "10px" }}>
              <Select
                //  fullWidth
                autoFocus
                sx={{ width: "100%" }}
                styles={CustomDropdownStyle}
                options={methodPayList}
                isSearchable={true}
                noOptionsMessage={() => "No existen elementos"}
                placeholder={"Seleccione un método de pago"}
                onChange={(e) => handleChangePay(e)}
                value={methodPayList.filter(
                  (element) => element.label === labelPay
                )}
              />
            </Grid>
            {labelPay.includes("GIFT") ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    margintTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 400,
                      color: "gray",
                      fontFamily: "Oswald",
                    }}
                  >
                    Gift card:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="gift"
                      name="gift"
                      onClick={(e) => handleSelectGift(e)}
                      onBlur={formikBilling.handleBlur}
                      onChange={(e) => {
                        e.target.value < 0
                          ? setGift(parseFloat(0))
                          : setGift(parseFloat(e.target.value));
                        formikBilling.handleChange(e);
                      }}
                      value={formikBilling.values.gift}
                    />
                    <FormHelperText
                      sx={{
                        color: "chocolate",
                        fontSize: "14px",
                      }}
                    >
                      {formikBilling.touched.gift && formikBilling.errors.gift}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            ) : (
              ""
            )}
            {labelPay.includes("TARJETA") ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 400,
                      color: "gray",
                      fontFamily: "Oswald",
                    }}
                  >
                    Número de tarjeta:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  sx={{
                    display: "flex",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      ref={metodosRef}
                      size="small"
                      fullWidth
                      autoFocus
                      id="tarjeta1"
                      name="tarjeta1"
                      onClick={(e) => handleSelectTarjeta1(e)}
                      onBlur={formikBilling.handleBlur}
                      onChange={formikBilling.handleChange}
                      value={formikBilling.values.tarjeta1}
                    />
                    <FormHelperText
                      sx={{
                        color: "chocolate",
                        fontSize: "14px",
                      }}
                    >
                      {formikBilling.touched.tarjeta1 &&
                        formikBilling.errors.tarjeta1}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      {/********************************************** Ventana modal para actualizar detalle de producto *****************************************/}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={openEdit}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "#D1B000", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Editar datos de la transacción
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikEditDetailBilling.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ display: "none" }}>
                <TextField
                  value={idDetail}
                  onChange={(e) => setIdDetail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ zIndex: 7 }}>
                <Typography className="labelFormClient">
                  Seleccione un producto
                </Typography>
                <AsyncSelect
                  styles={CustomDropdownEdit}
                  value={productDetails.filter(
                    (element) => element.label === descripcionProducto
                  )}
                  loadOptions={loadOptions}
                  isSearchable={true}
                  noOptionsMessage={() => "Escriba para buscar productos"}
                  placeholder={descripcionProducto}
                  onChange={handleChangeProductEdit}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                  Unidad de medida
                </Typography>
                <TextField
                  disabled
                  value={unidadMedidaE}
                  onChange={(e) => setUnidadMedidaE(e.target.value)}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                  Precio unitario
                </Typography>
                <TextField
                  disabled
                  value={precioUnitarioE}
                  onChange={(e) => setPrecioUnitarioE(e.target.value)}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">Cantidad</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="cantidad"
                    onClick={(e) => handleSelectCantidad(e)}
                    name="cantidad"
                    value={formikEditDetailBilling.values.cantidad}
                    onChange={handleNumericFieldChangeEdit}
                    onBlur={formikEditDetailBilling.handleBlur}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikEditDetailBilling.touched.cantidad &&
                      formikEditDetailBilling.errors.cantidad}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {tipoDescuento === 1 ? (
                <Grid item xs={12} md={9}>
                  <FormControl fullWidth>
                    <Typography className="labelFormClient">
                      Descuento (Bs)
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="montoDescuentoEdit"
                      onClick={(e) => handleSelectDescuentoEdit()}
                      name="montoDescuento"
                      onBlur={formikEditDetailBilling.handleBlur}
                      onChange={formikEditDetailBilling.handleChange}
                      value={formikEditDetailBilling.values.montoDescuento}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikEditDetailBilling.touched.montoDescuento &&
                        formikEditDetailBilling.errors.montoDescuento}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12} md={9}>
                  <FormControl fullWidth>
                    <Typography className="labelFormClient">
                      Descuento (%)
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      id="porcentajeEdit"
                      onClick={(e) => handleSelectPorcentajeEdit(e)}
                      name="porcentaje"
                      onBlur={formikEditDetailBilling.handleBlur}
                      onChange={formikEditDetailBilling.handleChange}
                      value={formikEditDetailBilling.values.porcentaje}
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                          border: "1px solid white",
                          borderColor: "white",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikEditDetailBilling.touched.porcentaje &&
                        formikEditDetailBilling.errors.porcentaje}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <Typography
                  className="labelFormClient"
                  style={{ visibility: "hidden" }}
                >
                  (Bs)
                </Typography>
                <Select
                  styles={CustomDropdownEdit}
                  value={tiposDescuento.filter(
                    (element) => element.value === tipoDescuento
                  )}
                  options={tiposDescuento}
                  onChange={(e) => handleChangeTipoDescuentoEdit(e)}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleCloseEdit()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              <button
                className="buttonRegisterData"
                style={{ border: "1px solid #D1B000" }}
                type="submit"
              >
                <span className="buttonEdit">Actualizar</span>
              </button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      {/********************************************** Ventana modal para buscar clientes *****************************************/}
      <Modal open={openSearch}>
        <Box>
          <Paper elevation={4} sx={ModalRegisterStyle}>
            <form onSubmit={formikSearchClient.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 700, color: "gray" }}
                  >
                    Buscar cliente
                  </Typography>
                  <hr />
                </Grid>
                <Grid item xs={12} md={12} sx={{ zIndex: 9 }}>
                  <Select
                    styles={CustomDropdownStyle}
                    options={tiposDocumentos}
                    placeholder="Seleccione un tipo de documento"
                    onChange={(e) => {
                      handleChangeSearch(e);
                    }}
                  />
                </Grid>
                <FormControl fullWidth>
                  <TextField
                    sx={{ display: "none" }}
                    variant="outlined"
                    label="Oculto"
                    size="small"
                    fullWidth
                    name="type"
                    onBlur={formikSearchClient.handleBlur}
                    onChange={formikSearchClient.handleChange}
                    value={formikSearchClient.values.type}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  >
                    {type === "" &&
                      formikSearchClient.touched.type &&
                      formikSearchClient.errors.type}
                  </FormHelperText>
                </FormControl>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Número de documento"
                      size="small"
                      fullWidth
                      id="documentoSearch"
                      name="documentoSearch"
                      onBlur={formikSearchClient.handleBlur}
                      onChange={formikSearchClient.handleChange}
                      value={formikSearchClient.values.documentoSearch}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikSearchClient.touched.documentoSearch &&
                        formikSearchClient.errors.documentoSearch}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {codigoClasificador === 1 ? (
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        label="Código de complemento"
                        size="small"
                        fullWidth
                        name="complementoSearch"
                        onBlur={formikSearchClient.handleBlur}
                        onChange={formikSearchClient.handleChange}
                        value={formikSearchClient.values.complementoSearch}
                      />
                      <FormHelperText
                        sx={{ color: "chocolate", fontSize: "14px" }}
                      >
                        {formikSearchClient.touched.complementoSearch &&
                          formikSearchClient.errors.complementoSearch}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                ) : (
                  ""
                )}

                <Grid item xs={12} md={12} sx={{ textAlign: "right" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                  >
                    Buscar
                  </Button>
                  <Button
                    onClick={() =>
                      handleCloseSearch(
                        formikSearchClient.resetForm({ data: "" })
                      )
                    }
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </Modal>

      {/********************************************** Ventana modal de cliente inexistente *****************************************/}
      <Modal open={openQuestion}>
        <Box>
          <Paper elevation={4} sx={ModalRegisterStyle}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 700, color: "gray", textAlign: "center" }}
                >
                  El cliente no exite. ¿Desea registrarlo?
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  onClick={() => {
                    handleOpenRegister();
                  }}
                  variant="contained"
                  sx={{ marginRight: "10px" }}
                >
                  Aceptar
                </Button>
                <Button
                  onClick={() => handleCloseQuestion()}
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>

      {/********************************************** Ventana modal para el registro de clientes *****************************************/}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={openRegister}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "green", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Registrar cliente
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikRegisterClient.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sx={{ display: "none" }}>
                <FormControl>
                  <TextField
                    value={businessId}
                    onChange={(e) => setBusinessId(e.target.value)}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterClient.touched.businessId &&
                      formikRegisterClient.errors.businessId}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ zIndex: 9 }}>
                <Typography className="labelFormClient">
                  Seleccione el tipo de documento
                </Typography>
                <Select
                  isSearchable
                  menuPosition="fixed"
                  styles={CustomDropdownStyle}
                  placeholder="Seleccionar..."
                  options={tiposDocumentos}
                  onChange={(e) => {
                    handleChangeCliente(e);
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="type"
                  onBlur={formikRegisterClient.handleBlur}
                  onChange={formikRegisterClient.handleChange}
                  value={formikRegisterClient.values.type}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {type === "" &&
                    formikRegisterClient.touched.type &&
                    formikRegisterClient.errors.type}
                </FormHelperText>
              </FormControl>

              {codigoClasificadorSiat !== 1 ? (
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <Typography className="labelFormClient">
                      Número de documento
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      name="documento"
                      onBlur={formikRegisterClient.handleBlur}
                      onChange={formikRegisterClient.handleChange}
                      value={formikRegisterClient.values.documento}
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                          border: "1px solid white",
                          borderColor: "white",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikRegisterClient.touched.documento &&
                        formikRegisterClient.errors.documento}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <>
                  <Grid item xs={7} md={7}>
                    <FormControl fullWidth>
                      <Typography className="labelFormClient">
                        Número de documento
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="documento"
                        onBlur={formikRegisterClient.handleBlur}
                        onChange={formikRegisterClient.handleChange}
                        value={formikRegisterClient.values.documento}
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: "0px",
                            border: "1px solid white",
                            borderColor: "white",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{ color: "chocolate", fontSize: "14px" }}
                      >
                        {formikRegisterClient.touched.documento &&
                          formikRegisterClient.errors.documento}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5} md={5}>
                    <FormControl fullWidth>
                      <Typography className="labelFormClient">
                        Complemento
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="complemento"
                        onBlur={formikRegisterClient.handleBlur}
                        onChange={formikRegisterClient.handleChange}
                        value={formikRegisterClient.values.complemento}
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: "0px",
                            border: "1px solid white",
                            borderColor: "white",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{ color: "chocolate", fontSize: "14px" }}
                      >
                        {formikRegisterClient.touched.complemento &&
                          formikRegisterClient.errors.complemento}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Razón social
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="razonSocial"
                    onBlur={formikRegisterClient.handleBlur}
                    onChange={formikRegisterClient.handleChange}
                    value={formikRegisterClient.values.razonSocial}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterClient.touched.razonSocial &&
                      formikRegisterClient.errors.razonSocial}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Correo electrónico
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="email"
                    onBlur={formikRegisterClient.handleBlur}
                    onChange={formikRegisterClient.handleChange}
                    value={formikRegisterClient.values.email}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterClient.touched.email &&
                      formikRegisterClient.errors.email}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Número de celular
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="numCelular"
                    onBlur={formikRegisterClient.handleBlur}
                    onChange={formikRegisterClient.handleChange}
                    value={formikRegisterClient.values.numCelular}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterClient.touched.numCelular &&
                      formikRegisterClient.errors.numCelular}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() =>
                  handleCloseRegister(
                    formikRegisterClient.resetForm({ data: "" })
                  )
                }
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
                <button className="buttonRegisterData" type="submit">
                  <span className="buttonRegisterData">Registrar</span>
                </button>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      {/********************************************** Ventana modal para el registro de detalle de producto *****************************************/}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "green", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Detalles de la transacción
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikRegisterDetailBilling.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ zIndex: 7 }}>
                <Typography className="labelFormClient">
                  Seleccione un producto
                </Typography>
                <AsyncSelect
                  menuPosition="fixed"
                  styles={CustomDropdownStyle}
                  defaultOptions
                  loadOptions={loadOptions}
                  isSearchable={true}
                  noOptionsMessage={() => "Escriba para buscar productos"}
                  placeholder={"Escriba un palabra..."}
                  onChange={(e) => handleChangeProduct(e)}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="Número de documento"
                  size="small"
                  fullWidth
                  name="type"
                  onBlur={formikRegisterDetailBilling.handleBlur}
                  onChange={formikRegisterDetailBilling.handleChange}
                  value={formikRegisterDetailBilling.values.type}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {type === "" &&
                    formikRegisterDetailBilling.touched.type &&
                    formikRegisterDetailBilling.errors.type}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                  Unidad de medida
                </Typography>
                <TextField
                  disabled
                  value={unidadMedidaR}
                  onChange={(e) => setUnidadMedidaR(e.target.value)}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: {
                      borderRadius: "0px",
                      border: "1px solid white",
                      borderColor: "white",
                    },
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="Número de documento"
                  size="small"
                  fullWidth
                  name="type"
                  onBlur={formikRegisterDetailBilling.handleBlur}
                  onChange={formikRegisterDetailBilling.handleChange}
                  value={formikRegisterDetailBilling.values.type}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {type === "" &&
                    formikRegisterDetailBilling.touched.type &&
                    formikRegisterDetailBilling.errors.type}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormClient">
                  Precio unitario
                </Typography>
                <TextField
                  disabled
                  value={Number(precioUnitarioR).toFixed(2)}
                  onChange={(e) => setPrecioUnitarioR(e.target.value)}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: {
                      borderRadius: "0px",
                      border: "1px solid white",
                      borderColor: "white",
                    },
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="Número de documento"
                  size="small"
                  fullWidth
                  name="type"
                  onBlur={formikRegisterDetailBilling.handleBlur}
                  onChange={formikRegisterDetailBilling.handleChange}
                  value={formikRegisterDetailBilling.values.type}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {type === "" &&
                    formikRegisterDetailBilling.touched.type &&
                    formikRegisterDetailBilling.errors.type}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">Cantidad</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    id="cantidad"
                    onClick={(e) => handleSelectCantidad(e)}
                    name="cantidad"
                    onChange={handleNumericFieldChange}
                    onBlur={formikRegisterDetailBilling.handleBlur}
                    value={formikRegisterDetailBilling.values.cantidad}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterDetailBilling.touched.cantidad &&
                      formikRegisterDetailBilling.errors.cantidad}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {tipoDescuento === 1 ? (
                <Grid item xs={12} md={9}>
                  <FormControl fullWidth>
                    <Typography className="labelFormClient">
                      Descuento (Bs)
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      id="montoDescuento"
                      onClick={(e) => handleSelectDescuento(e)}
                      name="montoDescuento"
                      onBlur={formikRegisterDetailBilling.handleBlur}
                      onChange={formikRegisterDetailBilling.handleChange}
                      value={formikRegisterDetailBilling.values.montoDescuento}
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                          border: "1px solid white",
                          borderColor: "white",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikRegisterDetailBilling.touched.montoDescuento &&
                        formikRegisterDetailBilling.errors.montoDescuento}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12} md={9}>
                  <FormControl fullWidth>
                    <Typography className="labelFormClient">
                      Descuento (%)
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      id="porcentaje"
                      onClick={(e) => handleSelectPorcentaje()}
                      name="porcentaje"
                      onBlur={formikRegisterDetailBilling.handleBlur}
                      onChange={formikRegisterDetailBilling.handleChange}
                      value={formikRegisterDetailBilling.values.porcentaje}
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                          border: "1px solid white",
                          borderColor: "white",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikRegisterDetailBilling.touched.porcentaje &&
                        formikRegisterDetailBilling.errors.porcentaje}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <Typography
                  className="labelFormClient"
                  sx={{ visibility: "hidden" }}
                >
                  Bs
                </Typography>
                <Select
                  styles={CustomDropdownStyle}
                  onChange={(e) => handleChangeTipoDescuento(e)}
                  options={tiposDescuento}
                  value={tiposDescuento.value}
                  defaultValue={tiposDescuento[0]}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() =>
                  handleClose(
                    formikRegisterDetailBilling.resetForm({ data: "" })
                  )
                }
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loaderProduct ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : auxProduct ? (
                <button className="buttonRegisterData" type="submit">
                  <span className="buttonRegisterData">Registrar</span>
                </button>
              ) : (
                ""
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <Grid item xs={12}>
        <Tooltip id="massive-tooltip" place="right" />
      </Grid>
      <Modal open={openBilling} id="viewBilling">
        <Box>
          <Paper elevation={4} sx={styleViewBillings}>
            <Box display={"flex"} justifyContent={"flex-end"} >
              <DisabledByDefaultSharpIcon onClick={() =>handleCloseBilling()} fontSize="medium"/>
            </Box>
            <Grid container >
              <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    fontFamily: "Oswald",
                    fontWeight: 400,
                    fontSize: "28px",
                  }}
                >
                  ¿Cómo desea imprimir la factura?
                </Typography>
              </Grid>
            </Grid>
            <Grid item sx={{ textAlign: "center", justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                  marginTop: "10px",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    color: "chocolate",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Oswald",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", width: "120px" }}
                      key={idBilling}
                      href={
                        process.env.REACT_APP_API +
                        "/api/v1/pdf/billing/show?cuf=" +
                        idBilling +
                        "&modality=" +
                        localStorage.getItem("modality") +
                        "&emissionCode=ONLINE&typePdf=ROLLO"
                      }
                      target="_blank"
                      className="buttonEdit"
                    >
                      <span
                        className="buttonEdit__text"
                        style={{ fontSize: "16px", fontWeight: 400 }}
                      >
                        Rollo
                      </span>
                      <span className="buttonEdit__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          viewBox="0 0 48 48"
                          height="48"
                          className="svgEdit"
                        >
                          <ReceiptLongIcon sx={{ color: "white" }} />
                        </svg>
                      </span>
                    </a>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    color: "goldenrod",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Oswald",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", width: "120px" }}
                      key={idBilling + 1}
                      href={
                        process.env.REACT_APP_API +
                        "/api/v1/pdf/billing/show?cuf=" +
                        idBilling +
                        "&modality=" +
                        localStorage.getItem("modality") +
                        "&emissionCode=ONLINE&typePdf=MEDIA"
                      }
                      target="_blank"
                      className="button"
                    >
                      <span
                        className="button__text"
                        style={{ fontSize: "16px", fontWeight: 400 }}
                      >
                        1/2 Página
                      </span>
                      <span className="button__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          viewBox="0 0 48 48"
                          height="48"
                          className="svg"
                        >
                          <ArticleIcon sx={{ color: "white" }} />
                        </svg>
                      </span>
                    </a>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};

export default FormMassiveEmission;
