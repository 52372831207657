import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

const registerElectronicCompany = async (payLoad) => {
  const { data } = await axios.post(
    process.env.REACT_APP_API + "/api/v1/billing/business",
    payLoad,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
  return data;
};

export const useRegisterCompany = () => {
  const queryClient = useQueryClient();
  return useMutation(registerElectronicCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-company"] });
    },
  });
};
