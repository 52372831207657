import { Box} from "@mui/material";
import DataTableShowBilling from "../components/DataTableShowBilling";
import DataTableCancelBilling from "../components/DataTableCancelBilling";
import { useState } from "react";

const ShowBilling = () => {
  const [search, setSearch] = useState("");
  const [switchBtn, setSwitchBtn] = useState(true);  
  return (
    <Box>
          {switchBtn ? (
            <DataTableShowBilling
              searchProduct={search}
              setSwitchBtn={setSwitchBtn}
              switchBtn={switchBtn}
            />
          ) : (
            <DataTableCancelBilling
              searchProduct={search}
              setSwitchBtn={setSwitchBtn}
              switchBtn={switchBtn}
            />
          )}
    </Box>
  );
};
export default ShowBilling;
