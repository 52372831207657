import DeleteIcon from "@mui/icons-material/Delete";
import { useEnableUser } from "../hooks/useEnableUser";
import Swal from "sweetalert2";
import './ButtonEnableUser.css';
import "./ButtonEnableTable.css";

const ButtonEnableUser = ({id}) => {

const { mutate } = useEnableUser();
const handleEnableUser = (id) => {
  mutate(id, {
    onSuccess: () => {
      Swal.fire({
        customClass: {
          container: "formEnableUser",
          title: "formTitleEnableUser",
        },
        icon: "success",
        title: "¡El usuario ha sido habilitado!",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    onError: (error) => {
      if (error.response.status === 504) {
        Swal.fire({
          customClass: {
            container: "formEnableUser",
            title: "formTitleEnableUser",
          },
          icon: "error",
          title: "!Lo sentimos!",
          text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }  
      if (error.request.status === 500 || error.response.status === 503) {
        Swal.fire({
          customClass: {
            container: "formEnableUser",
            title: "formTitleEnableUser",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: "Por favor comuniquese con el administrador",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.request.status === 422) {
        Swal.fire({
          customClass: {
            container: "formEnableUser",
            title: "formTitleEnableUser",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: error.response.data.errors.map(
            (element) => element.message
          ),
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.request.status === 404) {
        Swal.fire({
          customClass: {
            container: "formEnableUser",
            title: "formTitleEnableUser",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: error.response.data.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.request.status === 400) {
        Swal.fire({
          customClass: {
            container: "formEnableUser",
            title: "formTitleEnableUser",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: error.response.data.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.request.status >= 0 && error.request.status <= 99) {
        Swal.fire({
          customClass: {
            container: "formEnableUser",
            title: "formTitleEnableUser",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: "Compruebe su conexión a internet",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }              
    }
  });
};


  return (
    <button
      className="buttonEnableTable"
      type="button"
      onClick={() => handleEnableUser(id)}
    >
      <span className="buttonEnableTable__text">Habilitar</span>
      <span className="buttonEnableTable__icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          viewBox="0 0 48 48"
          height="48"
          className="svgEnableTable"
        >
          <DeleteIcon sx={{ color: "white" }} />
        </svg>
      </span>
    </button>
  );
}
export default ButtonEnableUser