import { Box, Grid, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "../features/auth/authSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import ValidationLogin from "../validation/ValidationLogin";
import Image from "../assets/img/Qhatubit3.png";
import './Login.css';
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
 
const Login = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [habilitarInput, setHabilitarInput] = useState(false);
  const [respuestaError, setRespuestaError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const mostrarError = () => {
    setHabilitarInput(true);
    window.setTimeout(() => {
      setRespuestaError("");
      setHabilitarInput(false);
    }, 2000);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: ValidationLogin,
    onSubmit: async (data) => {
      try {
        const response = await axios({
          method: "post",
          url: process.env.REACT_APP_API + "/api/v1/auth/rest/login",
          data: {
            userName: data.userName,
            password: data.password,
          },
          headers: {
            "content-type": "application/json",
          },
        });
        dispatch(
          login({
            userName: data.userName,
            password: data.password,
            isLoading: true,
          })
        );
        if (response.status === 200) {
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("expiresIn", response.data.expiresIn);
          localStorage.setItem("expireRefreshTokenIn",response.data.expireRefreshTokenIn);
          localStorage.setItem("isLoggedIn", true);
          navigation("/pov");
        }
      } catch (error) {
        if (
          error.response.status === 401 ||
          error.response.data.message ===
            "¡Usuario o contraseña incorrecto!"
        )
          setRespuestaError(error.response.data.message);
        mostrarError();
      }
    },
  });

  useEffect(() => {
    window.location.hash = "login";
    window.location.hash = "Login"; //esta linea es necesaria para chrome
    window.onhashchange = function () {
      window.location.hash = "login";
    };
  }, []);  

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Box
        className="panelLogo"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "380px",
          height: "500px",
          boxShadow: "5px 5px 15px black",
          backgroundColor: "black",
        }}
      >
        <img src={Image} alt="" width={"200px"} height={"200px"} />
      </Box>
      <Box
        className="panelFormLogin"
        sx={{
          width: "380px",
          height: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "5px 5px 15px black",
          backgroundColor: `rgba(202, 182, 118, 0.2)`,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            className="containerFormLogin"
            sx={{
              width: "290px",
              height: "420px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              className="logoIcon"
              sx={{
                display: "none",
                width: "80px",
                height: "80px",
                backgroundColor: "#0f0f0f",
                borderRadius: "50%",
                border: "2px solid white",
              }}
            >
              <img
                src={Image}
                alt=""
                width={"100%"}
                height={"100%"}
                style={{ borderRadius: "50%" }}
              />
            </Box>
            <Grid container className="containerLabelSignIn">
              <Grid
                item
                xs={12}
                md={12}
                align="center"
                sx={{ marginBottom: "10px" }}
              >
                <Typography
                  className="labelSignIn"
                  component={"span"}
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "42px",
                    color: "white",
                  }}
                >
                  Iniciar Sesión
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className="labelEmail"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "18px",
                    marginBottom: "5px",
                    color: "white",
                  }}
                >
                  Correo electrónico
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    className="textFieldEmail"
                    type="text"
                    disabled={habilitarInput}
                    fullWidth
                    variant="standard"
                    name="userName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.userName}
                    sx={{ color: "white" }}
                    InputProps={{
                      style: {
                        color: "white",
                        borderBottom: "2px solid white",
                        fontSize: "18px",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon sx={{ color: "white" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText
                    className="labelErrorEmail"
                    sx={{ color: "yellow", fontSize: "14px" }}
                  >
                    {formik.touched.userName && formik.errors.userName}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className="labelPassword"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "18px",
                    marginTop: "10px",
                    marginBottom: "5px",
                    color: "white",
                  }}
                >
                  Contraseña
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    className="textFieldPassword"
                    fullWidth
                    disabled={habilitarInput}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    variant="standard"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    InputProps={{
                      style: {
                        color: "white",
                        borderBottom: "2px solid white",
                        fontSize: "18px",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon sx={{ color: "white" }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                            sx={{ color: 'white' }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText
                    className="labelErrorPassword"
                    sx={{ color: "yellow", fontSize: "14px" }}
                  >
                    {formik.touched.password && formik.errors.password}
                    {respuestaError}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} align="right" sx={{ marginBottom: "10px" }}>
                <Typography
                  className="labelForgetPassword"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "14px",
                    marginBottom: "10px",
                    color: "white",
                  }}
                >
                  ¿Olvidaste tu contraseña?
                  <Link to="/forgot_password" style={{ color: "white" }}>
                    {" "}
                    Haz click aquí
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <button
                  className="buttonSubmit"
                  type="submit"
                  disabled= {formik.isSubmitting ? (true) : (false)}
                  style={{
                    width: "100%",
                    padding: "5px",
                    backgroundColor: "black",
                  }}
                >
                  <Typography
                    className="labelButton"
                    sx={{
                      fontFamily: "Oswald",
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    Iniciar Sesión
                  </Typography>
                </button>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Typography
                  className="labelForgetPassword"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "white",
                  }}
                >
                  ¿Aún no te llegó el correo de activación?
                  <Link
                    to="/forwarding"
                    style={{
                      fontFamily: "Oswald",
                      fontSize: "14px",
                      marginBottom: "10px",
                      color: "white",
                    }}
                  >
                    {" "}
                    Haz click aquí
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </div>
  );
}
export default Login