import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Image from "../assets/img/business.jpg";
import "./LoginPage.css";

const LoginPage = () => {
  return (
    <>
        <Box
          className="principal"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
            backgroundImage: `url(${Image})`,
            backgroundSize: "cover",
          }}
        >
          <Outlet />
        </Box>   
     
   </>
  );
};
export default LoginPage;
