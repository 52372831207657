import {useMutation, useQueryClient} from 'react-query';
import axios from 'axios';

const deleteCuf = async (payload) => {
    try {
      const response = await axios({
        method: "delete",
        url: process.env.REACT_APP_API + "/api/v1/billing/cufObservation",
        data: {
          observationId: payload.observationId,
          codigoMotivo: payload.codigoMotivo,
        },
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
    } catch (error) {
      throw error;
    }
}
export const useDeleteCuf = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: deleteCuf,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get-cuf"] });
      },
    });
}