import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

const saveUserAdmin = async (payLoad) => {
  const { data } = await axios.post(
    process.env.REACT_APP_API + "/api/v1/user/saveUserAdminWithAll",
    payLoad,
    {
      headers: {
        "Content-type": "application/json;charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
  return data;
};

export const useRegisterUserAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: saveUserAdmin,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-users"] });
    },
  });
};
