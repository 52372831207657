import { useState } from "react";
import {  Grid, Stack, Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useQuery } from "react-query";
import SearchCancelBilling from "./SearchCancelBilling";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Tooltip } from 'react-tooltip';
import "./DataTableCancelBilling.css";
import "./ButtonCancelBilling.css";
import "./ButtonGenerateBilling.css";
import ButtonShowBilling from "./ButtonShowBilling";
import handleError from './utilities/HandleError';

// Get cancelled billing list.
const getListCancel = async (page = 1, perPage = 10, search = '') => {
  try {
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_API + "/api/v1/billing/cancelBillingList",
      params: {
        pointOfSaleId: parseInt(localStorage.getItem("pointOfSaleId")),
        pageNumber: page,
        pageSize: perPage,
        search: search,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    handleError(error, 'No se pudo recuperar la lista de facturas anuladas');
    console.error('No se pudo recuperar la lista de facturas anuladas:', error);
    throw new Error('No se pudo recuperar la lista de facturas anuladas.');
  }
};

const DataTableCancelBilling = ({ searchProduct, setSwitchBtn, switchBtn }) => {
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState(searchProduct);
  const [errorMessage, setErrorMessage] = useState('');

  const handleHideCanceledBilling = () => {
    setSwitchBtn(!switchBtn);
  };
  const handleHideIssuedBilling = () => {
    setSwitchBtn(!switchBtn);
  };

  const { isLoading, isError, error, data } = useQuery(
    ["get-cancel", page, perPage, search],
    () => getListCancel(page, perPage, search),
    {
      onSuccess: (data) => {
        setTotalRows(data.totalElements);
        setPage(data.pageNumber + 1);
      },
      onError: (error) => {
        if (!errorMessage) {
          setErrorMessage(error.message);
        }
      },
    }
  );

  if (isError) {
    return (
      <>
        {isError && <div>Error: {errorMessage}</div>}
      </>
    );
  }

  const columns = [
    {
      name: "Nro",
      selector: (row, index) => index + 1,
      id: 1,
      omit: (row, index) => index + 1,
    },
    {
      name: "Nro. factura",
      selector: (row) => <p data-tooltip-id="cancel-tooltip" data-tooltip-content={row.numBilling}>{row.numBilling}</p>,
      id: 2,
    },
    {
      name: "Documento",
      selector: (row) => <p data-tooltip-id="cancel-tooltip" data-tooltip-content={row.clientDocument}>{row.clientDocument}</p>,
      id: 3,
    },
    {
      name: "Razón social",
      selector: (row) => <p data-tooltip-id="cancel-tooltip" data-tooltip-content={row.razonSocial}>{row.razonSocial}</p>,
      id: 4,
      grow: 2,
    },
    {
      name: "Monto total",
      selector: (row) => <p data-tooltip-id="cancel-tooltip" data-tooltip-content={row.montoTotal}>{row.montoTotal}</p>,
      id: 5,
    },
    {
      name: "Usuario",
      selector: (row) => <p data-tooltip-id="cancel-tooltip" data-tooltip-content={row.fullNameUser}>{row.fullNameUser}</p>,
      id: 6,
    },
    {
      name: "Sucursal",
      selector: (row) => <p data-tooltip-id="cancel-tooltip" data-tooltip-content={row.sucursal}>{row.sucursal}</p>,
      id: 7,
      grow: 2,
    },
    {
      name: "Fecha",
      selector: (row) => <p data-tooltip-id="cancel-tooltip" data-tooltip-content={row.fechaEmision}>{row.fechaEmision}</p>,
      id: 8,
    },
    {
      name: "Hora",
      selector: (row) => <p data-tooltip-id="cancel-tooltip" data-tooltip-content={row.hora}>{row.hora}</p>,
      id: 9,
    },
    {
      name: "Ver factura",
      button: true,
      cell: (row) => <ButtonShowBilling id={row.cuf} />,
    },
  ];

  const dataTable = data?.content.map((element) => {
    return {
      id: element.id,
      numBilling: element.numBilling,
      clientDocument: element.clientDocument,
      razonSocial: element.razonSocial,
      fechaEmision: element.fechaEmision.substring(0, 10),
      hora: element.fechaEmision.substring(11, 19),
      montoTotal: Number(element.montoTotal).toFixed(2),
      fullNameUser: element.fullNameUser,
      sucursal: element.sucursal,
      cuf: element.cuf
    };
  });
  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <table
        style={{
          paddingLeft: "60px",
          fontSize: "14px",
          backgroundColor: "whitesmoke",
          with: "100%",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Número de factura</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.numBilling}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Número de documento</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.clientDocument}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Razón social</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.razonSocial}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Monto total</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.montoTotal}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Usuario</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.fullNameUser}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Sucursal</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.sucursal}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Fecha</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.fechaEmision}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Hora</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.hora}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }; 

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "whitesmok",
        fontSize: "13px",
        fontWeight: 600,
        color: "black",
        flex: 2,
      },
    },
  };   

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className="titleCancelBilling"
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            Facturas anuladas
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", alignItems: "center" }}
          className="containerButtonsBilling"
        >
          <button
            disabled={switchBtn}
            className="buttonGenerateBilling"
            type="button"
            onClick={() => handleHideCanceledBilling()}
          >
            <span className="buttonGenerateBilling__text">
              Facturas emitidas
            </span>
            <span className="buttonGenerateBilling__textSmall">
              <ReceiptLongIcon sx={{ color: "white" }} />
            </span>
            <span className="buttonGenerateBilling__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svgButtonGenerateBilling"
              >
                <ContentPasteIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
          <button
            disabled={!switchBtn}
            className="buttonCancelBilling"
            type="button"
            onClick={() => handleHideIssuedBilling()}
          >
            <span className="buttonCancelBilling__text">Facturas anuladas</span>
            <span className="buttonCancelBilling__textSmall">
              <DeleteIcon sx={{ color: "white" }} />
            </span>
            <span className="buttonCancelBilling__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svgButtonCancelBilling"
              >
                <DeleteIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        </Grid>
        <Grid item xs={12} md={6} align="right">
          <SearchCancelBilling search={search} setSearch={setSearch} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={dataTable}
            customStyles={customStyles}
            fixedHeader={true}
            progressPending={isLoading}
            progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
            defaultSortFieldId={1}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            striped
            responsive
            pointerOnHover
            highlightOnHover
            selectableRowsSingle
            noDataComponent={
              <Stack
                height="50vh"
                width="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <h2
                  style={{
                    color: "gray",
                    fontFamily: "Oswald",
                    fontWeight: 200,
                    fontSize: "28px",
                  }}
                >
                  "No se encontró ningún registro"
                </h2>
              </Stack>
            }
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={paginationComponentOptions}
            visibleRowIndex
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
          <Tooltip id="cancel-tooltip" place='right'/>
      </Grid>  
    </>
  );
};
export default DataTableCancelBilling;
