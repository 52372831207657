import * as Yup from "yup";

const ValidationBilling = (productDetails, gift, desc) =>
  Yup.object().shape({
    code: Yup.boolean(),
    codeT: Yup.boolean(),
    codeG: Yup.boolean(),
    razonSocial99001: Yup.string(),
    tipoCambio: Yup.number()
      .typeError("El valor debe ser numérico")
      .moreThan(0, "El valor debe ser positivo")
      .required("El campo es requerido")
      .test("is-decimal", "El campo solo permite 2 decimales", (value) =>
        (value + "").match(/^\d*(\.\d{1})?\d{0,1}$/)
      ),
    descuento: Yup.number()
      .moreThan(-1, "El valor debe ser positivo")
      .lessThan(
        productDetails
          .map((item) => item.cantidad * item.precio - item.montoDescuento)
          .reduce((prev, curr) => prev + curr, 0) < gift
          ? 0
          : productDetails
              .map((item) => item.cantidad * item.precio - item.montoDescuento)
              .reduce((prev, curr) => prev + curr, 0) -
              gift ===
            0
          ? 2
          : productDetails
              .map((item) => item.cantidad * item.precio - item.montoDescuento)
              .reduce((prev, curr) => prev + curr, 0) -
            gift +
            1,
        "El valor debe ser menor a " +
          (productDetails
            .map((item) => item.cantidad * item.precio - item.montoDescuento)
            .reduce((prev, curr) => prev + curr, 0) < gift
            ? 0
            : productDetails
                .map(
                  (item) => item.cantidad * item.precio - item.montoDescuento
                )
                .reduce((prev, curr) => prev + curr, 0) -
                gift === 0
            ? 2
            : productDetails
                .map(
                  (item) => item.cantidad * item.precio - item.montoDescuento
                )
                .reduce((prev, curr) => prev + curr, 0) -
              gift +
              1)
      )
      .test("is-decimal", "El campo solo permite 2 decimales", (value) =>
        (value + "").match(/^\d*(\.\d{1})?\d{0,1}$/)
      )
      .typeError("El valor debe ser númerico")
      .required("El campo es requerido"),
    tipoPago: Yup.string().required("El campo es requerido"),

    gift: Yup.number()
      .moreThan(-1, "El valor debe ser positivo")
      .lessThan(
        productDetails
          .map((item) => item.cantidad * item.precio - item.montoDescuento)
          .reduce((prev, curr) => prev + curr, 0) -
          desc +
          1,
        "El valor debe ser menor a " +
          (productDetails
            .map((item) => item.cantidad * item.precio - item.montoDescuento)
            .reduce((prev, curr) => prev + curr, 0) -
            desc +
            1)
      )
      .test("is-decimal", "El campo solo permite 2 decimales", (value) =>
        (value + "").match(/^\d*(\.\d{1})?\d{0,1}$/)
      ),
    tarjeta1: Yup.string()
      .matches(/^\d+$/, "El campo debe ser numérico"),
  });

export default ValidationBilling;