import AutorenewIcon from "@mui/icons-material/Autorenew";
import "./PanelSyncUp.css";

const PanelSyncUp = () => {
  return (
    <>
      <div className="cardSync">
        <div className="cardSync1">
          <p
            style={{ fontWeight: 400, fontFamily: "Oswald", fontSize: "24px", marginTop: "25px" }}
          >
            Paramétricas
          </p>
          <AutorenewIcon
            className="iconSync"
            sx={{
              width: "60px",
              height: "60px",
              color: "gray",
              marginTop: "10px",
            }}
          />
          <p className="smallSync">Permite sincronizar la lista de paramétricas</p>
          <div className="go-cornerSync" href="#">
            <div className="go-arrowSync">
              <AutorenewIcon sx={{ paddingTop: "5px" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PanelSyncUp;
