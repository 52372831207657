import { useMutation, useQueryClient } from "react-query";
import axios from 'axios';

const registerProduct = async (payLoad) => {
    return axios.post(process.env.REACT_APP_API + "/api/v1/billing/product/save",payLoad,{
        headers: {
          "content-type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        }}
    )
}

export const useRegisterProduct = () =>{
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: registerProduct,
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ["get-products"]})
      }
    });
}
