import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import "./PanelMinorBilling.css";

const PanelMinorBilling = () => {
  return (
    <>
      <div className="cardBilling">
        <div className="cardBilling1">
          <p
            style={{
              fontWeight: 400,
              fontFamily: "Oswald",
              fontSize: "24px",
              marginTop: "25px",
            }}
          >
            Ventas menores
          </p>
          <ReceiptLongIcon
            className="iconBilling"
            sx={{
              width: "60px",
              height: "60px",
              color: "gray",
              marginTop: "10px",
            }}
          />
          <p className="smallBilling">
            Permite el envío de facturas menores a cinco bolivianos.
          </p>
          <div className="go-cornerBilling" href="#">
            <div className="go-arrowBilling">
              <ReceiptLongIcon sx={{ paddingTop: "5px" }} />
            </div>
          </div>
        </div>
      </div>
    </>
    /*
    <Card
      elevation={8}
      sx={{ height: "260px" }}
    >
      <CardContent>
        <ReceiptLongIcon
          sx={{
            width: "120px",
            height: "120px",
            color: "gray",
            marginTop: "30px",
          }}
        />
        <Box
          sx={{
            backgroundColor: "orange",
            height: "80px",
          }}
        >
          <Typography variant="p" component="h2" sx={{ color: "white" }}>
            Envio de facturas
          </Typography>
          <Typography
            variant="p"
            align="center"
            sx={{ color: "white" }}
          >
            Envio de facturas menor a 5 Bs{" "}
          </Typography>
        </Box>
      </CardContent>
    </Card>
    */
  );
};
export default PanelMinorBilling;
