import React, {useState} from 'react'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import DataTableProductSiat from './DataTableProductSiat';

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

const ButtonProductList = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [search, setSearch] = useState("");

  return (
    <>
      <button
        className="buttonRegister"
        type="button"
        onClick={() => handleOpen()}
      >
        <span className="buttonRegister__text">Registrar</span>
        <span className="buttonRegister__textSmall">
          <AddIcon sx={{ color: "white", borderRadius: "50%" }} />
        </span>
        <span className="buttonRegister__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgRegister"
          >
            <AddIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
      <Dialog

        fullScreen
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            sx={{
              fontFamily: "Oswald",
              fontSize: "30px",
              marginTop: "15px",
            }}
          >
            Productos homologados
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <DataTableProductSiat
            searchProductSiat={search}
            handleClose={handleClose}
            open={open}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonProductList;
