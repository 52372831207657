import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAddPov } from "../hooks/useAddPov";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Swal from "sweetalert2";
import "./ButtonAddPovUser.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonAddPovUser = ({ id, businessId }) => {
  const [open, setOpen] = useState(false);
  const [listPov, setListPov] = useState([]);
  const [loader, setLoader] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    formikAddPov.resetForm();
    setOpen(false);
  };
  const handleChangePov = (e) => {
    formikAddPov.setFieldValue("pointOfSaleId", e.value);
  };

  const pointOfSaleList = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/pointOfSale/business/"+businessId,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setListPov(
        response.data.map((sup) => ({
          label: sup.nombrePuntoVenta,
          value: sup.id,
        }))
      );
    } catch (error) {
      return error.response.message;
    }
  };

  useEffect(() => {
    pointOfSaleList();
  }, []);

  const { mutate } = useAddPov();
  const formikAddPov = useFormik({
    initialValues: {
      pointOfSaleId: "",
    },
    validationSchema: Yup.object({
      pointOfSaleId: Yup.string().required("El campo es requerido"),
    }),
    onSubmit: async (data) => {
      setLoader(true);
      data.userId = parseInt(id);
      data.businessId = businessId;
      data.pointOfSaleId = parseInt(data.pointOfSaleId);
      mutate(data, {
        onSuccess: () => {
          handleClose();
          Swal.fire({
            customClass: {
              container: "formAssignPov",
              title: "formTitleAssignPov",
            },
            icon: "success",
            title: "¡El punto de venta ha sido registrado!",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
          });
          setLoader(false);
          formikAddPov.resetForm();
        },
        onError: (error) => {
          setLoader(false);
          if (error.response.status === 504) {
            Swal.fire({
              customClass: {
                container: "formAssignPov",
                title: "formTitleAssignPov",
              },
              icon: "error",
              title: "!Lo sentimos!",
              text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status === 500 || error.response.status === 503) {
            Swal.fire({
              customClass: {
                container: "formAssignPov",
                title: "formTitleAssignPov",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status === 401) {
            Swal.fire({
              customClass: {
                container: "formAssignPov",
                title: "formTitleAssignPov",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status === 400) {
            Swal.fire({
              customClass: {
                container: "formAssignPov",
                title: "formTitleAssignPov",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });
    },
  });

  return (
    <div>
      <button
        className="buttonRegister"
        type="button"
        onClick={() => handleOpen()}
      >
        <span className="buttonRegister__text">Registrar</span>
        <span className="buttonRegister__textSmall">
          <AddIcon sx={{ color: "white", borderRadius: "50%" }} />
        </span>
        <span className="buttonRegister__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgRegister"
          >
            <AddIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "green", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Asignar punto de venta
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikAddPov.handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography className="labelFormEditClient">
                  Seleccione el tipo de documento
                </Typography>
                <Select
                  isSearchable
                  menuPosition="fixed"
                  styles={CustomDropdownStyle}
                  options={listPov}
                  placeholder="Seleccionar..."
                  onChange={(e) => {
                    handleChangePov(e);
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="Número de documento"
                  size="small"
                  fullWidth
                  name="pointOfSaleId"
                  onBlur={formikAddPov.handleBlur}
                  onChange={formikAddPov.handleChange}
                  value={formikAddPov.values.pointOfSaleId}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                  }}
                >
                  {formikAddPov.values.pointOfSaleId === "" &&
                    formikAddPov.touched.pointOfSaleId &&
                    formikAddPov.errors.pointOfSaleId}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
                <button disabled={formikAddPov.isSubmitting} className="buttonRegisterData" type="submit">
                  <span className="buttonRegisterData">Registrar</span>
                </button>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ButtonAddPovUser;
