import { useState, useEffect} from "react";
import { Grid, Stack, Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import ButtonRegisterClient from "./ButtonRegisterClient";
import ButtonDisableClient from "./ButtonDisableClient";
import ButtonEnableClient from "./ButtonEnableClient";
import ButtonEditClient from "./ButtonEditClient";
import SearchClient from "./SearchClient";
import { Tooltip } from 'react-tooltip';
import axios from "axios";
import "./RegisterClient.css";
import "./SearchClient.css";

const DataTableClient = ({ searchClient }) => {
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState(searchClient);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/client/page",
        params: {
          pageNumber: page,
          pageSize: perPage,
          search: search,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const { data } = response;
      setData(data);
      setTotalRows(data.totalElements);
      setPage(data.pageNumber + 1);
    } catch (error) {
      setIsError(true);
      setError(error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [search, page, perPage]); // Se ejecuta solo una vez al cargar la página

  if (isError) {
    return <h2>{error.message}</h2>;
  }
  const columns = [
    {
      name: "Nro",
      selector: (row, index) => index + 1,
      id: 1,
      omit: (row, index) => index + 1,
    },
    {
      name: "Tipo de documento",
      selector: (row) => <p data-tooltip-id="client-tooltip" data-tooltip-content={row.type}>{row.type}</p>,
      id: 2,
      grow: 2,
    },
    {
      name: "Documento",
      selector: (row) => <p data-tooltip-id="client-tooltip" data-tooltip-content={row.documento}>{row.documento}</p>,
      id: 3,
    },
    {
      name: "Razón social",
      selector: (row) => <p data-tooltip-id="client-tooltip" data-tooltip-content={row.razonSocial}>{row.razonSocial}</p>,
      id: 4,
      grow: 2,
    },
    {
      name: "Correo electrónico",
      selector: (row) => <p data-tooltip-id="client-tooltip" data-tooltip-content={row.email}>{row.email}</p>,
      id: 5,
      grow: 2,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <ButtonEditClient fetch={fetchData} id={row.businessClientId} disableBtn={!row.status} />
      ),
    },
    {
      name: "Inhabilitar",
      button: true,
      cell: (row) =>
        row.status ? (
          <>
            <ButtonDisableClient fetch={fetchData} id={row.businessClientId} />
          </>
        ) : (
          <>
            <ButtonEnableClient fetch={fetchData} id={row.businessClientId} />
          </>
        ),
    },
  ];

  const dataTable = data?.content.map((client) => {
    return {
      businessClientId: client.businessClientId,
      type: client.type,
      documento: client.documento,
      razonSocial: client.razonSocial,
      email: client.email,
      numCelular: client.numCelular,
      status: client.status,
    };
  });
  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <table
        style={{
          paddingLeft: "60px",
          fontSize: "14px",
          backgroundColor: "whitesmoke",
          with: "100%",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Tipo de documento</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.type}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Número de documento</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.documento}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Razón social</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.razonSocial}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Correo electrónico</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.email}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Celular</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.numCelular}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "whitesmok",
        fontSize: '13px',
        fontWeight: 600,
        color: "black",
      },
    },
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className="titleClient"
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            Gestión de clientes
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} className="containerAddClient">
          <ButtonRegisterClient fetch={fetchData}/>
        </Grid>
        <Grid item xs={12} md={6} align="left">
          <SearchClient search={search} setSearch={setSearch} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={dataTable}
            fixedHeader={true}
            customStyles={customStyles}
            progressPending={isLoading}
            progressComponent={<h2 style={{ color: "gray"}}>Cargando...</h2>}
            defaultSortFieldId={1}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            striped
            responsive
            pointerOnHover
            highlightOnHover
            selectableRowsSingle
            noDataComponent={
              <Stack
                height="50vh"
                width="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <h2
                  style={{
                    color: "gray",
                    fontFamily: "Oswald",
                    fontWeight: 200,
                    fontSize: "28px",
                  }}
                >
                  "No se encontró ningún registro"
                </h2>
              </Stack>
            }
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={paginationComponentOptions}
            visibleRowIndex
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
          <Tooltip id="client-tooltip" place='right'/>
      </Grid>   
    </>
  );
};
export default DataTableClient;