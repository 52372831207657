const CustomDropdownEdit = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "gray" : "#D1B000",
      color: "white",
      width: "100%",
      "&:hover": {
        backgroundColor: "gray",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
    }),
  };
  
  export default CustomDropdownEdit;