import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import ValidationProduct from "../validation/validationProduct";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import axios from "axios";
import { useRegisterProduct } from "../hooks/useRegisterProduct";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./ButtonRegisterClient.css";
import handleError from "./utilities/HandleError";
//import "./ButtonRegister.css"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonRegisterProduct = ({ list }) => {
  const [listUnitMeasure, setListUnitMeasure] = useState([]);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    setOpen(true);
    getUnitMeasure();
  };
  const handleClose = () => {
    setOpen(false);
    formikRegisterProduct.resetForm();
  };
  const handleChangeUnitMeasure = (e) => {
    formikRegisterProduct.setFieldValue("errorMessage", e.value);
  };

  const getUnitMeasure = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "UNIDAD_DE_MEDIDA",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setListUnitMeasure(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {
      handleError(error)
    }
  };

  const { mutate } = useRegisterProduct();

  const formikRegisterProduct = useFormik({
    initialValues: {
      codigoProducto: "",
      descripcionProducto: "",
      precioUnitario: "",
      errorMessage: 0,
    },
    validationSchema: ValidationProduct,
    onSubmit: async (data) => {
      setLoader(true);
      data.codigoProductoSiat = parseInt(list.codigoProducto);
      data.codigoActividad = parseInt(list.codigoActividad);
      data.codigoProducto = data.codigoProducto.trim();
      data.descripcionProducto = data.descripcionProducto.trim();
      data.precioUnitario = Number(parseFloat(data.precioUnitario)).toFixed(2);
      data.unidadDeMedida = parseInt(data.errorMessage);
      mutate(data, {
        onSuccess: () => {
          handleClose();
          formikRegisterProduct.resetForm();
          Swal.fire({
            customClass: {
              container: "formRegisterProduct",
              title: "formTitleRegisterProduct",
            },
            icon: "success",
            title: "¡El producto ha sido registrado!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setLoader(false);
        },
        onError: (error) => {
          setLoader(false);
          if (error.response.status === 503) {
            Swal.fire({
              customClass: {
                container: "formEditProduct",
                title: "formTitleEditProduct",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status === 500) {
            Swal.fire({
              target: document.getElementById("formRegisterProduct"),
              customClass: {
                container: "formRegisterProduct",
                title: "formTitleRegisterProduct",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 422) {
            Swal.fire({
              customClass: {
                container: "formRegisterProduct",
                title: "formTitleRegisterProduct",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.errors.map(
                (element) => element.message
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 404) {
            Swal.fire({
              customClass: {
                container: "formRegisterProduct",
                title: "formTitleRegisterProduct",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (
            error.response.status === 400 &&
            error.response.data.message === data.codigoProducto + " in use!"
          ) {
            Swal.fire({
              target: document.getElementById("formRegisterProduct"),
              customClass: {
                container: "formRegisterProduct",
                title: "formTitleRegisterProduct",
              },
              icon: "warning",
              title: "!Ha ocurrido un error!",
              text:
                "El producto con el código " +
                data.codigoProducto +
                " ya fue registrado",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 400) {
            Swal.fire({
              customClass: {
                container: "formRegisterProduct",
                title: "formTitleRegisterProduct",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status >= 0 && error.response.status <= 99) {
            Swal.fire({
              target: document.getElementById("formRegisterProduct"),
              customClass: {
                container: "formRegisterProduct",
                title: "formTitleRegisterProduct",
              },
              icon: "error",
              title: "!Ha ocurrido un problema de conectividad!",
              text: "Por favor vuelva a intentarlo más tarde",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });
    },
  });

  return (
    <>
      <button
        className="buttonRegisterPov"
        type="button"
        onClick={() => handleOpen()}
      >
        <span className="buttonRegisterPov__text">Registrar</span>
        <span className="buttonRegisterPov__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgRegisterPov"
          >
            <AddIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        id="formRegisterProduct"
      >
        <DialogTitle
          sx={{ backgroundColor: "green", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Registrar producto o servicio
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikRegisterProduct.handleSubmit}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ marginTop: "5px", display: "none" }}
              >
                <Typography className="labelFormClient">
                  Descripción del producto SIN
                </Typography>
                <TextField
                  readOnly
                  disabled
                  defaultValue={list.descripcionProducto}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: {
                      borderRadius: "0px",
                      border: "1px solid white",
                      borderColor: "white",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: "none" }}>
                <Typography className="labelFormClient">
                  Codigo de producto SIN
                </Typography>
                <TextField
                  readOnly
                  disabled
                  defaultValue={list.codigoProducto}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: {
                      borderRadius: "0px",
                      border: "1px solid white",
                      borderColor: "white",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: "none" }}>
                <Typography className="labelFormClient">
                  Código de actividad CAEB
                </Typography>
                <TextField
                  readOnly
                  disabled
                  defaultValue={list.codigoActividad}
                  size="small"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    style: {
                      borderRadius: "0px",
                      border: "1px solid white",
                      borderColor: "white",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Nombre del producto
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="descripcionProducto"
                    onBlur={formikRegisterProduct.handleBlur}
                    onChange={formikRegisterProduct.handleChange}
                    value={formikRegisterProduct.values.descripcionProducto}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterProduct.touched.descripcionProducto &&
                      formikRegisterProduct.errors.descripcionProducto}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Código del producto
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="codigoProducto"
                    onBlur={formikRegisterProduct.handleBlur}
                    onChange={formikRegisterProduct.handleChange}
                    value={formikRegisterProduct.values.codigoProducto}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterProduct.touched.codigoProducto &&
                      formikRegisterProduct.errors.codigoProducto}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ zIndex: 9 }}>
                <Typography className="labelFormClient">
                  Seleccione una unidad de medida
                </Typography>
                <Select
                  isSearchable
                  menuPosition="fixed"
                  placeholder="Seleccionar..."
                  styles={CustomDropdownStyle}
                  options={listUnitMeasure}
                  onChange={(e) => {
                    handleChangeUnitMeasure(e);
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="errorMessage"
                  onBlur={formikRegisterProduct.handleBlur}
                  onChange={formikRegisterProduct.handleChange}
                  value={formikRegisterProduct.values.errorMessage}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {formikRegisterProduct.values.errorMessage === 0 &&
                    formikRegisterProduct.touched.errorMessage &&
                    formikRegisterProduct.errors.errorMessage}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Precio unitario
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="precioUnitario"
                    onBlur={formikRegisterProduct.handleBlur}
                    onChange={formikRegisterProduct.handleChange}
                    value={formikRegisterProduct.values.precioUnitario}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterProduct.touched.precioUnitario &&
                      formikRegisterProduct.errors.precioUnitario}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
                <button className="buttonRegisterData" type="submit">
                  <span className="buttonRegisterData">Registrar</span>
                </button>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonRegisterProduct;
