import { Stack } from "@mui/material";
import DataTable from "react-data-table-component";

const DataTableSyncUp = ({rows}) => {
 const columns = [
   {
     name: "Nro",
     selector: (row, index) => index + 1,
     width: "70px",
     id: 1,
     omit: (row, index) => index + 1,
   },
   {
     name: "Código clasificador",
     selector: (row) => row.codigoClasificador,
     id: 2,
   },
   {
     name: "Descripción",
     selector: (row) => row.descripcion,
     grow: 2,
     id: 3,
   },
   {
     name: "Sincronización",
     selector: (row) => row.sincronizacion,
     id: 4,
   },
 ];
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "whitesmok",
        fontSize: "13px",
        fontWeight: 600,
        color: "black",
      },
    },
  }; 
  return (
    <>
      <DataTable
        columns={columns}
        data={rows}
        fixedHeader={true}
        customStyles={customStyles}
        progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
        defaultSortFieldId={1}
        striped
        responsive
        pointerOnHover
        highlightOnHover
        selectableRowsSingle
        noDataComponent={
          <Stack
            height="50vh"
            width="100%"
            display= "flex"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
          >
            <h2 style={{ color: "gray", fontFamily: "Oswald", fontWeight: 200, fontSize: "28px" }}>"No se encontró ningún registro"</h2>
          </Stack>
        }
        visibleRowIndex
      />
    </>
  );
};
export default DataTableSyncUp;
