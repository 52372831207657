import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import React, { useState } from "react";
import axios from "axios";
import "./ButtonLoader.css";
import handleError from "./utilities/HandleError";

const ButtonRegisterSync = ({ id, disableBtn }) => {
  const [loader, setLoader] = useState(false);
  const sincronizacion = async (businnessId) => {
    setLoader(true);
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/synchronization/allCatalogSynchronization/business/" +
          businnessId,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      if (response.status === 200) {
        Swal.fire({
          customClass: {
            container: "formRegisterClient",
            title: "formTitleRegisterClient",
          },
          icon: "success",
          title: "!La información ha sido sincronizada!",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      const { data } = response;
      return data;
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      {!disableBtn ? (
        loader ? (
          <button className="buttonLoaderTable" style={{ width: "100px" }}>
            <div className="loader">
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
              <div className="bar4"></div>
              <div className="bar5"></div>
              <div className="bar6"></div>
              <div className="bar7"></div>
              <div className="bar8"></div>
              <div className="bar9"></div>
              <div className="bar10"></div>
              <div className="bar11"></div>
              <div className="bar12"></div>
            </div>
          </button>
        ) : (
          <button
            disabled={disableBtn}
            className="buttonRegisterBranch"
            type="button"
            style={{ width: "100px" }}
            onClick={() => sincronizacion(id)}
          >
            <span className="buttonRegisterBranch__text">Sincronizar</span>
            <span className="buttonRegisterBranch__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svgRegisterBranch"
              >
                <AddIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        )
      ) : (
        <button
          disabled={disableBtn}
          className="buttonEnableTable"
          type="button"
          style={{ width: "100px" }}
          onClick={() => sincronizacion(id)}
        >
          <span className="buttonEnableTable__text">Sincronizar</span>
        </button>
      )}
    </div>
  );
};

export default ButtonRegisterSync;
