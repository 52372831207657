import { useMutation, useQueryClient } from "react-query";
import axios from 'axios';


  const disableCompany = async (id) => {
    try {
      const response = await axios({
        method: "delete",
        url: process.env.REACT_APP_API + "/api/v1/billing/business/disable",
        params: {
          businessId: id,
        },
        headers: {
          "content-type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
    } catch (error) {
      throw error
    }
  };

export const useDisableCompany = () => {
    const queryClient = useQueryClient();
   return useMutation({
     mutationFn: disableCompany,
     onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get-company"]});
     }
   });
}