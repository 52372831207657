import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { useFormik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import InputAdornment from "@mui/material/InputAdornment";
import MailIcon from "@mui/icons-material/Mail";
import SendIcon from "@mui/icons-material/Send";
import Image from "../assets/img/Qhatubit3.png";
import "./ForgotPassword.css";
import "./ActiveUser.css";
import "./Forwarding.css";
import handleError from '../components/utilities/HandleError';

const Forwarding = () => {

const [habilitarInput, setHabilitarInput] = useState(false);
const [respuestaError, setRespuestaError] = useState("");

const mostrarError = () => {
  setHabilitarInput(true);
  window.setTimeout(() => {
    setRespuestaError("");
    setHabilitarInput(false);
  }, 2000);
};

const formik = useFormik({
  initialValues: {
    email: "",
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("El correo electrónico no es valido")
      .required("El campo es requerido"),
  }),
  onSubmit: async (data) => {
    try {
      const response = await axios({
        method: "POST",
        url:
          process.env.REACT_APP_API +
          "/api/v1/auth/user/reSendActivationUser",
        data: {
          email: data.email,
        },
        headers: {
          "content-type": "application/json;charset=utf-8",
        },
      });
      if (response.status === 200) {
        formik.resetForm({ data: "" });
        Swal.fire({
          customClass: {
            container: "forgotPassword",
            title: "titleforgotPassword",
          },
          icon: "success",
          title: "¡Su solicitud ha sido envianda!",
          text: "Por favor verifique su correo electrónico",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    } catch (error) {
      handleError(error);
    }
  },
});
return (
  <>
    <CssBaseline />
    <Box
      className="panelLogoFP"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "300px",
        height: "270px",
        boxShadow: "5px 5px 15px black",
        backgroundColor: "black",
      }}
    >
      <img src={Image} alt="" width={"250px"} height={"auto"} />
    </Box>
    <Box
      className="panelFormFP"
      sx={{
        width: "420px",
        height: "270px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        boxShadow: "5px 5px 15px black",
        backgroundColor: `rgba(202, 182, 118, 0.2)`,
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box
          className="containerFormFP"
          sx={{
            width: "360px",
            height: "420px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {" "}
          <Box
            className="logoIconFP"
            sx={{
              display: "none",
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              backgroundColor: "#0f0f0f",
              border: "2px solid white",
            }}
          >
            <SendIcon
              className="iconMail"
              sx={{ color: "white", fontSize: "124px" }}
            />
            <Typography
              className="labelLogo"
              sx={{ color: "white", fontSize: "30px" }}
            ></Typography>
          </Box>
          <Grid container>
            <Grid item xs={12} align="center">
              <Typography
                className="forwardingPassword"
                sx={{
                  fontFamily: "Oswald",
                  fontSize: "28px",
                  color: "white",
                  marginBottom: "20px",
                }}
              >
                Solicitud de activación
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                className="labelEmailFP"
                sx={{
                  fontFamily: "Oswald",
                  fontSize: "18px",
                  marginBottom: "5px",
                  color: "white",
                }}
              >
                Ingresa tu correo electrónico
              </Typography>
              <FormControl fullWidth>
                <TextField
                  className="textFieldEmailFP"
                  type="text"
                  disabled={habilitarInput}
                  fullWidth
                  variant="standard"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  InputProps={{
                    style: {
                      color: "white",
                      borderBottom: "2px solid white",
                      fontSize: "22px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon
                          className="iconEmail"
                          sx={{ color: "white" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText
                  className="labelErrorEmail"
                  sx={{ color: "yellow", fontSize: "14px" }}
                >
                  {formik.touched.email && formik.errors.email}
                  {respuestaError}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} align="right" sx={{ marginBottom: "10px" }}>
              <Typography
                className="labelGoBackHome"
                sx={{
                  fontFamily: "Oswald",
                  fontSize: "14px",
                  marginBottom: "10px",
                  color: "white",
                }}
              >
                <Link to="/" style={{ color: "white" }}>
                  {" "}
                  Volver al inicio
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <button
                className="buttonFP"
                type="submit"
                style={{
                  width: "100%",
                  padding: "5px",
                  backgroundColor: "black",
                }}
              >
                <Typography
                  className="labelButtonFP"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "16px",
                    color: "white",
                  }}
                >
                  Aceptar
                </Typography>
              </button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  </>
);
};
export default Forwarding;
