import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "./ButtonGoBackSettings.css";

const ButtonGoBackUserTable = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/dashboard/user");
  };
  return (
    <>
      <button
        className="buttonGoBack"
        type="button"
        onClick={() => handleGoBack()}
        style={{ marginBottom: "40px" }}
      >
        <span className="buttonGoBack__text">Atrás</span>
        <span className="buttonRegister__textSmall">
          <CloseIcon sx={{ color: "white", borderRadius: "50%" }} />
        </span>
        <span className="buttonGoBack__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgGoBack"
          >
            <ChevronLeftIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
    </>
  );
};
export default ButtonGoBackUserTable;
