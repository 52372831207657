import { Box, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useState, useEffect } from "react";
import { Grid, TextField, InputAdornment } from "@mui/material";
import { useFormik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ValidationResetPassord from "../validation/ValidationResetPassword";
import LockResetIcon from "@mui/icons-material/LockReset";
import Image from "../assets/img/Qhatubit3.png";
import "./ResetPassword.css";
import handleError from '../components/utilities/HandleError';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [showComfirmPass, setShowComfirmPass] = useState(false);


  const getUrl = () => {
    return window.location.pathname.substring(16, 116);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: ValidationResetPassord,
    onSubmit: async (data) => {
      try {
        const response = await axios({
          method: "PUT",
          url: process.env.REACT_APP_API + "/api/v1/auth/user/newPassword/"+getUrl(),
          data: {
            password: data.password,
          },
          headers: {
            "content-type": "application/json",
          },
        });
        if (response.status === 200) {
          formik.resetForm({ data: "" });
          Swal.fire({
            customClass: {
              container: "resetPassword",
              title: "titleResetPassword",
            },
            icon: "success",
            title: "¡La contraseña a sido actualizada!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          navigate("/");
        }
      } catch (error) {
        if (error.response.status === 400) {
          Swal.fire({
            customClass: {
              container: "resetPassword",
              title: "titleResetPassword",
            },
            icon: "error",
            title: "!El token ha experado!",
            text: "Vuelva a solicitar su cambio de contraseña",
            showConfirmButton: true,
          });
        }else{
          handleError(error);
        }
      }
    },
  });

  useEffect(() => {
    getUrl();
  }, []);


  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Box
        className="panelLogoReset"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "380px",
          height: "450px",
          boxShadow: "5px 5px 15px black",
          backgroundColor: "black",
        }}
      >
        <img src={Image} alt="" width={"200px"} height={"200px"} />
      </Box>
      <Box
        className="panelFormReset"
        sx={{
          width: "380px",
          height: "450px",
          display: "flex",
          padding: "30px",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "5px 5px 15px black",
          backgroundColor: `rgba(202, 182, 118, 0.2)`,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            className="containerFormReset"
            sx={{
              width: "290px",
              height: "420px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              className="logoIconReset"
              sx={{
                display: "none",
                width: "80px",
                height: "80px",
                backgroundColor: "#0f0f0f",
                borderRadius: "50%",
                border: "2px solid white",
              }}
            >
              <LockResetIcon
                className="iconReset"
                sx={{ color: "white", fontSize: "124px" }}
              />
            </Box>
            <Grid container>
              <Grid item xs={12} align="center" sx={{ marginBottom: "20px" }}>
                <Typography
                  className="labelResetPassword"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "32px",
                    color: "white",
                  }}
                >
                  Restablecer contraseña
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className="labelPassword"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "18px",
                    marginBottom: "5px",
                    color: "white",
                  }}
                >
                  Contraseña
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    sx={{ color: "white" }}
                    type={showPass ? "text" : "password"}
                    variant="standard"
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    InputProps={{
                      style: {
                        color: "white",
                        borderBottom: "2px solid white",
                        fontSize: "22px",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {showPass ? (
                            <VisibilityOffIcon sx={{ color: "white" }} />
                          ) : (
                            <VisibilityIcon sx={{ color: "white" }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText
                    className="LabelErrorPassord"
                    sx={{ color: "yellow", fontSize: "14px" }}
                  >
                    {formik.touched.password && formik.errors.password}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className="labelComfirmPassword"
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "18px",
                    marginBottom: "5px",
                    color: "white",
                    marginTop: "20px",
                  }}
                >
                  Confirmar contraseña
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    type={showComfirmPass ? "text" : "password"}
                    fullWidth
                    variant="standard"
                    name="confirmPassword"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    InputProps={{
                      style: {
                        color: "white",
                        borderBottom: "2px solid white",
                        fontSize: "22px",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {showComfirmPass ? (
                            <VisibilityOffIcon sx={{ color: "white" }} />
                          ) : (
                            <VisibilityIcon sx={{ color: "white" }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText
                    className="LabelErrorResetPassord"
                    sx={{ color: "yellow", fontSize: "14px" }}
                  >
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <button
                  className="buttonResetPassword"
                  type="submit"
                  style={{
                    width: "100%",
                    padding: "5px",
                    backgroundColor: "black",
                  }}
                >
                  <Typography
                    className="labelButtonReset"
                    sx={{
                      fontFamily: "Oswald",
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    Restablecer
                  </Typography>
                </button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </div>
  );
};
export default ResetPassword;
