import * as Yup from 'yup';

const ValidationLogin = Yup.object({
  userName: Yup.string()
    .email("El formato es incorrecto")
    .required("El campo es requerido"),
  password: Yup.string()
    .required("El campo es requerido"),
});

export default ValidationLogin