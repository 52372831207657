import './SearchUser.css';

const SearchUser = ({ search = '', setSearch }) => {
  return (
    <input
      type="search"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      placeholder="Buscar"
      className="searchUser"
      style={{backgroundColor: 'whitesmoke'}}
    />
  );
};
export default SearchUser;
