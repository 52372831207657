import * as Yup from "yup";

const ValidationProduct = Yup.object().shape({
  codigoProducto: Yup.string()
    .matches(/^\S/, "El campo solo permite espacios entre palabras")
    .required("El campo es requerido"),
  descripcionProducto: Yup.string()
    .matches(/^\S/, "El campo solo permite espacios entre palabras")
    .required("El campo es requerido"),
  precioUnitario: Yup.number()
    .typeError("El valor debe ser númerico")
    .moreThan(0, "El valor debe ser positivo")
    .required("El campo es requerido")
    .test("is-decimal", "El campo solo permite 2 decimales", (value) =>
      (value + "").match(/^\d*(\.\d{1})?\d{0,1}$/)
    ),
  errorMessage: Yup.number()
    .required("El campo es requerido")
    .integer()
    .positive("El campo es requerido"),
});


export default ValidationProduct;