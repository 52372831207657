import React from 'react'
import DataTableCUF from '../components/DataTableCUF'

const CufObservation = () => {
  return (
    <>
        <DataTableCUF/>
    </>
  )
}

export default CufObservation