import ValidationEditCertificated from "../validation/ValidationEditCertificated";
import FormHelperText from "@mui/material/FormHelperText";
import React, { useState, useEffect, useRef } from "react";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import "./ButtonEditCompany.css";
import "./ButtonEnableTable.css";
import { useEditCertificated } from "../hooks/useEditCertificated";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonEditCertificated = ({ id, disableBtn }) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [loader, setLoader] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    formikEditCompany.resetForm();
    setOpen(false);
  };
  const handleSubmitCompany = () => {
    formikEditCompany.handleSubmit();
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const handleButtonEdit = (id) => {
    setOpen(true);
  };

  const { mutate } = useEditCertificated();

  const formikEditCompany = useFormik({
    initialValues: {
      file: "",
      password: "",
    },
    validationSchema: ValidationEditCertificated,
    onSubmit: async (values) => {
      setLoader(true);
      values.id = id;
      mutate(values, {
        onSuccess: () => {
          handleClose();
          formikEditCompany.resetForm();
          Swal.fire({
            customClass: {
              container: "formEditCompany",
              title: "formTitleEditCompany",
            },
            icon: "success",
            title: "¡La información se ha actualizado!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setLoader(false);
        },
        onError: (error) => {
          setLoader(false);
          if (error.response.status === 504) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Lo sentimos!",
              text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }           
          if (error.response.status === 500 || error.response.status === 503) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 422) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.errors.map(
                (element) => element.message
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 415) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.error,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 404) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 403) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 400) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status >= 0 && error.request.status <= 99) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Compruebe su conexión a internet",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });
    },
  });

  return (
    <>
      {!disableBtn ? (
        <button
          disabled={disableBtn}
          className="buttonRegisterBranch"
          type="button"
          onClick={() => handleButtonEdit(id)}
        >
          <span className="buttonRegisterBranch__text">Certificado</span>
          <span className="buttonRegisterBranch__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              viewBox="0 0 48 48"
              height="48"
              className="svgRegisterBranch"
            >
              <AddIcon sx={{ color: "white" }} />
            </svg>
          </span>
        </button>
      ) : (
        <button
          disabled={disableBtn}
          className="buttonEnableTable"
          type="button"
          onClick={() => handleButtonEdit(id)}
        >
          <span className="buttonEnableTable__text">Certificado</span>
        </button>
      )}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "green", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Registrar certificado
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikEditCompany.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <Typography>Archivo .p12</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={9}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    inputProps={{
                      type: "file",
                      name: "file",
                      accept: ".p12",
                    }}
                    onChange={(e) =>
                      formikEditCompany.setFieldValue("file", e.target.files[0])
                    }
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  >
                    {formikEditCompany.errors.file && formikEditCompany.errors.file}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <Typography>Contraseña</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={9}>
                <FormControl fullWidth>
                  <TextField
                    name="password"
                    type="password"
                    size="small"
                    value={formikEditCompany.values.password}
                    onChange={formikEditCompany.handleChange}
                    onBlur={formikEditCompany.handleBlur}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  >
                    {formikEditCompany.touched.password && formikEditCompany.errors.password}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
                <button
                  onClick={() => handleSubmitCompany()}
                  className="buttonRegisterData"
                  type="submit"
                >
                  <span className="buttonRegisterData">Registrar</span>
                </button>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonEditCertificated;
