import DeleteIcon from "@mui/icons-material/Delete";
import "./ButtonEnableTable.css";

const ButtonEnableViewPointOfSale = () => {
  return (
    <>
      <button className="buttonEnableTable" type="button">
        <span className="buttonEnableTable__text">Asignar</span>
        <span className="buttonEnableTable__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgEnableTable"
          >
            <DeleteIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
    </>
  );
};
export default ButtonEnableViewPointOfSale;
