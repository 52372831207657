
import React from "react";
import Box from "@mui/material/Box";
import DataTableCompany from "../components/DataTableCompany";
import { useState } from "react";
const Company = () => {
  const [search, setSearch] = useState("");
  return (
    <div>
      <Box>
          <DataTableCompany searchClient={search} />
      </Box>
    </div>
  );
}
export default Company