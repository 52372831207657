import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import axios from "axios";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import { useRegisterPointOfSale } from "../hooks/useRegisterPointOfSale";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Swal from "sweetalert2";
import "./ButtonRegisterPointOfSale.css";
import handleError from "./utilities/HandleError";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonRegisterPointOfSale = () => {
  const [tiposPuntosVenta, setTiposPuntosVenta] = useState([]);
  const [branch, setBranch] = useState([]);
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    getListTypePointOfSale();
    getBranch();
    setOpen(true);
  };
  const handleClose = () => {
    formikRegister.resetForm();
    setOpen(false);
  };

  const options = [{ value: 1, label: "Electrónica" }];

  const handleChangeCodigo = (e) => {
    formikRegister.setFieldValue("electronica", e.value);
  };

  const handleBranch = (e) => {
    formikRegister.setFieldValue("codigoSucursal", e.value);
  };

  const getBranch = async () => {
    
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API + "/api/v1/billing/pointOfSale/listBranch",
        params: {
          businessId: localStorage.getItem("businessId"),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }).then( data => {
        setBranch(
          response.data.map((sup) => ({
            label: sup.nombreSucursal,
            value: sup.sucursalId,
          }))
        );
      }).catch( error => {
        handleError(error);
      });
      
    
  };

  const getListTypePointOfSale = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "TIPO_PUNTO_VENTA",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setTiposPuntosVenta(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handlePointOfSale = (e) => {
    formikRegister.setFieldValue("type", e.value);
  };

  const { mutate } = useRegisterPointOfSale();

  const formikRegister = useFormik({
    initialValues: {
      descripcion: "",
      nombrePuntoVenta: "",
      codigoSucursal: "",
      type: "",
      electronica: "",
    },
    validationSchema: Yup.object().shape({
      descripcion: Yup.string().required("El campo es requerido"),
      nombrePuntoVenta: Yup.string().required("El campo es requerido"),
      codigoSucursal: Yup.number()
        .positive("El número debe ser positivo")
        .required("El campo es requerido"),
      type: Yup.number().required("El campo es requerido"),
      electronica: Yup.number().required("El campo es requerido"),
    }),
    onSubmit: async (data) => {
      data.businessId = parseInt(localStorage.getItem("businessId"));
      data.sucursalId = parseInt(data.codigoSucursal);
      data.codigoModalidad = parseInt(data.electronica);
      data.codigoTipoPuntoVenta = parseInt(data.type);
      data.descripcion = data.descripcion.trim();
      data.nombrePuntoVenta = data.nombrePuntoVenta.trim();
      mutate(data, {
        onSuccess: () => {
          handleClose();
          formikRegister.resetForm();
          Swal.fire({
            customClass: {
              container: "formRegisterPov",
              title: "formTitleRegisterPov",
            },
            icon: "success",
            title: "¡El punto de venta ha sido registrado!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        },
        onError: (error) => {
          if (error.response.status >= 500 && error.response.status <= 599) {
            Swal.fire({
              customClass: {
                container: "formRegisterPov",
                title: "formTitleRegisterPov",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 422) {
            Swal.fire({
              customClass: {
                container: "formRegisterPov",
                title: "formTitleRegisterPov",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.errors.map(
                (element) => element.message
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 404) {
            Swal.fire({
              customClass: {
                container: "formRegisterPov",
                title: "formTitleRegisterPov",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (
            error.response.status === 400 &&
            error.response.data.message ===
              "EL PARAMETRO TIPO DE PUNTO DE VENTA ES INVALIDO"
          ) {
            Swal.fire({
              customClass: {
                container: "formRegisterPov",
                title: "formTitleRegisterPov",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "El punto de venta no esta disponible.Por favor, seleccione otro",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status >= 0 && error.response.status <= 99) {
            Swal.fire({
              customClass: {
                container: "formRegisterPov",
                title: "formTitleRegisterPov",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Compruebe su conexión a internet",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });
    },
  });

  return (
    <>
      <button
        className="buttonRegister"
        type="button"
        onClick={() => handleOpen()}
      >
        <span className="buttonRegister__text">Punto de venta</span>
        <span className="buttonRegister__textSmall">
          <AddIcon sx={{ color: "white", borderRadius: "50%" }} />
        </span>
        <span className="buttonRegister__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgRegister"
          >
            <AddIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "gray",
              fontFamily: "Oswald",
              fontSize: "30px",
              marginTop: "15px",
            }}
          >
            Registrar punto de venta
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikRegister.handleSubmit}>
            <Grid container spacing={2} sx={{ marginTop: "5px" }}>
              <Grid item xs={12} md={12} sx={{ zIndex: 9999 }}>
                <Select
                  isSearchable
                  menuPosition="fixed"
                  placeholder="Seleccione el tipo punto de venta"
                  styles={CustomDropdownStyle}
                  options={tiposPuntosVenta}
                  onChange={(e) => {
                    handlePointOfSale(e);
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="Número de documento"
                  size="small"
                  fullWidth
                  name="type"
                  onBlur={formikRegister.handleBlur}
                  onChange={formikRegister.handleChange}
                  value={formikRegister.values.type}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {formikRegister.values.type === "" &&
                    formikRegister.touched.type &&
                    formikRegister.errors.type}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12} sx={{ zIndex: 999 }}>
                <Select
                  fullWidth
                  isSearchable
                  menuPosition="fixed"
                  placeholder="Seleccione una modalidad"
                  styles={CustomDropdownStyle}
                  options={options}
                  onChange={(e) => handleChangeCodigo(e)}
                  //  value={options.value}
                  //  defaultValue={options[0]}
                />
              </Grid>
              <FormControl fullWidth sx={{ display: "none" }}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="electronica"
                  onBlur={formikRegister.handleBlur}
                  onChange={formikRegister.handleChange}
                  value={formikRegister.values.electronica}
                />
                <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                  {formikRegister.touched.electronica &&
                    formikRegister.errors.electronica}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Nombre punto de venta"
                    size="small"
                    fullWidth
                    name="nombrePuntoVenta"
                    onBlur={formikRegister.handleBlur}
                    onChange={formikRegister.handleChange}
                    value={formikRegister.values.nombrePuntoVenta}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegister.touched.nombrePuntoVenta &&
                      formikRegister.errors.nombrePuntoVenta}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Descripción"
                    size="small"
                    fullWidth
                    name="descripcion"
                    onBlur={formikRegister.handleBlur}
                    onChange={formikRegister.handleChange}
                    value={formikRegister.values.descripcion}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegister.touched.descripcion &&
                      formikRegister.errors.descripcion}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Select
                  isSearchable
                  menuPosition="fixed"
                  placeholder="Seleccione una sucursal"
                  styles={CustomDropdownStyle}
                  options={branch}
                  onChange={(e) => handleBranch(e)}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="Sucursal"
                  size="small"
                  fullWidth
                  name="codigoSucursal"
                  onBlur={formikRegister.handleBlur}
                  onChange={formikRegister.handleChange}
                  value={formikRegister.values.codigoSucursal}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {formikRegister.touched.codigoSucursal &&
                    formikRegister.errors.codigoSucursal}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              <button className="buttonRegisterData" type="submit">
                <span className="buttonRegisterData">Registrar</span>
              </button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonRegisterPointOfSale;
