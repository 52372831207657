import React from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useRegisterPOV } from "../hooks/useRegisterPOV";
import ValidationRegisterPOV from "../validation/ValidationRegisterPOV";
import Select from "react-select";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import handleError from './utilities/HandleError';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonRegisterPOV = ({ id, disableBtn }) => {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [sucursales, setSucursales] = React.useState([]);
  const [tiposPuntosVenta, setTiposPuntosVenta] = React.useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpen = (ide) => {
    getListSucursales(ide);
    getListTypePointOfSale();
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm({});
    setOpen(false);
  };
  const handleSucursal = (e) => {
    formik.setFieldValue("sucursalId", e.value);
  };
  const handlePointOfSale = (e) => {
    formik.setFieldValue("codigoTipoPuntoVenta", e.value);
  };

  const getListSucursales = async (ide) => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/pointOfSale/listBranch/" +
          ide,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setSucursales(
        response.data.map((sup) => ({
          label: sup.nombreSucursal,
          value: sup.sucursalId,
        }))
      );
    } catch (error) {
      handleError(error);
    }
  };

  const getListTypePointOfSale = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "TIPO_PUNTO_VENTA",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setTiposPuntosVenta(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {
      handleError(error);
    }
  };

  const { mutate } = useRegisterPOV();

  const formik = useFormik({
    initialValues: {
      businessId: "",
      sucursalId: "",
      codigoTipoPuntoVenta: "",
      descripcion: "",
      nombrePuntoVenta: "",
    },
    validationSchema: ValidationRegisterPOV,
    onSubmit: async (values) => {
      setLoader(true);
      values.businessId = id;
      mutate(values, {
        onSuccess: () => {
          handleClose();
          formik.resetForm();
          Swal.fire({
            customClass: {
              container: "formEditCompany",
              title: "formTitleEditCompany",
            },
            icon: "success",
            title: "¡El punto de venta ha sido registrado!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setLoader(false);
        },
        onError: (error) => {
          setLoader(false);
          if (error.response.status === 504) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Lo sentimos!",
              text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }          
          if (error.response.status === 500 || error.response.status === 503) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 422) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.errors.map(
                (element) => element.message
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 415) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.error,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 404) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 403) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 400) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status >= 0 && error.request.status <= 99) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Compruebe su conexión a internet",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });
    },
  });

  return (
    <>
      {!disableBtn ? (
        <button
          disabled={disableBtn}
          className="buttonRegisterBranch"
          type="button"
          style={{ width: "100px" }}
          onClick={() => handleOpen(id)}
        >
          <span className="buttonRegisterBranch__text">POV</span>
          <span className="buttonRegisterBranch__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              viewBox="0 0 48 48"
              height="48"
              className="svgRegisterBranch"
            >
              <AddIcon sx={{ color: "white" }} />
            </svg>
          </span>
        </button>
      ) : (
        <button
          disabled={disableBtn}
          className="buttonEnableTable"
          type="button"
          style={{ width: "100px" }}
          onClick={() => handleOpen(id)}
        >
          <span className="buttonEnableTable__text">POV</span>
        </button>
      )}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "green", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Registrar puntos de venta
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Seleccione el tipo punto de venta
                  </Typography>
                  <Select
                    isSearchable
                    menuPosition="fixed"
                    name="sucursalId"
                    placeholder="Seleccionar..."
                    styles={CustomDropdownStyle}
                    options={sucursales}
                    onChange={(e) => {
                      handleSucursal(e);
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formik.touched.sucursalId && formik.errors.sucursalId}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Seleccione código Tipo Punto Venta
                  </Typography>
                  <Select
                    isSearchable
                    menuPosition="fixed"
                    name="codigoTipoPuntoVenta"
                    placeholder="Seleccionar..."
                    styles={CustomDropdownStyle}
                    options={tiposPuntosVenta}
                    onChange={(e) => {
                      handlePointOfSale(e);
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formik.touched.codigoTipoPuntoVenta &&
                      formik.errors.codigoTipoPuntoVenta}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Nombre del punto de venta
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="nombrePuntoVenta"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.nombrePuntoVenta}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formik.touched.nombrePuntoVenta &&
                      formik.errors.nombrePuntoVenta}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Descripción
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="descripcion"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.descripcion}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formik.touched.descripcion && formik.errors.descripcion}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
                <button className="buttonRegisterData" type="submit">
                  <span className="buttonRegisterData">Registrar</span>
                </button>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ButtonRegisterPOV;
