import { useMutation, useQueryClient } from "react-query";
import axios from 'axios';

const enableCompany = async (id) => {
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/api/v1/billing/business/enable`,
      params: {
        businessId: id,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
  } catch (error) {
    throw error
  }
};


export const useEnableCompany = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: enableCompany,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["get-company"]});
        }
    })
}