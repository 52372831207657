import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { useDisableCompany } from "../hooks/useDisableCompany";
import "./ButtonDisableCompany.css";
import Swal from "sweetalert2";

const ButtonDisableCompany = ({ id }) => {

  const {mutate} = useDisableCompany();

  const handleDisableCompany = (id) => {
    Swal.fire({
      customClass: {
        container: "formDisableCompany",
        title: "formTitleDisableCompany",
      },
      title: "¿Estás seguro de inhabilitarlo?",
      text: "¡La empresa quedará inhabilitada!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#94948f",
      confirmButtonColor: "#34974d",
      confirmButtonText: "Aceptar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        mutate(id, {
          onSuccess: (data) => {
            Swal.fire({
              customClass: {
                container: "formDisableCompany",
                title: "formTitleDisableCompany",
              },
              title: "¡El empresa ha sido inhabilitada!",
              icon: "success",
              allowOutsideClick: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              timer: 1500,
            });
          },
          onError: (error) => {
            if (error.response.status === 504) {
              Swal.fire({
                customClass: {
                  container: "formDisableCompany",
                  title: "formTitleDisableCompany",
                },
                icon: "error",
                title: "!Lo sentimos!",
                text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            }            
            if (error.request.status === 500 || error.response.status === 503) {
              Swal.fire({
                customClass: {
                  container: "formDisableCompany",
                  title: "formTitleDisableCompany",
                },
                icon: "error",
                title: "!Ha ocurrido un error!",
                text: "Por favor comuniquese con el administrador",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            }
            if (error.request.status === 422) {
              Swal.fire({
                customClass: {
                  container: "formDisableCompany",
                  title: "formTitleDisableCompany",
                },
                icon: "error",
                title: "!Ha ocurrido un error!",
                text: error.response.data.errors.map(
                  (element) => element.message
                ),
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            }
            if (error.request.status === 404) {
              Swal.fire({
                customClass: {
                  container: "formDisableCompany",
                  title: "formTitleDisableCompany",
                },
                icon: "error",
                title: "!Ha ocurrido un error!",
                text: error.response.data.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            }
            if (error.request.status === 400) {
              Swal.fire({
                customClass: {
                  container: "formDisableCompany",
                  title: "formTitleDisableCompany",
                },
                icon: "error",
                title: "!Ha ocurrido un error!",
                text: error.response.data.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            }
            if (error.request.status >= 0 && error.request.status <= 99) {
              Swal.fire({
                customClass: {
                  container: "formDisableCompany",
                  title: "formTitleDisableCompany",
                },
                icon: "error",
                title: "!Ha ocurrido un error!",
                text: "Compruebe su conexión a internet",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            } 
          }
        });
      }
    });
  }
  return (
    <>
      <button
        className="button"
        type="button"
        onClick={() => handleDisableCompany(id)}
      >
        <span className="button__text">Inhabilitar</span>
        <span className="button__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svg"
          >
            <NotInterestedIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
    </>
  );
};
export default ButtonDisableCompany;
