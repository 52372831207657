import * as Yup from 'yup';

const ValidationRegisterCompany = Yup.object({
  nombreSistema: Yup.string().required("El campo es requerido"),
  razonSocial: Yup.string().required("El campo es requerido"),
  departamentoEmpresa: Yup.string().required("El campo es requerido"),
  telefono: Yup.string().required("El campo es requerido"),
  nit: Yup.string().required("El campo es requerido"),
  siatToken: Yup.string().required("El campo es requerido"),
  modalidad: Yup.string().required("El campo es requerido"),
  email: Yup.string().email("El formato es incorrecto").required("El campo es requerido"),
  uniPersonal: Yup.bool().required("El campo es requerido"),
  fechaInicio: Yup.date().required('La fecha de inicio es requerida'),
  fechaFin: Yup.date()
    .required('La fecha final es requerida')
    .min(Yup.ref('fechaInicio'), 'La fecha final no puede ser anterior a la fecha de inicio'),
});

export default ValidationRegisterCompany;