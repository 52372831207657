import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import DataTableActivities from "./DataTableActivities";
import DataTableLegends from "./DataTableLegends";
import DataTableProductServices from "./DataTableProductServices";
import DataTableDocument from "./DataTableDocument";
import ButtonGoBackSetting from "./ButtonGoBackSetting";
import './ButtonLoaderPhone.css';
import "./RegisterSyncUp.css";

const RegisterParametrics = () => {
  const [label, setLabel] = useState("");
  const [rowX, setRowX] = useState([]);
  const [hideBtn, setHideBtn] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleChangeEvent = (e) => {
    handleSincronization(e.value);
    setLabel(e.value);
  };

  const list = [
    { value: "ACTIVIDADES", label: "ACTIVIDAD" },
    { value: "LISTA_LEYENDAS", label: "LISTA DE LEYENDAS" },
    { value: "PRODUCTOS_SERVICIO", label: "PRODUCTO SERVICIO" },
    { value: "ACTIVIDAD_DOCUMENTO_SECTOR", label: "DOCUMENTO SECTOR" },
  ];

  const handleSincronization = async (valor) => {
    setHideBtn(true);
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/catalogueSiatAll",
        params: {
          type: valor,
          pageNumber: 1,
          pageSize: 10000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setRowX(response.data.content);
      setHideBtn(false);
    } catch (error) {
      setHideBtn(false);
      if (error.response.status === 504) {
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "error",
          title: "!Lo sentimos!",
          text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }       
      if (error.response.status === 500 || error.response.status === 503) {
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: "Por favor comuniquese con el administrador",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status === 404) {
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: error.response.data.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status === 400) {
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: error.response.data.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status >= 0 && error.response.status <= 99) {
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "error",
          title: "!Ha ocurrido un problema de conectividad!",
          text: "Por favor vuelva a intentarlo más tarde",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    }
  };

  const rowsActividad = rowX.map((row) => ({
    codigoCaeb: row.codigoCaeb,
    descripcion: row.descripcion,
    tipoActividad: row.tipoActividad,
    sincronizacion: row.sincronizacion,
  }));

  const rowsLeyendas = rowX.map((row) => ({
    codigoActividad: row.codigoActividad,
    descripcionLeyenda: row.descripcionLeyenda,
    sincronizacion: row.sincronizacion,
  }));

  const rowsProductosServicios = rowX.map((row) => ({
    codigoActividad: row.codigoActividad,
    codigoProducto: row.codigoProducto,
    descripcionProducto: row.descripcionProducto,
    sincronizacion: row.sincronizacion,
  }));

  const rowsDocumentoSector = rowX.map((row) => ({
    codigoActividad: row.codigoActividad,
    codigoDocumentoSector: row.codigoDocumentoSector,
    tipoDocumentoSector: row.tipoDocumentoSector,
    sincronizacion: row.sincronizacion,
  }));

  const handleCatalogue = async () => {
    setLoader(true);
    setHideBtn(true);
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/synchronization/allCatalogSynchronization/" +
          label,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      if (response.status === 200) {
        setLoader(false);
        handleSincronization(label);
        setHideBtn(false);
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "success",
          title: "¡La sincronización fue exitosa!",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    } catch (error) {
      setLoader(false);
      setHideBtn(false);
      if (error.response.status === 504) {
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "error",
          title: "!Lo sentimos!",
          text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }       
      if (error.response.status === 500 ||  error.response.status === 503) {
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: "Por favor comuniquese con el administrador",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status === 404) {
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: error.response.data.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status === 403) {
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: error.response.data.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status === 400) {
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "error",
          title: "!Ha ocurrido un error!",
          text: error.response.data.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      if (error.response.status >= 0 && error.response.status <= 99) {
        Swal.fire({
          customClass: {
            container: "syncUp",
            title: "syncUpTitle",
          },
          icon: "error",
          title: "!Ha ocurrido un problema de conectividad!",
          text: "Por favor vuelva a intentarlo más tarde",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography

            className="titleProduct"
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald", marginBottom: "20px" }}
          >
            Sincronización de catálogos
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <ButtonGoBackSetting />
          {label !== "" ? (
            loader ? (
              <button
                className="buttonLoaderPhone"
                style={{ marginTop: "20px"}}
              >
                    <div className="loader">
                      <div className="bar1"></div>
                      <div className="bar2"></div>
                      <div className="bar3"></div>
                      <div className="bar4"></div>
                      <div className="bar5"></div>
                      <div className="bar6"></div>
                      <div className="bar7"></div>
                      <div className="bar8"></div>
                      <div className="bar9"></div>
                      <div className="bar10"></div>
                      <div className="bar11"></div>
                      <div className="bar12"></div>
                    </div>
              </button>
            ) : (
              <button
                className="buttonRegister"
                type="button"
                disabled={hideBtn}
                onClick={() => handleCatalogue()}
              >
                <span className="buttonRegister__text">Sincronizar</span>
                <span className="buttonRegister__textSmall">
                    <AutorenewIcon sx={{ color: "white", borderRadius: "50%" }} />
                </span>
                <span className="buttonRegister__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    viewBox="0 0 48 48"
                    height="48"
                    className="svgRegister"
                  >
                    <AutorenewIcon sx={{ color: "white" }} />
                  </svg>
                </span>
              </button>
            )
          ) : (
            ""
          )}
        </Grid>
        <Grid
          className="containerLabelSync"
          item
          xs={12}
          md={1}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            className="labelSync"
            variant="h5"
            sx={{
              fontWeight: 700,
              color: "gray",
              marginRight: "10px",
              fontFamily: "Oswald",
            }}
          >
            Buscar:{" "}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            zIndex: "999",
          }}
        >
          <Select
            className="selectSync"
            fullWidth
            styles={CustomDropdownStyle}
            options={list}
            onChange={(e) => handleChangeEvent(e)}
            placeholder="Catálogo"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {label === "ACTIVIDADES" ? (
            <DataTableActivities rows={rowsActividad} />
          ) : label === "LISTA_LEYENDAS" ? (
            <DataTableLegends rows={rowsLeyendas} />
          ) : label === "PRODUCTOS_SERVICIO" ? (
            <DataTableProductServices rows={rowsProductosServicios} />
          ) : (
            <DataTableDocument rows={rowsDocumentoSector} />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default RegisterParametrics;
