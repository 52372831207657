import * as Yup from 'yup';

const ValidationRegisterDetailProduct = (cantidadDescuento, precioUnitarioR) =>
  Yup.object().shape({
    cantidad: Yup.number()
      .typeError("El campo debe ser numérico")
      .required("El campo es requerido")
      .positive("El valor debe ser mayor a 0")
      .test('decimal-places', 'Se permiten hasta dos decimales', value => {
        if (!value) return true; // Permite valores nulos o vacíos
        return /^\d+(\.\d{1,2})?$/.test(value.toString()) && value > 0;
      }),
    montoDescuento: Yup.number()
      .typeError("El valor debe ser numérico")
      .moreThan(-1, "El valor debe ser mayor a -1")
      .test("max-discount", "El descuento debe ser menor que el valor total", function (value) {
        const cantidad = cantidadDescuento;
        const total = cantidad * precioUnitarioR;
        return value < total;
      })
      .test("is-decimal", "El campo permite valores numéricos de hasta 2 decimales", (value) =>
        /^\d+(\.\d{1,2})?$/.test(value)
      )
      .required("Este campo es requerido"),
    porcentaje: Yup.string()
      .matches(/^(\d{1,2}(\.\d{1,2})?|99(\.99?)?)$/, {
        message: "El campo permite valores numéricos de 0.00 a 99.99",
      })
      .required('El campo es requerido'),
  });
export default ValidationRegisterDetailProduct;
