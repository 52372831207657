import { useState } from "react";
import Box from "@mui/material/Box";
import DataTableProduct from "../components/DataTableProduct";

const Product = () => {
  const [search, setSearch] = useState("");
  return (
    <Box>
        <DataTableProduct searchProduct={search} />
    </Box>
  );
};

export default Product;
