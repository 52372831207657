import { Chart, registerables } from "chart.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CustomDropdownPOV from "../components/style/CustomDropdownPOV";
import { Box, CssBaseline, Grid, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../components/ButtonStatistics.css";
import { useQuery } from "react-query";
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import handleError from '../components/utilities/HandleError';
import axios from "axios";
import dayjs from "dayjs";
import "./Statistics.css";

Chart.register(...registerables);

const estadisticas = [
  { value: true, label: "FACTURAS EMITIDAS POR MES" },
  { value: false, label: "MONTO RECAUDADO POR MES" },
];

const Sale = () => {
  const [sucursales, setSucursales] = useState([]);
  const [fecha, setFecha] = useState(dayjs(Date.now()).year().toString());
  const [selectSucursal, setSelectSucursal] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf('year'));

  const navigate = useNavigate();

  const handleSelect = (e) => {
    if (e?.label === "FACTURAS EMITIDAS POR MES") {
      navigate("/dashboard/statistics/invoice");
    }
  };
  const handleSelectSucursal = (e) => {
    setSelectSucursal(e.value);
  };
  const handleSelectDate = ( date ) => {
    if (date) {
      setFecha(dayjs(date).year().toString());
    } else {
      setSelectedDate(dayjs().startOf('year'));
    }
  };
  const handleAccept = (date) => {
    if (!date) {
      setSelectedDate(dayjs().startOf('year'));
    }
  };

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Datos",
        data: [],
        backgroundColor: "#272C48",
      },
    ],
  });
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    updateMode: "resize",
    plugins: {
      customCanvasBackgroundColor: {
        color: "lightGreen",
      },
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monto acumulado en Bolivianos (Bs)",
        font: {
          size: 22,
          family: "Great Vibes, cursive",
        },
        margin: {
          top: 20,
        },
      },
    },
    scales: {
      y: {
        min: 0,
        max:
          Math.max(...chartData.datasets.flatMap((dataset) => dataset.data)) +
          5,
      },
    },
  };

  useEffect(() => {
    getSucursales();
  }, []);

  const getSucursales = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API + "/api/v1/billing/pointOfSale/listBranch",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setSucursales(
        response.data.map((element) => ({
          label: element.nombreSucursal,
          value: element.sucursalId,
        }))
      );
    } catch (error) {
      handleError(error);
    }
  };

  const getStatistics = async (year, selectSucursal1) => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/statistic",
        params: {
          year: year,
          sucursalId: selectSucursal1,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const newData = [
        {
          label: [],
          data: response.data.montos,
          backgroundColor: "chocolate",
          borderWidth: 1,
        },
      ];

      const label = response.data.meses;
      setChartData({
        labels: label,
        datasets: newData,
      });
      return response.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const { isLoading, isError } = useQuery({
    queryKey: ["getAll-statistics", fecha, selectSucursal],
    queryFn: () => getStatistics(fecha, selectSucursal),
  });

  if (isError) {
    return (
      <>
        <Box sx={{ height: "100vh" }}>
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "20%",
              textAlign: "center",
            }}
          >
            <h1
              style={{
                fontFamily: "Oswald",
                fontWeight: "200",
                fontSize: "40px",
                color: "gray",
              }}
            >
              Por favor, compruebe su conexión a internet
            </h1>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box>
        <CssBaseline />
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
            >
              {" "}
              Estadísticas
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4} sx={{ marginBottom: "10px" }}>
              <Box>
                <Typography
                  className=""
                  sx={{ color: "gray", fontFamily: "Oswald" }}
                >
                  Seleccione una opción
                </Typography>
              </Box>
              <Select
                styles={CustomDropdownPOV}
                options={estadisticas}
                placeholder={"Seleccione una opción"}
                defaultValue={estadisticas[1]}
                onChange={(selectedOption) => handleSelect(selectedOption)}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ marginBottom: "10px" }}>
              <Box className="selectSucursal">
                <Typography sx={{ color: "gray", fontFamily: "Oswald" }}>
                  Seleccione una sucursal
                </Typography>
              </Box>
              <Box className="selectSucursal">
                <Select
                  styles={CustomDropdownPOV}
                  options={sucursales}
                  placeholder={"Seleccione una opción"}
                  onChange={(selectedOption) =>
                    handleSelectSucursal(selectedOption)
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={{ width: "100%", marginBottom: '20px'}}>
                <div>
                  <Typography
                    className=""
                    sx={{ color: "gray", fontFamily: "Oswald" }}
                  >
                    Seleccione el año
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      minDate={dayjs().subtract(2, "year")}
                      maxDate={dayjs(Date.now())}
                      value={selectedDate}
                      onChange={(e) => handleSelectDate(e)}
                      onAccept={handleAccept}
                      views={["year"]}
                      slotProps={{
                        textField: {
                          sx: { width: "100%" },
                          size: "small",
                          placeholder: dayjs(Date.now()).year().toString(),
                          disabled: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}></Grid>

        {isLoading ? (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Oswald",
              fontWeight: "400",
              fontSize: "30px",
              color: "gray",
              height: "50vh",
            }}
          >
            {"Cargando..."}
          </p>
        ) : (
          <Box style={{ height: "70vh" }}>
            <Bar options={options} data={chartData} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Sale;