import { useMutation, useQueryClient } from "react-query";
import axios from 'axios';

const disableProduct = async (id) => {
    try {
      const response = await axios({
        method: "delete",
        url: process.env.REACT_APP_API + "/api/v1/billing/product/delete/"+id,
        headers: {
          "content-type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
    } catch (error) {
      throw error;
    }
  };


export const useDisabledProduct = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: disableProduct,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['get-products']})
        }
    })
}