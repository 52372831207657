import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

const savePov = async (payLoad) => {
  const { data } = await axios.post(
    process.env.REACT_APP_API + "/api/v1/pointOfSaleUser/set",
    payLoad,
    {
      headers: {
        "Content-type": "application/json;charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
  return data;
};

export const useAddPov = () => {
  const queryClient = useQueryClient();
  return useMutation(savePov, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-pov-user"] });
    },
  });
};
