import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

const disablePovUser = async (payload) => {
  try {
    const response = await axios({
      method: "delete",
      url: process.env.REACT_APP_API + "/api/v1/pointOfSaleUser/disable",
      params: {
        userId: payload.id,
        pointOfSaleUserId: payload.pointOfSaleId,
      },
      headers: {
        "content-type": "application/json;charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const useDisablePovUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: disablePovUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-pov-user"] });
    },
  });
};
