import BlockIcon from '@mui/icons-material/Block';
import Swal from "sweetalert2";
import { useDisablePovUser } from "../hooks/useDisablePovUser";
import "./ButtonDisablePov.css"

const ButtonDisablePovUser = ({
  id,
  pointOfSaleId,
  status,
}) => {
  const { mutate } = useDisablePovUser();

  const handleDisablePov = (id, pointOfSaleId, status) => {
    Swal.fire({
      customClass: {
        container: "formDisablePov",
        title: "formTitleDisablePov",
      },
      title: "¿Estás seguro de inhabilitarlo?",
      text: "¡La punto de venta quedará inhabilitado!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#94948f",
      confirmButtonColor: "#34974d",
      confirmButtonText: "Aceptar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        mutate(
          { id, pointOfSaleId },
          {
            onSuccess: () => {
              Swal.fire({
                customClass: {
                  container: "formDisablePov",
                  title: "formTitleDisablePov",
                },
                title: "¡El punto de venta ha sido inhabilitado!",
                icon: "success",
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: true,
              });
            },
          }
        );
      }
    });
  };
  return (
    <div>
      <button
        className="button"
        type="button"
        style={{width: '100px'}}
        onClick={() => handleDisablePov(id, pointOfSaleId, status)}
      >
        <span className="button__text">Inhabilitar</span>
        <span className="button__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svg"
          >
            <BlockIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
    </div>
  );
};
export default ButtonDisablePovUser;