import { useMutation, useQueryClient } from "react-query";
import axios from 'axios';

const uploadImage = async (payload) => {
  const { data } = await axios.put(
    process.env.REACT_APP_API + "/api/v1/billing/business/uploadLogo",
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    },
  );
  return data;
};

export const useUploadImage = () =>{
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: uploadImage,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get-upload-image"] });
      },
    });
}

