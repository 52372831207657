import AddIcon from "@mui/icons-material/Add";
import React, { useState, forwardRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {TextField } from "@mui/material";
import Select from "react-select";
import Swal from "sweetalert2";
import axios from "axios";
import { useFormik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import ValidationRegisterCustomer from "../validation/ValidationRegisterCustomer";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import { useRegisterClient } from "../hooks/useRegisterClient";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./ButtonRegisterClient.css";
import "./ButtonRegister.css";
import "./ButtonClose.css";
import "./ButtonLoadingData.css";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonRegisterClient = ({fetch}) => {
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [typeDocument, setTypeDocument] = useState("");
  const [codigoClasificadorSiat, setCodigoClasificadorSiat] = useState(0);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    formikRegisterClient.resetForm({ data: "" });
    documentList();
    setOpen(true);
  };

  const handleClose = () => {
    setTiposDocumentos(null);
    setTypeDocument("");
    setCodigoClasificadorSiat(0);
    formikRegisterClient.resetForm({ data: "" });
    setOpen(false);
  };

  /********************************************* Evento seleccionar tipo de documento ****************************************/
  const handleChangeDocumentType = (e) => {
    formikRegisterClient.setFieldValue("type", e.label);
    setTypeDocument(e.label);
    setCodigoClasificadorSiat(e.value);
  };

  /********************************************* Listar tipos de documento ****************************************/
  const documentList = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/parametric/parametricSiatAll",
        params: {
          type: "TIPO_DOCUMENTO_IDENTIDAD",
          pageNumber: 1,
          pageSize: 1000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setTiposDocumentos(
        response.data.content.map((sup) => ({
          label: sup.descripcion,
          value: sup.codigoClasificador,
        }))
      );
    } catch (error) {}
  };

  const { mutate } = useRegisterClient();

  /********************************************* Registrar clientes ****************************************/
  const formikRegisterClient = useFormik({
    initialValues: {
      documento: "",
      complemento: "",
      razonSocial: "",
      email: "",
      numCelular: "",
      type: "",
    },
    validationSchema: ValidationRegisterCustomer,
    onSubmit: async (data) => {
      setLoader(true)
      data.codigoClasificadorSiat = parseInt(codigoClasificadorSiat);
      data.documento = data.documento.trim();
      data.complemento = data.complemento.trim();
      data.razonSocial = data.razonSocial.trim();
      data.email = data.email.trim();
      data.numCelular = data.numCelular.trim();
      mutate(data, {
        onSuccess: () => {
          fetch();
          handleClose();
          setTypeDocument("");
          formikRegisterClient.resetForm();
          Swal.fire({
            customClass: {
              container: "formRegisterClient",
              title: "formTitleRegisterClient",
            },
            icon: "success",
            title: "¡El cliente ha sido registrado!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setLoader(false)
        },
        onError: (error) => {
          setLoader(false)
          if (error.response.status >= 500 && error.response.status <= 599) {
            Swal.fire({
              customClass: {
                container: "formRegisterClient",
                title: "formTitleRegisterClient",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 422) {
            Swal.fire({
              customClass: {
                container: "formRegisterClient",
                title: "formTitleRegisterClient",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.errors.map(
                (element) => element.message
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 404) {
            Swal.fire({
              customClass: {
                container: "formRegisterClient",
                title: "formTitleRegisterClient",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (
            error.response.status === 400 ) {
            Swal.fire({
              customClass: {
                container: "formRegisterClient",
                title: "formTitleRegisterClient",
              },
              icon: "warning",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status >= 0 && error.request.status <= 99) {
            Swal.fire({
              customClass: {
                container: "formRegisterClient",
                title: "formTitleRegisterClient",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Compruebe su conexión a internet",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });
    },
  });

  return (
    <>
      <button
        className="buttonRegister"
        type="button"
        onClick={() => handleOpen()}
      >
        <span className="buttonRegister__text">Registrar</span>
        <span className="buttonRegister__textSmall">
          <AddIcon sx={{ color: "white", borderRadius: "50%" }} />
        </span>
        <span className="buttonRegister__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgRegister"
          >
            <AddIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ backgroundColor: 'green', margin: "20px 23px 15px 23px"}}>
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
            >
            Registrar cliente
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog" >
          <form
            onSubmit={formikRegisterClient.handleSubmit}
            id="registrarCliente"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ zIndex: 9 }}>
                <Typography className="labelFormEditClient" sx={{color: ' !important'}}>
                  Seleccione un tipo de documento
                </Typography>
                <Select
                  isSearchable
                  menuPosition="fixed"
                  placeholder="Seleccionar..."
                  styles={CustomDropdownStyle}
                  options={tiposDocumentos}
                  onChange={(e) => {
                    handleChangeDocumentType(e);
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="type"
                  onBlur={formikRegisterClient.handleBlur}
                  onChange={formikRegisterClient.handleChange}
                  value={formikRegisterClient.values.type}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {formikRegisterClient.values.type === "" &&
                    formikRegisterClient.touched.type &&
                    formikRegisterClient.errors.type}
                </FormHelperText>
              </FormControl>
              {codigoClasificadorSiat !== 1 ? (
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <Typography className="labelFormClient" sx={{color: ' !important'}}>
                      Número de documento
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      name="documento"
                      onBlur={formikRegisterClient.handleBlur}
                      onChange={formikRegisterClient.handleChange}
                      value={formikRegisterClient.values.documento}
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                          border: "1px solid white",
                          borderColor: "white",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{ color: "chocolate", fontSize: "14px" }}
                    >
                      {formikRegisterClient.touched.documento &&
                        formikRegisterClient.errors.documento}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <>
                  <Grid item xs={7} md={7}>
                    <FormControl fullWidth>
                      <Typography className="labelFormClient" sx={{color: ' !important'}}>
                        Número de documento
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="documento"
                        onBlur={formikRegisterClient.handleBlur}
                        onChange={formikRegisterClient.handleChange}
                        value={formikRegisterClient.values.documento}
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: "0px",
                            border: "1px solid white",
                            borderColor: "white",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{ color: "chocolate", fontSize: "14px" }}
                      >
                        {formikRegisterClient.touched.documento &&
                          formikRegisterClient.errors.documento}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5} md={5}>
                    <FormControl fullWidth>
                      <Typography className="labelFormClient" sx={{color: ' !important'}}>
                        Complemento
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="complemento"
                        onBlur={formikRegisterClient.handleBlur}
                        onChange={formikRegisterClient.handleChange}
                        value={formikRegisterClient.values.complemento}
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: "0px",
                            border: "1px solid white",
                            borderColor: "white",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{ color: "chocolate", fontSize: "14px" }}
                      >
                        {formikRegisterClient.touched.complemento &&
                          formikRegisterClient.errors.complemento}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient" sx={{color: ' !important'}}>
                    Razón social
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="razonSocial"
                    onBlur={formikRegisterClient.handleBlur}
                    onChange={formikRegisterClient.handleChange}
                    value={formikRegisterClient.values.razonSocial}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterClient.touched.razonSocial &&
                      formikRegisterClient.errors.razonSocial}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient" sx={{color: ' !important'}}>
                    Correo electrónico
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="email"
                    onBlur={formikRegisterClient.handleBlur}
                    onChange={formikRegisterClient.handleChange}
                    value={formikRegisterClient.values.email}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterClient.touched.email &&
                      formikRegisterClient.errors.email}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Número de celular
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="numCelular"
                    onBlur={formikRegisterClient.handleBlur}
                    onChange={formikRegisterClient.handleChange}
                    value={formikRegisterClient.values.numCelular}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterClient.touched.numCelular &&
                      formikRegisterClient.errors.numCelular}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
              <button className="buttonRegisterData" type="submit">
                <span className="buttonRegisterData">Registrar</span>
              </button>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonRegisterClient;
