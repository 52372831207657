import DataTableContingencia from '../components/DataTableContingencia'
import { Grid, Typography } from '@mui/material'
import React from 'react'

export const Contingencia = () => {
  return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography
            className="titleClient"
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            Lista por contingencia
          </Typography>
        </Grid>
        <Grid item xs={12}>
            <DataTableContingencia/>
        </Grid>
    </Grid>
  )
}
