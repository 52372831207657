import Swal from "sweetalert2";

//function for error on axios
export default function handleError(error, message) {
  if(error){
    if(error.response.status === 400){
      Swal.fire({
        customClass: {
          container: "formRegisterClient",
          title: "formTitleRegisterClient",
        },
        icon: "error",
        title: "!Lo sentimos!",
        text: message !== null ? message : error.response.data.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
    else if (error.request.status === 404) {
      Swal.fire({
        customClass: {
          container: "formRegisterClient",
          title: "formTitleRegisterClient",
        },
        icon: "error",
        title: "!Ha ocurrido un error!",
        text: message !== null ? message : error.response.data.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
    else if (error.response.status === 500) {
      Swal.fire({
        customClass: {
          container: "formRegisterClient",
          title: "formTitleRegisterClient",
        },
        icon: "error",
        title: "!Ha ocurrido un error!",
        text: "Por favor comuniquese con el administrador",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
    else if (error.response.status === 503) {
      Swal.fire({
        customClass: {
          container: "formRegisterClient",
          title: "formTitleRegisterClient",
        },
        icon: "error",
        title: "!Ha ocurrido un error, vuelvalo a intentar!",
        text: error.response.data.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
    else if (error.response.status === 504) {
      Swal.fire({
        customClass: {
          container: "formRegisterClient",
          title: "formTitleRegisterClient",
        },
        icon: "error",
        title: "!Lo sentimos!",
        text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
    else if(error.response.status === 422) {
      Swal.fire({
        customClass: {
          container: "formRegisterClient",
          title: "formTitleRegisterClient",
        },
        icon: "error",
        title: "!Lo sentimos!",
        text: "Por favor verifique la información e intentelo de nuevo",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    } else {
      Swal.fire({
        customClass: {
          container: "formRegisterClient",
          title: "formTitleRegisterClient",
        },
        icon: "error",
        title: "!Lo sentimos!",
        text: "Por favor verifique la información e intentelo de nuevo",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
    
  }
  
}