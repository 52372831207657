import React from 'react';
import Box from "@mui/material/Box";
import FormBilling from '../components/FormBilling';
import './BillingPage.css'

const Billing = () => {
    return (
      <Box className="principal-box">
        <FormBilling />
      </Box>
    );
}

export default Billing;