import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useFormik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import axios from "axios";
import ValidationEditUser from "../validation/ValidationEditUser";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useEditUser } from "../hooks/useEditUser";
import "./ButtonEditUser.css";
import "./ButtonEnableTable.css";
import handleError from "./utilities/HandleError";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ButtonEditUser = ({ id, disableBtn }) => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [aux, setAux] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = (idUser) => {
    getUser(idUser);
    setOpen(true);
  };
  const handleClose = () => {
    setAux("")
    formikEditUser.resetForm();
    setOpen(false);
  };

  const getUser = async (idUser) => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/user/get/" + idUser,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })

      setAux(response.data.name)
      formikEditUser.setValues({
        name: response.data.name,
        lastName: response.data.lastName,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const { mutate } = useEditUser();

  const formikEditUser = useFormik({
    initialValues: {
      name: "",
      lastName: "",
    },
    validationSchema: ValidationEditUser,
    onSubmit: async (data) => {
      setLoader(true);
      setAux("")
      data.id = id;
      data.name = data.name.trim();
      data.lastName = data.lastName.trim();
      mutate(data, {
        onSuccess: () => {
          handleClose();
          formikEditUser.resetForm();
          Swal.fire({
            customClass: {
              container: "formEditUser",
              title: "formTitleEditUser",
            },
            icon: "success",
            title: "¡El datos han sido actualizado!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setLoader(false);
        },
        onError: (error) => {
          setLoader(false);
          if (error.response.status === 504) {
            Swal.fire({
              customClass: {
                container: "formEditUser",
                title: "formTitleEditUser",
              },
              icon: "error",
              title: "!Lo sentimos!",
              text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }          
          if (error.response.status === 500 ||  error.response.status === 503) {
            Swal.fire({
              customClass: {
                container: "formEditUser",
                title: "formTitleEditUser",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 422) {
            Swal.fire({
              customClass: {
                container: "formEditUser",
                title: "formTitleEditUser",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.errors.map(
                (element) => element.message
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 404) {
            Swal.fire({
              customClass: {
                container: "formEditUser",
                title: "formTitleEditUser",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 403) {
            Swal.fire({
              customClass: {
                container: "formEditUser",
                title: "formTitleEditUser",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 400) {
            Swal.fire({
              customClass: {
                container: "formEditUser",
                title: "formTitleEditUser",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status >= 0 && error.response.status <= 99) {
            Swal.fire({
              customClass: {
                container: "formEditUser",
                title: "formTitleEditUser",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Compruebe su conexión a internet",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });
    },
  });

  return (
    <>
      {!disableBtn ? (
        <button
          disabled={disableBtn}
          className="buttonEdit"
          type="button"
          onClick={() => handleOpen(id)}
        >
          <span className="buttonEdit__text">Editar</span>
          <span className="buttonEdit__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              viewBox="0 0 48 48"
              height="48"
              className="svgEdit"
            >
              <EditIcon sx={{ color: "white" }} />
            </svg>
          </span>
        </button>
      ) : (
        <button
          disabled={disableBtn}
          className="buttonEnableTable"
          type="button"
          onClick={() => handleOpen(id)}
        >
          <span className="buttonEnableTable__text">Editar</span>
        </button>
      )}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "#D1B000", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Editar usuario
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikEditUser.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ marginTop: "5px" }}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">Nombre</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="name"
                    onBlur={formikEditUser.handleBlur}
                    onChange={formikEditUser.handleChange}
                    value={formikEditUser.values.name}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikEditUser.touched.name && formikEditUser.errors.name}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Apellido(s)
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="lastName"
                    onBlur={formikEditUser.handleBlur}
                    onChange={formikEditUser.handleChange}
                    value={formikEditUser.values.lastName}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikEditUser.touched.lastName &&
                      formikEditUser.errors.lastName}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
                aux ? (
                <button
                  className="buttonRegisterData"
                  style={{ border: "1px solid #D1B000" }}
                  type="submit"
                >
                  <span className="buttonEdit">Actualizar</span>
                </button>
                ) : (
                  ""
                )
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonEditUser;
