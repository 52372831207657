import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../page/Home";
import Setting from "../page/Setting";
import Client from "../page/Client";
import Product from "../page/Product";
import ShowBilling from "../page/ShowBilling";
import MassiveEmission from "../page/MassiveEmission";
import MinorBilling from "../page/MinorBilling";
import UserPov from "../page/UserPov";
import Company from "../page/Company";
import PageNotFound from "../page/PageNotFound";
import Layout from "../page/Layout";
import LoginPage from "../page/LoginPage";
import ForgotPassword from "../page/ForgotPassword";
import ActiveUser from "../page/ActiveUser";
import ResetPassword from "../page/ResetPassword";
import Login from "../page/Login";
import SelectPointOfSale from "../page/SelectPointOfSale";
import User from "../page/User";
import PointOfSale from "../page/PointOfSale";
import SyncUp from "../page/SyncUp";
import Billing from "../page/Billing";
import Forwarding from "../page/Forwarding";
import ChangePointOfSale from "../page/ChangePointOfSale";
import Parametrics from "../page/Parametrics";
import CufObservation from "../page/CufObservation";
import Statistics from "../page/Statistics";
import Sale from "../page/Sale";
import LogoTipo from "../page/LogoTipo";
import { Contingencia } from "../page/Contingencia";


const RoutesApp = () => {
  const loggedIn = window.localStorage.getItem("isLoggedIn") ;

  return (
    <Routes>
      <Route path="/" element={<LoginPage />}>
        <Route
          index
          element={loggedIn ? <Navigate to="/dashboard/statistics/invoice" /> : <Login />}
        />
        <Route path="pov" element={<SelectPointOfSale />} />
        <Route path="activate_user/*" element={<ActiveUser />} />
        <Route path="reset_password/*" element={<ResetPassword />} />
        <Route path="forgot_password/*" element={<ForgotPassword />} />
        <Route path="forwarding" element={<Forwarding />} />
      </Route>
      <Route path="/dashboard" element={<Layout loggedIn={loggedIn} />}>
        <Route path="home" element={<Home />} />
        <Route path="statistics/invoice" element={<Statistics />} />
        <Route path="statistics/sale" element={<Sale />} />
        <Route path="setting" element={<Setting />} />
        <Route path="billing" element={<Billing />} />
        <Route path="client" element={<Client />} />
        <Route path="contingencia" element={<Contingencia />} />
        <Route path="product" element={<Product />} />
        <Route path="bills_list" element={<ShowBilling />} />
        <Route path="cuf" element={<CufObservation />} />
        <Route path="massive_emission" element={<MassiveEmission />} />
        <Route path="company" element={<Company />} />
        <Route path="user" element={<User />} />
        <Route path="change_pov" element={<ChangePointOfSale/>}/>
        <Route path="upload_logo" element={<LogoTipo/>}/>
        <Route path="user/:id/:userName/:businessId" element={<UserPov />} />
        <Route path="point_of_sale" element={<PointOfSale />} />
        <Route path="syncup" element={<SyncUp />} />
        <Route path="parametrics" element={<Parametrics />} />
        <Route path="send_billing_minor" element={<MinorBilling />} />
        <Route path="row.linkQr"/>
        <Route path="*" element={<Navigate to="/not_found" />} />
      </Route>
      <Route path="/not_found" element={<PageNotFound />} />
    </Routes>
  );
}

export default RoutesApp;
