import { useMutation , useQueryClient} from "react-query";
import axios from 'axios';

/********************************************* Enable product ****************************************/
const changeStatus = async (id) => {
  try {
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_API + "/api/v1/billing/product/enable/"+id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
  } catch (error) {
    throw error
  }    
}


export const useEnableProduct = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: changeStatus,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['get-products']})
        }
    })
}
