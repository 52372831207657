import React from 'react';
import {Box} from '@mui/material';
import FormMassiveEmission from '../components/FormMassiveEmission';
import './BillingPage.css'

const MassiveEmission = () => {
    return (
        <Box className="principal-box">
            <FormMassiveEmission/>
        </Box>
    );
}

export default MassiveEmission