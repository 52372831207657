import * as Yup from "yup";

const ValidationRegisterCustomer = Yup.object().shape({
  documento: Yup.string()
    .matches(/^[a-zA-Z0-9\\-]*$/, "El campo solo permite valores alfanuméricos")
    .max(15, "El campo permite un máximo de 15 caracteres")
    .required("El campo es requerido"),
  complemento: Yup.string()
    .matches(/^\S+$/, "El campo no permite espacios en blanco")
    .min(1, "El campo permite un minimo de 1 caracter")
    .max(2, "El campo permite un máximo de 2 caracteres"),
  razonSocial: Yup.string()
    .matches(/^\S/, "El campo solo permite espacios entre palabras")
    .max(240, "El campo permite un máximo de 240 caracteres")
    .required("El campo es requerido"),
  email: Yup.string().email("No es un correo valido"),
  type: Yup.string().required("El campos es requerido"),
  numCelular: Yup.string()
    .nullable()
    .matches(/^([0-9]{8})?$/, "El número de celular debe ser de 8 dígitos"),
});

export default ValidationRegisterCustomer;
