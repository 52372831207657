import { useQuery } from "react-query";
import axios from 'axios';

    const getUserId = async () => {
        try {
        const response = await axios({
            method: "get",
            url: process.env.REACT_APP_API + "/api/v1/auth/me",
            params: {
            accessToken: localStorage.getItem("accessToken"),
            },
        });
        return response.data.id;
        } catch (error) {
        console.log(error);
        }
    };

  const getCountBillingMinor = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billingMinor/count",
        params: {
          pointOfSaleId: localStorage.getItem("pointOfSaleId"),
          userId: await getUserId(),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response);
    }
  };  
  
  export const useCount = () => {
    return useQuery(['count'], 
    getCountBillingMinor);
  }
  