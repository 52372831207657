import React from "react";
import { Box } from "@mui/material";
import SystemSetting from "../components/SystemSetting";


const Setting = () => {
  return (
    <Box >
        <SystemSetting/>
    </Box>
  );
};

export default Setting;
