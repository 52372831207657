import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { Grid, Stack, Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import axios from "axios";
import SearchUser from "./SearchUser";
import ButtonGoBackSetting from "./ButtonGoBackSetting";
import ButtonRegisterUser from "./ButtonRegisterUser";
import ButtonDisableUser from "./ButtonDisableUser";
import ButtonEnableUser from "./ButtonEnableUser";
import ButtonEditUser from "./ButtonEditUser";
import ButtonViewPointOfSale from "./ButtonViewPointOfSale";
import { Link } from "react-router-dom";
import ButtonEnableViewPointOfSale from "./ButtonEnableViewPointOfSale";
import ButtonRegisterUserAdmin from "./ButtonRegisterUserAdmin";
import { RoleContext } from "../context/useRoleContext";
import { Tooltip } from 'react-tooltip';
import handleError from './utilities/HandleError';

// User List function.
const userList = async (page = 1, perPage = 10, search = '') => {
  try {
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_API + "/api/v1/user/all/users",
      params: {
        pageNumber: page,
        pageSize: perPage,
        search: search,
      },
      headers: {
        'content-type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });   
    const { data } = response;
    return data;
  } catch (error) {
    handleError(error);
    //return error.response.message;
    console.error('No se pudo recuperar la lista de usuarios:', error);
    throw new Error('No se pudo recuperar la lista de usuarios.');
  }
};

const DataTableUser = ({ searchUser }) => {
  const {rol} = useContext(RoleContext);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState(searchUser || '');
  const [errorMessage, setErrorMessage] = useState('');

  const { isLoading, isError, error, data } = useQuery(
    ["get-users", page, perPage, search],
    () => userList(page, perPage, search),
    {
      onSuccess: (data) => {
        setTotalRows(data.totalElements);
        setPage(data.pageNumber);
      },
      onError: (error) => {
        if (!errorMessage) {
          setErrorMessage(error.message);
        }
      },
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  if (isError) {
    return (
      <>
        {isError && <div>Error: {errorMessage}</div>}
      </>
    );
  }

  const columns = [
    {
      name: "Nro",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      id: 1,
      omit: (row, index) => index + 1,
    },
    {
      name: "Nombre",
      selector: (row) => <p data-tooltip-id="user-tooltip" data-tooltip-content={row.name}>{row.name}</p>,
      sortable: true,
      id: 2,
    },
    {
      name: "Correo electrónico",
      selector: (row) => <p data-tooltip-id="user-tooltip" data-tooltip-content={row.userName}>{row.userName}</p>,
      id: 3,
    },
    {
      name: "Rol",
      selector: (row) => <p data-tooltip-id="user-tooltip" data-tooltip-content={row.roleUser}>{row.roleUser}</p>,
      id: 4,
    },
    {
      name: "Empresa",
      selector: (row) => <p data-tooltip-id="user-tooltip" data-tooltip-content={row.nameBusiness}>{row.nameBusiness}</p>,
      id: 5,
    },
    {
      name: "Estado",
      selector: (row) => <p data-tooltip-id="user-tooltip" data-tooltip-content={row.statusAccounts}>{row.statusAccounts}</p>,
      id: 6,
    },
    {
      name: "PDV",
      button: true,
      cell: (row) =>
        row.status ? (
          <Link
            to={`${row.id}/${row.userName}/${row.businessId}`}
            style={{ textDecoration: "none" }}
          >
            <ButtonViewPointOfSale />
          </Link>
        ) : (
          <ButtonEnableViewPointOfSale />
        ),
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => <ButtonEditUser id={row.id} disableBtn={!row.status} />,
    },
    {
      name: "Inhabilitar",
      button: true, 
      cell: (row) =>
        row.id === 1 ? (
          ""
        ) : row.status ? (
          <>
            <ButtonDisableUser id={row.id} />
          </>
        ) : (
          <>
            <ButtonEnableUser id={row.id} />
          </>
        ),
    },
  ];

  const dataTable = data?.content.map((row) => {
    return {
      id: row.id,
      name: row.name,
      lastName: row.lastName,
      userName: row.userName,
      roleUser: row.roleUser,
      nameBusiness: row.nameBusiness,
      statusAccounts: row.statusAccounts,
      businessId: row.businessId,
      status: row.status,
    };
  });

  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <table
        style={{
          paddingLeft: "60px",
          fontSize: "14px",
          backgroundColor: "whitesmoke",
          with: "100%",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Nombre</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.name}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Apellido</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.lastName}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Nombre de usuario</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.userName}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Rol</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.roleUser}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Nombre empresa</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.nameBusiness}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Estado</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.statusAccounts}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }; 

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };  
  
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "whitesmok",
        fontSize: "13px",
        fontWeight: 600,
        color: "black",
      },
    },
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            Gestión de usuarios
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
          }}
        >
          <ButtonGoBackSetting />
          <ButtonRegisterUser />
          {rol === "SUPER_ADMIN" ? <ButtonRegisterUserAdmin /> : " "}
        </Grid>
        <Grid item xs={12} align="left">
          <SearchUser search={search} setSearch={setSearch} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={dataTable}
            customStyles={customStyles}
            fixedHeader={true}
            progressPending={isLoading}
            progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
            defaultSortFieldId={1}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            striped
            responsive
            pointerOnHover
            highlightOnHover
            selectableRowsSingle
            noDataComponent={
              <Stack
                height="50vh"
                width="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <h2
                  style={{
                    color: "gray",
                    fontFamily: "Oswald",
                    fontWeight: 200,
                    fontSize: "28px",
                  }}
                >
                  "No se encontró ningún registro"
                </h2>
              </Stack>
            }
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={paginationComponentOptions}
            visibleRowIndex
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
          <Tooltip id="user-tooltip" place='right'/>
      </Grid> 
    </>
  );
};
export default DataTableUser;
