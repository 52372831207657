import { useState } from "react";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import {Grid, Stack} from '@mui/material';
import axios from 'axios';
import ButtonRegisterProduct from "./ButtonRegisterProduct";
import SearchProductSiat from "./SearchProductSiat";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from 'react-tooltip';
import "./ButtonCloseProductModal.css";
import handleError from "./utilities/HandleError";

// Catalogue SIAT list.
const getListSiat = async (page = 1, perPage = 10, search = '') => {
  try {
    const response = await axios({
      method: "get",
      url:
        process.env.REACT_APP_API +
        "/api/v1/billing/parametric/catalogueSiatAll",
      params: {
        type: "PRODUCTOS_SERVICIO",
        pageNumber: page,
        pageSize: perPage,
        search: search,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    handleError(error, 'No se pudo recuperar la lista de productos homologados');
    console.error('No se pudo recuperar la lista de productos homologados:', error);
    throw new Error('No se pudo recuperar la lista de productos homologados.');
  }
};

const DataTableProductSiat = ({ searchProductSiat, handleClose, open }) => {
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState(searchProductSiat);
  const [errorMessage, setErrorMessage] = useState('');

  const { isLoading, isError, error, data } = useQuery(
    ["get-siat", page, perPage, search],
    () => getListSiat(page, perPage, search),
    {
      onSuccess: (data) => {
        setTotalRows(data.totalElements);
        setPage(data.pageNumber + 1);
      },
      onError: (error) => {
        if (!errorMessage) {
          setErrorMessage(error.message);
        }
      },
    }
  );

  if (isError) {
    return (
      <>
        {isError && <div>Error: {errorMessage}</div>}
      </>
    );
  }

  const columns = [
    {
      name: "Nro",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      id: 1,
      omit: (row, index) => index + 1,
    },
    {
      name: "Código actividad",
      selector: (row) => <p data-tooltip-id="homologado-tooltip" data-tooltip-content={row.codigoActividad}>{row.codigoActividad}</p>,
      sortable: true,
      id: 2,
    },
    {
      name: "Código producto",
      selector: (row) => <p data-tooltip-id="homologado-tooltip" data-tooltip-content={row.codigoProducto}>{row.codigoProducto}</p>,
      id: 3,
    },
    {
      name: "Descripción producto",
      selector: (row) => <p data-tooltip-id="homologado-tooltip" data-tooltip-content={row.descripcionProducto}>{row.descripcionProducto}</p>,
      id: 4,
      grow: 5,
    },
    {
      name: "Seleccionar",
      button: true,
      cell: (row) => <ButtonRegisterProduct list={row} />,
    },
  ];

  const dataTable = data?.content.map((product) => {
    return {
      codigoActividad: product.codigoActividad,
      codigoProducto: product.codigoProducto,
      descripcionProducto: product.descripcionProducto,
    };
  });
  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <table
        style={{
          paddingLeft: "60px",
          fontSize: "14px",
          backgroundColor: "whitesmoke",
          with: "100%",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "10px" }}>Código actividad</td>
            <td style={{ padding: "10px" }}>Código producto</td>
            <td style={{ padding: "10px" }}>Descripción producto</td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.codigoActividad}
            </td>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.codigoProducto}
            </td>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.descripcionProducto}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "whitesmok",
        fontSize: "13px",
        fontWeight: 600,
        color: "black",
      },
    },
  };  
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <button
            className="buttonClose"
            type="button"
            onClick={() => handleClose()}
          >
            <span className="buttonClose__text">Cerrar</span>
            <span className="buttonClose__textSmall">
              <CloseIcon sx={{ color: "white" }} />
            </span>
            <span className="buttonClose__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svgClose"
              >
                <CloseIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        </Grid>
        <Grid item xs={12} md={6} align="right">
          <SearchProductSiat
            search={search}
            setSearch={setSearch}
            open={open}
          />
        </Grid>
      </Grid>
      <DataTable
        columns={columns}
        data={dataTable}
        fixedHeader={true}
        customStyles={customStyles}
        fixedHeaderScrollHeight="560px"
        progressPending={isLoading}
        progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
        defaultSortFieldId={1}
        paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
        striped
        responsive
        pointerOnHover
        highlightOnHover
        selectableRowsSingle
        noDataComponent={
          <Stack
            height="50vh"
            width="100%"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
          >
            <h2
              style={{
                color: "gray",
                fontFamily: "Oswald",
                fontWeight: 200,
                fontSize: "28px",
              }}
            >
              "No se encontró ningún registro"
            </h2>
          </Stack>
        }
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        paginationComponentOptions={paginationComponentOptions}
        visibleRowIndex
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
      <Grid item xs={12}>
        <Tooltip id="homologado-tooltip" place='right'/>
      </Grid>  
    </>
  );
};
export default DataTableProductSiat;
