import ApartmentIcon from "@mui/icons-material/Apartment";
import './PanelCompany.css';
const PanelCompany = () => {
  return (
    <>
      <div className="card">
        <div className="card1">
          <p
            style={{ fontWeight: 400, fontFamily: "Oswald", fontSize: "24px", marginTop: "25px" }}
          >
            Empresas
          </p>
          <ApartmentIcon
            className="icon"
            sx={{
              width: "65px",
              height: "65px",
              color: "gray",
              marginTop: "5px",
            }}
          />
          <p className="small">
            Permite el registro, modificación y anulación de empresas.
          </p>
          <div className="go-corner" href="#">
            <div className="go-arrow">
              <ApartmentIcon sx={{ paddingTop: "5px" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PanelCompany;
