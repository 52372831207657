import { useState } from "react";
import Box from "@mui/material/Box";
import DataTableClient from "../components/DataTableClient";
import { CssBaseline } from "@mui/material";

const Client = () => {
  const [search, setSearch] = useState("");
  return (
    <Box>
        <CssBaseline/>
        <DataTableClient searchClient={search} />
    </Box>
  );
};

export default Client;
