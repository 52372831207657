import ValidationRegisterCompany from "../validation/ValidationRegisterCompany";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useRegisterCompany } from "../hooks/useRegisterCompany";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState, useEffect, useRef } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "./ButtonRegisterCompany.css";
import "./ButtonRegisterClient.css";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";
import dayjs from 'dayjs';

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { daysInWeek } from "date-fns";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonRegisterCompany = () => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [loader, setLoader] = useState(false);

  const selectInputRef = useRef(null);

  const handleChange = (event) => {
    formikRegisterCompany.setFieldValue(
      "uniPersonal",
      event.target.value === "true" ? true : false
    );
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSelectItem = (e) => {
    formikRegisterCompany.setFieldValue("departamentoEmpresa", e.value);
  };
  const handleSelectModality = (e) => {
    formikRegisterCompany.setFieldValue("modalidad", e.value);
  };

  const deperamentosBolivia = [
    { value: "LA_PAZ", label: "LA PAZ" },
    { value: "COCHABAMBA", label: "COCHABAMBA" },
    { value: "SANTA_CRUZ", label: "SANTA CRUZ" },
    { value: "ORURO", label: "ORURO" },
    { value: "POTOSI", label: "POTOSI" },
    { value: "PANDO", label: "PANDO" },
    { value: "CHUQUISACA", label: "CHUQUISACA" },
    { value: "TARIJA", label: "TARIJA" },
    { value: "BENI", label: "BENI" },
  ];
  const modality = [
    { value: "ELECTRONIC_ONLINE", label: "ELECTRONICA  EN LINEA" },
    { value: "COMPUTERIZED_ONLINE", label: "COMPUTARIZADA EN LINEA" },
  ];

  const handleOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    formikRegisterCompany.resetForm();
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const { mutate } = useRegisterCompany();

  const formikRegisterCompany = useFormik({
    initialValues: {
      nombreSistema: "",
      razonSocial: "",
      departamentoEmpresa: "",
      modalidad: "",
      email: "",
      telefono: "",
      nit: "",
      siatToken: "",
      uniPersonal: true,
      fechaInicio: undefined,
      fechaFin: undefined 
    },
    validationSchema: ValidationRegisterCompany,
    onSubmit: async (values) => {
      setLoader(true);
      values.fechaInicio = dayjs(values.fechaInicio).format("YYYY-MM-DD")
      values.fechaFin = dayjs(values.fechaFin).format("YYYY-MM-DD")
      mutate(values, {
        onSuccess: () => {
          handleClose();
          formikRegisterCompany.resetForm();
          Swal.fire({
            customClass: {
              container: "formRegisterCompany",
              title: "formTitleRegisterCompany",
            },
            icon: "success",
            title: "¡La empresa ha sido registrada exitosamente!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setLoader(false);
        },
        onError: (error) => {
          setLoader(false);
          if (error.response.status === 504) {
            Swal.fire({
              customClass: {
                container: "formRegisterCompany",
                title: "formTitleRegisterCompany",
              },
              icon: "error",
              title: "!Lo sentimos!",
              text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }          
          if (error.response.status === 500 || error.response.status === 503) {
            Swal.fire({
              customClass: {
                container: "formRegisterCompany",
                title: "formTitleRegisterCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 422) {
            Swal.fire({
              customClass: {
                container: "formRegisterCompany",
                title: "formTitleRegisterCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.errors.map(
                (element) => element.message
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 404) {
            Swal.fire({
              customClass: {
                container: "formRegisterCompany",
                title: "formTitleRegisterCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 403) {
            Swal.fire({
              customClass: {
                container: "formRegisterCompany",
                title: "formTitleRegisterCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 400) {
            Swal.fire({
              customClass: {
                container: "formRegisterCompany",
                title: "formTitleRegisterCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.response.status >= 0 && error.response.status <= 99) {
            Swal.fire({
              customClass: {
                container: "formRegisterCompany",
                title: "formTitleRegisterCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Compruebe su conexión a internet",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });
    },
  });

  return (
    <>
      <button
        className="buttonRegister"
        type="button"
        onClick={handleOpen("paper")}
      >
        <span className="buttonRegister__text">Empresa</span>
        <span className="buttonRegister__textSmall">
          <AddIcon sx={{ color: "white", borderRadius: "50%" }} />
        </span>
        <span className="buttonRegister__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgRegister"
          >
            <AddIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "green", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Registrar empresa
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikRegisterCompany.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Nombre de la empresa
                  </Typography>
                  <TextField
                    name="nombreSistema"
                    size="small"
                    value={formikRegisterCompany.values.nombreSistema}
                    onChange={formikRegisterCompany.handleChange}
                    onBlur={formikRegisterCompany.handleBlur}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikRegisterCompany.touched.nombreSistema &&
                      formikRegisterCompany.errors.nombreSistema}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Nombre representante
                  </Typography>
                  <TextField
                    name="razonSocial"
                    size="small"
                    value={formikRegisterCompany.values.razonSocial}
                    onChange={formikRegisterCompany.handleChange}
                    onBlur={formikRegisterCompany.handleBlur}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikRegisterCompany.touched.razonSocial &&
                      formikRegisterCompany.errors.razonSocial}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Fecha de inicio del contrato
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name='fechaInicio'
                        format="DD/MM/YYYY"
                        views={['day','month','year']}
                        value={formikRegisterCompany.values.fechaInicio}
                        onChange={(date) => formikRegisterCompany.setFieldValue('fechaInicio', date)}                        
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                        />
                  </LocalizationProvider>
                  <FormHelperText sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}>
                    {formikRegisterCompany.touched.fechaInicio &&
                      formikRegisterCompany.errors.fechaInicio}                      
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Fecha final del contrato
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name='fechaFin'
                        format="DD/MM/YYYY"
                        views={['day','month','year']}                   
                        value={formikRegisterCompany.values.fechaFin}
                        onChange={(date) => formikRegisterCompany.setFieldValue('fechaFin', date)}  
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                        />
                  </LocalizationProvider>                  
                  <FormHelperText sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}>
                    {formikRegisterCompany.touched.fechaFin &&
                      formikRegisterCompany.errors.fechaFin}                        
                  </FormHelperText>
                </FormControl>
              </Grid>              
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">NIT</Typography>
                  <TextField
                    name="nit"
                    size="small"
                    value={formikRegisterCompany.values.nit}
                    onChange={formikRegisterCompany.handleChange}
                    onBlur={formikRegisterCompany.handleBlur}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikRegisterCompany.touched.nit &&
                      formikRegisterCompany.errors.nit}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Correo electrónico
                  </Typography>
                  <TextField
                    name="email"
                    size="small"
                    value={formikRegisterCompany.values.email}
                    onChange={formikRegisterCompany.handleChange}
                    onBlur={formikRegisterCompany.handleBlur}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikRegisterCompany.touched.email &&
                      formikRegisterCompany.errors.email}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">Teléfono</Typography>
                  <TextField
                    name="telefono"
                    size="small"
                    value={formikRegisterCompany.values.telefono}
                    onChange={formikRegisterCompany.handleChange}
                    onBlur={formikRegisterCompany.handleBlur}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikRegisterCompany.touched.telefono &&
                      formikRegisterCompany.errors.telefono}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">Modalidad</Typography>
                  <Select
                    name="modalidad"
                    menuPosition="fixed"
                    placeholder="Seleccionar..."
                    ref={selectInputRef}
                    styles={CustomDropdownStyle}
                    options={modality}
                    onChange={(selectedOption) =>
                      handleSelectModality(selectedOption)
                    }
                    onBlur={formikRegisterCompany.handleBlur}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikRegisterCompany.touched.modalidad &&
                      formikRegisterCompany.errors.modalidad}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Departamento
                  </Typography>
                  <Select
                    name="departamentoEmpresa"
                    menuPosition="fixed"
                    placeholder="Seleccionar..."
                    ref={selectInputRef}
                    styles={CustomDropdownStyle}
                    options={deperamentosBolivia}
                    onChange={(selectedOption) =>
                      handleSelectItem(selectedOption)
                    }
                    onBlur={formikRegisterCompany.handleBlur}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikRegisterCompany.touched.departamentoEmpresa &&
                      formikRegisterCompany.errors.departamentoEmpresa}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Empresa unipersonal
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="uniPersonal"
                    value={formikRegisterCompany.values.uniPersonal}
                    onChange={(e) => handleChange(e)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">Token</Typography>
                  <TextField
                    name="siatToken"
                    size="small"
                    value={formikRegisterCompany.values.siatToken}
                    onChange={formikRegisterCompany.handleChange}
                    onBlur={formikRegisterCompany.handleBlur}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikRegisterCompany.touched.siatToken &&
                      formikRegisterCompany.errors.siatToken}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
                <button className="buttonRegisterData" type="submit">
                  <span className="buttonRegisterData">Registrar</span>
                </button>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonRegisterCompany;
