import { useMutation, useQueryClient } from "react-query";
import axios from 'axios';

  /********************************************* Eliminar de cliente ****************************************/
  const disableClient = async (id) => {
    try {
      const response = await axios({
        method: "delete",
        url: process.env.REACT_APP_API + "/api/v1/billing/client/delete/" + id,
        headers: {
          "content-type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
    } catch (error) {
      throw error
    }
  };

export const useDisableClient = () =>{
  const queryClient = useQueryClient();
    return useMutation({
      mutationFn: disableClient,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get-clients"] });
      },
    });
} 
