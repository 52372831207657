import React from 'react'
import RegisterParametrics from '../components/RegisterParametrics';

const Parametrics = () => {
    return (
        <>
          <RegisterParametrics/>
        </>      
      );
}

export default Parametrics