import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { useFormik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import axios from "axios";
import ValidationUserAdmin from "../validation/ValidationUserAdmin";
import { useRegisterUserAdmin } from "../hooks/useRegisterUserAdmin";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./ButtonRegisterUserAdmin.css";
import "./ButtonAdmin.css";
import handleError from './utilities/HandleError';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonRegisterUserAdmin = () => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [puntosVenta, setPuntosVenta] = useState([]);
  const [business, setBusiness] = useState([]);
  const [loader, setLoader] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpen = (scrollType) => () => {
    listarBusiness();
    getPuntosVenta();
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    formikRegisterUser.resetForm();
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const docSector = [
    {
      label: "FACTURA CON DERECHO A CREDITO FISCAL",
      value: 1,
    },
  ];
  const tipFactura = [
    {
      label: "FACTURA COMPRA-VENTA",
      value: 1,
    },
  ];

  const handleChangeBusiness = (e) => {
    formikRegisterUser.setFieldValue("type1", e.value);
    getPuntosVenta(e.value);
  };

  const handleChangeSector = (e) => {
    formikRegisterUser.setFieldValue("type3", e.value);
  };
  const handleChangeDocumento = (e) => {
    formikRegisterUser.setFieldValue("type4", e.value);
  };
  const listarBusiness = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/business/all",
        params: {
          pageNumber: 1,
          pageSize: 10000,
          search: "",
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setBusiness(
        response.data.content.map((sup) => ({
          label: sup.nombreSistema,
          value: sup.id,
        }))
      );
    } catch (error) {
      handleError(error);
    }
  };

  const getPuntosVenta = async (id) => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/pointOfSale/listPointOfSales",
        params: {
          businessId: localStorage.getItem("businessId"),
          pageNumber: 1,
          pageSize: 10000,
          search: "",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setPuntosVenta(
        response.data.content.map((sup) => ({
          label: sup.nombrePuntoVenta,
          value: sup.id,
        }))
      );
    } catch (error) {
      handleError(error);
    }
  };

  const { mutate } = useRegisterUserAdmin();
  const formikRegisterUser = useFormik({
    initialValues: {
      name: "",
      lastName: "",
      username: "",
      type1: "",
      type3: "",
      type4: "",
    },
    validationSchema: ValidationUserAdmin,
    onSubmit: async (data) => {
      setLoader(true);
      data.name = data.name.trim();
      data.lastName = data.lastName.trim();
      data.username = data.username.trim();
      data.businessId = parseInt(data.type1);
      data.codigoDocumentoSector = parseInt(data.type3);
      data.tipoFacturaDocumento = parseInt(data.type4);
      mutate(data, {
        onSuccess: () => {
          handleClose();
          formikRegisterUser.resetForm();
          Swal.fire({
            customClass: {
              container: "formRegisterUserAdmin",
              title: "formTitleRegisterUserAdmin",
            },
            icon: "success",
            title: "¡El usuario ha sido registrado!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setLoader(false);
        },
        onError: (error) => {
          setLoader(false);
          if (error.request.status >= 500 && error.response.status <= 599) {
            Swal.fire({
              customClass: {
                container: "formRegisterUserAdmin",
                title: "formTitleRegisterUserAdmin",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 422) {
            Swal.fire({
              customClass: {
                container: "formRegisterUserAdmin",
                title: "formTitleRegisterUserAdmin",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.errors.map(
                (element) => element.message
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 404) {
            Swal.fire({
              customClass: {
                container: "formRegisterUserAdmin",
                title: "formTitleRegisterUserAdmin",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 400) {
            Swal.fire({
              customClass: {
                container: "formRegisterUserAdmin",
                title: "formTitleRegisterUserAdmin",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status >= 0 && error.request.status <= 99) {
            Swal.fire({
              customClass: {
                container: "formRegisterUserAdmin",
                title: "formTitleRegisterUserAdmin",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Compruebe su conexión a internet",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });
    },
  });

  return (
    <>
      <button
        className="buttonAdmin"
        type="button"
        onClick={handleOpen("paper")}
      >
        <span className="buttonAdmin__text">Admin</span>
        <span className="buttonAdmin__textSmall">
          <AddIcon sx={{ color: "white", borderRadius: "50%" }} />
        </span>
        <span className="buttonAdmin__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgAdmin"
          >
            <AddIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "green", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Registrar usuario administrador
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikRegisterUser.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">Nombre</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="name"
                    onBlur={formikRegisterUser.handleBlur}
                    onChange={formikRegisterUser.handleChange}
                    value={formikRegisterUser.values.name}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterUser.touched.name &&
                      formikRegisterUser.errors.name}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Apellido(s)
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="lastName"
                    onBlur={formikRegisterUser.handleBlur}
                    onChange={formikRegisterUser.handleChange}
                    value={formikRegisterUser.values.lastName}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterUser.touched.lastName &&
                      formikRegisterUser.errors.lastName}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Correo electrónico
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="username"
                    onBlur={formikRegisterUser.handleBlur}
                    onChange={formikRegisterUser.handleChange}
                    value={formikRegisterUser.values.username}
                    variant="outlined"
                    type="email"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterUser.touched.username &&
                      formikRegisterUser.errors.username}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ zIndex: 99 }}>
                <Typography className="labelFormClient">
                  Seleccione una empresa
                </Typography>
                <Select
                  menuPosition="fixed"
                  styles={CustomDropdownStyle}
                  placeholder="Seleccionar..."
                  options={business}
                  onChange={(e) => {
                    handleChangeBusiness(e);
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="type1"
                  onBlur={formikRegisterUser.handleBlur}
                  onChange={formikRegisterUser.handleChange}
                  value={formikRegisterUser.values.type1}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {formikRegisterUser.values.type1 === "" &&
                    formikRegisterUser.touched.type1 &&
                    formikRegisterUser.errors.type1}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12} sx={{ zIndex: 9 }}>
                <Typography className="labelFormClient">
                  Seleccione un documento sector
                </Typography>
                <Select
                  menuPosition="fixed"
                  styles={CustomDropdownStyle}
                  placeholder="Seleccionar..."
                  options={docSector}
                  onChange={(e) => {
                    handleChangeSector(e);
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="Número de documento"
                  size="small"
                  fullWidth
                  name="type3"
                  onBlur={formikRegisterUser.handleBlur}
                  onChange={formikRegisterUser.handleChange}
                  value={formikRegisterUser.values.type3}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {formikRegisterUser.values.type3 === "" &&
                    formikRegisterUser.touched.type3 &&
                    formikRegisterUser.errors.type3}
                </FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12} sx={{ zIndex: 1 }}>
                <Typography className="labelFormClient">
                  Seleccione el tipo de factura documento
                </Typography>
                <Select
                  menuPosition="fixed"
                  styles={CustomDropdownStyle}
                  placeholder="Seleccionar..."
                  options={tipFactura}
                  onChange={(e) => {
                    handleChangeDocumento(e);
                  }}
                />
              </Grid>
              <FormControl fullWidth>
                <TextField
                  sx={{ display: "none" }}
                  variant="outlined"
                  label="Número de documento"
                  size="small"
                  fullWidth
                  name="type4"
                  onBlur={formikRegisterUser.handleBlur}
                  onChange={formikRegisterUser.handleChange}
                  value={formikRegisterUser.values.type4}
                />
                <FormHelperText
                  sx={{
                    color: "chocolate",
                    fontSize: "14px",
                    marginLeft: "30px",
                  }}
                >
                  {formikRegisterUser.values.type4 === "" &&
                    formikRegisterUser.touched.type4 &&
                    formikRegisterUser.errors.type4}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
                <button className="buttonRegisterData" type="submit">
                  <span className="buttonRegisterData">Registrar</span>
                </button>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonRegisterUserAdmin;
