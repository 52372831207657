import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Swal from "sweetalert2";
import React, { useState } from "react";
import axios from "axios";
import "./ButtonEditClient.css";
import "./ButtonLoaderTable.css";
import handleError from "./utilities/HandleError";

const ButtonValidateCuf = ({ id, refetch }) => {
  const [loader, setLoader] = useState(false);

  const validateCuf = async (ide) => {
    setLoader(true);
    try {
      const response = await axios({
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/cufObservation/validation/" +
          ide,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        Swal.fire({
          customClass: {
            container: "formEditCompany",
            title: "formTitleEditCompany",
          },
          icon: "success",
          title: "¡La validación a sido completada!",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        (function () {
          refetch();
        })();
      }
    } catch (error) {
      handleError(error, error.response.data.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {loader ? (
        <button className="buttonLoaderTable" style={{ width: "100px" }}>
          <div className="loader">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            <div className="bar4"></div>
            <div className="bar5"></div>
            <div className="bar6"></div>
            <div className="bar7"></div>
            <div className="bar8"></div>
            <div className="bar9"></div>
            <div className="bar10"></div>
            <div className="bar11"></div>
            <div className="bar12"></div>
          </div>
        </button>
      ) : (
        <button
          style={{ width: "100px" }}
          className="buttonEdit"
          type="button"
          onClick={() => validateCuf(id)}
        >
          <span className="buttonEdit__text">Verificar</span>
          <span className="buttonEdit__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              viewBox="0 0 48 48"
              height="48"
              className="svgEdit"
            >
              <CheckCircleIcon sx={{ color: "white" }} />
            </svg>
          </span>
        </button>
      )}
    </>
  );
};

export default ButtonValidateCuf;
