import { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import ButtonCancelCUF from "./ButtonCancelCUF";
import SearchClient from "./SearchClient";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import "./RegisterClient.css";
import "./SearchClient.css";
import ButtonValidateCuf from "./ButtonValidateCuf";
import dayjs from "dayjs";

const DataTableCUF = () => {
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API +
          "/api/v1/billing/cufObservation/list?pageNumber=" +
          page +
          "&pageSize=" +
          perPage +
          "&sortField=created_at&sortOrder=DESC&search=" +
          search,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      setData(response.data);
      setTotalRows(response.data.totalElements);
      setPage(response.data.pageNumber);
      setIsLoading(false);
      setError(null); // Clear any previous errors if request is successful
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  // Function to return custom messages based on the value of modalidad.
  const getCustomModality = (modalidad) => {
    switch (modalidad) {
      case 'COMPUTERIZED_ONLINE':
        return 'Computarizada';
      case 'ELECTRONIC_ONLINE':
        return 'Electrónica';
      default:
        return 'No asignado';
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search]);
  
  const refetch = () => {
    setIsLoading(true);
    fetchData();
  };

  const columns = [
    {
      name: "Nro",
      selector: (row) => row.id,
      id: 1,
      omit: (row) => row.id,
    },
    {
      name: "Código de autorización",
      selector: (row) => (
        <p data-tooltip-id="cuf-tooltip" data-tooltip-content={row.cuf}>
          {row.cuf}
        </p>
      ),
      width: "200px",
      id: 2,
    },
    {
      name: "Link QR",
      selector: (row) => (
        <a href={row.linkQr} target="blank">
          {" "}
          QR
        </a>
      ),
      width: "100px",
      id: 3,
    },
    {
      name: "Código de descripción",
      selector: (row) => (
        <p
          data-tooltip-id="cuf-tooltip"
          data-tooltip-content={row.codigoDescripcion}
        >
          {row.codigoDescripcion}
        </p>
      ),
      width: "200px",
      id: 4,
    },
    {
      name: "Código de estado",
      selector: (row) => (
        <p
          data-tooltip-id="cuf-tooltip"
          data-tooltip-content={row.codigoEstado}
        >
          {row.codigoEstado}
        </p>
      ),
      width: '150px',
      id: 5,
    },
    {
      name: "NIT",
      selector: (row) => (
        <p data-tooltip-id="cuf-tooltip" data-tooltip-content={row.businessNit}>
          {row.businessNit}
        </p>
      ),
      width: '150px',
      id: 6,
    },
    {
      name: "Código de punto de venta",
      selector: (row) => (
        <p
          data-tooltip-id="cuf-tooltip"
          data-tooltip-content={row.codigoPuntoVenta}
        >
          {row.codigoPuntoVenta}
        </p>
      ),
      width: '200px',
      id: 7,
    },
    {
      name: "Código de sucursal",
      selector: (row) => (
        <p
          data-tooltip-id="cuf-tooltip"
          data-tooltip-content={row.codigoSucursal}
        >
          {row.codigoSucursal}
        </p>
      ),
      width: '180px',
      id: 8,
    },
    {
      name: "Modalidad",
      selector: (row) => (
        <p data-tooltip-id="cuf-tooltip" data-tooltip-content={getCustomModality(row.modalidad)}>
          {getCustomModality(row.modalidad)}
        </p>
      ),
      width: "200px",
      id: 9,
    },
    {
      name: "Fecha",
      selector: (row) => (
        <p data-tooltip-id="cuf-tooltip" data-tooltip-content={dayjs(row.createdAt).format('YYYY-MM-D, h:mm A')}>
          {dayjs(row.createdAt).format('YYYY-MM-D, h:mm A')}
        </p>
      ),
      width: "200px",
      id: 10,
    },
    {
      name: "Verificar",
      button: true,
      cell: (row) =>
        row.verified === true ? (
          ""
        ) : (
          <p data-tooltip-id="cuf-tooltip" data-tooltip-content={"Verificar"}>
             <ButtonValidateCuf id={row.id} refetch={refetch}/>
          </p>
        ),
    },
    {
      name: "Anular",
      button: true,
      cell: (row) =>
        row.verified === false ? "" : <ButtonCancelCUF id={row.id} />,
    },
  ];

  const dataTable = data?.content.map((element) => {
    return {
      id: element.id,
      cuf: element.cuf,
      linkQr: element.linkQr,
      codigoDescripcion: element.codigoDescripcion,
      codigoEstado: element.codigoEstado,
      mensajesList: element.mensajesList,
      businessNit: element.businessNit,
      codigoPuntoVenta: element.codigoPuntoVenta,
      codigoSucursal: element.codigoSucursal,
      modalidad: element.modalidad,
      status: element.status,
      createdAt: element.createdAt,
      verified: element.verified,
    };
  });
  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <table
        style={{
          paddingLeft: "60px",
          fontSize: "14px",
          backgroundColor: "whitesmoke",
          with: "100%",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Código CUF</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.cuf}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Link QR</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              <a href={data.linkQr} target="blank">
                {" "}
                QR
              </a>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Código de descripción</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.codigoDescripcion}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Código de estado</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.codigoEstado}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>NIT</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.businessNit}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Código de punto de venta</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.codigoPuntoVenta}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Código sucursal</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.codigoSucursal}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Modalidad</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {getCustomModality(data.modalidad)}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Fecha de creación</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.createdAt}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "whitesmok",
        fontSize: "13px",
        fontWeight: 600,
        color: "black",
      },
    },
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className="titleClient"
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            CUF observados
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} align="left">
          <SearchClient search={search} setSearch={setSearch} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={dataTable}
            fixedHeader={true}
            customStyles={customStyles}
            progressPending={isLoading}
            progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
            defaultSortFieldId={1}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            striped
            responsive
            pointerOnHover
            highlightOnHover
            selectableRowsSingle
            noDataComponent={
              <Stack
                height="50vh"
                width="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <h2
                  style={{
                    color: "gray",
                    fontFamily: "Oswald",
                    fontWeight: 200,
                    fontSize: "28px",
                  }}
                >
                  "No se encontró ningún registro"
                </h2>
              </Stack>
            }
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={paginationComponentOptions}
            visibleRowIndex
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Tooltip id="cuf-tooltip" place="right" />
      </Grid>
    </>
  );
};
export default DataTableCUF;
