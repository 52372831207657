import { Box } from "@mui/material";
import DataTableMinorBilling from "../components/DataTableMinorBilling";

const MinorBilling = () => {
  return (
    <Box>
        <DataTableMinorBilling sx={{backgroundColor: 'red'}}/>
    </Box>
  );
};

export default MinorBilling;
