import { useMutation, useQueryClient } from "react-query";
import axios from 'axios';


const savePointOfSale = async (payload) => {
    const {data} = await axios.post(
        process.env.REACT_APP_API + "/api/v1/billing/pointOfSale/register",payload,
        {
            headers: {
                "content-type": "application/json;charset=utf-8",
                 Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
        }
    );
    return data;
}

export const useRegisterPointOfSale = () => {
    const queryClient = useQueryClient();
    return useMutation(savePointOfSale,{
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["get-pov"] });
        }
    });
}