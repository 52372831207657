import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import {
  Grid,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomDropdownPOV from "./style/CustomDropdownPOV";
import ButtonCancelBilling from "./ButtonCancelBilling";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonShowBilling from "./ButtonShowBilling";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import SearchBilling from "./SearchBilling";
import { Tooltip } from "react-tooltip";
import { useQuery } from "react-query";
import Select from "react-select";
import "./ButtonEnableClient.css";
import "./ButtonShowBilling.css";
import "./DataTableShowBilling.css";
import dayjs from "dayjs";
import axios from "axios";
import handleError from './utilities/HandleError';

// Get List Billing.
const getListBilling = async (
  page = 1,
  perPage = 10,
  search = '',
  selectSucursal = '',
  fecha = ''
) => {
  try {
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_API + "/api/v1/billing/listPageable",
      params: {
        pointOfSaleId: parseInt(localStorage.getItem("pointOfSaleId")),
        pageNumber: page,
        pageSize: perPage,
        search: search,
        sucursalId: selectSucursal === "" ? null : selectSucursal,
        year: fecha,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    handleError(error, 'No se pudo recuperar la lista de facturas.');
    console.error('No se pudo recuperar la lista de facturas:', error);
    throw new Error('No se pudo recuperar la lista de facturas.');
  }
};

const DataTableShowBilling = ({ searchProduct, setSwitchBtn, switchBtn }) => {
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState(searchProduct || '');
  const [fecha, setFecha] = useState(dayjs(Date.now()).year().toString());
  const [sucursales, setSucursales] = useState([]);
  const [selectSucursal, setSelectSucursal] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleHideCanceledBilling = () => {
    setSwitchBtn(!switchBtn);
  };
  const handleHideIssuedBilling = () => {
    setSwitchBtn(!switchBtn);
  };
  const handleSelectSucursal = (e) => {
    setSelectSucursal(e.value);
  };
  const handleSelectDate = (e) => {
    setFecha(dayjs(e).year().toString());
  };

  useEffect(() => {
    getSucursales();
  }, []);

  const getSucursales = async () => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API + "/api/v1/billing/pointOfSale/listBranch",
        headers: {
          'Content-Type': "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const nuevasSucursales = response.data.map((element) => ({
        label: element.nombreSucursal,
        value: element.sucursalId,
      }));
      nuevasSucursales.push({ label: "Todas las sucursales", value: "" });
      setSucursales(nuevasSucursales);
    } catch (error) {
      handleError(error);
    }
  };

  const { isLoading, isError, error, data } = useQuery(
    ["get-billings", page, perPage, search, selectSucursal, fecha],
    () => getListBilling(page, perPage, search, selectSucursal, fecha),
    {
      onSuccess: (data) => {
        setTotalRows(data.totalElements);
        setPage(data.pageNumber);
      },
      onError: (error) => {
        if (!errorMessage) {
          setErrorMessage(error.message);
        }
      },
    }
  );

  if (isError) {
    return (
      <>
        {isError && <div>Error: {errorMessage}</div>}
      </>
    );
  }

  const columns = [
    {
      name: "Nro",
      selector: (row, index) => index + 1,
      id: 1,
      omit: (row, index) => index + 1,
    },
    {
      name: "Nro. factura",
      selector: (row) => (
        <p
          data-tooltip-id="billing-tooltip"
          data-tooltip-content={row.numBilling}
        >
          {row.numBilling}
        </p>
      ),
      id: 2,
    },
    {
      name: "Documento",
      selector: (row) => (
        <p
          data-tooltip-id="billing-tooltip"
          data-tooltip-content={row.clientDocument}
        >
          {row.clientDocument}
        </p>
      ),
      id: 3,
    },
    {
      name: "Razón social",
      selector: (row) => (
        <p
          data-tooltip-id="billing-tooltip"
          data-tooltip-content={row.razonSocial}
        >
          {row.razonSocial}
        </p>
      ),
      id: 4,
    },
    {
      name: "Usuario",
      selector: (row) => (
        <p
          data-tooltip-id="billing-tooltip"
          data-tooltip-content={row.fullNameUser}
        >
          {row.fullNameUser}
        </p>
      ),
      id: 5,
    },
    {
      name: "Sucursal",
      selector: (row) => (
        <p
          data-tooltip-id="billing-tooltip"
          data-tooltip-content={row.sucursal}
        >
          {row.sucursal}
        </p>
      ),
      id: 6,
    },
    {
      name: "Fecha",
      selector: (row) => (
        <p
          data-tooltip-id="billing-tooltip"
          data-tooltip-content={row.fechaEmision}
        >
          {row.fechaEmision}
        </p>
      ),
      id: 7,
    },
    {
      name: "Hora",
      selector: (row) => (
        <p data-tooltip-id="billing-tooltip" data-tooltip-content={row.hora}>
          {row.hora}
        </p>
      ),
      id: 8,
    },
    {
      name: "Ver factura",
      button: true,
      cell: (row) => <ButtonShowBilling id={row.cuf} />,
    },
    {
      name: "Anular",
      button: true,
      cell: (row) => <ButtonCancelBilling billing={row} />,
    },
  ];

  const dataTable = data?.content.map((element) => {
    return {
      id: element.id,
      numBilling: element.numBilling,
      clientDocument: element.clientDocument,
      razonSocial: element.razonSocial,
      fechaEmision: element.fechaEmision.substring(0, 10),
      hora: element.fechaEmision.substring(11, 19),
      montoTotal: Number(element.montoTotal).toFixed(2),
      fullNameUser: element.fullNameUser,
      sucursal: element.sucursal,
      cuf: element.cuf,
    };
  });
  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <table
        style={{
          paddingLeft: "60px",
          fontSize: "14px",
          backgroundColor: "whitesmoke",
          with: "100%",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Número de factura</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.numBilling}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Número de documento</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.clientDocument}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Razón social</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.razonSocial}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Monto total Bs.</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.montoTotal}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Fecha</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.fechaEmision}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Usuario</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.fullNameUser}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Sucursal</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.sucursal}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Hora</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.hora}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: "13px",
        fontWeight: 600,
        color: "black",
      },
    },
    headRow: {
      zIndex: 1, // Adjust this value as needed
      backgroundColor: "red",
    },
    
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className="titleBilling"
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            Facturas emitidas
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          className="containerButtonsBilling"
        >
          <button
            disabled={switchBtn}
            className="buttonEnable"
            type="button"
            onClick={() => handleHideCanceledBilling()}
          >
            <span className="buttonEnable__text">Facturas emitidas</span>
            <span className="buttonEnable__textSmall">
              <ReceiptLongIcon sx={{ color: "white" }} />
            </span>
            <span className="buttonEnable__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svgEnable"
              >
                <DeleteIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
          <button
            disabled={!switchBtn}
            className="buttonShow"
            type="button"
            onClick={() => handleHideIssuedBilling()}
          >
            <span className="buttonShow__text">Facturas anuladas</span>
            <span className="buttonShow__textSmall">
              <DeleteIcon sx={{ color: "white" }} />
            </span>
            <span className="buttonShow__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                viewBox="0 0 48 48"
                height="48"
                className="svgShow"
              >
                <DeleteIcon sx={{ color: "white" }} />
              </svg>
            </span>
          </button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              marginLeft: "0px",
              marginRight: "20px",
            }}
          >
            <Typography sx={{ color: "gray", fontFamily: "Oswald" }}>
              Seleccione una sucursal
            </Typography>
          </Box>
          <Box className="containerSucursal">
            <Select
              menuPosition="fixed"
              className={"my-custom-react-select"}
              styles={CustomDropdownPOV}
              options={sucursales}
              placeholder={"Todas las sucursales"}
              onChange={(selectedOption) =>
                handleSelectSucursal(selectedOption)
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ width: "100%" }}>
            <div>
              <Typography
                className=""
                sx={{ color: "gray", fontFamily: "Oswald" }}
              >
                Seleccione el año
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture={true}
                  onChange={(e) => handleSelectDate(e)}
                  views={["year"]}
                  slotProps={{
                    textField: {
                      sx: { width: "100%" },
                      size: "small",
                      placeholder: dayjs(Date.now()).year().toString(),
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} align="right">
          <Box className="containerSearch">
            <SearchBilling search={search} setSearch={setSearch} />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={dataTable}
            customStyles={customStyles}
            fixedHeader={true}
            progressPending={isLoading}
            progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
            defaultSortFieldId={1}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            striped
            responsive
            pointerOnHover
            highlightOnHover
            selectableRowsSingle
            noDataComponent={
              <Stack
                height="50vh"
                width="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <h2
                  style={{
                    color: "gray",
                    fontFamily: "Oswald",
                    fontWeight: 200,
                    fontSize: "28px",
                  }}
                >
                  "No se encontró ningún registro"
                </h2>
              </Stack>
            }
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={paginationComponentOptions}
            visibleRowIndex
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Tooltip id="billing-tooltip" place="right" />
      </Grid>
    </>
  );
};
export default DataTableShowBilling;
