import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import SendIcon from "@mui/icons-material/Send";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import axios from "axios";
import "./ButtonSendContingencia.css";
import handleError from "./utilities/HandleError";

const DataTableContingencia = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getContingencia = async () => {
    setIsLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/pendingBillingList",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setData(response.data);
      return data;
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getContingencia();
  }, []);

  const columns = [
    {
      name: "Nro",
      selector: (row) => row.id,
      id: 1,
      omit: (row) => row.id,
    },
    {
      name: "Nro. factura",
      selector: (row) => (
        <p
          data-tooltip-id="contingencia-tooltip"
          data-tooltip-content={row.numBilling}
        >
          {row.numBilling}
        </p>
      ),
      id: 2,
      width: "130px",
    },
    {
      name: "Documento",
      selector: (row) => (
        <p
          data-tooltip-id="contingencia-tooltip"
          data-tooltip-content={row.clientDocument}
        >
          {row.clientDocument}
        </p>
      ),
      id: 3,
    },
    {
      name: "Razón social",
      selector: (row) => (
        <p
          data-tooltip-id="contingencia-tooltip"
          data-tooltip-content={row.razonSocial}
        >
          {row.razonSocial}
        </p>
      ),
      id: 4,
    },
    {
      name: "Usuario",
      selector: (row) => (
        <p
          data-tooltip-id="contingencia-tooltip"
          data-tooltip-content={row.fullNameUser}
        >
          {row.fullNameUser}
        </p>
      ),
      id: 5,
    },
    {
      name: "Sucursal",
      selector: (row) => (
        <p
          data-tooltip-id="contingencia-tooltip"
          data-tooltip-content={row.sucursal}
        >
          {row.sucursal}
        </p>
      ),
      id: 6,
    },
    {
      name: "Fecha",
      selector: (row) => (
        <p
          data-tooltip-id="contingencia-tooltip"
          data-tooltip-content={row.fechaEmision}
        >
          {row.fechaEmision}
        </p>
      ),
      id: 7,
    },
    {
      name: "Hora",
      selector: (row) => (
        <p
          data-tooltip-id="contingencia-tooltip"
          data-tooltip-content={row.hora}
        >
          {row.hora}
        </p>
      ),
      id: 8,
    },
  ];

  const dataTable = data?.map((element) => {
    return {
      id: element.id,
      numBilling: element.numBilling,
      clientDocument: element.clientDocument,
      razonSocial: element.razonSocial,
      fullNameUser: element.fullNameUser,
      sucursal: element.sucursal,
      fechaEmision: element.fechaEmision.substring(0, 10),
      hora: element.fechaEmision.substring(11, 19),
    };
  });

  const ExpandedComponent = ({ data }) => {
    return (
      <table
        style={{
          paddingLeft: "60px",
          fontSize: "14px",
          backgroundColor: "whitesmoke",
          with: "100%",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Número de factura</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.numBilling}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Número de documento</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.clientDocument}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Razón social</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.razonSocial}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Monto total</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.montoTotal}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Fecha</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.fechaEmision}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Usuario</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.fullNameUser}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Sucursal</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.sucursal}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <strong>Hora</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", backgroundColor: "white" }}>
              {data.hora}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "whitesmok",
        fontSize: "13px",
        fontWeight: 600,
        color: "black",
      },
    },
  };

  const handleSubmit = async () => {
    setLoader(true);
    try {
      const response = await axios({
        url:
          process.env.REACT_APP_API + "/api/v1/billing/receiptPackageInvoice",
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      if (response.status === 200) {
        Swal.fire({
          customClass: {
            container: "formRegisterClient",
            title: "formTitleRegisterClient",
          },
          icon: "success",
          title: "¡El paquete ha sido enviado correctamente!",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        setLoader(false);
      }
      getContingencia();
    } catch (error) {
      setLoader(false);
      handleError(error, null);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item>
          {data.length > 0 ? (
            loader ? (
              <button className="buttonLoader" style={{ width: "150px" }}>
                <div className="loader">
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                  <div className="bar3"></div>
                  <div className="bar4"></div>
                  <div className="bar5"></div>
                  <div className="bar6"></div>
                  <div className="bar7"></div>
                  <div className="bar8"></div>
                  <div className="bar9"></div>
                  <div className="bar10"></div>
                  <div className="bar11"></div>
                  <div className="bar12"></div>
                </div>
              </button>
            ) : (
              <button
                className="buttonSendLoader"
                type="button"
                onClick={() => handleSubmit()}
              >
                <span className="buttonSendLoader__text">Enviar</span>
                <span className="buttonSendLoader__textSmall">
                  <SendIcon sx={{ color: "white", borderRadius: "50%" }} />
                </span>
                <span className="buttonSendLoader__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    viewBox="0 0 48 48"
                    height="48"
                    className="svgSendLoader"
                  >
                    <SendIcon sx={{ color: "white" }} />
                  </svg>
                </span>
              </button>
            )
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={dataTable}
            customStyles={customStyles}
            fixedHeader={true}
            progressPending={isLoading}
            progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
            defaultSortFieldId={1}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            striped
            responsive
            pointerOnHover
            highlightOnHover
            selectableRowsSingle
            noDataComponent={
              <Stack
                height="50vh"
                width="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <h2
                  style={{
                    color: "gray",
                    fontFamily: "Oswald",
                    fontWeight: 200,
                    fontSize: "28px",
                  }}
                >
                  "No se encontró ningún registro"
                </h2>
              </Stack>
            }
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={paginationComponentOptions}
            visibleRowIndex
            pagination
            paginationServer
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Tooltip id="contingencia-tooltip" place="right" />
      </Grid>
    </>
  );
};

export default DataTableContingencia;
