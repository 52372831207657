import { useState } from "react";
import { useUpdateList } from "../hooks/useSendMinorBilling";
import SendIcon from "@mui/icons-material/Send";
import Swal from "sweetalert2";
import "./ButtonLoaderPhone.css";

const ButtonSendMinorBilling = () => {
  const [hideButton, setHideButton] = useState(false);
  const [loader, setLoader] = useState(false);

  const { mutate } = useUpdateList();

  const handleSendBillings = (e) => {
    setHideButton(true);
    setLoader(true);
    mutate(e, {
      onSuccess: () => {
        Swal.fire({
          customClass: {
            container: "sendMinorBilling",
            title: "sendTitleMinorBilling",
          },
          icon: "success",
          title: "¡Las facturas se han enviado exitosamente!",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      },
      onError: (error) => {
        setLoader(false);
        setHideButton(false);
        if (error.response.status === 504) {
          Swal.fire({
            customClass: {
              container: "sendMinorBilling",
              title: "sendTitleMinorBilling",
            },
            icon: "error",
            title: "!Lo sentimos!",
            text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }          
        if (error.response.status === 500 || error.response.status === 503) {
          Swal.fire({
            customClass: {
              container: "sendMinorBilling",
              title: "sendTitleMinorBilling",
            },
            icon: "error",
            title: "!Ha ocurrido un error!",
            text: "Por favor vuelva a intentarlo más tarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
        if (error.response.status === 404) {
          Swal.fire({
            customClass: {
              container: "sendMinorBilling",
              title: "sendTitleMinorBilling",
            },
            icon: "error",
            title: "!Ha ocurrido un error!",
            text: error.response.data.message,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
        if (error.response.status === 400) {
          Swal.fire({
            customClass: {
              container: "sendMinorBilling",
              title: "sendTitleMinorBilling",
            },
            icon: "error",
            title: "!Ha ocurrido un error!",
            text: error.response.data.message,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
        if (error.response.status >= 0 && error.response.status <= 99) {
          Swal.fire({
            customClass: {
              container: "sendMinorBilling",
              title: "sendTitleMinorBilling",
            },
            icon: "error",
            title: "!Ha ocurrido un problema de conectividad!",
            text: "Por favor vuelva a intentarlo más tarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      },
    });
  };

  return (
    <>
      {loader ? (
        <button className="buttonLoaderPhone" style={{ marginTop: "20px" }}>
          <div className="loader">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            <div className="bar4"></div>
            <div className="bar5"></div>
            <div className="bar6"></div>
            <div className="bar7"></div>
            <div className="bar8"></div>
            <div className="bar9"></div>
            <div className="bar10"></div>
            <div className="bar11"></div>
            <div className="bar12"></div>
          </div>
        </button>
      ) : (
        <button
          disabled={hideButton}
          className="buttonRegister"
          onClick={() => handleSendBillings()}
        >
          <span className="buttonRegister__text">Enviar</span>
          <span className="buttonRegister__textSmall">
            <SendIcon sx={{ color: "white", borderRadius: "50%" }} />
          </span>
          <span className="buttonRegister__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              viewBox="0 0 48 48"
              height="48"
              className="svgRegister"
            >
              <SendIcon sx={{ color: "white" }} />
            </svg>
          </span>
        </button>
      )}
    </>
  );
};
export default ButtonSendMinorBilling;
