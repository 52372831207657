import {useState} from 'react';
import { useQuery } from 'react-query';
import { Grid, Stack, Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import axios from 'axios';
import SearchPointOfSales from './SearchPointOfSales';
import ButtonGoBackSetting from "./ButtonGoBackSetting";
import ButtonRegisterPointOfSale from "./ButtonRegisterPointOfSale";
import handleError from './utilities/HandleError';

const DataTablePointOfSale = ({ searchPointOfSale }) => {
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState(searchPointOfSale);

  const pointOfSaleList = async (page, perPage, search) => {
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/pointOfSale/listPointOfSales",
        params: {
          businessId: localStorage.getItem("businessId"),
          pageNumber: page,
          pageSize: perPage,
          search: search,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const { data } = response;
      return data;
    } catch (error) {
      handleError(error);
      return error.response.message;
    }
  };

  const { isLoading, isError, error, data } = useQuery(
    ["get-pov", page, perPage, search],
    () => pointOfSaleList(page, perPage, search),
    {
      onSuccess: (data) => {
        setTotalRows(data.totalElements);
        setPage(data.pageNumber);
      },
    }
  );

  if (isError) {
    return <h2>{error.message}</h2>;
  }  

const columns = [
  {
    name: "Nro",
    selector: (row, index) => index + 1,
    sortable: true,
    width: "65px",
    id: 1,
    omit: (row, index) => index + 1,
  },
  {
    name: "Tipo punto de venta",
    selector: (row) => row.tipoPuntoVenta,
    sortable: true,
    id: 2,
  },
  {
    name: "Nombre punto de venta",
    selector: (row) => row.nombrePuntoVenta,
    sortable: true,
    id: 3,
  },
  {
    name: "Descripción",
    selector: (row) => row.descripcion,
    id: 4,
  },
];

const dataTable = data?.content.map((pov) => {
  return {
    id: pov.id,
    tipoPuntoVenta: pov.tipoPuntoVenta,
    nombrePuntoVenta: pov.nombrePuntoVenta,
    descripcion: pov.descripcion,
  };
});

const handlePerRowsChange = (newPerPage) => {
  setPerPage(newPerPage);
};

const handlePageChange = (page) => {
  setPage(page);
};

const ExpandedComponent = ({ data }) => {
  return (
    <table
      style={{
        paddingLeft: "60px",
        fontSize: "14px",
        backgroundColor: "whitesmoke",
        with: "100%",
      }}
    >
      <tbody>
        <tr>
          <td style={{ padding: "10px" }}>
            <strong>Tipo punto de venta</strong>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "10px", backgroundColor: "white" }}>
            {data.tipoPuntoVenta}
          </td>
        </tr>
        <tr>
          <td style={{ padding: "10px" }}>
            <strong>Nombre punto de venta</strong>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "10px", backgroundColor: "white" }}>
            {data.nombrePuntoVenta}
          </td>
        </tr>
        <tr>
          <td style={{ padding: "10px" }}>
            <strong>Descripción</strong>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "10px", backgroundColor: "white" }}>
            {data.descripcion}
          </td>
        </tr>
      </tbody>
    </table>
  );
};  

const paginationComponentOptions = {
  rowsPerPageText: "Filas por página:",
  rangeSeparatorText: "de",
};

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "whitesmok",
      fontSize: "13px",
      fontWeight: 600,
      color: "black",
    },
  },
};  

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            Gestión de puntos de venta
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          sx={{
            display: "flex",
          }}
        >
          <ButtonGoBackSetting />
          <ButtonRegisterPointOfSale />
        </Grid>
        <Grid item xs={12} md={6} align="right">
          <SearchPointOfSales search={search} setSearch={setSearch} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={dataTable}
            customStyles={customStyles}
            fixedHeader={true}
            progressPending={isLoading}
            progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
            defaultSortFieldId={1}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            striped
            responsive
            pointerOnHover
            highlightOnHover
            selectableRowsSingle
            noDataComponent={
              <Stack
                height="50vh"
                width="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <h2
                  style={{
                    color: "gray",
                    fontFamily: "Oswald",
                    fontWeight: 200,
                    fontSize: "28px",
                  }}
                >
                  "No se encontró ningún registro"
                </h2>
              </Stack>
            }
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={paginationComponentOptions}
            visibleRowIndex
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default DataTablePointOfSale;
