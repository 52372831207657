import { useMutation, useQueryClient } from "react-query";
import axios from 'axios';

const registerPOV = async (payLoad) => {
    return axios.post(process.env.REACT_APP_API + "/api/v1/billing/pointOfSale/register",payLoad,{
        headers: {
          "content-type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        }}
    )
}

export const useRegisterPOV = () =>{
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: registerPOV,
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ["get-company"]})
      }
    });
}
