import { Grid, Stack, Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import { useListMinor } from "../hooks/useShowMinorBilling";
import { useCount } from "../hooks/useShowCountMinorBilling";
import ButtonGoBackSetting from "./ButtonGoBackSetting";
import ButtonSendMinorBilling from "./ButtonSendMinorBilling";

const DataTableMinorBilling = () => {

  const {
    data: count,
  } = useCount(); 

  const { data, isLoading, isError, error } = useListMinor();


  if(isError){
    return <h2>{error}</h2>
  }


  const columns = [
    {
      name: "Nro",
      selector: (row, index) => index + 1,
      id: 1,
      omit: (row, index) => index + 1,
    },
    {
      name: "Razón social",
      selector: (row) => row.razonSocial,
      id: 2,
    },
    {
      name: "Número de tarjeta",
      selector: (row) => row.numeroTarjeta,
      id: 3,
    },
    {
      name: "Descuento global",
      selector: (row) => row.montoDescuento,
      id: 4,
    },
    {
      name: "Fecha",
      selector: (row) => row.createdDate,
      id: 5,
    },
    {
      name: "Estado",
      selector: (row) => row.status,
      id: 6,
    },
  ];

  const dataTable = data?.map((row) => {
    return {
      id: row.id,
      razonSocial: row.razonSocial,
      numeroTarjeta: row.numeroTarjeta,
      montoGiftCard: row.montoGiftCard,
      montoDescuento: row.montoDescuento,
      createdDate: row.createdDate.substring(0, 10),
      status: "Factura pendiente",
    };
  });

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "whitesmok",
        fontSize: "13px",
        fontWeight: 600,
        color: "black",
      },
    },
  }; 

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            Facturas menores a 5 Bs
          </Typography>
        </Grid>
        <Grid item xs={6} md={6} sx={{ display: "flex" }}>
          <ButtonGoBackSetting />
          {count > 0 ? <ButtonSendMinorBilling /> : ""}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={dataTable}
            customStyles={customStyles}
            fixedHeader={true}
            progressPending={isLoading}
            progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
            defaultSortFieldId={1}
            paginationRowsPerPageOptions={[1000]}
            striped
            responsive
            pointerOnHover
            highlightOnHover
            selectableRowsSingle
            noDataComponent={
              <Stack
                height="50vh"
                width="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <h2
                  style={{
                    color: "gray",
                    fontFamily: "Oswald",
                    fontWeight: 200,
                    fontSize: "28px",
                  }}
                >
                  "No se encontró ningún registro"
                </h2>
              </Stack>
            }
            paginationComponentOptions={paginationComponentOptions}
            visibleRowIndex
            pagination
            paginationServer
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default DataTableMinorBilling;
