import * as Yup from "yup";

const ValidationUser = Yup.object().shape({
  name: Yup.string().required("El campo es requerido"),
  lastName: Yup.string().required("El campo es requerido"),
  username: Yup.string()
    .email("El formato es incorrecto")
    .required("El campo es requerido"),
  type1: Yup.string().required("El campo es requerido"),
  type2: Yup.array().min(1).required(),
  aux: Yup.string().required("El campo es requerido"),
  type3: Yup.string().required("El campo es requerido"),
  type4: Yup.string().required("El campo es requerido"),
});

export default ValidationUser;