import * as Yup from 'yup';

const ValidationActiveUser = Yup.object().shape({
  password: Yup.string()
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .max(20, "La contraseña debe tener como máximo 20 caracteres")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&.])[A-Za-z\d#@$!%*?&.]+$/,
      "La contraseña debe contener al menos:\n *Una letra mayúscula\n* Una letra minúscula, un número y un carácter especial ej. (#@$!%*?&)"
    )
    .required("Este campo es requerido"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
    .required("Este campo es requerido"),
});

export default ValidationActiveUser;
