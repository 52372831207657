import * as Yup from "yup";

const ValidationSearchCustomer = Yup.object().shape({
      type: Yup.string().required("El campo es requerido"),
      documentoSearch: Yup.string()
        .required("El campo es requerido"),
      complementoSearch: Yup.string(),
    })

    export default ValidationSearchCustomer;
