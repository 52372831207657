import { createSlice } from "@reduxjs/toolkit";

const initialStateUser = {
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    isLoading: ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialStateUser,

    reducers:{
        login: (state, action) => {
            //state.auth = action.payload
            const { useName, isLoading } = action.payload;
            state.useName = useName;
            state.isLoading = isLoading;
        },
        logout: (state) => {
            state.isLoading = false
            //state.auth = null
        },
    }
});

export const selectUser = (state) => state.auth.auth

export const {login, logout} = authSlice.actions

export default authSlice.reducer
