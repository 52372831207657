import { useMutation, useQueryClient } from "react-query";

import axios from "axios";
import "../components/ButtonMinorBilling.css";

const sendList = async () => {
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_API + "/api/v1/billingMinor/sendListToSiat",
      params: {
        pointOfSaleId: parseInt(localStorage.getItem("pointOfSaleId")),
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
};

export const useUpdateList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: sendList,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["list-minor"] });
      queryClient.invalidateQueries({ queryKey: ["count"] });
    },
    onError: (error) => {
        return error
    }
  });
};
