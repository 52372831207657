import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import "./PanelUser.css";

const PanelUser = () => {
  return (
    <>
      <div className="cardUser">
        <div className="cardUser1">
          <p
            style={{
              fontWeight: 400,
              fontFamily: "Oswald",
              fontSize: "24px",
              marginTop: "25px",
            }}
          >
            Usuarios
          </p>
          <AccountCircleSharpIcon
            className="iconUser"
            sx={{
              width: "60px",
              height: "60px",
              color: "gray",
              marginTop: "10px",
            }}
          />
          <p className="smallUser">
            Permite el registro, modificación y anulación de usuarios
          </p>
          <div className="go-cornerUser" href="#">
            <div className="go-arrowUser">
              <AccountCircleSharpIcon sx={{ paddingTop: "5px" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PanelUser;
