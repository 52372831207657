import {Box, Paper, Typography, Grid, Stack} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
const PageNotFound = () => {
  const navigation = useNavigate();
  const handleNotFound = () => {
    navigation("/dashboard/statistics/invoice");
  }
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper elevation={12} sx={{ width: "785px", height: "500px" }}>
          <Grid container>
            <Stack direction="row">
              <Box
                sx={{
                  width: "375px",
                  height: "500px",
                  backgroundColor: "#f0f0f0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ margin: "35px" }}>
                  <Typography variant="h1" sx={{ fontWeight: "600" }}>
                    404
                  </Typography>
                  <Typography variant="h5" sx={{ fontWeight: "600" }}>
                    La página que buscas no existe
                  </Typography>
                  <p>
                    Es posible que haya escrito mal la dirección, por favor
                    verifique la dirección
                  </p>
                  <button 
                  style={{backgroundColor: "black", color: "white", width: "300px", padding: "15px", marginTop: "10px"}} 
                    onClick={() => handleNotFound()}
                  >
                    Ir a la página de inicio
                  </button>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "410px",
                  height: "500px",
                  backgroundColor: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    height: "400px",
                  }}
                  src="robot.png"
                  alt="MDN"
                />
              </Box>
            </Stack>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}
export default PageNotFound