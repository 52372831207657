import React from 'react'
import DropDownChangePOS from '../components/DropDownChangePOS'

const ChangePointOfSale = () => {
  return (
    <>
      <DropDownChangePOS/>
    </>
  )
}

export default ChangePointOfSale