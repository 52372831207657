import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import "./PanelSyncUp.css";

const PanelPametricas = () => {
  return (
    <>
      <div className="cardSync">
        <div className="cardSync1">
          <p
            style={{ fontWeight: 400, fontFamily: "Oswald", fontSize: "24px", marginTop: "25px" }}
          >
            Catálogos
          </p>
          <FormatListBulletedIcon
            className="iconSync"
            sx={{
              width: "60px",
              height: "60px",
              color: "gray",
              marginTop: "10px",
            }}
          />
          <p className="smallSync">Permite sincronizar la lista de catálogos</p>
          <div className="go-cornerSync" href="#">
            <div className="go-arrowSync">
              <FormatListBulletedIcon sx={{ paddingTop: "5px" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PanelPametricas;
