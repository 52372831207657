import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import ButtonGoBackSetting from "./ButtonGoBackSetting";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import Swal from "sweetalert2";
import './ButtonLoaderChangePOV.css';
import handleError from './utilities/HandleError';

const DropDownChangePOS = () => {
  const [listPointOfSale, setListPointOfSale] = useState([]);
  const selectInputRef = useRef(null);
  const [nombrePoV, setNombrePoV] = useState();
  const [loader, setLoader] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const validationSchema = Yup.object().shape({
    selectField: Yup.string().required("Debes seleccionar una opción"),
  });
  const handleSelectItem = (e) => {
    formik.setFieldValue("selectField", e.value);
    setNombrePoV(e.label);
  };

  const cuis = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/siat/generateCuis",
        params: {
          pointOfSaleId: localStorage.getItem("pointOfSaleId"),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      getCufd();
    } catch (error) {
      handleError(error);
    }
  };

  const getCufd = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billing/siat/generateCufd",
        params: {
          pointOfSaleId: parseInt(localStorage.getItem("pointOfSaleId")),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message ===
          "CODIGO UNICO DE INICIO DE SISTEMA (CUIS) INVALIDO"
      ) {
        cuis();
      }else{
        handleError(error);
      }
    }
  };

  const getPointOfSales = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/auth/me",
        params: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      setListPointOfSale(
        await response.data.pointOfSaleUser.map((sup) => ({
          label: sup.nombrePuntoVenta,
          value: sup.pointOfSaleId,
        }))
      );
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getPointOfSales();
  }, []);

  const formik = useFormik({
    initialValues: {
      selectField: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoader(true);
      window.localStorage.setItem("pointOfSaleId", values.selectField);
      window.localStorage.setItem("nombrePuntoVenta", nombrePoV);
      await cuis();
      let timerInterval;
      Swal.fire({
        customClass: {
          container: "formEditClient",
          title: "formEditTitleClient",
        },
        title: "Espere por favor...",
        html: "El cambio se efectuará en <b></b> segundos.",
        timer: 3000,
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${(Swal.getTimerLeft() / 1000).toFixed(0)}`;
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          Swal.fire({
            customClass: {
              container: "formEditClient",
              title: "formEditTitleClient",
            },
            icon: "success",
            title: "¡La información ha sido actualizada!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      });
      selectInputRef.current.setValue("");
      formik.resetForm();
      setTimeout(() => {
        window.location.reload(true);
      }, 3000);
      setLoader(false);
    },
  });

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className="titleProduct"
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            Cambio de punto de venta
          </Typography>
        </Grid>
        <Grid item>
          <ButtonGoBackSetting />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center"}}>
            <h1
              className="titleProduct"
              style={{ color: "gray", fontFamily: "Oswald", marginTop: "40px" }}
            >
              {localStorage.getItem("nombrePuntoVenta")}
            </h1>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          height: "20vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: fullScreen ? "100%" : "50%", height: "50px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Select
                    ref={selectInputRef}
                    name="selectField"
                    styles={CustomDropdownStyle}
                    options={listPointOfSale}
                    onChange={(e) => handleSelectItem(e)}
                    onBlur={formik.handleBlur}
                    className={
                      formik.touched.selectField && formik.errors.selectField
                        ? "error"
                        : ""
                    }
                    placeholder="Seleccione un punto de venta"
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    {formik.touched.selectField && formik.errors.selectField
                      ? formik.errors.selectField
                      : null}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                {loader ? (
                  <button
                    className="buttonLoaderChangePOV"
                    style={{ width: "100%", height: "40px" }}
                  >
                    <div className="loader">
                      <div className="bar1"></div>
                      <div className="bar2"></div>
                      <div className="bar3"></div>
                      <div className="bar4"></div>
                      <div className="bar5"></div>
                      <div className="bar6"></div>
                      <div className="bar7"></div>
                      <div className="bar8"></div>
                      <div className="bar9"></div>
                      <div className="bar10"></div>
                      <div className="bar11"></div>
                      <div className="bar12"></div>
                    </div>
                  </button>
                ) : (
                  <button
                    style={{ width: "100%", height: "40px", backgroundColor: "black", color: "white" }}
                    type="submit"
                  >
                    Cambiar
                  </button>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default DropDownChangePOS;
