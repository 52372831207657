
import { useState } from "react";
import DataTable from "react-data-table-component";
import Stack from '@mui/material/Stack';

const DataTableProductServices = ({rows}) => {
 const [totalRows, setTotalRows] = useState(1000);
 const columns = [
   {
     name: "Nro",
     selector: (row, index) => index + 1,
     width: "70px",
     id: 1,
     omit: (row, index) => index + 1,
   },
   {
     name: "Código actividad",
     selector: (row) => row.codigoActividad,
     id: 2,
   },
   {
     name: "Código producto",
     selector: (row) => row.codigoProducto,
     id: 3,
   },
   {
     name: "Descripción producto",
     selector: (row) => row.descripcionProducto,
     grow: 3,
     id: 4,
   },
   {
     name: "Sincronización",
     selector: (row) => row.sincronizacion,
     id: 5,
   },
 ];
   const dataTable = rows?.map((row) => {
     return {
       codigoActividad: row.codigoActividad,
       codigoProducto: row.codigoProducto,
       descripcionProducto: row.descripcionProducto,
       sincronizacion: row.sincronizacion,
     };
   });

 const paginationComponentOptions = {
   rowsPerPageText: "Filas por página:",
   rangeSeparatorText: "de",
 }; 
 
const customStyles = {
  headCells: {
    style: {
      backgroundColor: "whitesmok",
      fontSize: "13px",
      fontWeight: 600,
      color: "black",
    },
  },
};  
  return (
    <>
      <DataTable
        columns={columns}
        data={dataTable}
        customStyles={customStyles}
        fixedHeader={true}
        progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
        defaultSortFieldId={1}
        paginationRowsPerPageOptions={[1000]}
        striped
        responsive
        pointerOnHover
        highlightOnHover
        selectableRowsSingle
        noDataComponent={
          <Stack
            height="50vh"
            width="100%"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
          >
            <h2
              style={{
                color: "gray",
                fontFamily: "Oswald",
                fontWeight: 200,
                fontSize: "28px",
              }}
            >
              "No se encontró ningún registro"
            </h2>
          </Stack>
        }
        paginationComponentOptions={paginationComponentOptions}
        visibleRowIndex
        paginationTotalRows={totalRows}
      />
    </>
  );
}
export default DataTableProductServices