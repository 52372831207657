import React,{ useContext} from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PanelCompany from "./PanelCompany";
import PanelUser from "./PanelUser";
import PanelSyncUp from "./PanelSyncUp";
import PanelMinorBilling from "./PanelMinorBilling";
import PanelSwitchPointOfSale from "./PanelSwitchPointOfSale";
import { RoleContext } from "../context/useRoleContext";
import PanelPametricas from "./PanelParametricas";
import PanelUploadImage from "./PanelUploadImage";

const SystemSetting = () => {

  const navigate = useNavigate();

  const {rol} = useContext(RoleContext)

  const goCompany = () => {
    navigate("/dashboard/company");
  }
  const goUser = () => {
    navigate("/dashboard/user");
  };
  const goSyncAUp = () => {
    navigate("/dashboard/syncup");
  };
  const goParametrics = () => {
    navigate("/dashboard/parametrics");
  };
  const goSendBillingMinor = () => {
    navigate("/dashboard/send_billing_minor");
  };
  const goChangePov = () => {
    navigate("/dashboard/change_pov");
  };
  const goUpload = () => {
    navigate("/dashboard/upload_logo");
  };
  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 400,
                fontFamily: "Oswald",
                marginBottom: "20px",
              }}
            >
              Configuraciones
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {rol === "SUPER_ADMIN" ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            textAlign: "center",
            gap: "10px",
          }}
        >
          <div onClick={(e) => goCompany(e)}>
            <PanelCompany />
          </div>
          <div onClick={(e) => goUser(e)}>
            <PanelUser />
          </div>
          <div onClick={(e) => goSyncAUp(e)}>
            <PanelSyncUp />
          </div>
          <div onClick={(e) => goParametrics(e)}>
            <PanelPametricas />
          </div>
          <div onClick={(e) => goSendBillingMinor(e)}>
            <PanelMinorBilling />
          </div>
          <div onClick={(e) => goChangePov(e)}>
            <PanelSwitchPointOfSale/>
          </div>
          <div onClick={(e) => goUpload(e)}>
            <PanelUploadImage/>
          </div>
        </div>
      ) : rol === "ADMIN" ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            textAlign: "center",
            gap: "10px",
          }}
        >
          <div onClick={(e) => goParametrics(e)}>
            <PanelPametricas />
          </div>
          <div onClick={(e) => goSendBillingMinor(e)}>
            <PanelMinorBilling />
          </div>
          <div onClick={(e) => goChangePov(e)}>
            <PanelSwitchPointOfSale/>
          </div>
          <div onClick={(e) => goUpload(e)}>
            <PanelUploadImage/>
          </div>
        </div>
      ) : rol === "USER" ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            textAlign: "center",
            gap: "10px",
          }}
        >
          <div onClick={(e) => goSendBillingMinor(e)}>
            <PanelMinorBilling />
          </div>
          <div onClick={(e) => goChangePov(e)}>
            <PanelSwitchPointOfSale/>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SystemSetting;
