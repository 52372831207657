import { useQuery } from "react-query";
import axios from "axios";

  const getListMinorBilling = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/billingMinor/list",
        params: {
          pointOfSaleId: localStorage.getItem("pointOfSaleId"),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response);
    }
  };  

export const useListMinor = () => {
    return useQuery(["list-minor"], 
    getListMinorBilling);
}
