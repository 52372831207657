import {useMutation, useQueryClient} from 'react-query';
import axios from 'axios';

const cancelBilling = async (payload) => {
    try {
      const response = await axios({
        method: "delete",
        url: process.env.REACT_APP_API + "/api/v1/billing/cancelBilling",
        params: {
          billingId: parseInt(payload.billingId),
          codigoMotivo: parseInt(payload.codigoMotivo),
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
    } catch (error) {
      throw error;
    }
}
export const useDeleteBilling = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: cancelBilling,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get-billings"] });
      },
    });
}