const CustomDropdownStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "gray" : "green",
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "gray",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
  }),
};

export default CustomDropdownStyle;
