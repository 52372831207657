import {useMutation, useQueryClient} from 'react-query';
import axios from 'axios';

const enableUser = async (id) => {
  try {
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_API + "/api/v1/user/" + id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
  } catch (error) {
    throw error
  }
};

export const useEnableUser = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: enableUser,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['get-users']})
        }
    })
}