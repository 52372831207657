
import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import DataTable from "react-data-table-component";
import ButtonDisablePovUser from "./ButtonDisablePovUser";
import ButtonEnablePovUser from "./ButtonEnablePovUser";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ButtonGoBackUserTable from "./ButtonGoBackUserTable";
import ButtonAddPovUser from "./ButtonAddPovUser";
import { Stack } from "@mui/material";
import handleError from './utilities/HandleError';

const DataTableUserPov = ({ id, username, businessId }) => {
  const getPointOfUser = async (id) => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API + "/api/v1/pointOfSaleUser/get",
        params: {
          userId: id,
          businessId: businessId,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const { data } = response;
      return data;
    } catch (error) {
      handleError(error);
      return error.response.message;
    }
  };

  const { isLoading, isError, error, data } = useQuery(
    ["get-pov-user", id],
    () => getPointOfUser(id),
    {
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const columns = [
    {
      name: "Nro",
      selector: (row, index) => index + 1,
      id: 1,
      width: "70px",
    },
    {
      name: "Nombre del punto de venta",
      selector: (row) => row.nombrePuntoVenta,
      id: 2,
    },
    {
      name: "Inhabilitar",
      button: true,
      cell: (row) =>
        row.status ? (
          <ButtonDisablePovUser
            id={id}
            status={row.status}
            pointOfSaleId={row.pointOfSaleId}
          />
        ) : (
          <ButtonEnablePovUser
            id={id}
            status={row.status}
            pointOfSaleId={row.pointOfSaleId}
          />
        ),
      grow: 1,
    },
  ];

  const dataTable = data?.map((row) => {
    return {
      id: row.id,
      nombrePuntoVenta: row.nombrePuntoVenta,
      pointOfSaleId: row.pointOfSaleId,
      status: row.status,
    };
  });
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "whitesmok",
        fontSize: "13px",
        fontWeight: 600,
        color: "black",
      },
    },
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className="labelAddPov"
            sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
          >
            Puntos de venta asignados al usuario:{" "}
            <span style={{ color: "darkblue" }}>{username}</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
          <ButtonGoBackUserTable />
          <ButtonAddPovUser id={id} businessId={businessId}/>
        </Grid>

        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={dataTable}
            fixedHeader={true}
            customStyles={customStyles}
            progressPending={isLoading}
            progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
            defaultSortFieldId={1}
            striped
            responsive
            pointerOnHover
            highlightOnHover
            noDataComponent={
              <Stack
                height="50vh"
                width="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <h2
                  style={{
                    color: "gray",
                    fontFamily: "Oswald",
                    fontWeight: 200,
                    fontSize: "28px",
                  }}
                >
                  "No se encontró ningún registro"
                </h2>
              </Stack>
            }
            paginationComponentOptions={paginationComponentOptions}
            visibleRowIndex
            pagination
            paginationServer
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default DataTableUserPov