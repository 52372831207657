import React, { useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ValidationLogotipo from "../validation/ValidationLogotipo";
import { useUploadImage } from "../hooks/useUploadImage";
import ButtonGoBackSetting from "./ButtonGoBackSetting";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";
import "./ButtonLoaderChangePOV.css";
import CustomDropdownStyle from "./style/CustomDropdownStyle";
import ValidationLogo from "../validation/ValidationLogo";
import axios from "axios";
import "./FormUploadimage.css";
import handleError from './utilities/HandleError';

const options = [
  { value: "MOSTRAR_LOGO", label: "MOSTRAR LOGO" },
  { value: "OCULTAR_LOGO", label: "OCULTAR LOGO" },
];

const FormUploadimage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const selectRef = useRef(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSelectLogo = (e) => {
    formikRemove.setFieldValue("statusLogo", e.value);
  };

  const formikRemove = useFormik({
    initialValues: {
      statusLogo: "",
    },
    validationSchema: ValidationLogo,
    onSubmit: async (values) => {
      try {
        const response = await axios({
          url:
            process.env.REACT_APP_API +
            "/api/v1/billing/business/showLogo/" +
            values.statusLogo,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
        if (response.status === 200) {
          localStorage.setItem(
            "mostrarLogo",
            values.statusLogo === "MOSTRAR_LOGO" ? true : false
          );
          formikRemove.resetForm();
          selectRef.current.setValue("")
          Swal.fire({
            customClass: {
              container: "formEditClient",
              title: "formEditTitleClient",
            },
            icon: "success",
            title: "¡La información ha sido actualizada!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      } catch (error) {
        handleError(error);
      }
    },
  });

  const { mutate } = useUploadImage();

  const formik = useFormik({
    initialValues: {
      file: "",
    },
    validationSchema: ValidationLogotipo,
    onSubmit: (values) => {
      setLoader(true);
      mutate(values, {
        onSuccess: (data) => {
          localStorage.setItem("fileLogo", data.logoFile);
          let timerInterval;
          Swal.fire({
            customClass: {
              container: "formEditClient",
              title: "formEditTitleClient",
            },
            title: "Espere por favor",
            html: "La imagen se subirá en <b></b> milisegundos.",
            timer: 2000,
            timerProgressBar: true,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getPopup().querySelector("b");
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                customClass: {
                  container: "formEditClient",
                  title: "formEditTitleClient",
                },
                icon: "success",
                title: "¡La información ha sido actualizada!",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            }
          });
          setLoader(false);
          setSelectedFile(null);
        },
        onError: (error) => {
          setLoader(false);
          if (error.response.status === 504) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Lo sentimos!",
              text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }           
          if (error.response.status === 500 || error.response.status === 503) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 422) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.errors.map(
                (element) => element.message
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 415) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.error,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 404) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 403) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 400) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status >= 0 && error.request.status <= 99) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Compruebe su conexión a internet",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });

      formik.resetForm();
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const objectURL = URL.createObjectURL(file);
        setSelectedFile(objectURL);

        formik.setFieldValue("file", file);
      } catch (error) {
        console.error("Error al crear la URL del objeto:", error);
      }
    }
  };

  return (
    <Box>
      <form onSubmit={formikRemove.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              className="titleProduct"
              sx={{ fontSize: "32px", fontWeight: 400, fontFamily: "Oswald" }}
            >
              Logotipo
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <ButtonGoBackSetting />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              marginTop={"20px"}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <FormControl fullWidth>
                <Select
                  fullWidth
                  ref={selectRef}
                  styles={CustomDropdownStyle}
                  options={options}
                  onChange={(e) => handleSelectLogo(e)}
                  placeholder="Seleccione una opción"
                />
                <FormHelperText>
                  <div
                    className="inputErrorForm"
                    style={{ color: "chocolate", fontSize: '14px' }}
                  >
                    {formikRemove.touched.statusLogo && formikRemove.errors.statusLogo}
                  </div>
                </FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={1} sx={{ marginTop: "20px" }}>
            <FormControl fullWidth>
              <button
                className="buttonUploadLogotipo"
                type="submit"
                style={{
                  width: "100px",
                  height: "37px",
                  marginLeft: "10px",
                  marginBottom: "20px",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Enviar
              </button>
            </FormControl>
          </Grid>
        </Grid>
      </form>

      <Grid container>
        <Grid item xs={12}>
          <h1 style={{ textAlign: "center", fontFamily: "Oswald"}}>
            {localStorage.getItem("mostrarLogo").includes(true) ? "": "El logo está oculto"}
          </h1>
          <Box display={"flex"} justifyContent={"center"}>
            {localStorage.getItem("mostrarLogo").includes(true) ? (
              <img
                src={localStorage.getItem("fileLogo")}
                alt="SIN LOGOTIPO"
                width="300px"
                height="auto"
                margin="20px 0px"
                style={{ textAlign: "center" }}
              />
            ) : (
              " "
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <h6
              style={{
                color: "gray",
                fontFamily: "Oswald",
                fontWeight: "400",
                marginTop: "20px",
                marginBottom: "20px",
                textAlign: "center",
                fontSize: "18px",
              }}
            >
              Debe seleccionar una imagen en formato <b>PNG</b> y no debe
              contar con margenes muy amplios
            </h6>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: fullScreen ? "100%" : "50%", height: "200px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    inputProps={{
                      type: "file",
                      name: "file",
                      accept: ".png",
                    }}
                    onChange={(e) =>
                      formik.setFieldValue("file", e.target.files[0])
                    }
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  >
                    {formik.touched.file && formik.errors.file}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                {loader ? (
                  <button
                    className="buttonLoaderChangePOV"
                    style={{ width: "100%", height: "40px" }}
                  >
                    <div className="loader">
                      <div className="bar1"></div>
                      <div className="bar2"></div>
                      <div className="bar3"></div>
                      <div className="bar4"></div>
                      <div className="bar5"></div>
                      <div className="bar6"></div>
                      <div className="bar7"></div>
                      <div className="bar8"></div>
                      <div className="bar9"></div>
                      <div className="bar10"></div>
                      <div className="bar11"></div>
                      <div className="bar12"></div>
                    </div>
                  </button>
                ) : (
                  <button
                    style={{
                      width: "100%",
                      height: "40px",
                      backgroundColor: "black",
                      color: "white",
                    }}
                    type="submit"
                  >
                    Subir
                  </button>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default FormUploadimage;
