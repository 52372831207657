import { useState } from "react";
import Box from "@mui/material/Box";
import DataTablePointOfSale from "../components/DataTablePointOfSale";

const PointOfSale = () => {
  const [search, setSearch] = useState("");
  return (
    <Box>
        <DataTablePointOfSale searchPointOfSale={search} />
    </Box>
  );
};
export default PointOfSale;
