import DataTable from "react-data-table-component";
import Stack from "@mui/material/Stack";

const DataTableActivities = ({ rows }) => { 
  const columns = [
    {
      name: "Nro",
      selector: (row, index) => index + 1,
      id: 1,
      omit: (row, index) => index + 1,
    },
    {
      name: "Código CAEB",
      selector: (row) => row.codigoCaeb,
      id: 2,
    },
    {
      name: "Descripción",
      selector: (row) => row.descripcion,
      grow: 5,
      id: 3,
    },
    {
      name: "Sincronización",
      selector: (row) => row.sincronizacion,
      id: 4,
    },
  ];

  const dataTable = rows?.map((row) => {
    return {
      codigoCaeb: row.codigoCaeb,
      descripcion: row.descripcion,
      tipoActividad: row.tipoActividad,
      sincronizacion: row.sincronizacion,
    };
  });

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "whitesmok",
      fontSize: "13px",
      fontWeight: 600,
      color: "black",
    },
  },
};  
  return (
    <>
      <DataTable
        columns={columns}
        data={dataTable}
        fixedHeader={true}
        customStyles={customStyles}
        progressComponent={<h2 style={{ color: "gray" }}>Cargando...</h2>}
        defaultSortFieldId={1}
        paginationRowsPerPageOptions={[1000]}
        striped
        responsive
        pointerOnHover
        highlightOnHover
        selectableRowsSingle
        noDataComponent={
          <Stack
            height="50vh"
            width="100%"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
          >
            <h2
              style={{
                color: "gray",
                fontFamily: "Oswald",
                fontWeight: 200,
                fontSize: "28px",
              }}
            >
              "No se encontró ningún registro"
            </h2>
          </Stack>
        }
        paginationComponentOptions={paginationComponentOptions}
        visibleRowIndex
        paginationTotalRows={10000}
      />
    </>
  );
};
export default DataTableActivities;
