import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { useEnablePovUser } from "../hooks/useEnablePovUser";
import "./ButtonDisablePov.css";
import "./ButtonEnableTable.css";

const ButtonEnablePovUser = ({ id, pointOfSaleId, status }) => {
  const { mutate } = useEnablePovUser();

  const handleEnablePov = (id, pointOfSaleId, status) => {
    mutate(
      { id, pointOfSaleId },
      {
        onSuccess: () => {
          Swal.fire({
            customClass: {
              container: "formDisablePov",
              title: "formTitleDisablePov",
            },
            icon: "success",
            title: "¡El punto de venta ha sido habilitado!",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
          });
        },
        onError: (error) => {
          if (error.response.status === 504) {
            Swal.fire({
              customClass: {
                container: "formDisablePov",
                title: "formTitleDisablePov",
              },
              icon: "error",
              title: "!Lo sentimos!",
              text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }          
        if (error.response.status === 500 && error.response.status === 503) {
          Swal.fire({
            customClass: {
              container: "formDisablePov",
              title: "formTitleDisablePov",
            },
            icon: "error",
            title: "!Ha ocurrido un error!",
            text: "Por favor vuelva a intentarlo más tarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
        if (error.response.status >= 400 && error.response.status <= 499) {
          Swal.fire({
            customClass: {
              container: "formDisablePov",
              title: "formTitleDisablePov",
            },
            icon: "error",
            title: "!Ha ocurrido un error!",
            text: "Compruebe si los campos han sido llenados correctamente",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
        if (error.response.status >= 0 && error.response.status <= 99) {
          Swal.fire({
            customClass: {
              container: "formDisablePov",
              title: "formTitleDisablePov",
            },
            icon: "error",
            title: "!Ha ocurrido un problema de conectividad!",
            text: "Por favor vuelva a intentarlo más tarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }          
        }
      }
    );
  };
  return (
    <div>
      <button
        style={{width: '100px'}}
        className="buttonEnableTable"
        type="button"
        onClick={() => handleEnablePov(id, pointOfSaleId, status)}
      >
        <span className="buttonEnableTable__text">Habilitar</span>
        <span className="buttonEnableTable__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgEnableTable"
          >
            <DeleteIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
    </div>
  );
};
export default ButtonEnablePovUser;
