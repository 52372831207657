import ValidationEditComputarize from "../validation/ValidationEditComputarize";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEditComputarize } from "../hooks/useEditComputarize";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState, useEffect, useRef } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Radio from "@mui/material/Radio";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "react-select";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import axios from "axios";
import "./ButtonEditCompany.css";
import "./ButtonEnableTable.css";
import CustomDropdownEdit from "./style/CustomDropdownEdit";
import dayjs from 'dayjs';
import handleError from './utilities/HandleError';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const deperamentosBolivia = [
  { value: "LA_PAZ", label: "LA PAZ" },
  { value: "COCHABAMBA", label: "COCHABAMBA" },
  { value: "SANTA_CRUZ", label: "SANTA CRUZ" },
  { value: "ORURO", label: "ORURO" },
  { value: "POTOSI", label: "POTOSI" },
  { value: "PANDO", label: "PANDO" },
  { value: "CHUQUISACA", label: "CHUQUISACA" },
  { value: "TARIJA", label: "TARIJA" },
  { value: "BENI", label: "BENI" },
];

const ButtonEditComputarize = ({ id, disableBtn }) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [loader, setLoader] = useState(false);
  const [auxCompany, setAuxCompany] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setAuxCompany("")
    formikEditCompany.resetForm();
    setOpen(false);
  };

  const handleChange = (event) => {
    formikEditCompany.setFieldValue("uniPersonal",event.target.value === "true" ? true : false);
  };

  const handleChangeDepartamento = (e) =>{
    formikEditCompany.setFieldValue('departamento',e.value)
  }

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const handleButtonEdit = (id) => {
    getCompany(id);
    setOpen(true);
  };

  const getCompany = async (id) => {
    try{
      const response = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}/api/v1/billing/business/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      const {
        nombreSistema,
        razonSocial,
        phoneNumber: telefono,
        dependencia,
        email,
        uniPersonal,
        fechaInicio,
        fechaFin,
      } = response.data;

      setAuxCompany(response.data.razonSocial)

      formikEditCompany.setValues({
        nombreSistema,
        razonSocial,
        telefono,
        departamento: dependencia === "SANTA CRUZ" ? "SANTA_CRUZ" : dependencia === "LA PAZ" ? "LA_PAZ" : dependencia,
        email,
        uniPersonal,
        fechaInicio: dayjs(fechaInicio),
        fechaFin: dayjs(fechaFin),
      });
    } catch (error) {
      handleError(error);
    }
  };

  const { mutate } = useEditComputarize();

  const formikEditCompany = useFormik({
    initialValues: {
      nombreSistema: "",
      razonSocial: "",
      departamento: "",
      telefono: "",
      uniPersonal: true,
      siatToken: "",
      email: "",
      fechaInicio: null,
      fechaFin: null 
    },
    validationSchema: ValidationEditComputarize,
    onSubmit: async (values) => {
      setLoader(true);
      values.id = id;
      mutate(values, {
        onSuccess: () => {
          handleClose();
          formikEditCompany.resetForm();
          Swal.fire({
            customClass: {
              container: "formEditCompany",
              title: "formTitleEditCompany",
            },
            icon: "success",
            title: "¡La información se ha actualizado!",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          setLoader(false);
          setAuxCompany("")
        },
        onError: (error) => {
          setLoader(false);
          if (error.response.status === 504) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Lo sentimos!",
              text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }            
          if (error.response.status === 500 || error.response.status === 503) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Por favor comuniquese con el administrador",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 422) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.errors.map(
                (element) => element.message
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 415) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.error,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 404) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 403) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status === 400) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: error.response.data.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          if (error.request.status >= 0 && error.request.status <= 99) {
            Swal.fire({
              customClass: {
                container: "formEditCompany",
                title: "formTitleEditCompany",
              },
              icon: "error",
              title: "!Ha ocurrido un error!",
              text: "Compruebe su conexión a internet",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        },
      });
    },
  });

  return (
    <>
      {!disableBtn ? (
        <button
          disabled={disableBtn}
          className="buttonEdit"
          type="button"
          onClick={() => handleButtonEdit(id)}
        >
          <span className="buttonEdit__text">Editar</span>
          <span className="buttonEdit__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              viewBox="0 0 48 48"
              height="48"
              className="svgEdit"
            >
              <EditIcon sx={{ color: "white" }} />
            </svg>
          </span>
        </button>
      ) : (
        <button
          disabled={disableBtn}
          className="buttonEnableTable"
          type="button"
          onClick={() => handleButtonEdit(id)}
        >
          <span className="buttonEnableTable__text">Editar</span>
        </button>
      )}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "#D1B000", margin: "20px 23px 15px 23px" }}
        >
          <Typography
            className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Editar empresa
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikEditCompany.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ marginTop: "5px" }}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Nombre de la empresa
                  </Typography>
                  <TextField
                    name="nombreSistema"
                    size="small"
                    value={formikEditCompany.values.nombreSistema}
                    onChange={formikEditCompany.handleChange}
                    onBlur={formikEditCompany.handleBlur}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikEditCompany.touched.nombreSistema &&
                      formikEditCompany.errors.nombreSistema}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Nombre del representante
                  </Typography>
                  <TextField
                    name="razonSocial"
                    size="small"
                    value={formikEditCompany.values.razonSocial}
                    onChange={formikEditCompany.handleChange}
                    onBlur={formikEditCompany.handleBlur}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikEditCompany.touched.razonSocial &&
                      formikEditCompany.errors.razonSocial}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Fecha de inicio del contrato
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name='fechaInicio'
                        format="DD/MM/YYYY"
                        views={['day','month','year']}
                        value={formikEditCompany.values.fechaInicio}
                        onChange={(date) => formikEditCompany.setFieldValue('fechaInicio', date)}                        
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                        />
                  </LocalizationProvider>
                  <FormHelperText sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}>
                    {formikEditCompany.touched.fechaInicio &&
                      formikEditCompany.errors.fechaInicio}                      
                  </FormHelperText>
                </FormControl>                      
              </Grid>
              <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Fecha final del contrato
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name='fechaFin'
                        format="DD/MM/YYYY"
                        views={['day','month','year']}
                        value={formikEditCompany.values.fechaFin}
                        onChange={(date) => formikEditCompany.setFieldValue('fechaFin', date)}                        
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                        />
                  </LocalizationProvider>
                  <FormHelperText sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}>
                    {formikEditCompany.touched.fechaFin &&
                      formikEditCompany.errors.fechaFin}                      
                  </FormHelperText>
                </FormControl>                      
              </Grid>              
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Correo electrónico
                  </Typography>
                  <TextField
                    name="email"
                    size="small"
                    value={formikEditCompany.values.email}
                    onChange={formikEditCompany.handleChange}
                    onBlur={formikEditCompany.handleBlur}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikEditCompany.touched.email &&
                      formikEditCompany.errors.email}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">Teléfono</Typography>
                  <TextField
                    name="telefono"
                    size="small"
                    value={formikEditCompany.values.telefono}
                    onChange={formikEditCompany.handleChange}
                    onBlur={formikEditCompany.handleBlur}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikEditCompany.touched.telefono &&
                      formikEditCompany.errors.telefono}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">
                    Departamento
                  </Typography>
                  <Select 
                      name="departamento"
                      size="small"
                      styles={CustomDropdownEdit}
                      options={deperamentosBolivia}
                      value={deperamentosBolivia.filter(
                        (element) => 
                          element.value === 
                          formikEditCompany.values.departamento)}
                      onChange={(e)=>{
                        handleChangeDepartamento(e)
                      }}
                      onBlur={formikEditCompany.handleBlur}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikEditCompany.touched.departamento &&
                      formikEditCompany.errors.departamento}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Empresa unipersonal
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="uniPersonal"
                    value={formikEditCompany.values.uniPersonal}
                    onChange={(e) => handleChange(e)}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Typography className="labelFormClient">Token</Typography>
                  <TextField
                    name="siatToken"
                    size="small"
                    value={formikEditCompany.values.siatToken}
                    onChange={formikEditCompany.handleChange}
                    onBlur={formikEditCompany.handleBlur}
                  />
                  <FormHelperText
                    sx={{
                      color: "chocolate",
                      fontSize: "14px",
                    }}
                  >
                    {formikEditCompany.touched.siatToken &&
                      formikEditCompany.errors.siatToken}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>
              ) : (
                auxCompany ? (
                <button
                  className="buttonRegisterData"
                  type="submit"
                  style={{ border: "1px solid #D1B000" }}
                >
                  <span className="buttonEdit">Actualizar</span>
                </button>
                ) : ("")
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonEditComputarize;
