import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Box, Divider, Grid, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import DataTable from "react-data-table-component";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import Swal from "sweetalert2";
import React from "react";
import axios from "axios";
import handleError from "./utilities/HandleError";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonViewBranch = ({ id, disableBtn }) => {
  const [open, setOpen] = React.useState(false);
  const [branches, setBranches] = React.useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpen = (ide) => {
    getAllBranch(ide);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      name: "sucursalId",
      selector: (row) => row.sucursalId,
      id: 1,
      omit: (row) => row.id,
    },
    {
      name: "Código de la sucursal",
      selector: (row) => row.codigoSucursal,
      id: 2,
    },
    {
      name: "Nombre de la sucursal",
      selector: (row) => row.nombreSucursal,
      id: 3,
    },
  ];
  const [loader, setLoader] = React.useState(false)
  const getAllBranch = async (ide) => {
    setLoader(true);
    try {
      const response = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API +
          "/api/v1/billing/pointOfSale/listBranch/" +
          ide,
        headers: {
          "content-type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      if(response.status === 200) {
        setLoader(false)
      }
      setBranches(response.data);
    } catch (error) {
      setLoader(false)
      handleError(error);
    }
  };

  const dataTable = branches?.map((element) => {
    return {
      sucursalId: element.sucursalId,
      codigoSucursal: element.codigoSucursal,
      nombreSucursal: element.nombreSucursal,
    };
  });

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "whitesmok",
        fontSize: "13px",
        fontWeight: 600,
        color: "black",
        width: "200px",
      },
    },
  };
  return (
    <div>
      {!disableBtn ? (
        <button
          disabled={disableBtn}
          className="buttonRegisterBranch"
          type="button"
          style={{ width: "100px" }}
          onClick={() => handleOpen(id)}
        >
          <span className="buttonRegisterBranch__text">Sucursales</span>
          <span className="buttonRegisterBranch__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              viewBox="0 0 48 48"
              height="48"
              className="svgRegisterBranch"
            >
              <RemoveRedEyeIcon sx={{ color: "white" }} />
            </svg>
          </span>
        </button>
      ) : (
        <button
          disabled={disableBtn}
          className="buttonEnableTable"
          type="button"
          style={{ width: "100px" }}
          onClick={() => handleOpen(id)}
        >
          <span className="buttonEnableTable__text">Sucursales</span>
        </button>
      )}
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <h1 style={{ fontFamily: "Oswald", color: 'gray' }}>Lista de sucursales</h1>
            </Grid>
            <Grid item xs={12}>
              <DataTable
                columns={columns}
                data={dataTable}
                customStyles={customStyles}
                progressComponent={<h2 style={{ color: "gray"}}>Cargando...</h2>}
                progressPending={loader}
                noDataComponent={
                  <Stack
                    height="50vh"
                    width="100%"
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <h2
                      style={{
                        color: "gray",
                        fontFamily: "Oswald",
                        fontWeight: 200,
                        fontSize: "28px",
                      }}
                    >
                      "No se encontró ningún registro"
                    </h2>
                  </Stack>
                }
              />
            </Grid>
            <Grid item xs={12}>
                
            </Grid>
          </Grid>
          <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
            </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ButtonViewBranch;
