import './SearchClient.css';

const SearchClient = ({search = '', setSearch}) => {
  return (
    <input
      className="searchClient"
      placeholder='Buscar'
      type="search"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      style={{ backgroundColor: "whitesmoke" }}
    />
  );
}
export default SearchClient