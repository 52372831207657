import { Box, Typography } from "@mui/material";
import '../page/Home.css';
const WelcomeMessage = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "90%",
          flexDirection: "column",
        }}
      >
        <Typography
          varaint="h1"
          className="homeMessage"
          sx={{ fontFamily: "Oswald", fontSize: "4vw", textAlign: "center" }}
        >
          Bienvenido al sistema de facturación en linea{" "}
        </Typography>
        <Typography
          varaint="h1"
          className="homeMessage"
          sx={{
            fontFamily: "Oswald",
            fontSize: "4vw",
            color: "darkblue",
            fontWeight: 600,
          }}
        >
          QhatuBit
        </Typography>
      </Box>
    </>
  );
};
export default WelcomeMessage;
