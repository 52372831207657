import React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react';
import DataTableUser from '../components/DataTableUser';

const User = () => {
    const [search, setSearch] = useState("");
  return (
    <Box>
        <DataTableUser searchUser={search} />
    </Box>
  );
}
export default User;