import ValidationRegisterBranch from "../validation/ValidationRegisterBranch";
import { useRegisterBranch } from "../hooks/useRegisterSucursal";
import FormHelperText from "@mui/material/FormHelperText";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React, { useState, forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import "./ButtonRegisterBranch.css";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonRegisterBranch = ({ id, disableBtn }) => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    formikRegisterBranch.resetForm();
    setOpen(false);
  };
  const {mutate} = useRegisterBranch();

  const formikRegisterBranch = useFormik({
    initialValues: {
      businessId: 0,
      branch: "",
      telefono: "",
    },
    validationSchema: ValidationRegisterBranch,
    onSubmit: async (values) => {
      setLoader(true)
      values.businessId = id
      mutate(values, {
      onSuccess: () => {
        handleClose();
        Swal.fire({
          customClass: {
            container: "formRegisterClient",
            title: "formTitleRegisterClient",
          },
          icon: "success",
          title: "¡La sucursal ha sido registrada!",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        setLoader(false)
      },
      onError: (error) => {
        setLoader(false)
        if (error.response.status === 504) {
          Swal.fire({
            customClass: {
              container: "formRegisterClient",
              title: "formTitleRegisterClient",
            },
            icon: "error",
            title: "!Lo sentimos!",
            text: "Los servidores de impuestos se encuentran saturados. Por favor, vuelva a intentarlo",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }         
        if (error.response.status === 500 || error.response.status === 503) {
          Swal.fire({
            customClass: {
              container: "formRegisterClient",
              title: "formTitleRegisterClient",
            },
            icon: "error",
            title: "!Ha ocurrido un error!",
            text: "Por favor vuelva a intentarlo más tarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }  
        if (error.request.status === 422) {
          Swal.fire({
            customClass: {
              container: "formRegisterClient",
              title: "formTitleRegisterClient",
            },
            icon: "error",
            title: "!Ha ocurrido un error!",
            text: error.response.data.errors.map(
              (element) => element.message
            ),
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
        if (error.request.status === 404) {
          Swal.fire({
            customClass: {
              container: "formRegisterClient",
              title: "formTitleRegisterClient",
            },
            icon: "error",
            title: "!Ha ocurrido un error!",
            text: error.response.data.message,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        } 
        if (error.request.status === 400) {
          Swal.fire({
            customClass: {
              container: "formRegisterClient",
              title: "formTitleRegisterClient",
            },
            icon: "error",
            title: "!Ha ocurrido un error!",
            text: error.response.data.message,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }                     
        if (error.request.status >= 0 && error.request.status <= 99) {
          Swal.fire({
            customClass: {
              container: "formRegisterClient",
              title: "formTitleRegisterClient",
            },
            icon: "error",
            title: "!Ha ocurrido un error!",
            text: "Compruebe su conexión a internet",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        } 
      },
    });
},
});

  return (
    <>
      {!disableBtn ? (
        <button
          disabled={disableBtn}
          className="buttonRegisterBranch"
          type="button"
          style={{ width: "100px" }}
          onClick={() => handleOpen()}
        >
          <span className="buttonRegisterBranch__text">Sucursal</span>
          <span className="buttonRegisterBranch__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              viewBox="0 0 48 48"
              height="48"
              className="svgRegisterBranch"
            >
              <AddIcon sx={{ color: "white" }} />
            </svg>
          </span>
        </button>
      ) : (
        <button
          disabled={disableBtn}
          className="buttonEnableTable"
          type="button"
          style={{ width: "100px" }}
          onClick={() => handleOpen()}
        >
          <span className="buttonEnableTable__text">Sucursal</span>
        </button>
      )}

      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ backgroundColor: 'green', margin: "20px 23px 15px 23px"}}>
          <Typography
          className="titleProduct"
            sx={{
              color: "white",
              fontFamily: "Oswald",
              fontSize: "30px",
            }}
          >
            Registrar sucursal
          </Typography>
        </DialogTitle>
        <DialogContent className="contentDialog">
          <form onSubmit={formikRegisterBranch.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ marginTop: "5px" }}>
                <FormControl fullWidth>
                <Typography className="labelFormClient">
                Sucursal
                    </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="branch"
                    onBlur={formikRegisterBranch.handleBlur}
                    onChange={formikRegisterBranch.handleChange}
                    value={formikRegisterBranch.values.branch}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterBranch.touched.branch &&
                      formikRegisterBranch.errors.branch}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                <Typography className="labelFormClient">
                Teléfono
                    </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="telefono"
                    onBlur={formikRegisterBranch.handleBlur}
                    onChange={formikRegisterBranch.handleChange}
                    value={formikRegisterBranch.values.telefono}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        border: "1px solid white",
                        borderColor: "white",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "chocolate", fontSize: "14px" }}>
                    {formikRegisterBranch.touched.telefono &&
                      formikRegisterBranch.errors.telefono}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="buttonCloseX"
                type="button"
                onClick={() => handleClose()}
                style={{ marginRight: "10px" }}
              >
                <span className="buttonCloseX__text">Cerrar</span>
              </button>
              {loader ? (
                <button
                  className="buttonLoaderChangePOV"
                  style={{ width: "150px", height: "40px" }}
                >
                  <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                </button>                
              ) : (
                <button className="buttonRegisterData" type="submit">
                  <span className="buttonRegisterData">Registrar</span>
                </button>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ButtonRegisterBranch;
