import './SearchProduct.css';

const SearchProduct = ({ search, setSearch }) => {
  return (
    <input
      className="searchProduct"
      placeholder="Buscar"
      type="search"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      style={{ backgroundColor: "whitesmoke" }}
    />
  );
};
export default SearchProduct;
