import React from "react";
import StoreIcon from "@mui/icons-material/Store";
import './ButtonRegisterPov.css';


const ButtonViewPointOfSale = () => {

  return (
    <button className="buttonRegisterPov" type="button">
      <span className="buttonRegisterPov__text">Asignar</span>
      <span className="buttonRegisterPov__icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          viewBox="0 0 48 48"
          height="48"
          className="svgRegisterPov"
        >
          <StoreIcon sx={{ color: "white" }} />
        </svg>
      </span>
    </button>
  );
};
export default ButtonViewPointOfSale;
