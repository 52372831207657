import React from 'react'
import FormUploadimage from '../components/FormUploadimage'

const LogoTipo = () => {
  return (
    <>
        <FormUploadimage/>
    </>
  )
}

export default LogoTipo