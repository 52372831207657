import { Box } from "@mui/material";
import { Outlet, Navigate } from "react-router-dom";
import SidebarComponent from "../components/SidebarComponent";
import CssBaseline from "@mui/material/CssBaseline";
import './Layout.css';
import { RoleContext } from "../context/useRoleContext";


const Layout = () => {
  const rol = localStorage.getItem('role'); 
  return (
    <div>
      <CssBaseline />
      {window.localStorage.getItem("isLoggedIn") ? (
        <RoleContext.Provider value={{rol}}> 

        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box>
            <SidebarComponent />
          </Box>
          <Box className='principal' component="main" sx={{ flexGrow: 1, pt: 10, pl: 3, pr: 3 }}>
            <Outlet />
          </Box>
        </Box>
        </RoleContext.Provider> 
      ) : (
        <Navigate to={"/"} />
      )}
    </div>
  );
};
export default Layout